import React from 'react';
import PropTypes from 'prop-types';
import SlaList from './sla-list';
import { usePostData } from '../../hooks/use-post-data.hook';
import { AppUrls } from '../../core/route.url';
import { LinearProgress } from '@mui/material';
import { usePostData2 } from '../../hooks/use-post-data-2.hook';
import { useLoadData } from '../../hooks/use-load-data.hook';

const SlaComponent = () => {
    //Assign SLA List API
    const { submitData: submitDataForSLA, response: responseSLA } = usePostData(
        AppUrls.GetAllSLA,
        'POST',
    );

    // const { response: statusResponse } = usePostData2(AppUrls.ClaimProfileStatusBar, {
    //     pageName: 'Task',
    //     ClaimTaskStatusCode: 'CLAIM_TASK',
    // });

    const { result: statusResponse } = useLoadData(
        AppUrls.GetClaimTaskStatuses + '?taskTypeCode=CLAIM_TASK',
    );

    const [isSLALoad, setSLAIsLoad] = React.useState(true);

    //Call SLA API
    React.useEffect(() => {
        if (isSLALoad == true) {
            setSLAIsLoad(false);
            submitDataForSLA({
                PageNo: 1,
                PageSize: 50,
                SortDir: 'Ascending',
                SortExp: '',
                StartDate: null,
                EndDate: null,
                SLAType: '',
            });
        }
    }, [isSLALoad]);

    return (
        <div className="main-content">
            {responseSLA.result != undefined ? (
                <>
                    <SlaList
                        SLAList={
                            responseSLA.result.Data !== undefined &&
                            responseSLA.result.Data !== null
                                ? responseSLA.result.Data
                                : []
                        }
                        setSLAIsLoad={setSLAIsLoad}
                        statusResponse={statusResponse}
                    />
                </>
            ) : (
                <>
                    <LinearProgress />
                </>
            )}
        </div>
    );
};

export default SlaComponent;

SlaComponent.propTypes = {};
