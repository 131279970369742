import axios from 'axios';

const CATALYST_API_URL = process.env.REACT_APP_BASE_API_URL;
// console.log(33, process.env.REACT_APP_BASE_API_URL);
export const getData = async (route, name, opts, fetchParams) => {
    //   const authState = JSON.parse(localStorage.getItem("AUTH_STATE")); //Need to move this to common const
    //   const requestOptions = {
    //     method: "GET",
    //     headers: {
    //       "Content-Type": "application/json",
    //       ...(authState && { Authorization: `Bearer ${authState}` }),
    //     },
    //   };

    //   const result = await fetch(
    //     `${WINDOWS_DOORS_API_URL}/api/${route}`,
    //     requestOptions
    //   );

    const accessToken_ = JSON.parse(localStorage.getItem('AUTH_STATE'));
    if (accessToken_?.startsWith(`"`)) {
        accessToken_ = accessToken_.substring(1);
        accessToken_ = accessToken_.slice(0, -1);
    }

    let headers = {
        Authorization: 'bearer ' + accessToken_,
        'Content-Type': 'application/json',

        islogin: true,
    };

    if (opts && opts.headers) {
        headers = Object.assign({}, headers, opts.headers);
    }

    return axios({
        method: 'get',
        url: CATALYST_API_URL + route,
        data: {},
        headers,
        ...fetchParams,
    });
    //   return {
    //     name,
    //     status: result.status,
    //     data: await result.json(),
    //   };
};

// export const postData = async (route, method, contentType, data) => {
//   const authState = JSON.parse(localStorage.getItem("AUTH_STATE"));
//   const submitData =
//     contentType === "application/json" ? JSON.stringify(data) : data;
//   const skipContentType = contentType === "multipart/form-data";
//   const requestOptions = {
//     method: method,
//     headers: {
//       ...(!skipContentType && { "Content-Type": contentType }),
//       ...(authState && { Authorization: `Bearer ${authState}` }),
//     },
//     body: submitData,
//   };
//   const result = await fetch(`${route}`, requestOptions);
//   return {
//     status: result.status,
//     data: await result.json(),
//   };
// };

export const postData = async (path, data, method, contentType, opts) => {
    const accessToken_ = JSON.parse(localStorage.getItem('AUTH_STATE'));
    if (accessToken_?.startsWith(`"`)) {
        accessToken_ = accessToken_.substring(1);
        accessToken_ = accessToken_.slice(0, -1);
    }

    let headers = {
        Authorization: 'bearer ' + accessToken_,
        'Content-Type': contentType === undefined ? 'application/json' : contentType,

        islogin: true,
    };

    if (opts && opts.headers) {
        headers = Object.assign({}, headers, opts.headers);
    }

    return axios({
        method: method || 'post',
        url: CATALYST_API_URL + path,
        data: data || {},
        headers,
    });
};

export const postDataForOneLogin = async (path, data, method, contentType, opts, token) => {
    const accessToken_ = JSON.parse(token);
    if (accessToken_?.startsWith(`"`)) {
        accessToken_ = accessToken_.substring(1);
        accessToken_ = accessToken_.slice(0, -1);
    }

    let headers = {
        _jwttoken: accessToken_,
        'Content-Type': contentType === undefined ? 'application/json' : contentType,

        islogin: true,
    };

    if (opts && opts.headers) {
        headers = Object.assign({}, headers, opts.headers);
    }

    return axios({
        method: method || 'post',
        url: CATALYST_API_URL + path,
        data: data || {},
        headers,
    });
};
