import {
    Scheduler,
    TimelineView,
    DayView,
    WeekView,
    MonthView,
    AgendaView,
} from '@progress/kendo-react-scheduler';
const Claim_Inspector_calendar = ({ inspectorDetails }) => {
    const modelFields = {
        id: 'ScheduleId',
        title: 'ScheduleType',
        start: 'ScheduleStartTime',
        end: 'ScheduleEndTime',
    };
    return (
        <Scheduler
            className="inspectorCalender"
            style={{ fontSize: '12px' }}
            data={inspectorDetails}
            modelFields={modelFields}
        >
            <MonthView />
            <WeekView />
            <DayView />
           
            <AgendaView />
        </Scheduler>
    );
 };

export default Claim_Inspector_calendar;
