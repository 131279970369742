import { useContext } from "react";
import { AppContext } from "../core/app.context";

export const useCurrentUser = () => {
  const context = useContext(AppContext);
  if (context && context.state && context.state.currentUser)
    return context.state.currentUser;
  else {
    throw new Error("app context not available");
  }
};
