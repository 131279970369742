import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { process } from '@progress/kendo-data-query';
import { Grid, GridColumn as Column, GridToolbar } from '@progress/kendo-react-grid';

import { useNavigate } from 'react-router-dom';
import { Button, Modal, Box, Breadcrumbs, Typography } from '@mui/material';
import AssignmentTypesForm from './assignmentTypeForm';
import editIcon from '../../images/pencil.svg';
import deleteIcon from '../../images/trash.svg';
import DeleteModal from '../../components/delete-modal';
import { ColumnMenu } from '../../components/kendoGrid/customColumnMenu';
import TextField from '@mui/material/TextField';
import { Link } from 'react-router-dom';
const AssignmentListing = (props) => {
    const history = useNavigate();
    const createDataState = (dataState) => {
        return {
            result: process(props.filterList.slice(0), dataState),
            dataState: dataState,
        };
    };

    const [isOpen, setIsOpen] = React.useState(false);
    const [propertyValues, setPropertyValues] = React.useState({ idValue: 0, name: '' });
    const [isDeleteOpen, setIsDeleteOpen] = React.useState(false);
    const [groupOfGrid, setGroupOfGrid] = React.useState([]);
    const [initialState, setInitialState] = React.useState(
        createDataState({
            take: 100,
            skip: 0,
        }),
    );
    const [result, setResult] = React.useState(initialState.result);

    React.useEffect(() => {
        setInitialState(
            createDataState({
                take: 100,
                skip: 0,
                group: groupOfGrid,
            }),
        );
    }, [props.filterList]);

    React.useEffect(() => {
        setResult(initialState.result);
    }, [initialState]);

    const [dataState, setDataState] = React.useState(initialState.dataState);

    const dataStateChange = (event) => {
        let updatedState = createDataState(event.dataState);
        setGroupOfGrid(event.dataState.group);
        setResult(updatedState.result);
        setDataState(updatedState.dataState);
    };

    const handleDeleteClick = (assignmentTypeId, name) => {
        // setIdValue(assignmentTypeId);
        setPropertyValues({ idValue: assignmentTypeId, name: name });
        // props.onHandleDeleteClick(assignmentTypeId);
        setIsDeleteOpen(true);
    };

    const editCell = (props) => {
        if (props.rowType === 'groupHeader') {
            return null;
        }
        return (
            <>
                <td className="k-command-cell">
                    <button
                        className="icon-btn grid-icon"
                        onClick={() => {
                            setPropertyValues({
                                idValue: props.dataItem.AssignmentTypeId,
                                name: '',
                            });
                            setIsOpen(true);
                        }}
                    >
                        <img src={editIcon} title="Edit" />
                    </button>

                    <button
                        className="icon-btn grid-icon"
                        onClick={() => {
                            handleDeleteClick(
                                props.dataItem.AssignmentTypeId,
                                props.dataItem.AssignmentType,
                            );
                        }}
                    >
                        <img src={deleteIcon} title="Delete" />
                    </button>
                </td>
            </>
        );
    };
    const editCellForIsActive = (props) => {
        if (props.rowType === 'groupHeader') {
            return null;
        }
        return (
            <>
                <td className="k-command-cell">
                    {props.dataItem.IsActive ? 'Active' : 'Inactive'}
                </td>
            </>
        );
    };

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 550,
        bgcolor: 'background.paper',

        boxShadow: 24,
        p: 4,
    };

    const handleClose = () => {
        setIsOpen(false);

        setPropertyValues({ idValue: 0, name: '' });
    };

    const handleChangeValue = (e) => {
        props.OnHandleChange(e.target.value);
    };

    return (
        <>
            <DeleteModal
                isDeleteOpen={isDeleteOpen}
                setIsDeleteOpen={setIsDeleteOpen}
                propertyValues={propertyValues}
                setPropertyValues={setPropertyValues}
                handleDelete={props.onHandleDeleteClick}
            />
            <Modal
                open={isOpen}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <AssignmentTypesForm
                        idValue={propertyValues.idValue}
                        setIsLoadList={props.setIsLoadList}
                        setIsOpen={setIsOpen}
                        setPropertyValues={setPropertyValues}
                    />
                </Box>
            </Modal>
            <Box style={{ display: 'flex', alignItems: 'flex-end', marginBottom: 15 }}>
                <Box>
                    <Typography className="title" variant="h1">
                        Assignment Types
                    </Typography>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link
                            to={'/'}
                            underline="hover"
                            color="inherit"
                            style={{ textDecoration: 'none' }}
                        >
                            Home
                        </Link>
                        <Link
                            to={'/assignmentTypes'}
                            underline="hover"
                            color="text.primary"
                            aria-current="page"
                            style={{ textDecoration: 'none' }}
                        >
                            Assignment Types
                        </Link>
                    </Breadcrumbs>
                </Box>
                <Button
                    style={{ marginLeft: 'auto' }}
                    variant="contained"
                    type="button"
                    onClick={() => {
                        setIsOpen(true);
                        setPropertyValues({ idValue: 0, name: '' });
                    }}
                >
                    Add New
                </Button>
            </Box>

            <div>
                <Grid
                    data={result}
                    {...dataState}
                    style={{
                        maxHeight: 'calc(100vh - 122px)',
                        minHeight: 'calc(100vh - 122px)',
                    }}
                    onDataStateChange={dataStateChange}
                    sortable={true}
                    pageable={{
                        buttonCount: 5,
                        info: true,
                        type: 'numeric',
                        pageSizes: [100, 200],
                        previousNext: true,
                    }}
                    groupable={true}
                    pageSize={10}
                >
                    <GridToolbar>
                        <div className="search-wrap">
                            <div>
                                <TextField
                                    required
                                    id="outlined-required"
                                    label=""
                                    defaultValue=""
                                    size="small"
                                    onChange={(e) => handleChangeValue(e)}
                                />
                            </div>
                        </div>
                    </GridToolbar>
                    <Column field="AssignmentType" title="Assignment Type" />
                    <Column field="AssignmentDescription" title="Assignment Description" />
                    <Column field="IsActive" cell={editCellForIsActive} title="Active" />
                    <Column cell={editCell} title="Option" width="100px" />
                </Grid>
            </div>
        </>
    );
};

export default AssignmentListing;
