import { useCallback, useState, useEffect } from 'react';
import {
    GoogleMap,
    useJsApiLoader,
    InfoWindow,
    Autocomplete,
    LoadScript,
} from '@react-google-maps/api';
import PropTypes from 'prop-types';
import { MapInfoWindowComponent } from './map-info-window.component';
import { MarkerClustererComponent } from './marker-clusterer.component';
import { MapRadiusComponent } from './map-radius.component';
import { getCirclePath } from '../../utils/map.util';
import { DEFAULT_MAP_POINT_RADIUS } from '../../utils/constants';
import { isNil, some } from 'lodash';

export const MapComponent = ({
    mapData,
    mapContainerStyle,
    showClusters,
    onMarkerClick,
    centerPosition,
}) => {
    const [activeMarker, setActiveMarker] = useState(null);
    const [mapRef, setMap] = useState(null);
    const [autoComplete, setAutoComplete] = useState(null);
    const [address, setAddress] = useState('');

    const onLoad = useCallback(function callback(map) {
        if (mapData && mapData.length) {
            const bounds = new window.google.maps.LatLngBounds();
            mapData.forEach(({ position }) => bounds.extend(position));
            map.fitBounds(bounds);
        }
        setMap(map);
    }, []);

    const onUnmount = useCallback(function callback(map) {
        setMap(null);
    }, []);

    useEffect(() => {
        const { markerData } = centerPosition ? centerPosition : {};
        if (markerData && markerData.length) {
            const bounds = new window.google.maps.LatLngBounds();
            mapData.forEach(({ position }) => bounds.extend(position));
            mapRef?.fitBounds(bounds);
            setActiveMarker(null);
        } else if (markerData) {
            const position = centerPosition.markerData.position;
            const points = getCirclePath(
                position,
                centerPosition.markerData.radius
                    ? centerPosition.markerData.radius / 500 //Hack to avoid clustering while selecting a resource or claim
                    : DEFAULT_MAP_POINT_RADIUS,
                4,
            );
            const bounds = new window.google.maps.LatLngBounds();
            points.forEach((position) => bounds.extend(position));
            mapRef?.fitBounds(bounds);
            setActiveMarker(centerPosition.markerData);
        } else {
            setActiveMarker(null);
        }
    }, [centerPosition]);

    const circles = mapData?.filter((m) => (m.isSelected || m.showInfo) && m.radius > 0);

    const showMarkerInfo = (position, dataType, data) => {
        if (position && data) {
            setActiveMarker({ position, dataType, data });
        } else {
            setActiveMarker(null);
        }
    };

    const mapOptions = {
        styles: [
            {
                featureType: 'poi',
                elementType: 'labels',
                stylers: [{ visibility: 'off' }],
            },
        ],
    };

    const closeMarkerInfo = () => {
        setActiveMarker(null);
    };

    const onAutoCompleteIsLoad = (inputAutoComplete) => {
        setAutoComplete(inputAutoComplete);
    };

    const onAutoCompletePlaceIsChanged = () => {
        if (autoComplete !== null) {
            let place = autoComplete.getPlace();
            if (place) {
                const lat = place.geometry.location.lat();
                const lng = place.geometry.location.lng();

                const points = getCirclePath({ lat, lng }, 25000, 4);
                const bounds = new window.google.maps.LatLngBounds();
                points.forEach((position) => bounds.extend(position));
                mapRef.fitBounds(bounds);
            }
        }
    };

    const validateMapData = (mapData) => {
        return !some(
            mapData,
            (m) =>
                isNil(m.id) ||
                isNil(m.data) ||
                isNil(m.position?.lat) ||
                isNil(m.position?.lng) ||
                isNil(m.color),
        );
    };

    if (mapData && mapData.length > 0 && !validateMapData(mapData)) {
        return <>Invalid Map Data</>;
    }

    return (
        // <LoadScript googleMapsApiKey={GOOGLE_MAPS_API_KEY} libraries={['places']}>
        <GoogleMap
            mapContainerStyle={mapContainerStyle}
            onLoad={onLoad}
            options={mapOptions}
            onClick={closeMarkerInfo}
            onUnmount={onUnmount}
        >
            <Autocomplete
                onLoad={onAutoCompleteIsLoad}
                onPlaceChanged={onAutoCompletePlaceIsChanged}
            >
                <input
                    type="text"
                    placeholder="Search locations"
                    style={{
                        boxSizing: `border-box`,
                        border: `2px solid transparent`,
                        width: `320px`,
                        height: `32px`,
                        padding: `0 12px`,
                        borderRadius: `3px`,
                        boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                        fontSize: `14px`,
                        outline: `none`,
                        textOverflow: `ellipses`,
                        position: 'absolute',
                        left: '50%',
                        marginLeft: '-160px',
                        marginTop: '5px',
                    }}
                    value={address}
                    onChange={(event) => setAddress(event['target']['value'])}
                />
            </Autocomplete>

            <MarkerClustererComponent
                markerData={mapData}
                showMarkerInfo={showMarkerInfo}
                showClusters={showClusters}
                onMarkerClick={onMarkerClick}
            />
            {circles &&
                circles.map(({ position, color, radius }) => (
                    <MapRadiusComponent center={position} radius={radius} color={color} />
                ))}

            {activeMarker && activeMarker.position && (
                <InfoWindow
                    onCloseClick={closeMarkerInfo}
                    position={activeMarker.position}
                    options={{ pixelOffset: new window.google.maps.Size(0, -30) }}
                >
                    <MapInfoWindowComponent
                        dataType={activeMarker.dataType}
                        data={activeMarker.data}
                    />
                </InfoWindow>
            )}
        </GoogleMap>
        // </LoadScript>
    );
    //}
};

MapComponent.propTypes = {
    mapData: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.any.isRequired,
            position: PropTypes.shape({
                lat: PropTypes.number.isRequired,
                lng: PropTypes.number.isRequired,
            }),
            markerType: PropTypes.string.isRequired,
            radius: PropTypes.number,
            isSelected: PropTypes.bool,
            showInfo: PropTypes.bool,
            color: PropTypes.shape({
                fillColor: PropTypes.string.isRequired,
                strokeColor: PropTypes.string,
            }),
            data: PropTypes.any.isRequired,
        }).isRequired,
    ).isRequired,
    currentPosition: PropTypes.shape({
        lat: PropTypes.number.isRequired,
        lng: PropTypes.number.isRequired,
        radius: PropTypes.number.isRequired,
    }),
    onMarkerClick: PropTypes.func,
    mapContainerStyle: PropTypes.any,
};
