import {
    Scheduler,
    TimelineView,
    DayView,
    WeekView,
    MonthView,
    AgendaView,
} from '@progress/kendo-react-scheduler';

const Inspector_calendar = ({ inspectorDetails }) => {
    const modelFields = {
        id: 'ScheduleId',
        title: 'ScheduleType',
        start: 'ScheduleStartTime',
        end: 'ScheduleEndTime',
    };
    return (
        <Scheduler
            className="inspectorCalender"
            style={{ fontSize: '12px' }}
            data={inspectorDetails}
            modelFields={modelFields}
        >
            <MonthView />
            <DayView />
            <WeekView />
            <TimelineView />
            <AgendaView />
        </Scheduler>
    );
};

export default Inspector_calendar;
