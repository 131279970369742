import React, { useEffect, useState } from 'react';
import { Grid, Typography, Box, Breadcrumbs, Link } from '@mui/material';
import { Colors } from '../../utils/colors';
import { AppInputTypes, ExperienceCodes } from '../../utils/constants';
import { usePostData2 } from '../../hooks/use-post-data-2.hook';
import { AppUrls } from '../../core/route.url';
import { LoadingIndicator } from '../../components/loading-indicator.component';
import ProfileFilter from '../../components/profile-search/ProfileFilter';
import SelectedFilterParams from '../../components/profile-search/SelectedFilterParams';
import ProfileCard from '../../components/profile-search/ProfileCard';
import { useDownloadFile } from '../../hooks/use-download-file.hook';
import { uuIdv4 } from '../../utils/functions';

const ProfileSearch = (props) => {
    const [searchParams, setSearchParams] = useState([]);

    const { response: getSearchParamsByEXPCodesResponse, submitData: getSearchParamsByEXPCodes } =
        usePostData2(AppUrls.GetQuestionnaire);

    const [profiles, setProfiles] = useState([]);

    const { response: searchProfilesResponse, submitData: searchProfiles } = usePostData2(
        AppUrls.GetPagedProfiles,
    );

    const {
        result: downloadResumePdfResponse,
        reload: downloadPdf,
        isLoading: isDownloadingPdf,
        clearState,
    } = useDownloadFile(AppUrls.DownloadResumePdf);

    const onDownloadPdf = (entryId, email) => {
        downloadPdf(`${AppUrls.DownloadResumePdf}/${entryId}/${email}`);
    };

    useEffect(() => {
        if (downloadResumePdfResponse) {
            const url = window.URL.createObjectURL(new Blob([downloadResumePdfResponse]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${uuIdv4()}.pdf`); //or any other extension
            document.body.appendChild(link);
            link.click();
            clearState();
        }
    }, [downloadResumePdfResponse]);

    useEffect(() => {
        const { IsSuccess, Data } = searchProfilesResponse?.result || {};
        if (IsSuccess) {
            let profileList = Data[0] ?? [];
            let result = profileList.map((profileItem, profileItemIndex) => {
                const { LastName, FirstName, PilotNumber, Email, StreetAddress, EntryID } =
                    profileItem?.rBAdjusterAnswers || {};
                return {
                    id: profileItemIndex + 1,
                    personalInfo: {
                        entryId: EntryID,
                        firstName: FirstName ?? '',
                        lastName: LastName ?? '',
                        pilotNumber: PilotNumber ?? '',
                        email: Email ?? '',
                        address: StreetAddress ?? '',
                    },
                    experiences: profileItem?.ExperienceInfo?.map((experience, experienceIndex) => {
                        return {
                            id: experienceIndex + 1,
                            label: experience?.ExperienceTitle,
                            questions: experience?.QuestionInfo?.map((question) => {
                                return {
                                    id: question?.QuestionId,
                                    label: question?.QuestionTitle,
                                    selectedOptions: question?.AnswerSubmitted,
                                };
                            }),
                        };
                    }),
                };
            });
            setProfiles(result);
        }
    }, [searchProfilesResponse?.result]);

    useEffect(() => {
        getSearchParamsByEXPCodes({
            ExpCodes: [
                ExperienceCodes.AUTOA,
                ExperienceCodes.CMDA,
                ExperienceCodes.CMDFA,
                ExperienceCodes.CMDFR,
                ExperienceCodes.INSDA,
                ExperienceCodes.NFIFA,
                ExperienceCodes.PTYFA,
            ],
            ExpIDs: [1, 2, 3, 4, 5, 6, 7],
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const convertExperienceToParams = (experienceItem) => {
        let questions = [];
        experienceItem?.Questions?.forEach((questionItem) => {
            if (questionItem?.fieldType === AppInputTypes.FromToDatePicker) {
                let selected = questionItem?.options?.filter((q) => q.isSelected);
                if (selected?.length) {
                    questions.push({
                        QuestionId: questionItem?.LovID,
                        QuestionAnswer: selected?.toString(),
                    });
                }
            } else {
                questionItem?.options
                    ?.filter((q) => q.isSelected && q.option)
                    .forEach((ValueItem) => {
                        questions.push({
                            QuestionId: questionItem?.LovID,
                            QuestionAnswer: ValueItem?.option,
                        });
                    });
            }
        });

        return questions;
    };

    useEffect(() => {
        if (searchParams) {
            searchProfiles({
                PageNo: 1,
                PageSize: 100,
                SortDir: 'Ascending',
                SortExp: '',
                SearchText: '',
                StartDate: null,
                EndDate: null,
                DetailInfo: searchParams?.map((searchParam, searchParmIndex) => {
                    return {
                        ExperienceID: searchParam?.ExperienceID,
                        DetailInfo: convertExperienceToParams(searchParam),
                    };
                }),
            });
        }
    }, [searchParams]);

    useEffect(() => {
        if (getSearchParamsByEXPCodesResponse?.result) {
            const params = getSearchParamsByEXPCodesResponse?.result?.Data;
            if (params?.length) {
                // for time being
                params.map((param) => {
                    param.Questions.map((question) => {
                        let optionsString =
                            typeof question?.options === 'string' ? question?.options : '';
                        const options = optionsString?.split(',') ?? [];
                        let optionsArray =
                            Array.isArray(options) &&
                            options.map((option, optionIndex) => {
                                return {
                                    id: optionIndex + 1,
                                    isSelected: false,
                                    option,
                                };
                            });
                        question.options = optionsArray;
                        return question;
                    });
                    return param;
                });
                //
                params[0].isVisible = true;
                if (params[0].Questions.length) {
                    params[0].Questions[0].isVisible = true;
                }
                setSearchParams(params);
            }
        }
    }, [getSearchParamsByEXPCodesResponse?.result]);

    const onClickParamAccordion = (paramIndex) => {
        let searchParamsCopy = [...searchParams];
        let param = searchParamsCopy[paramIndex];
        param.isVisible = !param.isVisible;
        setSearchParams(searchParamsCopy);
    };

    const onClickParamQuestionAccordion = (paramIndex, questionIndex) => {
        let searchParamsCopy = [...searchParams];
        let param = searchParamsCopy[paramIndex];
        let question = param.Questions[questionIndex];
        question.isVisible = !question.isVisible;
        setSearchParams(searchParamsCopy);
    };

    const onClickQuestionOptionCheckBox = (paramIndex, questionIndex, optionId) => {
        let searchParamsCopy = [...searchParams];
        let param = searchParamsCopy[paramIndex];
        let question = param.Questions[questionIndex];
        let option = question?.options?.find((x) => x.id === optionId);
        if (option) {
            option.isSelected = !option.isSelected;
            let displayOrder = Math.max(
                ...param?.Questions?.map((question) => {
                    return Math.max(
                        ...question?.options
                            ?.filter((x) => x.isSelected)
                            ?.map((option) => option?.order + 1 || 0),
                    );
                }),
            );
            if (option.isSelected) {
                option.order = displayOrder;
            } else {
                option.order = displayOrder > 0 ? displayOrder - 1 : 0;
            }
            setSearchParams(searchParamsCopy);
        }
    };

    const onClearSelectedParamOption = (selectedParam, selectedOption) => {
        let searchParamsCopy = [...searchParams];
        let paramIndex = searchParams.findIndex((x) => x.ExperienceID === selectedParam?.id);
        let param = searchParamsCopy[paramIndex];
        let question = param?.Questions.find((x) => x.fieldName === selectedOption?.questionLabel);
        if (question) {
            let option = question?.options.find((x) => x.option === selectedOption?.label);
            if (option) {
                option.isSelected = false;
                setSearchParams(searchParamsCopy);
            }
        }
    };

    const onFilterSearch = (paramIndex, value) => {
        let searchParamsCopy = [...searchParams];
        let param = searchParamsCopy[paramIndex];
        param.searchKey = value;
        param?.Questions.map((question) => {
            if (value) {
                let isValueMatches = question?.options.some((x) =>
                    x.option?.toLowerCase()?.includes(value?.toLowerCase()),
                );
                if (isValueMatches) {
                    question.isVisible = true;
                } else {
                    question.isVisible = false;
                }
            } else {
                question.isVisible = false;
            }
            return question;
        });
        setSearchParams(searchParamsCopy);
    };

    const onClickViewDetail = (itemId) => {
        let profilesCopy = [...profiles];
        let findProfile = profilesCopy.find((x) => x.id === itemId);
        if (findProfile) {
            findProfile.isExpanded = !findProfile.isExpanded;
            setProfiles(profilesCopy);
        }
    };

    return (
        <Grid id="resume-builder-workspace" className="gridRsbuilder">
            <LoadingIndicator
                isLoading={
                    getSearchParamsByEXPCodesResponse?.isLoading ||
                    searchProfilesResponse?.isLoading
                }
            />
            <Grid
                className="leftMenupanel"
                sx={{ background: Colors.WHITE, boxShadow: '8px 0 9px -5px #e9e9e9' }}
                item
            >
                <Grid p={2} pb={1} sx={{ color: 'gray' }} item component={'h3'}>
                    FILTER
                </Grid>

                <Grid p={2} pt={0} pr={1} item md={12} sx={{ height: '93vh', overflowY: 'scroll' }}>
                    <ProfileFilter
                        searchParams={searchParams}
                        onClickParamAccordion={onClickParamAccordion}
                        onClickParamQuestionAccordion={onClickParamQuestionAccordion}
                        onClickQuestionOptionCheckBox={onClickQuestionOptionCheckBox}
                        onFilterSearch={onFilterSearch}
                    />
                </Grid>
            </Grid>

            <Grid ml={'337px'} p={2}>
                <Grid item>
                    <Typography variant="h1" className="title">
                        Resume Builder Workspace
                    </Typography>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit">
                            Home
                        </Link>
                        <Link underline="hover" color="text.primary" aria-current="page">
                            Resume Builder
                        </Link>
                    </Breadcrumbs>

                    <Grid container item md={12} mt={1}>
                        <SelectedFilterParams
                            searchParams={searchParams}
                            onClearSelectedParamOption={onClearSelectedParamOption}
                        />
                    </Grid>
                </Grid>

                <Grid item>
                    {profiles?.map((profileItem, profileIndex) => {
                        const { personalInfo, experiences, id, isExpanded } = profileItem || {};
                        return (
                            <ProfileCard
                                key={profileIndex}
                                profileInfo={personalInfo}
                                experiences={experiences}
                                onClickViewDetail={onClickViewDetail}
                                id={id}
                                expanded={isExpanded}
                                onDownloadPdf={onDownloadPdf}
                            />
                        );
                    })}
                    {profiles?.length === 0 && (
                        <Grid
                            item
                            display={'flex'}
                            alignItems="center"
                            justifyContent={'center'}
                            flex={1}
                        >
                            <Typography>No data</Typography>
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
};

export default ProfileSearch;
