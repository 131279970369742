export const getCirclePath = (center, radius, numberOfPoints = 32) => {
    const dir = 1;
    const radiusInMeter = radius / 1609.344;
    const d2r = Math.PI / 180; // degrees to radians
    const r2d = 180 / Math.PI; // radians to degrees
    const earthsradius = 3963; // 3963 is the radius of the earth in miles

    // find the raidus in lat/lon
    const rlat = (radiusInMeter / earthsradius) * r2d;
    const rlng = rlat / Math.cos(center.lat * d2r);

    const extp = new Array();
    const start = dir === 1 ? 0 : numberOfPoints + 1;
    const end = dir === 1 ? numberOfPoints + 1 : 0;

    for (var i = start; dir === 1 ? i < end : i > end; i = i + dir) {
        const theta = Math.PI * (i / (numberOfPoints / 2));
        const ey = center.lng + rlng * Math.cos(theta); // center a + radius x * cos(theta)
        const ex = center.lat + rlat * Math.sin(theta); // center b + radius y * sin(theta)
        extp.push(new window.google.maps.LatLng(ex, ey));
    }
    return extp;
};
