import * as React from 'react';
import { Grid, GridColumn as Column, GridToolbar } from '@progress/kendo-react-grid';
import { columnsForCarriers } from '../../components/kendoGrid/columns';
import { process } from '@progress/kendo-data-query';
import { ColumnMenu } from '../../components/kendoGrid/customColumnMenu';
import { GridPDFExport } from '@progress/kendo-react-pdf';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import iconexcel from '../../images/file-excel.svg';
import iconpdf from '../../images/file-pdf-solid.svg';
import editIcon from '../../images/pencil.svg';
import { Button, Container, Box, Breadcrumbs, Link, Typography, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';

const Carrier_manage_list = (props) => {
    let initialState = {};

    const createDataState = (dataState) => {
        return {
            result: process(props.filterList.slice(0), dataState),
            dataState: dataState,
        };
    };
    const history = useNavigate();
    const [stateColumns, setStateColumns] = React.useState(columnsForCarriers);

    initialState = createDataState({
        take: 100,
        skip: 0,
    });

    const [result, setResult] = React.useState(initialState.result);

    React.useEffect(() => {
        if (props.filterList.length > 0) {
            initialState = createDataState({
                take: 100,
                skip: 0,
            });
            setResult(initialState.result);
        }
    }, [props.filterList]);

    const [dataState, setDataState] = React.useState(initialState.dataState);

    const dataStateChange = (event) => {
        let updatedState = createDataState(event.dataState);

        setResult(updatedState.result);
        setDataState(updatedState.dataState);
    };

    const editCell = (props) => {
        if (props.rowType === 'groupHeader') {
            return null;
        }

        return (
            <>
                <td className="k-command-cell">
                    <button
                        className="icon-btn grid-icon"
                        onClick={() => {
                            history('/carrier/add', {
                                state: { carrierId: `${props.dataItem.CarrierId}` },
                            });
                        }}
                    >
                        <img src={editIcon} title="Edit" />
                    </button>
                </td>
            </>
        );
    };

    const onColumnsSubmit = (columnsState) => {
        setStateColumns(columnsState);
    };

    const _export = React.useRef(null);

    const excelExport = () => {
        if (_export.current !== null) {
            _export.current.save();
        }
    };

    let _pdfExport;

    const exportPDF = () => {
        _pdfExport.save(
            process(props.filterList, {
                take: props.filterList.length + 1,
                skip: 0,
                group: dataState.group,
                filter: dataState.filter,
            }).data,
        );
    };

    const editCellForIsActive = (props) => {
        if (props.rowType === 'groupHeader') {
            return null;
        }
        return (
            <>
                <td className="k-command-cell">
                    {props.dataItem.IsActive ? 'Active' : 'Inactive'}
                </td>
            </>
        );
    };

    const exportGrid = (value) => {
        return (
            <Grid
                data={result}
                {...dataState}
                onDataStateChange={dataStateChange}
                sortable={true}
                pageable={{
                    buttonCount: 5,
                    info: true,
                    type: 'numeric',
                    pageSizes: [100, 200, 500],
                    previousNext: true,
                }}
                resizable={true}
                reorderable={true}
                groupable={true}
                pageSize={10}
                style={{
                    maxHeight: 'calc(100vh - 182px)',
                    display: value ? 'none' : '',
                }}
            >
                {stateColumns
                    .filter((data, i) => {
                        return data.show === true && data.field !== 'optn';
                    })
                    .map((d, index) => {
                        return <Column key={index} field={d.field} title={d.title} />;
                    })}
            </Grid>
        );
    };

    return (
        <>
            <Container maxWidth="false" style={{ padding: '0px', marginBottom: '1rem' }}>
                <Box className="row">
                    <Stack>
                        <Typography variant="h1" sx={{ mb: 0 }} className="title">
                            Carriers
                        </Typography>
                        <Breadcrumbs aria-label="breadcrumb">
                            <Link underline="hover" color="inherit">
                                Home
                            </Link>
                            <Link underline="hover" color="text.primary" aria-current="page">
                                Carriers
                            </Link>
                        </Breadcrumbs>
                    </Stack>
                    <Button
                        sx={{ ml: 'auto', alignSelf: 'flex-end' }}
                        variant="contained"
                        type="button"
                        onClick={() => {
                            history('/carrier/add', { state: { carrierId: 0 } });
                        }}
                    >
                        Add New
                    </Button>
                </Box>
            </Container>

            <div className="claims-list-grid">
                <Grid
                    data={
                        result !== undefined && result.data !== undefined
                            ? result.data.map((item) => ({
                                  ...item,
                              }))
                            : []
                    }
                    {...dataState}
                    style={{
                        minHeight: 'calc(100vh - 132px)',
                        maxHeight: 'calc(100vh - 132px)',
                    }}
                    onDataStateChange={dataStateChange}
                    sortable={true}
                    pageable={{
                        buttonCount: 5,
                        info: true,
                        type: 'numeric',
                        pageSizes: [100, 200],
                        previousNext: true,
                    }}
                    groupable={true}
                    total={result.total}
                    pageSize={8}
                    onRowDoubleClick={() => {}}
                >
                    <GridToolbar></GridToolbar>
                    <GridToolbar>
                        <div>
                            <button
                                title="Export to Excel"
                                className="icon-btn"
                                onClick={excelExport}
                            >
                                <img src={iconexcel} title="Export to Excel" />
                            </button>

                            <button className="icon-btn" onClick={exportPDF}>
                                <img src={iconpdf} title="Export to PDF" />
                            </button>
                        </div>
                    </GridToolbar>

                    {stateColumns.map(
                        (column, idx) =>
                            column.show &&
                            (column.field === 'UserName' ? (
                                <Column
                                    key={idx}
                                    field={column.field}
                                    title={column.title}
                                    filter={column.filter}
                                    columnMenu={(props) => (
                                        <ColumnMenu
                                            {...props}
                                            columns={stateColumns}
                                            onColumnsSubmit={onColumnsSubmit}
                                        />
                                    )}
                                />
                            ) : column.field === 'optn' ? (
                                <Column
                                    width={100}
                                    key={idx}
                                    field={column.field}
                                    title={column.title}
                                    cell={editCell}
                                    filter={column.filter}
                                    columnMenu={(props) => (
                                        <ColumnMenu
                                            {...props}
                                            columns={stateColumns}
                                            onColumnsSubmit={onColumnsSubmit}
                                        />
                                    )}
                                />
                            ) : (
                                <Column
                                    key={idx}
                                    field={column.field}
                                    title={column.title}
                                    // cell={editCell}
                                    filter={column.filter}
                                    columnMenu={(props) => (
                                        <ColumnMenu
                                            {...props}
                                            columns={stateColumns}
                                            onColumnsSubmit={onColumnsSubmit}
                                        />
                                    )}
                                />
                            )),
                    )}
                </Grid>

                <ExcelExport
                    fileName={`CarrierManageExport_${moment(new Date()).format(
                        'MM-DD-YYYY hh mm a',
                    )}`}
                    style={{ display: 'none' }}
                    // data={result !== undefined && result.data !== undefined ? result.data : []}
                    // ref={_export}
                    data={
                        props.filterList
                            ? process(props.filterList, {
                                  take: props.filterList.length + 1,
                                  skip: 0,
                                  group: dataState.group,
                                  filter: dataState.filter,
                              }).data
                            : []
                    }
                    ref={_export}
                    groupable={true}
                    group={dataState.group}
                >
                    {exportGrid(true)}
                </ExcelExport>
                <GridPDFExport
                    fileName={`CarrierManageExport_${moment(new Date()).format(
                        'MM-DD-YYYY hh mm a',
                    )}`}
                    ref={(element) => {
                        _pdfExport = element;
                    }}
                    margin="1cm"
                >
                    {exportGrid(false)}
                </GridPDFExport>
            </div>
        </>
    );
};

export default Carrier_manage_list;

Carrier_manage_list.propTypes = {
    filterList: PropTypes.array.isRequired,
    setIsLoadList: PropTypes.any.isRequired,
};
