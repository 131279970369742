import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    Alert,
    Box,
    Button,
    CircularProgress,
    FormControl,
    TextField,
    Modal,
    TextareaAutosize,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';

import { AppUrls } from '../../core/route.url';
import { usePostData } from '../../hooks/use-post-data.hook';
import { useLoadData2 } from '../../hooks/use-load-data-2.hook';
import SelectOption from '../../components/select-option';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 760,
    bgcolor: 'background.paper',
    boxShadow: 3,
    p: 3,
};
const ClaimInsuredInformationForm = ({
    isClaimInsureInfoOpen,
    setIsClaimInsureInfoOpen,
    cliamProfileDetails,
    reload,
}) => {
    const {
        register,
        handleSubmit,
        control,
        setValue,
        watch,
        formState: { errors },
    } = useForm();

    const watchAllFields = watch();

    const [savedResponse, setSaveResponse] = useState({});

    const { submitData, response } = usePostData(AppUrls.ClaimInfoSave, 'POST');
    const { result: stateList, reload: stateReload } = useLoadData2();
    useEffect(() => {
        if (isClaimInsureInfoOpen) {
            stateReload(AppUrls.GetAllStates, '');
        }
    }, [isClaimInsureInfoOpen]);

    useEffect(() => {
        if (response.result != undefined) {
            setSaveResponse(response.result);
            reload();
            const timer = setTimeout(() => {
                setSaveResponse({});

                setIsClaimInsureInfoOpen(false);
            }, 1000);
            return () => clearTimeout(timer);
        }
    }, [response]);

    React.useEffect(() => {
        if (cliamProfileDetails != undefined && cliamProfileDetails !== null) {
            setValue('email', cliamProfileDetails.InsuredEmail);
            setValue('officePhone', cliamProfileDetails.InsuredOfficePhone);
            setValue('cellPhone', cliamProfileDetails.InsuredCellPhone);
            setValue('altPhone', cliamProfileDetails.InsuredAltPhone);
            setValue('inspectionType', cliamProfileDetails.InspectionType);
            setValue('assignmentType', cliamProfileDetails.AssignmentType);
            setValue('preferredContact', cliamProfileDetails.InsuredPreferredContact);
            setValue('insuredAddress', cliamProfileDetails.InsuredAddress);
            setValue('address', cliamProfileDetails.InsuredStreet);
            setValue('city', cliamProfileDetails.Insured_City);
            setValue('State', cliamProfileDetails.Insured_StateCode);
            setValue('zip', cliamProfileDetails.Insured_Zip);
        }
    }, [cliamProfileDetails]);

    const onSubmit = async (data) => {
        submitData({
            IsActive: true,
            ClaimGuID: cliamProfileDetails.ClaimGuID,
            InspectionType:
                cliamProfileDetails.InspectionType === null
                    ? ''
                    : cliamProfileDetails.InspectionType,
            AssignmentType:
                cliamProfileDetails.AssignmentType === null
                    ? ''
                    : cliamProfileDetails.AssignmentType,
            InspectionTypeId: cliamProfileDetails.InspectionTypeId,
            AssignmentTypeId: cliamProfileDetails.AssignmentTypeId,
            InsuredOfficePhone: data.officePhone,
            InsuredCellPhone: data.cellPhone,
            InsuredAltPhone: data.altPhone,
            CarrierId: cliamProfileDetails.CarrierId,
            CarrierName: cliamProfileDetails.CarrierName,
            PolicyNo: cliamProfileDetails.PolicyNo,
            PolicyType: cliamProfileDetails.PolicyType,
            TypeOfLoss: cliamProfileDetails.TypeOfLoss,
            LossTypeId: cliamProfileDetails.LossTypeId,
            DateOfLoss: cliamProfileDetails.DateOfLoss,
            PerilName: cliamProfileDetails.PerilName,
            PerilId: cliamProfileDetails.PerilId,
            InsuredName: cliamProfileDetails.InsuredName,
            InsuredFirstName: '',
            InsuredLastName: '',
            InsuredAddress: data.insuredAddress,
            InsuredEmail: data.email,
            InsuredPreferredContact: data.preferredContact,
            MFN: cliamProfileDetails.MFN,
            Insured_City: data.city,
            Insured_Zip: data.zip,
            Insured_StateName:
                stateList && stateList?.Data
                    ? stateList.Data.filter((e) => {
                          return e.StateCode === data.State;
                      })[0]?.StateName
                    : '',
            Insured_StateCode: data.State,
            Insured_StateId:
                stateList && stateList?.Data
                    ? stateList.Data.filter((e) => {
                          return e.StateCode === data.State;
                      })[0]?.StateId
                    : 0,
            Insured_Street: data.address,
            Property_StateId: cliamProfileDetails.StateId,
            Property_StateCode: cliamProfileDetails.StateCode,
            Property_StateName: cliamProfileDetails.StateName,
            Property_City: cliamProfileDetails.City,
            Property_postal: cliamProfileDetails.Zip,
            Property_Street: cliamProfileDetails.PropertyStreet,
        });
    };

    // if (carrierError) {
    //     return <div>Error Loading Data</div>;
    // }
    const handleClose = () => {
        setIsClaimInsureInfoOpen(false);
    };

    return (
        <>
            <Modal
                open={isClaimInsureInfoOpen}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <h2 style={{ marginBottom: 20 }}>Insured Information</h2>
                    <Box
                        component="form"
                        noValidate
                        autoComplete="off"
                        onSubmit={handleSubmit(onSubmit)}
                    >
                        <Box className="claim-info-poup">
                            <TextField
                                className="span2"
                                // required
                                error={errors.email}
                                id="outlined-required"
                                label="Email"
                                defaultValue=""
                                InputLabelProps={{
                                    shrink: watchAllFields.email === '' ? false : true,
                                }}
                                {...register('email', { required: false })}
                            />
                            <TextField
                                // required
                                error={errors.officePhone}
                                id="outlined-required"
                                label="Office Phone"
                                defaultValue=""
                                InputLabelProps={{
                                    shrink: watchAllFields.officePhone === '' ? false : true,
                                }}
                                {...register('officePhone', { required: false })}
                            />
                            <TextField
                                // required
                                error={errors.cellPhone}
                                id="outlined-required"
                                label="Cell Phone"
                                defaultValue=""
                                InputLabelProps={{
                                    shrink: watchAllFields.cellPhone === '' ? false : true,
                                }}
                                {...register('cellPhone', { required: false })}
                            />
                            <TextField
                                // required
                                error={errors.altPhone}
                                id="outlined-required"
                                label="Alt Phone"
                                defaultValue=""
                                InputLabelProps={{
                                    shrink: watchAllFields.altPhone === '' ? false : true,
                                }}
                                {...register('altPhone', { required: false })}
                            />
                            <TextField
                                // required
                                error={errors.preferredContact}
                                id="outlined-required"
                                label="Preferred Contact"
                                defaultValue=""
                                InputLabelProps={{
                                    shrink: watchAllFields.preferredContact === '' ? false : true,
                                }}
                                {...register('preferredContact', { required: false })}
                            />
                            {/* <TextField
                                className="span2"
                                multiline
                                rows={2}
                                // required
                                error={errors.insuredAddress}
                                id="outlined-required"
                                label="Insured Address"
                                defaultValue=""
                                InputLabelProps={{
                                    shrink: watchAllFields.insuredAddress === '' ? false : true,
                                }}
                                {...register('insuredAddress', { required: false })}
                            /> */}
                            <TextField
                                size="small"
                                style={{ marginBottom: 15 }}
                                fullWidth
                                error={errors.address}
                                id="outlined-required"
                                label="Address"
                                defaultValue=""
                                InputLabelProps={{
                                    shrink: watchAllFields.address === '' ? false : true,
                                }}
                                {...register('address', { required: false })}
                            />
                            <TextField
                                size="small"
                                style={{ marginBottom: 15 }}
                                fullWidth
                                error={errors.city}
                                id="outlined-required"
                                label="City"
                                defaultValue=""
                                InputLabelProps={{
                                    shrink: watchAllFields.city === '' ? false : true,
                                }}
                                {...register('city', { required: false })}
                            />
                            <FormControl fullWidth size="small" style={{ marginBottom: 15 }}>
                                <Controller
                                    size="small"
                                    control={control}
                                    name="State"
                                    id="outlined-required"
                                    rules={{
                                        required: false,
                                    }}
                                    render={({ field: { onChange, onBlur, value, name } }) => (
                                        <SelectOption
                                            fullWidth
                                            values={
                                                stateList && stateList?.Data
                                                    ? stateList.Data.map((e) => {
                                                          return {
                                                              value: e['StateCode'],
                                                              label: e['StateName'],
                                                          };
                                                      })
                                                    : []
                                            }
                                            disabled={false}
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            value={
                                                value === undefined && value === null ? '' : value
                                            }
                                            name={name || ''}
                                            error={errors.State}
                                        />
                                    )}
                                />
                            </FormControl>
                            <TextField
                                size="small"
                                style={{ marginBottom: 15 }}
                                fullWidth
                                error={errors.zip}
                                id="outlined-required"
                                label="Zip"
                                defaultValue=""
                                InputLabelProps={{
                                    shrink: watchAllFields.zip === '' ? false : true,
                                }}
                                {...register('zip', { required: false })}
                            />
                        </Box>
                        <div className="d-flex" style={{ marginTop: 20 }}>
                            <Button
                                style={{ marginLeft: 2 }}
                                onClick={() => {
                                    setIsClaimInsureInfoOpen(false);
                                    setValue('noteBody', '');
                                }}
                                variant="outlined"
                                type="button"
                            >
                                Cancel
                            </Button>

                            <Button
                                style={{ marginLeft: 'auto' }}
                                variant="contained"
                                type="submit"
                            >
                                {response.isSubmitting ? (
                                    <CircularProgress color="inherit" />
                                ) : (
                                    'Save'
                                )}
                            </Button>
                        </div>
                    </Box>
                    {savedResponse.IsSuccess != undefined ? (
                        <Alert
                            style={{ marginTop: 15 }}
                            severity={savedResponse.IsSuccess ? 'success' : 'error'}
                        >
                            {savedResponse.Message}
                        </Alert>
                    ) : null}
                </Box>
            </Modal>
        </>
    );
};

export default ClaimInsuredInformationForm;
ClaimInsuredInformationForm.propTypes = {
    isClaimInsureInfoOpen: PropTypes.bool,
    setIsClaimInsureInfoOpen: PropTypes.func,
    cliamProfileDetails: PropTypes.any.isRequired,
    reload: PropTypes.func,
};
