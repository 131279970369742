import {
  // Dispatch, SetStateAction,
  useEffect,
  useState,
} from "react";

export const useStorage = (key, initialValue) => {
  const readValue = () => {
    if (!window) {
      return initialValue;
    }

    const item = window.localStorage.getItem(key);
    return item ? JSON.parse(item) : initialValue;
  };

  const setValue = (value) => {
    if (!window) {
      console.warn(
        `Tried setting localStorage key “${key}” even though environment is not a client`
      );
    }

    window.localStorage.setItem(key, JSON.stringify(value));
    setStoredValue(value);
    window.dispatchEvent(new Event("local-storage"));
  };

  const [storedValue, setStoredValue] = useState(readValue);

  useEffect(() => {
    setStoredValue(readValue());
  }, []);

  useEffect(() => {
    const handleStorageChange = () => {
      setStoredValue(readValue());
    };

    window.addEventListener("storage", handleStorageChange);
    window.addEventListener("local-storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
      window.removeEventListener("local-storage", handleStorageChange);
    };
  }, []);

  return [storedValue, setValue];
};

export const Logout = (key) => {
  window.localStorage.removeItem(key);
  window.dispatchEvent(new Event("local-storage"));
};

export const LogIn = (key) => {
  window.localStorage.removeItem(key);
  window.dispatchEvent(new Event("local-storage"));
};
