import React from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    Button,
    Checkbox,
    Divider,
    FormControl,
    FormControlLabel,
    FormGroup,
    Grid,
    Modal,
    Typography,
} from '@mui/material';
import { useState } from 'react';
import { useEffect } from 'react';

const ColumnOptionsModal = (props) => {
    const {
        open,
        filterData,
        checkedArray: existingCheckedValues,
        onChangeVisibility,
        onSubmit,
    } = props;

    const [checkedArray, setCheckedArray] = useState(existingCheckedValues || []);

    useEffect(() => {
        if (existingCheckedValues) {
            setCheckedArray(existingCheckedValues);
        }
    }, [existingCheckedValues, open]);

    const onCliCkCheckBox = (event) => {
        const { value, checked } = event.target;
        // Case 1 : The user checks the box
        if (checked) {
            setCheckedArray([...checkedArray, parseInt(value)]);
        }
        // Case 2  : The user unchecks the box
        else {
            setCheckedArray(checkedArray.filter((e) => e !== parseInt(value)));
        }
    };

    const onClickCheckAll = (e) => {
        if (filterData.length > 0) {
            let array = [];
            if (e.target.checked) {
                filterData.forEach((element) => {
                    array.push(element.EntityFieldId);
                });
                setCheckedArray(array);
            } else {
                setCheckedArray(array);
            }
        }
    };

    const onClose = () => {
        setCheckedArray([]);
        onChangeVisibility();
    };

    return (
        <Modal
            open={open}
            onClose={onChangeVisibility}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        >
            <Box sx={{ background: 'white', minWidth: '500px' }}>
                <Typography margin={1} id="modal-modal-title" variant="h6" component="h2">
                    Column options
                </Typography>
                <Divider />
                <Grid item style={{ height: '400px', overflow: 'auto' }}>
                    <Box sx={{ display: 'flex' }}>
                        <FormControl style={{ width: '100%' }} sx={{ m: 3 }} variant="standard">
                            {filterData?.length > 0 ? (
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={
                                                    filterData?.length > checkedArray?.length
                                                        ? false
                                                        : true
                                                }
                                                onChange={onClickCheckAll}
                                                name={'checkBox'}
                                                value={''}
                                            />
                                        }
                                        label={'Select All'}
                                    />
                                </FormGroup>
                            ) : null}
                            <Box>
                                <FormGroup>
                                    {filterData?.length > 0
                                        ? filterData.map((data, i) => {
                                              return (
                                                  <FormControlLabel
                                                      key={i}
                                                      control={
                                                          <Checkbox
                                                              checked={checkedArray.includes(
                                                                  data.EntityFieldId,
                                                              )}
                                                              onChange={onCliCkCheckBox}
                                                              name={data.FieldName}
                                                              value={data.EntityFieldId}
                                                          />
                                                      }
                                                      label={data.FieldDisplayName}
                                                  />
                                              );
                                          })
                                        : null}
                                </FormGroup>
                            </Box>
                        </FormControl>
                    </Box>
                </Grid>
                <Divider />
                <Grid item margin={1} display="flex" justifyContent={'flex-end'}>
                    <Button onClick={onClose} style={{ marginRight: 3 }} variant="outlined">
                        Cancel
                    </Button>
                    <Button
                        onClick={() => onSubmit(checkedArray)}
                        style={{ marginLeft: 3 }}
                        variant="contained"
                    >
                        Submit
                    </Button>
                </Grid>
            </Box>
        </Modal>
    );
};

ColumnOptionsModal.propTypes = {};

export default ColumnOptionsModal;
