import { useState, useEffect } from 'react';
import {
    Grid,
    Typography,
    TextField,
    Button,
    Link,
} from '@mui/material/';
import { Link as RouterLink } from 'react-router-dom';

export const CarrierListPage = () => {
    const [CarrierSearch, setCarrierSearch] = useState('');

    const onSearchChange = (event) => {
        //event.target.name, event.target.value
    };

    return (
        <div className='main-content'>
            <Grid container marginBottom={2}>
                <Grid item xs={12} md={8}>
                    <Typography variant="h1" className="title m-0">
                        Carriers Onboarding
                    </Typography>
                </Grid>
                <Grid item xs={7} md={2}>
                    <TextField
                        className="bg-White"
                        label="Search"
                        size="small"
                        fullWidth
                        id="outlined-basic"
                        variant="outlined"
                        name="CarrierSearch"
                        value={CarrierSearch}
                        onChange={onSearchChange}
                    />
                </Grid>
                <Grid item xs={5} md={2}>
                    <Button component={RouterLink} to="/carrier-create"
                        style={{ marginLeft: 20 }} variant="contained" >
                        + ADD NEW
                    </Button>
                </Grid>
            </Grid>
        </div>
    );
};