import * as React from "react";
import {  GridColumnMenuCheckboxFilter
} from "@progress/kendo-react-grid";
export const CheckBoxColumnMenu = (props) => {
  return (
    <div>
        <GridColumnMenuCheckboxFilter {...props} data={props.data} expanded={true} />     
    </div>
  );
};
