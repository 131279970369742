import React from 'react';
import { usePostData } from '../../hooks/use-post-data.hook';
import { AppUrls } from '../../core/route.url';
import Carrier_manage_list from './carrier-manage-list';
import LinearProgress from '@mui/material/LinearProgress';

const Carrier_manage = () => {
    const { submitData, response } = usePostData(AppUrls.carrierList, 'POST');

    const [filterList, setFilterList] = React.useState([]);
    const [isLoadList, setIsLoadList] = React.useState(true);

    React.useEffect(() => {
        if (isLoadList) {
            setIsLoadList(false);
            submitData({
                CarrierName: '',
                CarrierUniqueId: '',
            });
        }
    }, [isLoadList, response.result]);

    React.useEffect(() => {
        if (response.result != undefined) {
            response.result.Data.forEach((o) => {
                o.IsActive = o.IsActive === true ? 'Active' : 'Inactive';
            });
            setFilterList(response.result.Data);
        }
    }, [response]);

    return (
        <div className="main-content">
            {filterList.length > 0 ? (
                <>
                    <Carrier_manage_list filterList={filterList} setIsLoadList={setIsLoadList} />
                </>
            ) : (
                <>
                    <LinearProgress />
                </>
            )}
        </div>
    );
};

export default Carrier_manage;
Carrier_manage.propTypes = {};
