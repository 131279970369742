import React, { useRef } from 'react';
import { useLocation, Link } from 'react-router-dom';
import Backdrop from '@mui/material/Backdrop';
import Button from '@mui/material/Button';
import { Logout } from '../hooks/use-storage';
import { useNavigate } from 'react-router-dom';
import { useCurrentUser } from '../hooks/user-current-user.hook';
import { AppUrls } from './route.url';
import { useLoadData } from '../hooks/use-load-data.hook';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import User_impersonate from '../features/user/user-impersonate';
import { useMsal } from '@azure/msal-react';
import { useStorage } from '../hooks/use-storage';
import { Tooltip, Badge, Box } from '@mui/material';
import TimeZoneSelect from '../components/timeZone/time-zone-select';
import { useAppContext } from '../hooks/use-app-context.hook';
const authority = process.env.REACT_APP_AUTHORITY;
const SideNavbarComponent = ({ impersonateDetails }) => {
    const [isLogOutState, setisLogOutState] = useStorage('LogOut_STATE', false);
    const { instance } = useMsal();
    const { dispatch } = useAppContext();
    const user = useCurrentUser();
    const [isOpen, setIsOpen] = React.useState(false);

    let location = useLocation();
    const navigate = useNavigate();

    const [mopen, setOpen] = React.useState(false);
    const [logOut, setLogout] = React.useState(false);
    const [popen, popOpen] = React.useState(false);
    const [menuIds, setMenuIds] = React.useState(0);
    const [menuWithRoles, setMmenuWithRoles] = React.useState([]);
    const [menuDisplayList, setMenuDisplayList] = React.useState([]);
    const [userProfilePic, setUserProfilePic] = React.useState(require('../images/no-image.png'));
    const ref = useRef(null);
    const refProf = useRef(null);
    let parrentArray = [];
    let IconArray = [
        { name: 'Administration', icon: ' icon-administration' },
        { name: 'Entities', icon: ' icon-entities' },
        { name: 'Reports', icon: ' icon-reports' },
        { name: 'Users', icon: '  icon-users' },
        { name: 'Roles', icon: ' icon-roles' },
        { name: 'Notifications', icon: ' icon-notifications' },
        { name: 'Inspection Types', icon: '  icon-inspection' },
        { name: 'Assignment Types', icon: '  icon-assignment' },
        { name: 'Manage Carriers', icon: '  icon-manage-carriers' },
        { name: 'Audit Logs', icon: '  icon-audit-logs' },
        { name: 'Query Builder', icon: '  icon-query-builder' },
        { name: 'Global SLAs', icon: ' icon-global-sla' },
    ];

    const handleClose = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setOpen(false);
            setMenuIds(1000);
        }
        if (refProf.current && !refProf.current.contains(event.target)) {
            popOpen(false);
        }
    };

    const handleToggle = () => {
        setOpen(!mopen);
        setMenuIds(1000);
    };
    const profileToggle = () => {
        popOpen(!popen);
    };

    React.useEffect(() => {
        if (logOut) {
            const userData = {};
            dispatch({
                // ...user,
                ...userData,
                // currentPermissions: uniq(currentPermissions),
                isAuthenticated: true,
            });
            Logout('AUTH_STATE');
            Logout('LogOut_STATE');
            Logout('queary_data');
            Logout('report_name');

            setLogout(false);
            setisLogOutState(true);
            // window.location.href = '/';
            const authUrl = `${authority}/logout`;
            window.location.assign(authUrl);

            /*========Azure Sign out ==========*/
            /*  instance.logoutRedirect({
        postLogoutRedirectUri: "/",
    })*/
        }
    }, [logOut]);

    React.useEffect(() => {
        if (user.UserId !== undefined) {
            setMmenuWithRoles(
                user.UserMenuPermissions.filter((data, i) => {
                    return data.IsQuickLink === false;
                }),
            );
            if (
                user.UserProfileImage === undefined ||
                user.UserProfileImage === 'NULL' ||
                user.UserProfileImage === null ||
                user.UserProfileImage === ''
            ) {
                setUserProfilePic(require('../images/no-image.png'));
            } else {
                setUserProfilePic(user.UserProfileImage);
            }
            parentMenusList();
        } else {
            setUserProfilePic(require('../images/no-image.png'));
        }
    }, [user, isOpen, mopen]);

    const checkPermission = (menuName) => {
        if (user && user.UserMenuPermissions && user.UserMenuPermissions.length > 0) {
            const permissionView = user.UserMenuPermissions.filter((data, i) => {
                return data.MenuName === menuName;
            })[0]?.IsView;
            return permissionView !== undefined ? permissionView : true;
        }
    };

    const subMenuList = (menuId, menuName) => {
        menuWithRoles
            .filter((data) => data.ParentMenu === menuId)
            .forEach((element) => {
                parrentArray.push(
                    <Collapse
                        key={`${Math.random()}`}
                        // in={isOpen && menuId === menuIds}
                        in={menuId === menuIds ? true : false}
                        timeout="auto"
                        unmountOnExit
                    >
                        {checkPermission(element.MenuName) ? (
                            <ul
                                style={{
                                    marginLeft: 14,
                                    // display: isOpen && menuId === menuIds ? "" : "none",
                                }}
                                key={`${Math.random()}`}
                            >
                                <li
                                    onClick={() => {
                                        setOpen(!mopen);
                                    }}
                                >
                                    <Link to={element.MenuUrl}>
                                        {' '}
                                        <i
                                            className={
                                                IconArray.filter((data, i) => {
                                                    return data.name === element.MenuName;
                                                })[0]?.icon
                                            }
                                        />
                                        {element.MenuName}
                                    </Link>
                                </li>
                            </ul>
                        ) : null}
                    </Collapse>,
                );
                subMenuList(element.MenuId, element.MenuName);
            });
    };

    const parentMenusList = () => {
        if (menuWithRoles.length > 0) {
            setMenuDisplayList([]);
            // console.log(31, menuWithRoles);
            parrentArray.splice(0, parrentArray.length);

            menuWithRoles
                .filter((data) => data.ParentMenu === 0)
                .forEach((element) => {
                    parrentArray.push(
                        <>
                            {checkPermission(element.MenuName) ? (
                                <li
                                    key={`${element.MenuId}`}
                                    onClick={() => {
                                        // setMenuIds(element.MenuId);
                                        setMenuIds(
                                            element.MenuId === menuIds ? 10000 : element.MenuId,
                                        );
                                        setIsOpen(!isOpen);
                                    }}
                                >
                                    <a>
                                        {' '}
                                        <i
                                            className={
                                                IconArray.filter((data, i) => {
                                                    return data.name === element.MenuName;
                                                })[0]?.icon
                                            }
                                        />
                                        {element.MenuName}
                                    </a>
                                </li>
                            ) : null}
                        </>,
                    );
                    subMenuList(element.MenuId, element.MenuName);
                });

            setMenuDisplayList([]);
            setMenuDisplayList(parrentArray);
            // parrentArray.splice(0, parrentArray0)
        }
    };

    return (
        <>
            {/* <!-- Left Nav Start here--> */}
            <header className="header">
                <Link to="/">
                    <div className="logo" title="Pilot Catalyst">
                        {' '}
                    </div>
                </Link>
                <nav className="nav">
                    <ul className="primary-menu">
                        {/* <li style={{ display: 'none' }}>
                            <Link to="/">
                                <Tooltip title="Dashboard" placement="right">
                                    <i className="dashboard" />
                                </Tooltip>
                            </Link>
                            <span>Dashboard</span>
                        </li>  */}
                        {checkPermission('Claims') ? (
                            <li className={location.pathname === '/' ? 'active' : ''}>
                                <Link to="/">
                                    <Tooltip title="Claims" placement="right">
                                        <i className="claimlist" />
                                    </Tooltip>
                                </Link>
                                <span>claim List</span>
                            </li>
                        ) : null}
                        {checkPermission('Tasks') ? (
                            <li className={location.pathname === '/claimtask' ? 'active' : ''}>
                                <Link to="/claimtask">
                                    <Tooltip title=" Tasks" placement="right">
                                        <i className="checklist" />
                                    </Tooltip>
                                </Link>
                                <span>Check List</span>
                            </li>
                        ) : null}
                        {checkPermission('Map') ? (
                            <li className={location.pathname === '/mapview' ? 'active' : ''}>
                                <Link to="/mapview">
                                    <Tooltip title="Map" placement="right">
                                        <i className="map-view" />
                                    </Tooltip>
                                </Link>
                                <span>Map View</span>
                            </li>
                        ) : null}
                        {checkPermission('Resources') ? (
                            <li className={location.pathname === '/inspector' ? 'active' : ''}>
                                <Link to="/inspector">
                                    <Tooltip title="Resources" placement="right">
                                        <i className="userslist" />
                                    </Tooltip>
                                </Link>
                            </li>
                        ) : null}
                        {checkPermission('Carriers') ? (
                            <li className={location.pathname === '/carriers' ? 'active' : ''}>
                                <Link to="/carriers">
                                    <Tooltip title="Carriers" placement="right">
                                        <i className="carrierlist" />
                                    </Tooltip>
                                </Link>
                                <span>Carrier List</span>
                            </li>
                        ) : null}
                        {checkPermission('Query Builder') ? (
                            <li className={location.pathname === '/custom_query' ? 'active' : ''}>
                                <Link to="/custom_query">
                                    <Tooltip title="Query Builder" placement="right">
                                        <i className="quryBuilder" />
                                    </Tooltip>
                                </Link>
                                <span>Query Builder</span>
                            </li>
                        ) : null}
                        {/* <li className={location.pathname === '/saved_query' ? 'active' : ''}>
                            <Link to="/saved_query">
                                <Tooltip title="Saved Queries" placement="right">
                                    <i className="iconReport" />
                                </Tooltip>
                            </Link>
                            <span>Saved Queries</span>
                        </li> */}

                        {checkPermission('Profiles') ? (
                            <li
                                // style={{ display: 'none' }}
                                className={location.pathname === '/profiles' ? 'active' : ''}
                            >
                                <Link to="/profiles">
                                    <Tooltip title="Profiles" placement="right">
                                        <i className="resumes" />
                                    </Tooltip>
                                </Link>
                                <span>Profiles</span>
                            </li>
                        ) : null}
                    </ul>
                    <ul className="stack-menu-bottom">
                        {/* <li>
                            <Link to="/alerts">
                                <Tooltip title="Alert" placement="right">
                                   
                                    <i className="alert-icon" />
                                   
                                </Tooltip>
                            </Link>
                            <span>Alert</span>
                        </li> */}
                        <li
                            className={
                                location.pathname === '/user' ||
                                location.pathname === '/inspection_type' ||
                                location.pathname === '/assignmentTypes'
                                    ? 'active'
                                    : ''
                            }
                        >
                            <a onClick={handleToggle}>
                                <Tooltip title=" Settings " placement="right">
                                    <i className="settings" />
                                </Tooltip>
                            </a>

                            <span>settings</span>
                        </li>
                        <li>
                            <a
                                onClick={profileToggle}
                                style={{ position: 'relative', left: '-3px' }}
                            >
                                <figure className="user-profile">
                                    <img alt=" " src={userProfilePic} />
                                </figure>
                            </a>
                            <span>User Name</span>
                        </li>
                    </ul>
                </nav>
            </header>
            {/* Left Nav end here */}
            <Backdrop
                className="SettingsPop"
                sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={mopen}
                onClick={(e) => handleClose(e)}
            >
                <div className="popWrap" ref={ref}>
                    <ul>
                        {menuDisplayList !== undefined || menuDisplayList.length > 0
                            ? menuDisplayList.map((data, index) => {
                                  return data;
                              })
                            : null}
                    </ul>
                </div>
            </Backdrop>

            <Backdrop
                className="profilePop"
                sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={popen}
                onClick={handleClose}
            >
                <div className="popWrap" ref={refProf}>
                    <div className="userimgwrap">
                        <figure className="user-profile">
                            <img alt=" " src={userProfilePic} />
                        </figure>
                        <h4>{user.UserName}</h4>
                    </div>
                    <div
                        className="dide imp"
                        style={{ display: user.AllowImpersonate === true ? '' : 'none' }}
                    >
                        <User_impersonate
                            impersonateDetails={impersonateDetails}
                            popOpen={popOpen}
                        />
                    </div>
                    <div className="dide">
                        <TimeZoneSelect popOpen={popOpen} popen={popen} />
                    </div>
                    <div className="pro-card-footer">
                        <Box></Box>
                        <Button style={{ display: 'none' }} href="#outlined-buttons">
                            Manage my profile
                        </Button>
                        <Button
                            variant="contained"
                            onClick={() => {
                                setLogout(true);
                            }}
                            // href="#outlined-buttons"
                        >
                            Logout
                        </Button>
                    </div>
                </div>
            </Backdrop>
        </>
    );
};

export default SideNavbarComponent;
