import PropTypes from 'prop-types';
import { MarkerClusterer, Marker } from '@react-google-maps/api';
import { getMarkerPath } from '../../utils/marker-util';
import { MAP_MARKER_SELECT_COLOR } from '../../utils/constants';
import { MapMarkerComponent } from './map-marker.component';

export const MarkerClustererComponent = ({
    markerData,
    showMarkerInfo,
    showClusters,
    onMarkerClick,
}) => {
    const iconOptions = {
        imagePath:
            'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
    };

    const getMarker = (markerType, { fillColor, strokeColor }, isSelected) => {
        return {
            path: getMarkerPath(markerType),
            fillColor: fillColor,
            fillOpacity: 1,
            strokeColor: isSelected ? MAP_MARKER_SELECT_COLOR : strokeColor,
            strokeWeight: 1,
            anchor: new window.google.maps.Point(15, 30),
        };
    };

    const handleMarkerClick = (markerId, dataType, data) => {
        if (onMarkerClick) {
            onMarkerClick(markerId, dataType, data);
        }
        showMarkerInfo();
    };

    return (
        <>
            {showClusters && (
                <MarkerClusterer options={iconOptions}>
                    {(clusterer) =>
                        markerData.map(
                            (
                                { position, data, dataType, color, markerType, id, isSelected },
                                index,
                            ) => (
                                <MapMarkerComponent
                                    index={index}
                                    position={position}
                                    clusterer={clusterer}
                                    markerType={markerType}
                                    color={color}
                                    isSelected={isSelected}
                                    id={id}
                                    dataType={dataType}
                                    data={data}
                                    getMarker={getMarker}
                                    handleMarkerClick={handleMarkerClick}
                                    showMarkerInfo={showMarkerInfo}
                                />
                            ),
                        )
                    }
                </MarkerClusterer>
            )}
            {!showClusters &&
                markerData.map(
                    ({ position, data, dataType, color, markerType, id, isSelected }, index) => (
                        <Marker
                            key={index}
                            position={position}
                            icon={getMarker(markerType, color, isSelected)}
                            onClick={() => handleMarkerClick(id, dataType, data)}
                            onMouseOver={() => showMarkerInfo(position, dataType, data)}
                        />
                    ),
                )}
        </>
    );
};

MarkerClustererComponent.propTypes = {
    markerData: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.any.isRequired,
            position: PropTypes.shape({
                lat: PropTypes.number.isRequired,
                lng: PropTypes.number.isRequired,
            }),
            markerType: PropTypes.string.isRequired,
            isSelected: PropTypes.bool,
            dataType: PropTypes.string.isRequired,
            color: PropTypes.shape({
                fillColor: PropTypes.string.isRequired,
                strokeColor: PropTypes.string,
            }),
            data: PropTypes.any.isRequired,
        }).isRequired,
    ).isRequired,
    onMarkerClick: PropTypes.func,
    showMarkerInfo: PropTypes.func.isRequired,
};
