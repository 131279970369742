import React from 'react';
import { process } from '@progress/kendo-data-query';
import { Grid, GridColumn as Column, GridToolbar } from '@progress/kendo-react-grid';
import { columnsForClaimTaskInspectorProfile } from '../../components/kendoGrid/columns';
import { ColumnMenu } from '../../components/kendoGrid/customColumnMenu';
import viewIcon from '../../images/eye-outline.svg';
import iconexcel from '../../images/file-excel.svg';
import iconpdf from '../../images/file-pdf-solid.svg';
import { GridPDFExport } from '@progress/kendo-react-pdf';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { encryptWithAES } from '../../utils/global-functions';
import useUserPermissions from '../../hooks/use-user-permissions.hook';
import moment from 'moment';

const Inspector_claim_task = (props) => {
    const navigate = useNavigate();
    const { handleSetPrintPermissions } = useUserPermissions();
    const location = useLocation();
    const createDataState = (dataState) => {
        return {
            result: process(props.filterList.slice(0), dataState),
            dataState: dataState,
        };
    };

    const [stateColumns, setStateColumns] = React.useState(columnsForClaimTaskInspectorProfile);

    const [initialState, setInitialState] = React.useState(
        createDataState({
            take: 100,
            skip: 0,
        }),
    );
    const [result, setResult] = React.useState(initialState.result);

    React.useEffect(() => {
        setInitialState(
            createDataState({
                take: 100,
                skip: 0,
            }),
        );
    }, [props.filterList]);

    React.useEffect(() => {
        setResult(initialState.result);
    }, [initialState]);

    const [dataState, setDataState] = React.useState(initialState.dataState);

    const dataStateChange = (event) => {
        let updatedState = createDataState(event.dataState);
        setResult(updatedState.result);
        setDataState(updatedState.dataState);
    };

    const onColumnsSubmit = (columnsState) => {
        setStateColumns(columnsState);
    };

    const _export = React.useRef(null);

    const excelExport = () => {
        if (_export.current !== null) {
            _export.current.save();
        }
    };

    let _pdfExport;

    const exportPDF = () => {
        _pdfExport.save();
    };

    const navigateCarrier = (props) => {
        if (props.rowType === 'groupHeader') {
            return null;
        }
        return (
            <>
                <td className="k-command-cell hover">
                    <a
                        onClick={() => {
                            navigate('/carriers/view', {
                                state: { carrierId: `${props.dataItem.CarrierUniqueId}` },
                            });
                        }}
                    >
                        {props.dataItem.CarrierName}
                    </a>
                </td>
            </>
        );
    };

    const editCell = (props) => {
        if (props.rowType === 'groupHeader') {
            return null;
        }
        return (
            <>
                <td className="k-command-cell">
                    <button
                        onClick={() => {
                            let param = {
                                state: {
                                    page: 'task',
                                    taskGuId: props.dataItem.ClaimTaskGuID,
                                    taskCode: props.dataItem.ClaimTaskTypeId,
                                    guId: props.dataItem.ClaimGuID,
                                },
                            };
                            let encryptedParam = encryptWithAES(JSON.stringify(param));
                            navigate(`/claimtask/${encryptedParam}`);
                        }}
                        className="icon-btn grid-icon"
                    >
                        <img src={viewIcon} title="view" />
                    </button>
                </td>
            </>
        );
    };

    const exportGrid = (value) => {
        return (
            <Grid
                data={result}
                style={{
                    maxHeight: 'calc(100vh - 234px)',
                    display: value ? 'none' : '',
                }}
                {...dataState}
                onDataStateChange={dataStateChange}
                sortable={true}
                pageable={{
                    buttonCount: 5,
                    info: true,
                    type: 'numeric',
                    pageSizes: [100, 200, 500],
                    previousNext: true,
                }}
                groupable={true}
                pageSize={10}
                // pageSize={8}
            >
                {stateColumns
                    .filter((data, i) => {
                        return data.show === true && data.field !== 'optn';
                    })
                    .map((d, index) => {
                        return <Column key={index} field={d.field} title={d.title} />;
                    })}
                {/* <Column key={1} field="ClaimNumber" title="Claim Number" />
                <Column key={2} field="InsuredName" title="Insured Name" />
                <Column key={3} field="CarrierName" title="Carrier" />
                <Column key={4} field="StateCode" title="State Code" />
                <Column key={5} field="ClaimStatusName" title="Status" />
                <Column key={6} field="ScheduledOn" title="Scheduled" />
                <Column key={7} field="InspectedOn" title="Inspected" />
                <Column key={8} field="AssignedToName" title="Assigned" />
                <Column key={9} field="ScheduledOn" title="Scheduled" />
                <Column key={9} field="InspectedOn" title="Inspected" /> */}
            </Grid>
        );
    };

    return (
        <>
            <div className="claims-list-grid" style={{ marginBottom: '10px' }}>
                <Grid
                    data={result}
                    style={{
                        maxHeight: 'calc(100vh - 182px)',
                    }}
                    {...dataState}
                    onDataStateChange={dataStateChange}
                    sortable={true}
                    pageable={{
                        buttonCount: 5,
                        info: true,
                        type: 'numeric',
                        pageSizes: [100, 200, 500],
                        previousNext: true,
                    }}
                    groupable={true}
                    pageSize={10}
                >
                    <GridToolbar>
                        <div className="search-wrap"></div>
                    </GridToolbar>
                    <GridToolbar>
                        {location.pathname.split('/').includes('inspector') ? (
                            handleSetPrintPermissions('Resources') ? (
                                <div>
                                    <button
                                        title="Export to Excel"
                                        className="icon-btn"
                                        onClick={excelExport}
                                    >
                                        <img src={iconexcel} title="Export to Excel" />
                                    </button>

                                    <button className="icon-btn" onClick={exportPDF}>
                                        <img src={iconpdf} title="Export to PDF" />
                                    </button>
                                </div>
                            ) : null
                        ) : handleSetPrintPermissions('Users') ? (
                            <div>
                                <button
                                    title="Export to Excel"
                                    className="icon-btn"
                                    onClick={excelExport}
                                >
                                    <img src={iconexcel} title="Export to Excel" />
                                </button>

                                <button className="icon-btn" onClick={exportPDF}>
                                    <img src={iconpdf} title="Export to PDF" />
                                </button>
                            </div>
                        ) : null}
                    </GridToolbar>
                    {stateColumns.map(
                        (column, idx) =>
                            column.show &&
                            (column.field === 'CarrierName' ? (
                                <Column
                                    key={idx}
                                    field={column.field}
                                    title={column.title}
                                    cell={navigateCarrier}
                                    filter={column.filter}
                                    columnMenu={(props) => (
                                        <ColumnMenu
                                            {...props}
                                            columns={stateColumns}
                                            onColumnsSubmit={onColumnsSubmit}
                                        />
                                    )}
                                />
                            ) : column.field === 'ClaimTaskNumber' ? (
                                <Column
                                    key={idx}
                                    field={column.field}
                                    title={column.title}
                                    cell={(props) =>
                                        props.rowType === 'groupHeader' ? null : (
                                            <td className="k-command-cell hover">
                                                {' '}
                                                <a
                                                    onClick={() => {
                                                        let param = {
                                                            state: {
                                                                page: 'task',
                                                                taskGuId:
                                                                    props.dataItem.ClaimTaskGuID,
                                                                taskCode:
                                                                    props.dataItem.ClaimTaskTypeId,
                                                                guId: props.dataItem.ClaimGuID,
                                                            },
                                                        };
                                                        let encryptedParam = encryptWithAES(
                                                            JSON.stringify(param),
                                                        );
                                                        navigate(`/claimtask/${encryptedParam}`);
                                                    }}
                                                >
                                                    {props.dataItem.ClaimTaskNumber}
                                                </a>
                                            </td>
                                        )
                                    }
                                    filter={column.filter}
                                    columnMenu={(props) => (
                                        <ColumnMenu
                                            {...props}
                                            columns={stateColumns}
                                            onColumnsSubmit={onColumnsSubmit}
                                        />
                                    )}
                                />
                            ) : column.field === 'ClaimNumber' ? (
                                <Column
                                    key={idx}
                                    field={column.field}
                                    title={column.title}
                                    cell={(props) =>
                                        props.rowType === 'groupHeader' ? null : (
                                            <td className="k-command-cell hover">
                                                {' '}
                                                <a
                                                    onClick={() => {
                                                        let param = {
                                                            state: {
                                                                page: 'claim',
                                                                guId: props.dataItem.ClaimGuID,
                                                            },
                                                        };
                                                        let encryptedParam = encryptWithAES(
                                                            JSON.stringify(param),
                                                        );
                                                        navigate(`/${encryptedParam}`, {
                                                            state: {
                                                                page: 'claim',
                                                            },
                                                        });
                                                    }}
                                                >
                                                    {props.dataItem.ClaimNumber}
                                                </a>
                                            </td>
                                        )
                                    }
                                    filter={column.filter}
                                    columnMenu={(props) => (
                                        <ColumnMenu
                                            {...props}
                                            columns={stateColumns}
                                            onColumnsSubmit={onColumnsSubmit}
                                        />
                                    )}
                                />
                            ) : column.field === 'optn' ? (
                                <Column
                                    width={100}
                                    key={idx}
                                    field={column.field}
                                    title={column.title}
                                    cell={editCell}
                                    filter={column.filter}
                                    columnMenu={(props) => (
                                        <ColumnMenu
                                            {...props}
                                            columns={stateColumns}
                                            onColumnsSubmit={onColumnsSubmit}
                                        />
                                    )}
                                />
                            ) : (
                                <Column
                                    key={idx}
                                    field={column.field}
                                    title={column.title}
                                    // cell={editCell}
                                    filter={column.filter}
                                    columnMenu={(props) => (
                                        <ColumnMenu
                                            {...props}
                                            columns={stateColumns}
                                            onColumnsSubmit={onColumnsSubmit}
                                        />
                                    )}
                                />
                            )),
                    )}
                </Grid>
                <ExcelExport
                    fileName={`InspectorClaimTaskExport_${moment(new Date()).format(
                        'MM-DD-YYYY hh mm a',
                    )}`}
                    style={{ display: 'none' }}
                    data={
                        props?.filterList
                            ? process(props?.filterList, {
                                  take: props?.filterList.length + 1,
                                  skip: 0,
                                  group: dataState.group,
                                  filter: dataState.filter,
                              }).data
                            : []
                    }
                    groupable={true}
                    group={dataState.group}
                    ref={_export}
                >
                    {exportGrid(true)}
                </ExcelExport>
                <GridPDFExport
                    fileName={`InspectorClaimTaskExport_${moment(new Date()).format(
                        'MM-DD-YYYY hh mm a',
                    )}`}
                    ref={(element) => {
                        _pdfExport = element;
                    }}
                    margin="1cm"
                >
                    {exportGrid(false)}
                </GridPDFExport>
            </div>
        </>
    );
};

export default Inspector_claim_task;

Inspector_claim_task.propTypes = {
    filterList: PropTypes.array.isRequired,
};
