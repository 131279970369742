import * as React from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import { AppUrls } from '../../core/route.url';
import { usePostData } from '../../hooks/use-post-data.hook';
import CarrierClaimList from './carrier-claim-list';
import PropTypes from 'prop-types';
import Moment from 'moment';

const CarrierClaimsTab = ({
    CarrierUniqueID,
    fromDatevalue,
    toDatevalue,
    statusValue,
    handleReload,
    response,
}) => {
    //Assign Claim List API
    // const { submitData, response } = usePostData(AppUrls.claimListForCarrier, 'POST');

    const [filterList, setFilterList] = React.useState([]);
    const [initialResult, setInitialResult] = React.useState([]);

    //Call Claim API
    // React.useEffect(() => {
    //     if (isclaimLoad == true && CarrierUniqueID != undefined) {
    //         setIsclaimLoad(false);
    //         submitData({
    //             PageNo: 1,
    //             PageSize: 500,
    //             SortDir: 'Ascending',
    //             SortExp: '',
    //             StartDate: fromDatevalue,
    //             EndDate: toDatevalue,
    //             CarrierUniqueID: CarrierUniqueID,
    //             StatusId: statusValue,
    //         });
    //     }
    // }, [isclaimLoad, CarrierUniqueID]);

    React.useEffect(() => {
        if (response.result !== undefined ? (response.result.Data !== null ? 1 : 0) : 0) {
            if (response.result.Data.length > 0) {
                response.result.Data.forEach((o) => {
                    o.InspectedOn =
                        o.InspectedOn === null
                            ? ''
                            : Moment(o.InspectedOn).format('MM-DD-YYYY hh:mm A');
                    o.ScheduledOn =
                        o.ScheduledOn === null
                            ? ''
                            : Moment(o.ScheduledOn).format('MM-DD-YYYY hh:mm A');
                    o.DateReceived =
                        o.DateReceived === null
                            ? ''
                            : Moment(o.DateReceived).format('MM-DD-YYYY hh:mm A');
                    o.ReservationStart =
                        o.ReservationStart === null
                            ? ''
                            : Moment(o.ReservationStart).format('MM-DD-YYYY hh:mm A');
                    o.ReservationEnd =
                            o.ReservationEnd === null
                                ? ''
                                : Moment(o.ReservationEnd).format('MM-DD-YYYY hh:mm A');
                });
                setFilterList(
                    response.result.Data.map((dataItem) =>
                        Object.assign(
                            {
                                selected: false,
                            },
                            dataItem,
                        ),
                    ),
                );
                setInitialResult(
                    response.result.Data.map((dataItem) =>
                        Object.assign(
                            {
                                selected: false,
                            },
                            dataItem,
                        ),
                    ),
                );
            } else {
                setFilterList([]);
                setInitialResult([]);
            }
        }
    }, [response]);

    // const handleReload = () => {
    //     submitData({
    //         PageNo: 1,
    //         PageSize: 500,
    //         SortDir: 'Ascending',
    //         SortExp: '',
    //         StartDate: fromDatevalue,
    //         EndDate: toDatevalue,
    //         CarrierUniqueID: CarrierUniqueID,
    //         StatusId: 0,
    //     });
    // };

    const OnHandleChange = (searchterm) => {
        setFilterList(
            initialResult.filter((data) => {
                return (
                    (data.ClaimNumber &&
                        data.ClaimNumber.toLowerCase().includes(searchterm.toLocaleLowerCase())) ||
                    (data.CarrierName &&
                        data.CarrierName.toLowerCase().includes(searchterm.toLocaleLowerCase())) ||
                    (data.InsuredName &&
                        data.InsuredName.toLowerCase().includes(searchterm.toLocaleLowerCase())) ||
                    (data.StateCode &&
                        data.StateCode.toLowerCase().includes(searchterm.toLocaleLowerCase())) ||
                    (data.ClaimStatusName &&
                        data.ClaimStatusName.toLowerCase().includes(
                            searchterm.toLocaleLowerCase(),
                        )) ||
                    (data.SLAStatusTemp &&
                        data.SLAStatusTemp.toLowerCase().includes(
                            searchterm.toLocaleLowerCase(),
                        )) ||
                    (data.AssignedToName &&
                        data.AssignedToName.toLowerCase().includes(
                            searchterm.toLocaleLowerCase(),
                        )) ||
                    (data.ScheduledOn &&
                        data.ScheduledOn.toLowerCase().includes(searchterm.toLocaleLowerCase())) ||
                    (data.InspectedOn &&
                        data.InspectedOn.toLowerCase().includes(searchterm.toLocaleLowerCase()))
                );
            }),
        );
    };

    return (
        <>
            <div>
                {response.result != undefined ? (
                    <>
                        {filterList.length > 0 ? (
                            <CarrierClaimList
                                claimList={filterList}
                                OnHandleChange={OnHandleChange}
                                handleReload={handleReload}
                            />
                        ) : (
                            <CarrierClaimList
                                claimList={[]}
                                OnHandleChange={OnHandleChange}
                                handleReload={handleReload}
                            />
                        )}
                    </>
                ) : (
                    <>
                        <LinearProgress />
                    </>
                )}
            </div>
        </>
    );
};
export default CarrierClaimsTab;
CarrierClaimsTab.propTypes = {
    CarrierUniqueID: PropTypes.any.isRequired,
    fromDatevalue: PropTypes.any.isRequired,
    toDatevalue: PropTypes.any.isRequired,
    statusValue: PropTypes.any,
    handleReload: PropTypes.func,
    response: PropTypes.any,
};
