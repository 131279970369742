import { useEffect, useState } from 'react';
import { getData } from '../utils/network.client';

export const useDownloadFile = (route, disable = false) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [result, setResult] = useState();
    const [paramValue, setParamValue] = useState('');
    const [dropDownData, setDropDownData] = useState({ allDropDownData: [] });
    const { allDropDownData } = dropDownData;

    const loadData = async (route, paramValues) => {
        try {
            setIsError(false);
            setIsLoading(true);
            let result = await getData(route, null, null, { responseType: 'blob' });
            if (result.status === 200) {
                setResult(result.data);
                setParamValue(paramValues);
            } else {
                setIsError(true);
            }
        } catch (ex) {
            setIsError(true);
        }
        setIsLoading(false);
    };

    const clearState=()=>{
        setResult();
    }

    return {
        isLoading,
        result,
        isError,
        reload: loadData,
        paramValue,
        allDropDownData1: allDropDownData,
        clearState,
    };
};
