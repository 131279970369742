import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    Alert,
    Box,
    Button,
    CircularProgress,
    FormControl,
    TextField,
    Modal,
    Select,
    InputLabel,
    MenuItem,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import SelectOption from '../../components/select-option';
import { AppUrls } from '../../core/route.url';
import { usePostData } from '../../hooks/use-post-data.hook';
import { useLoadData } from '../../hooks/use-load-data.hook';
import { usePostData2 } from '../../hooks/use-post-data-2.hook';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useLoadData2 } from '../../hooks/use-load-data-2.hook';

import Moment from 'moment';
import ClaimCancelDelete from './claim-cancel-delete';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 980,
    bgcolor: 'background.paper',
    boxShadow: 2,
    p: 3,
};
const ClaimInformationForm = ({
    isClaimInfoOpen,
    setIsClaimInfoOpen,
    cliamProfileDetails,
    reload,
    handleReloadStatus,
    handleRefreshTask,
    handleRefreshNotes,
    setLoadStatus,
}) => {
    const {
        register,
        handleSubmit,
        control,
        setValue,
        watch,
        formState: { errors },
    } = useForm();

    // const {
    //     isLoading: inspectionTypeLoading,
    //     result: inspectionTypeResult,
    //     isError: inspectionTypeError,
    // } = useLoadData(AppUrls.InspesctionForSelect);
    // const {
    //     isLoading: assignmentTypeLoading,
    //     result: lassignmentTypeResult,
    //     isError: assignmentTypeError,
    // } = useLoadData(AppUrls.GetAllAssignmentType);

    const {
        isLoading: perilLoading,
        result: perilResult,
        isError: perilError,
    } = useLoadData(AppUrls.GetPeril);

    const { response: carrierList, isError: carrierError } = usePostData2(AppUrls.carrierList, {
        CarrierName: '',
        CarrierUniqueId: '',
    });

    const watchAllFields = watch();

    const [savedResponse, setSaveResponse] = useState({});

    const { submitData, response } = usePostData(AppUrls.ClaimInfoSave, 'POST');
    const { result: stateList, reload: stateReload } = useLoadData2();
    useEffect(() => {
        if (isClaimInfoOpen) {
            stateReload(AppUrls.GetAllStates, '');
        }
    }, [isClaimInfoOpen]);
    const EgleView = [
        {
            value: '1',
            label: 'Yes',
        },
        {
            value: '0',
            label: 'No',
        },
    ];

    useEffect(() => {
        if (response.result != undefined) {
            setSaveResponse(response.result);
            reload();
            const timer = setTimeout(() => {
                setSaveResponse({});

                setIsClaimInfoOpen(false);
            }, 1000);
            return () => clearTimeout(timer);
        }
    }, [response]);

    React.useEffect(() => {
        if (cliamProfileDetails != undefined && cliamProfileDetails !== null) {
            setValue('CarrierId', cliamProfileDetails.CarrierId);
            setValue('lossDate', cliamProfileDetails.DateOfLoss);
            setValue('mfn', cliamProfileDetails.MFN);
            // setValue('perilId', cliamProfileDetails.PerilId);
            setValue('policy', cliamProfileDetails.PolicyNo);
            // setValue('policyType', cliamProfileDetails.PolicyType);
            setValue('typeOfLoss', cliamProfileDetails.TypeOfLoss);
            setValue('inspectionType', cliamProfileDetails.InspectionTypeId);
            setValue('assignmentTypeId', cliamProfileDetails.AssignmentTypeId);
            setValue(
                'egleView',
                cliamProfileDetails.HasEagleView !== null
                    ? cliamProfileDetails.HasEagleView === true
                        ? '1'
                        : '0'
                    : null,
            );
            setValue('address', cliamProfileDetails.PropertyStreet);
            setValue('State', cliamProfileDetails.StateCode);
            setValue('city', cliamProfileDetails.City);
            setValue('zip', cliamProfileDetails.Zip);
        }
    }, [cliamProfileDetails]);

    if (
        // inspectionTypeError || assignmentTypeError ||
        perilError
    ) {
        return <div>Error Loading Data</div>;
    }

    if (
        // inspectionTypeLoading || assignmentTypeLoading ||
        perilLoading
    ) {
        return <div>Loading....</div>;
    }

    const onSubmit = async (data) => {
        submitData({
            IsActive: true,
            ClaimGuID: cliamProfileDetails.ClaimGuID,
            InspectionType: '',
            // data.inspectionType !== undefined
            //     ? inspectionTypeResult.Data !== undefined
            //         ? inspectionTypeResult.Data !== null
            //             ? inspectionTypeResult.Data.filter((d) => {
            //                   return d.InspectionTypeId === data.inspectionType;
            //               })[0]?.InspectionType
            //             : ''
            //         : ''
            //     : '',
            AssignmentType: '',
            // data.assignmentTypeId !== undefined
            //     ? data.assignmentTypeId !== 0 || lassignmentTypeResult.Data !== undefined
            //         ? lassignmentTypeResult.Data !== null
            //             ? lassignmentTypeResult.Data.filter((d) => {
            //                   return d.AssignmentTypeId === data.assignmentTypeId;
            //               })[0]?.AssignmentType
            //             : ''
            //         : ''
            //     : '',
            InspectionTypeId: 0,
            //  data.inspectionType,
            AssignmentTypeId: 0,
            //  data.assignmentTypeId,
            InsuredOfficePhone: cliamProfileDetails.InsuredOfficePhone,
            InsuredCellPhone: cliamProfileDetails.InsuredCellPhone,
            InsuredAltPhone: cliamProfileDetails.InsuredAltPhone,
            CarrierId: data.CarrierId,
            CarrierName:
                data.CarrierId !== undefined
                    ? data.CarrierId !== 0 || carrierList.result?.Data !== undefined
                        ? carrierList.result.Data !== null
                            ? carrierList.result.Data.filter((d) => {
                                  return d.CarrierId === data.CarrierId;
                              })[0]?.CarrierName
                            : ''
                        : ''
                    : '',
            PolicyNo: data.policy,
            // PolicyType: data.policyType,
            TypeOfLoss: data.typeOfLoss,
            LossTypeId: 0,
            DateOfLoss: data.lossDate,

            // PerilName:
            //     data.perilId !== undefined
            //         ? data.perilId !== 0 || perilResult.Data !== undefined
            //             ? perilResult.Data?.filter((d) => {
            //                   return d.PerilId === data.perilId;
            //               })[0]?.PerilName
            //             : ''
            //         : '',
            // PerilId: data.perilId,
            InsuredName: cliamProfileDetails.InsuredName,
            InsuredFirstName: '',
            InsuredLastName: '',
            InsuredAddress: cliamProfileDetails.InsuredAddress,
            InsuredEmail: cliamProfileDetails.InsuredEmail,
            InsuredPreferredContact: cliamProfileDetails.InsuredPreferredContact,
            MFN: data.mfn,
            HasEagleView: data.egleView === '1' ? true : false,
            Property_Street: data.address,
            Property_StateId:
                stateList && stateList?.Data
                    ? stateList.Data.filter((e) => {
                          return e.StateCode === data.State;
                      })[0]?.StateId
                    : 0,
            Property_StateCode: data.State,
            Property_StateName:
                stateList && stateList?.Data
                    ? stateList.Data.filter((e) => {
                          return e.StateCode === data.State;
                      })[0]?.StateName
                    : '',
            Property_City: data.city,
            Property_postal: data.zip,
            Insured_City: cliamProfileDetails.Insured_City,
            Insured_Zip: cliamProfileDetails.Insured_Zip,
            Insured_StateName: cliamProfileDetails.Insured_StateName,
            Insured_StateCode: cliamProfileDetails.Insured_StateCode,
            Insured_StateId: cliamProfileDetails.Insured_StateId,
            Insured_Street: cliamProfileDetails.InsuredStreet,
        });
    };

    // if (carrierError) {
    //     return <div>Error Loading Data</div>;
    // }
    const handleClose = () => {
        setIsClaimInfoOpen(false);
    };

    return (
        <>
            <Modal
                open={isClaimInfoOpen}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div className="d-flex" style={{}}>
                        <ClaimCancelDelete
                            cliamProfileDetails={cliamProfileDetails}
                            reload={reload}
                            handleReloadStatus={handleReloadStatus}
                            handleRefreshTask={handleRefreshTask}
                            handleRefreshNotes={handleRefreshNotes}
                            handleCloseModal={handleClose}
                            setLoadStatus={setLoadStatus}
                        />
                    </div>
                    <h2 style={{ marginBottom: 20 }}>Claim Information</h2>

                    <Box
                        component="form"
                        noValidate
                        autoComplete="off"
                        onSubmit={handleSubmit(onSubmit)}
                    >
                        <Box className="claim-info-poup">
                            <FormControl>
                                <Controller
                                    control={control}
                                    name="CarrierId"
                                    rules={{
                                        required: false,
                                    }}
                                    render={({ field: { onChange, onBlur, value, name } }) => (
                                        <SelectOption
                                            values={
                                                carrierList && carrierList.result !== undefined
                                                    ? carrierList.result?.Data &&
                                                      carrierList.result?.Data?.map((e) => {
                                                          return {
                                                              value: e['CarrierId'],
                                                              label: e['CarrierName'],
                                                          };
                                                      })
                                                    : []
                                            }
                                            disabled={true}
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            value={value === undefined ? '' : value}
                                            // name={name || ''}
                                            name={'Carrier ' || ''}
                                            error={errors.CarrierId}
                                        />
                                    )}
                                />
                            </FormControl>
                            <TextField
                                disabled
                                error={errors.inspectionType}
                                id="outlined-required"
                                label="MFN"
                                defaultValue=""
                                InputLabelProps={{
                                    shrink: watchAllFields.mfn === '' ? false : true,
                                }}
                                {...register('mfn', { required: false })}
                            />
                            <TextField
                                error={errors.policy}
                                id="outlined-required"
                                label="Policy #"
                                defaultValue=""
                                InputLabelProps={{
                                    shrink: watchAllFields.policy === '' ? false : true,
                                }}
                                {...register('policy', { required: false })}
                            />
                            {/* <TextField
                                error={errors.inspectionType}
                                id="outlined-required"
                                label="Policy Type"
                                defaultValue=""
                                InputLabelProps={{
                                    shrink: watchAllFields.policyType === '' ? false : true,
                                }}
                                {...register('policyType', { required: false })}
                            /> */}
                            <TextField
                                error={errors.inspectionType}
                                id="outlined-required"
                                label="Type of Loss"
                                defaultValue=""
                                InputLabelProps={{
                                    shrink: watchAllFields.typeOfLoss === '' ? false : true,
                                }}
                                {...register('typeOfLoss', { required: false })}
                            />
                            <FormControl>
                                <Controller
                                    control={control}
                                    name="lossDate"
                                    rules={{
                                        required: false,
                                    }}
                                    render={({ field: { onChange, onBlur, value, name } }) => (
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DesktopDatePicker
                                                label="Loss Date"
                                                inputFormat="dd/MM/yyyy"
                                                value={value}
                                                onChange={onChange}
                                                renderInput={(params) => <TextField {...params} />}
                                            />
                                        </LocalizationProvider>
                                    )}
                                />
                            </FormControl>

                            {/* <FormControl>
                                <Controller
                                    control={control}
                                    name="inspectionType"
                                    rules={{
                                        required: false,
                                    }}
                                    render={({ field: { onChange, onBlur, value, name } }) => (
                                        <SelectOption
                                            values={
                                                inspectionTypeResult !== undefined
                                                    ? inspectionTypeResult.Data !== undefined
                                                        ? inspectionTypeResult.Data !== null
                                                            ? inspectionTypeResult.Data.map((e) => {
                                                                  return {
                                                                      value: e['InspectionTypeId'],
                                                                      label: e['InspectionType'],
                                                                  };
                                                              })
                                                            : []
                                                        : []
                                                    : []
                                            }
                                            disabled={false}
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            value={value === undefined ? '' : value}
                                            // name={name || ''}
                                            name={'Inspection Type  ' || ''}
                                            error={errors.inspectionType}
                                        />
                                    )}
                                />
                            </FormControl>
                            <FormControl>
                                <Controller
                                    control={control}
                                    name="assignmentTypeId"
                                    rules={{
                                        required: false,
                                    }}
                                    render={({ field: { onChange, onBlur, value, name } }) => (
                                        <SelectOption
                                            values={
                                                lassignmentTypeResult !== undefined
                                                    ? lassignmentTypeResult.Data !== undefined
                                                        ? lassignmentTypeResult.Data !== null
                                                            ? lassignmentTypeResult.Data.map(
                                                                  (e) => {
                                                                      return {
                                                                          value: e[
                                                                              'AssignmentTypeId'
                                                                          ],
                                                                          label: e[
                                                                              'AssignmentType'
                                                                          ],
                                                                      };
                                                                  },
                                                              )
                                                            : []
                                                        : []
                                                    : []
                                            }
                                            disabled={false}
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            value={value === undefined ? '' : value}
                                            // name={name || ''}
                                            name={'Assignment Type  ' || ''}
                                            error={errors.assignmentTypeId}
                                        />
                                    )}
                                />
                            </FormControl> */}

                            {/* <FormControl>
                                <Controller
                                    control={control}
                                    name="perilId"
                                    rules={{
                                        required: false,
                                    }}
                                    render={({ field: { onChange, onBlur, value, name } }) => (
                                        <SelectOption
                                            values={
                                                perilResult !== undefined
                                                    ? perilResult.Data !== undefined
                                                        ? perilResult.Data !== null
                                                            ? perilResult.Data.map((e) => {
                                                                  return {
                                                                      value: e['PerilId'],
                                                                      label: e['PerilName'],
                                                                  };
                                                              })
                                                            : []
                                                        : []
                                                    : []
                                            }
                                            disabled={false}
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            value={value === undefined ? '' : value}
                                            // name={name || ''}
                                            name={'Peril' || ''}
                                            error={errors.perilId}
                                        />
                                    )}
                                />
                            </FormControl> */}
                            {/* <FormControl fullWidth>
                                <InputLabel id="EagleView">Has EagleView</InputLabel>
                                <Select labelId="EagleView" id="EagleView" label="Has EagleView">
                                    <MenuItem>Yes</MenuItem>
                                    <MenuItem>No</MenuItem>
                                </Select>
                            </FormControl> */}
                            <FormControl>
                                <Controller
                                    control={control}
                                    name="egleView"
                                    rules={{
                                        required: false,
                                    }}
                                    render={({ field: { onChange, onBlur, value, name } }) => (
                                        <SelectOption
                                            values={EgleView}
                                            disabled={false}
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            value={value === undefined ? '' : value}
                                            // name={name || ''}
                                            name={'Has EagleView' || ''}
                                            error={errors.egleView}
                                        />
                                    )}
                                />
                            </FormControl>
                            <TextField
                                size="small"
                                style={{ marginBottom: 15 }}
                                fullWidth
                                error={errors.address}
                                id="outlined-required"
                                label="Address"
                                defaultValue=""
                                InputLabelProps={{
                                    shrink: watchAllFields.address === '' ? false : true,
                                }}
                                {...register('address', { required: false })}
                            />
                            <TextField
                                size="small"
                                style={{ marginBottom: 15 }}
                                fullWidth
                                error={errors.city}
                                id="outlined-required"
                                label="City"
                                defaultValue=""
                                InputLabelProps={{
                                    shrink: watchAllFields.city === '' ? false : true,
                                }}
                                {...register('city', { required: false })}
                            />
                            <FormControl fullWidth size="small" style={{ marginBottom: 15 }}>
                                <Controller
                                    size="small"
                                    control={control}
                                    name="State"
                                    id="outlined-required"
                                    rules={{
                                        required: false,
                                    }}
                                    render={({ field: { onChange, onBlur, value, name } }) => (
                                        <SelectOption
                                            fullWidth
                                            values={
                                                stateList && stateList?.Data
                                                    ? stateList.Data.map((e) => {
                                                          return {
                                                              value: e['StateCode'],
                                                              label: e['StateName'],
                                                          };
                                                      })
                                                    : []
                                            }
                                            disabled={false}
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            value={
                                                value === undefined && value === null ? '' : value
                                            }
                                            name={name || ''}
                                            error={errors.State}
                                        />
                                    )}
                                />
                            </FormControl>
                            <TextField
                                size="small"
                                style={{ marginBottom: 15 }}
                                fullWidth
                                error={errors.zip}
                                id="outlined-required"
                                label="Zip"
                                defaultValue=""
                                InputLabelProps={{
                                    shrink: watchAllFields.zip === '' ? false : true,
                                }}
                                {...register('zip', { required: false })}
                            />
                        </Box>
                        <div className="d-flex" style={{ marginTop: 20 }}>
                            <Button
                                style={{ marginLeft: 2 }}
                                onClick={() => {
                                    setIsClaimInfoOpen(false);
                                    setValue('noteBody', '');
                                }}
                                variant="outlined"
                                type="button"
                            >
                                Cancel
                            </Button>

                            <Button
                                style={{ marginLeft: 'auto' }}
                                variant="contained"
                                type="submit"
                            >
                                {response.isSubmitting ? (
                                    <CircularProgress color="inherit" />
                                ) : (
                                    'Save'
                                )}
                            </Button>
                        </div>
                    </Box>
                    {savedResponse.IsSuccess != undefined ? (
                        <Alert
                            style={{ marginTop: 15 }}
                            severity={savedResponse.IsSuccess ? 'success' : 'error'}
                        >
                            {savedResponse.Message}
                        </Alert>
                    ) : null}
                </Box>
            </Modal>
        </>
    );
};

export default ClaimInformationForm;
ClaimInformationForm.propTypes = {
    isClaimInfoOpen: PropTypes.bool,
    setIsClaimInfoOpen: PropTypes.func,
    cliamProfileDetails: PropTypes.any.isRequired,
    reload: PropTypes.func,
    handleReloadStatus: PropTypes.func,
    handleRefreshTask: PropTypes.func,
    handleRefreshNotes: PropTypes.func,
    setLoadStatus: PropTypes.func,
};
