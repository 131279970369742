import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
export const LoadingIndicator = ({ isLoading }) => {
  if (!isLoading) {
    return null;
  }
  return (
    <div
      style={{
        position: "relative",
        width: "100%",
        height: "100%",
        zIndex: 10,
        top: 0,
        left: 0,
        textAlign: "center",
      }}
    >
      <Box sx={{ width: "100%" }}>
        <LinearProgress />
      </Box>
    </div>
  );
};
