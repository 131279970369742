import React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CarrierSLAData from './carrier-sla-data';
import CarrierClaimsTab from './carrier-claims-data';
import { usePostData } from '../../hooks/use-post-data.hook';
import { AppUrls } from '../../core/route.url';
import useUserPermissions from '../../hooks/use-user-permissions.hook';
const CarrierProfileTab = ({
    CarrierUniqueID,
    fromDatevalue,
    toDatevalue,
    statusValue,
    carrierId,
    setStatusValue,
}) => {
    const { handleSetSpecialPermissions } = useUserPermissions();
    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{ p: 3 }}>
                        <Typography component={'span'}>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }
    const { submitData, response } = usePostData(AppUrls.claimListForCarrier, 'POST');
    const [isclaimLoad, setIsclaimLoad] = React.useState(true);

    React.useEffect(() => {
        if (statusValue != -1) {
            setIsclaimLoad(true);
        }
    }, [statusValue]);

    React.useEffect(() => {
        if (isclaimLoad == true && CarrierUniqueID != undefined) {
            setIsclaimLoad(false);
            submitData({
                PageNo: 1,
                PageSize: 500,
                SortDir: 'Ascending',
                SortExp: '',
                StartDate: fromDatevalue,
                EndDate: toDatevalue,
                CarrierUniqueID: CarrierUniqueID,
                StatusId: statusValue,
            });
            setStatusValue(-1);
        }
    }, [isclaimLoad, CarrierUniqueID]);

    const handleReload = () => {
        submitData({
            PageNo: 1,
            PageSize: 500,
            SortDir: 'Ascending',
            SortExp: '',
            StartDate: fromDatevalue,
            EndDate: toDatevalue,
            CarrierUniqueID: CarrierUniqueID,
            StatusId: 0,
        });
    };

    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
    };

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <>
            <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                        <Tab label="Claims" {...a11yProps(0)} />
                        <Tab
                            disabled={
                                handleSetSpecialPermissions('SLA') !== undefined
                                    ? !handleSetSpecialPermissions('SLA')
                                    : true
                            }
                            label="SLA"
                            {...a11yProps(1)}
                        />
                    </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                    <CarrierClaimsTab
                        CarrierUniqueID={CarrierUniqueID}
                        fromDatevalue={fromDatevalue}
                        toDatevalue={toDatevalue}
                        statusValue={statusValue}
                        response={response}
                        handleReload={handleReload}
                    />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <CarrierSLAData CarrierUniqueID={CarrierUniqueID} carrierId={carrierId} />
                </TabPanel>
            </Box>
        </>
    );
};
export default CarrierProfileTab;

CarrierProfileTab.propTypes = {
    CarrierUniqueID: PropTypes.any.isRequired,
    fromDatevalue: PropTypes.any.isRequired,
    toDatevalue: PropTypes.any.isRequired,
    statusValue: PropTypes.any.isRequired,
    carrierId: PropTypes.any.isRequired,
    setStatusValue: PropTypes.func,
};
