import React, { useState } from 'react';
import PropTypes from 'prop-types';
import CustomExecutQueryList from './custom-execute-query-list';
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import CustomExecutQueryListAggregates from './custom-execute-query-list-aggregates';
import { columnForCustomQuery } from '../../components/kendoGrid/columns';
import { Box, Breadcrumbs, Typography } from '@mui/material';

const CustomExecuteQuery = ({ value, runQueryResult }) => {
    console.log(value, runQueryResult);
    const { state, search } = useLocation();
    const [searchParams] = useSearchParams();
    const [filterList, setFilterList] = React.useState([]);
    const [customColumn, setCustomColumn] = React.useState([]);
    const [displayQuery, setDisplayQuery] = useState('');

    React.useEffect(() => {
        if (localStorage.getItem('queary_data') !== 'null' && value !== 0) {
            // JSON.parse(localStorage.getItem('queary_data')).DisplayQuery
            setDisplayQuery(JSON.parse(localStorage.getItem('queary_data'))?.DisplayQuery);
            if (JSON.parse(localStorage.getItem('queary_data')).Columns.length > 0) {
                setCustomColumn(
                    JSON.parse(localStorage.getItem('queary_data')).Columns.map((dataItem) =>
                        Object.assign({
                            title: dataItem.ColumnHeader,

                            field: dataItem.ColumnName,

                            show: true,
                            filter: 'text',
                        }),
                    ),
                );
                setFilterList(JSON.parse(localStorage.getItem('queary_data')).CustomReports);
            }
        }
    }, [
        localStorage.getItem('queary_data') !== null
            ? searchParams
            : localStorage.getItem('queary_data'),
    ]);

    React.useEffect(() => {
        if (runQueryResult !== 'null' && value !== undefined) {
            if (runQueryResult.Columns.length > 0) {
                setCustomColumn(
                    runQueryResult.Columns.map((dataItem) =>
                        Object.assign({
                            title: dataItem.ColumnHeader,

                            field: dataItem.ColumnName,

                            show: true,
                            filter: 'text',
                        }),
                    ),
                );
                setFilterList(runQueryResult.CustomReports);
            }
        }
    }, [runQueryResult]);

    const displayList = () => {
        if (filterList.length > 0 && customColumn.length > 0) {
            return (
                <CustomExecutQueryListAggregates
                    filterList={filterList}
                    customColumn={customColumn}
                    displayQuery={displayQuery}
                />
            );
        } else {
            if (customColumn.length > 0) {
                return (
                    <CustomExecutQueryListAggregates
                        displayQuery={displayQuery}
                        filterList={[]}
                        customColumn={customColumn}
                    />
                );
            } else {
                return <>Invalid Query</>;
            }
        }
    };

    return (
        <div>
            <Box>
                <Typography variant="h1" className="title m-0">
                    {localStorage.getItem('report_name') === ''
                        ? 'Query Result'
                        : localStorage.getItem('report_name')}
                </Typography>
                {localStorage.getItem('report_name') === '' ? null : (
                    <Breadcrumbs aria-label="breadcrumb" style={{ marginBottom: 10 }}>
                        <Link
                            to={'/'}
                            underline="hover"
                            color="inherit"
                            style={{ textDecoration: 'none' }}
                        >
                            Home
                        </Link>
                        <Link
                            to={'/execute_query'}
                            underline="hover"
                            color="text.primary"
                            aria-current="page"
                            style={{ textDecoration: 'none' }}
                        >
                            Query Report
                        </Link>
                    </Breadcrumbs>
                )}
            </Box>
            {displayList()}
        </div>
    );
};
export default CustomExecuteQuery;
CustomExecuteQuery.propTypes = {
    value: PropTypes.number,
};
