import { stringify } from 'query-string';

// Static oidc params for a single provider
// const authority = 'https://wbprocc-dev.onelogin.com/oidc/2';
// const client_id = 'ed604fa0-2c39-013b-d9f1-06ac2dc8b0a7217230';
// const redirect_uri = 'http://localhost:3000/';
// const response_type = 'id_token token';
// const scope = 'openid profile';

const authority = process.env.REACT_APP_AUTHORITY;
const client_id = process.env.REACT_APP_CLIENT_ID;
const redirect_uri = process.env.REACT_APP_LOGIN_REDIRECT_URI;
const response_type = process.env.REACT_APP_RESPONSE_TYPE;
const scope = process.env.REACT_APP_SCOPE;
export const beginAuth = ({ state, nonce }) => {
    // Generate authentication URL
    const params = stringify({
        client_id,
        redirect_uri,
        response_type,
        scope,
        state,
        nonce,
    });
    const authUrl = `${authority}/auth?${params}`;
    // console.log(authUrl);

    // Attempt login by navigating to authUrl
    window.location.assign(authUrl);
};
