import React from 'react';
import { AppUrls } from '../../core/route.url';
import { usePostData } from '../../hooks/use-post-data.hook';
import cameraIcon from '../../images/camera-plus.svg';
import { useForm } from 'react-hook-form';
import { Box, CircularProgress, Link, Stack } from '@mui/material';
const Inspector_profile_pic = (props) => {
    const {} = useForm();

    const { submitData: PostFile, response: getResponsePost } = usePostData(
        AppUrls.UserProfilePicSave,
        'POST',
        {},
        'multipart/form-data',
    );

    const [uploadFile, setUploadFile] = React.useState({});
    const hiddenFileInput = React.useRef(null);

    React.useEffect(() => {
        if (props.inspectorProfileDetails.UserProfileImage) {
            setUploadFile(props.inspectorProfileDetails.UserProfileImage);
        } else {
            setUploadFile(require('../../images/no-image.png'));
        }
    }, [props.inspectorProfileDetails.UserProfileImage]);

    function handleBrowseClick() {
        hiddenFileInput.current.click();
    }

    function handleFile(e) {
        e.preventDefault();
        if (e.target.files[0].type !== undefined) {
            setUploadFile(URL.createObjectURL(e.target.files[0]));
        }
        const formData = new FormData();
        formData.append('Image', e.target.files[0]);
        formData.append('FileName', 'Img');
        formData.append('UserUniqueId', props.inspectorProfileDetails.UserUniqueId);
        PostFile(formData);
    }

    return (
        <>
            <Stack style={{ m: 'auto', position: 'relative' }}>
                <Box
                    component={'figure'}
                    style={{ borderColor: '#337dff' }}
                    className="pic"
                    sx={{
                        backgroundImage: `url(${uploadFile})`,
                        backgroundPosition: 'center',
                        backgroundSize: '100%',
                    }}
                ></Box>
                <Link component={'button'} className="add-image" onClick={handleBrowseClick}>
                    {getResponsePost.isSubmitting ? (
                        <>
                            <CircularProgress color="inherit" />
                        </>
                    ) : (
                        <>
                            <img id="userlogo" src={cameraIcon} alt="" />
                        </>
                    )}
                </Link>
            </Stack>

            <input
                ref={hiddenFileInput}
                type="file"
                onChange={(e) => handleFile(e)}
                style={{ display: 'none' }}
                accept="image/*"
            />
        </>
    );
};
export default Inspector_profile_pic;
