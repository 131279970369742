import * as React from "react";
import { usePostData } from "../../hooks/use-post-data.hook";
import { usePostData2 } from "../../hooks/use-post-data-2.hook";
import { AppUrls } from "../../core/route.url";
import { useNavigate } from "react-router-dom";
import LinearProgress from "@mui/material/LinearProgress";
import PropTypes from 'prop-types';
const CarrierDashboard = () =>
{
const navigate = useNavigate();

const { submitData, response } = usePostData(
  AppUrls.CarrierForDashboard,
  "POST"
);
const [isLoaddt, setIsLoaddt] = React.useState(true);
const [listCarrier, setlistCarrier] = React.useState([]);


React.useEffect(() => {
  if (isLoaddt==true) {
   setIsLoaddt(false);
   submitData({
    CarrierId:0,
    CarrierName:"",
    CarrierUniqueId:""
     });
  }
 }, [isLoaddt]);


 React.useEffect(() => {
  if (response.result != undefined) {
    setlistCarrier(response.result.Data);
  }
}, [response]);


 const handleClick = (id) => {
    navigate("/carriers/view", { state: { carrierId: `${id}`} });
  };

  if(listCarrier.length==0)
  {
    return <div className="main-content">Loading...</div>
  }

  return (
   <div className="main-content">

  <section className="carrier-dashboard-header">
    <h1 className="title m-0">Carriers</h1>
    <ol className="breadcr-pilot">
      <li><a href="">Home /</a></li>
      <li>Carriers</li>
    </ol>
  </section>


 <div className="carrier-list-grid"> 
   {
   listCarrier.map(cObj => (
     
   <>
   <div id={cObj.CarrierUniqueId} className="card hover" onClick={() => handleClick(cObj.CarrierUniqueId)}>
      <div className="carrier-list-stack">
        <div className="align-self-center">
          <figure>
            {cObj.CarrierImage=="" ? (
              <>
            <img src={require("../../images/no-image.png")}  width="92" height="92" alt=""/>
            </>
            )
          :(
            <>
             <img src={cObj.CarrierImage} width="92" height="92" alt=""/>
            </>
          )}
          </figure>
        </div>
        <div>
          <h3>{cObj.CarrierName}</h3>
          <div className="xact-sec">
            <div className="light-green">XA ID: <span className="bold"> {cObj.XACarrierId}</span> </div>
            <div>
            <span className="bold">{cObj.TotalClaims} </span>              
             Claims</div>
          </div>
          <ul>
            <li className="phone"> {cObj.ContactPhone} </li>
            <li className="email"> {cObj.ContactEmail} </li>
            <li className="address">{cObj.ContactAddress}</li>
          </ul>
        </div>
      </div>
    </div>
    </>
   )   
   )}
  </div>
</div>
  );
};

export default CarrierDashboard;
CarrierDashboard.propTypes = {};