import React from 'react';

const File_upload = (props) =>
    // onChanges,
    // accept,
    // name,
    // disabled,
    {
        return (
            <div className="box1">
                <input
                    disabled={props.disabled}
                    className={`inputfile ${
                        props.disabled ? 'inputfile-101' : 'inputfile-1'
                    }  _cursor-pointer`}
                    style={{
                        opacity: '0',
                        position: 'absolute',
                        width: '100%',
                        height: '50px',
                    }}
                    type="file"
                    accept={props.accept}
                    onChange={(event) => {
                        props.onChange(event.target.files[0]);
                    }}
                    // onChange={(event: any) => {
                    //   this.props.onChange(
                    //     this.handleCompressedImage(event.target.files[0])
                    //   );
                    // }}
                    onClick={(event) => {
                        event.target.value = null;
                    }}
                />
                <label htmlFor={`${props.disabled ? 'file-101' : 'file-1'}`} style={{}}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={20}
                        height={20}
                        viewBox="0 0 20 17"
                    >
                        <path d="M10 0l-5.2 4.9h3.3v5.1h3.8v-5.1h3.3l-5.2-4.9zm9.3 11.5l-3.2-2.1h-2l3.4 2.6h-3.5c-.1 0-.2.1-.2.1l-.8 2.3h-6l-.8-2.2c-.1-.1-.1-.2-.2-.2h-3.6l3.4-2.6h-2l-3.2 2.1c-.4.3-.7 1-.6 1.5l.6 3.1c.1.5.7.9 1.2.9h16.3c.6 0 1.1-.4 1.3-.9l.6-3.1c.1-.5-.2-1.2-.7-1.5z" />
                    </svg>
                    <span>{props.name}</span>
                </label>
            </div>
        );
    };

export default File_upload;
