import { useEffect, useState } from 'react';
import { postData, postDataForOneLogin } from '../utils/network.client';

export const usePostData2 = (route, postContent) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [result, setResult] = useState();

    useEffect(() => {
        if (postContent) {
            submitData(postContent);
        }
    }, []);

    const submitData = async (data) => {
        setIsLoading(true);
        setIsError(false);
        let resultData = {};
        try {
            const result = await postData(route, data, 'POST', 'application/json');
            // console.log(result);
            if (result.status === 200) {
                resultData = result.data;
                setResult(resultData);
            } else {
                setIsError(true);
            }
        } catch (ex) {
            setIsError(true);
        }
        setIsLoading(false);
        return resultData;
    };

    return { submitData, response: { isLoading, result, isError } };
};

export const usePostDataForOneLogin = (
    route,
    method,
    optn,

    contentType,
) => {
    const [response, setResponse] = useState({ isSubmitting: false });
    // useState < PostDataHookResponse < T >> { isSubmitting: false };

    const submitData = async (data, token) => {
        let resultData;
        try {
            setResponse({ isSubmitting: true });
            //   const result =await postData <T >
            const result = await postDataForOneLogin(route, data, method, contentType, optn, token);
            // console.log(result);
            if (result.status === 200) {
                resultData = result.data;
                setResponse({ isSubmitting: false, result: result.data });
            } else {
                resultData = { message: 'Failed' };
                setResponse({
                    isSubmitting: false,
                    result: result.data,
                    error: { message: 'Failed' },
                });
            }
        } catch (ex) {
            setResponse({ isSubmitting: false, error: ex });
        }
        return resultData;
    };

    return { submitData, response };
};
