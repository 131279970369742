import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { Colors } from '../../utils/colors';

const TillNowCheckBox = (props) => {
    const { tillNow, onClickCheckBox, containerStyle } = props;
    return (
        <Grid
            item
            sx={{
                alignItems: 'center',
            }}
            display="flex"
            ml={1}
            style={containerStyle}
        >
            <Grid item component={'h4'} color={Colors.DODGER_BLUE}>
                {'Present'}
            </Grid>
            &nbsp;&nbsp;
            <input type="checkbox" checked={tillNow} onChange={onClickCheckBox} />
        </Grid>
    );
};

TillNowCheckBox.propTypes = {
    tillNow: PropTypes.bool,
    onClickCheckBox: PropTypes.func,
    containerStyle: PropTypes.any,
};

export default TillNowCheckBox;
