import React from 'react';
import {
    Grid,
    GridColumn as Column,
    getSelectedState,
    GridToolbar,
} from '@progress/kendo-react-grid';
import { getter } from '@progress/kendo-react-common';
import { columnsForClaims } from '../../components/kendoGrid/columns';
import { process } from '@progress/kendo-data-query';
import { ColumnMenu } from '../../components/kendoGrid/customColumnMenu';
import { GridPDFExport } from '@progress/kendo-react-pdf';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import TextField from '@mui/material/TextField';
import iconexcel from '../../images/file-excel.svg';
import iconpdf from '../../images/file-pdf-solid.svg';
import mapIcon from '../../images/map-marked-alt.svg';
import Icon from '@mui/material/Icon';
import editIcon from '../../images/file-signature.svg';
import deleteIcon from '../../images/file-alt.svg';
import bulkAsign from '../../images/assign-bulk.svg';
import { Link, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Tooltip } from '@progress/kendo-react-tooltip';
import { LinearProgress, Typography, Box } from '@mui/material';
import { encryptWithAES } from '../../utils/global-functions';
import { IconButton } from '@mui/material/';
import { Cached } from '@mui/icons-material';
import ClaimContact from '../claim/claim-contact';
import fileIcon from '../../images/file-alt.svg';
import desabledFileIcon from '../../images/Icon-awesome-file-alt.svg';
import viewIcon from '../../images/eye-outline.svg';
import useUserPermissions from '../../hooks/use-user-permissions.hook';
import moment from 'moment';
const DATA_ITEM_KEY = 'ClaimId';
const SELECTED_FIELD = 'selected';
const idGetter = getter(DATA_ITEM_KEY);

const CarrierClaimList = ({ claimList, OnHandleChange, handleReload }) => {
    const history = useNavigate();
    const { handleSetPrintPermissions } = useUserPermissions();
    let initialState = {};
    const [display, setDisplay] = React.useState(false);
    const [groupOfGrid, setGroupOfGrid] = React.useState([]);
    const [currentContactInfo, setCurrentContactInfo] = React.useState({
        mobile: '',
        email: '',
        claimId: '',
        emailToName: '',
    });
    const [isContactOpen, setIsContactOpen] = React.useState(false);
    const createDataState = (dataState) => {
        return {
            result: process(claimList.slice(0), dataState),
            dataState: dataState,
        };
    };

    const [stateColumns, setStateColumns] = React.useState(columnsForClaims);
    initialState = createDataState({
        take: 25,
        skip: 0,
    });

    const [result, setResult] = React.useState(initialState.result);

    React.useEffect(() => {
        if (claimList.length > 0) {
            initialState = createDataState({
                take: 25,
                skip: 0,
                group: groupOfGrid,
            });
            setResult(initialState.result);
        }
    }, [claimList]);

    const [dataState, setDataState] = React.useState(initialState.dataState);

    const dataStateChange = (event) => {
        let updatedState = createDataState(event.dataState);
        setGroupOfGrid(event.dataState.group);
        setResult(updatedState.result);
        setDataState(updatedState.dataState);
    };

    const editCell = (props) => {
        if (props.rowType === 'groupHeader') {
            return null;
        }

        return (
            <>
                <td className="k-command-cell">
                    <button
                        className="icon-btn grid-icon"
                        onClick={() => {
                            let param = {
                                state: {
                                    page: 'claim',
                                    guId: props.dataItem.ClaimGuID,
                                },
                            };
                            let encryptedParam = encryptWithAES(JSON.stringify(param));
                            history(`/${encryptedParam}`);
                        }}
                    >
                        <img src={viewIcon} title="view" />
                    </button>
                    {/* {props.dataItem.AssignedToName !== null ? (
                        <button
                            className="icon-btn file-icon"
                            onClick={() => {
                                setCurrentContactInfo({
                                    email: props.dataItem.InspectorEmail,
                                    mobile: props.dataItem.InspectorPhone,
                                    claimId: props.dataItem.ClaimGuID,
                                    emailToName: props.dataItem.AssignedToName,
                                });
                                setIsContactOpen(true);
                            }}
                        >
                            <img src={fileIcon} title=" " />
                        </button>
                    ) : (
                        <button
                            disabled={props.dataItem.AssignedToName !== null ? false : true}
                            className="icon-btn file-icon"
                        >
                            <img src={desabledFileIcon} title=" " />
                        </button>
                    )} */}
                </td>
            </>
        );
    };

    const onColumnsSubmit = (columnsState) => {
        setStateColumns(columnsState);
    };

    const [selectedState, setSelectedState] = React.useState({});
    const onSelectionChange = React.useCallback(
        (event) => {
            const newSelectedState = getSelectedState({
                event,
                selectedState: selectedState,
                dataItemKey: DATA_ITEM_KEY,
            });
            setSelectedState(newSelectedState);

            if (Object.values(newSelectedState).filter((data) => data === true).length > 2) {
                setDisplay(true);
            } else {
                setDisplay(false);
            }
        },
        [selectedState],
    );
    const onHeaderSelectionChange = React.useCallback((event) => {
        const checkboxElement = event.syntheticEvent.target;
        const checked = checkboxElement.checked;
        const newSelectedState = {};
        event.dataItems.forEach((item) => {
            newSelectedState[idGetter(item)] = checked;
        });
        setSelectedState(newSelectedState);
        if (Object.values(newSelectedState).filter((data) => data === true).length > 2) {
            setDisplay(true);
        } else {
            setDisplay(false);
        }
    }, []);

    const _export = React.useRef(null);

    const excelExport = () => {
        if (_export.current !== null) {
            _export.current.save();
        }
    };

    let _pdfExport;

    const exportPDF = () => {
        _pdfExport.save(
            process(claimList, {
                take: claimList.length + 1,
                skip: 0,
                group: dataState.group,
                filter: dataState.filter,
            }).data,
        );
    };

    const getExcelFilterList = (claimList) => {
        return claimList.map((p) => {
            if (p.DateReceived)
                p.DateReceived = moment(p.DateReceived).format('MM-DD-YYYY hh:mm a');
            if (p.ScheduledOn) p.ScheduledOn = moment(p.ScheduledOn).format('MM-DD-YYYY hh:mm a');
            if (p.InspectedOn) p.InspectedOn = moment(p.InspectedOn).format('MM-DD-YYYY hh:mm a');
            if (p.ReservationStart)
                p.ReservationStart = moment(p.ReservationStart).format('MM-DD-YYYY hh:mm a');
            if (p.AcceptedDate)
                p.AcceptedDate = moment(p.AcceptedDate).format('MM-DD-YYYY hh:mm a');
            if (p.RejectedDate)
                p.RejectedDate = moment(p.RejectedDate).format('MM-DD-YYYY hh:mm a');
            return p;
        });
    };

    const handleChangeValue = (e) => {
        OnHandleChange(e.target.value);
    };

    function LinearProgressWithLabel(props) {
        return (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ width: '100%', mr: 1 }}>
                    <LinearProgress variant="determinate" {...props} />
                </Box>
            </Box>
        );
    }

    const statusCell = (props) => {
        if (props.rowType === 'groupHeader') {
            return null;
        }
        return (
            <>
                <td className="k-command-cell hover">
                    {' '}
                    {props.dataItem.ClaimStatusName}
                    {props.dataItem.ClaimSLAColorIndicator === 'green-circle' && (
                        <LinearProgressWithLabel value={props.dataItem.Progress} color="success" />
                    )}
                    {props.dataItem.ClaimSLAColorIndicator === 'yellow-circle' && (
                        <LinearProgressWithLabel value={props.dataItem.Progress} color="warning" />
                    )}
                    {props.dataItem.ClaimSLAColorIndicator === 'red-circle' && (
                        <LinearProgressWithLabel value={props.dataItem.Progress} color="error" />
                    )}
                </td>
            </>
        );
    };

    const exportGrid = (value) => {
        return (
            <Grid
                data={
                    result !== undefined && result.data !== undefined
                        ? result.data.map((item) => ({
                              ...item,
                              [SELECTED_FIELD]: selectedState[idGetter(item)],
                          }))
                        : []
                }
                {...dataState}
                style={{
                    maxHeight: 'calc(100vh - 234px)',
                    display: value ? 'none' : '',
                }}
                dataItemKey={DATA_ITEM_KEY}
                selectedField={SELECTED_FIELD}
                selectable={{
                    enabled: true,
                    drag: false,
                    cell: false,
                    mode: 'multiple',
                }}
                onSelectionChange={onSelectionChange}
                onHeaderSelectionChange={onHeaderSelectionChange}
                onDataStateChange={dataStateChange}
                sortable={true}
                pageable={true}
                groupable={true}
                total={result.total}
                // pageSize={8}
            >
                {stateColumns
                    .filter((data, i) => {
                        return data.show === true && data.field !== 'optn';
                    })
                    .map((d, index) => {
                        return <Column key={index} field={d.field} title={d.title} />;
                    })}
            </Grid>
        );
    };

    return (
        <>
            <div className="claims-list-grid" style={{ marginBottom: '10px' }}>
                {/* <ClaimContact
                    setIsContactOpen={setIsContactOpen}
                    isContactOpen={isContactOpen}
                    email={currentContactInfo.email}
                    phoneNumber={currentContactInfo.mobile}
                    claimId={currentContactInfo.claimId}
                    EmailToName={currentContactInfo.emailToName}
                    inspectorId={null}
                /> */}
                <Grid
                    data={
                        result !== undefined && result.data !== undefined
                            ? result.data.map((item) => ({
                                  ...item,
                                  [SELECTED_FIELD]: selectedState[idGetter(item)],
                              }))
                            : []
                    }
                    {...dataState}
                    style={{
                        maxHeight: 'calc(100vh - 234px)',
                    }}
                    dataItemKey={DATA_ITEM_KEY}
                    selectedField={SELECTED_FIELD}
                    selectable={{
                        enabled: true,
                        drag: false,
                        cell: false,
                        mode: 'multiple',
                    }}
                    onSelectionChange={onSelectionChange}
                    /* onHeaderSelectionChange={onHeaderSelectionChange} */
                    onDataStateChange={dataStateChange}
                    sortable={true}
                    resizable={true}
                    pageable={{
                        buttonCount: 5,
                        info: true,
                        type: 'numeric',
                        pageSizes: [100, 200, 500],
                        previousNext: true,
                    }}
                    groupable={true}
                    total={result.total}
                    //pageSize={10}
                >
                    <GridToolbar>
                        <div className="search-wrap">
                            <Icon style={{ marginLeft: 1, display: display ? '' : 'none' }}>
                                <img src={bulkAsign} alt="Bulk Asign" />
                            </Icon>
                            <div>
                                <TextField
                                    required
                                    id="outlined-required"
                                    label=""
                                    defaultValue=""
                                    size="small"
                                    onChange={(e) => handleChangeValue(e)}
                                />
                            </div>
                            <IconButton
                                onClick={() => {
                                    handleReload();
                                }}
                                className="clearIcon"
                                title="Clear All filter"
                            >
                                <Cached />
                            </IconButton>{' '}
                        </div>
                    </GridToolbar>
                    <GridToolbar>
                        {handleSetPrintPermissions('Carriers') ? (
                            <div>
                                <button
                                    title="Export to Excel"
                                    className="icon-btn"
                                    onClick={excelExport}
                                >
                                    <img src={iconexcel} title="Export to Excel" />
                                </button>

                                <button className="icon-btn" onClick={exportPDF}>
                                    <img src={iconpdf} title="Export to PDF" />
                                </button>
                            </div>
                        ) : null}
                    </GridToolbar>
                    {/* <Column
            field={SELECTED_FIELD}
            width="40px"
            headerSelectionValue={
              result !== undefined && result.data !== undefined
                ? result.data.findIndex(
                    (item) => !selectedState[idGetter(item)]
                  ) === -1
                : 0
            }
          />*/}

                    {stateColumns.map(
                        (column, idx) =>
                            column.show &&
                            (column.field === 'optn' ? (
                                <Column
                                    width={100}
                                    key={idx}
                                    field={column.field}
                                    title={
                                        <Tooltip
                                            openDelay={100}
                                            position="top"
                                            anchorElement="target"
                                        >
                                            <div
                                                style={{
                                                    textAlign: 'center',
                                                }}
                                            >
                                                <span title={column.title}>{column.title}</span>
                                            </div>
                                        </Tooltip>
                                    }
                                    cell={editCell}
                                    filter={column.filter}
                                    columnMenu={(props) => (
                                        <ColumnMenu
                                            {...props}
                                            columns={stateColumns}
                                            onColumnsSubmit={onColumnsSubmit}
                                        />
                                    )}
                                />
                            ) : column.field === 'ClaimNumber' ? (
                                <Column
                                    width={175}
                                    key={idx}
                                    field={column.field}
                                    title={
                                        <Tooltip
                                            openDelay={100}
                                            position="top"
                                            anchorElement="target"
                                        >
                                            <div
                                                style={{
                                                    textAlign: 'center',
                                                }}
                                            >
                                                <span title={column.title}>{column.title}</span>
                                            </div>
                                        </Tooltip>
                                    }
                                    cell={(props) =>
                                        props.rowType === 'groupHeader' ? null : (
                                            <td className="k-command-cell hover">
                                                {' '}
                                                <a
                                                    onClick={() => {
                                                        let param = {
                                                            state: {
                                                                page: 'claim',
                                                                guId: props.dataItem.ClaimGuID,
                                                            },
                                                        };
                                                        let encryptedParam = encryptWithAES(
                                                            JSON.stringify(param),
                                                        );
                                                        history(`/${encryptedParam}`);
                                                    }}
                                                >
                                                    <i
                                                        className={
                                                            props.dataItem.ClaimSLAColorIndicator
                                                        }
                                                    />
                                                    {props.dataItem.ClaimNumber}
                                                </a>
                                            </td>
                                        )
                                    }
                                    filter={column.filter}
                                    columnMenu={(props) => (
                                        <ColumnMenu
                                            {...props}
                                            columns={stateColumns}
                                            onColumnsSubmit={onColumnsSubmit}
                                        />
                                    )}
                                />
                            ) : column.field === 'AssignedToName' ? (
                                <Column
                                    key={idx}
                                    field={column.field}
                                    title={
                                        <Tooltip
                                            openDelay={100}
                                            position="top"
                                            anchorElement="target"
                                        >
                                            <div
                                                style={{
                                                    textAlign: 'center',
                                                }}
                                            >
                                                <span title={column.title}>{column.title}</span>
                                            </div>
                                        </Tooltip>
                                    }
                                    cell={(props) =>
                                        props.rowType === 'groupHeader' ? null : props.dataItem
                                              .AssignedToId == null ? (
                                            <td className="k-command-cell">
                                                {props.dataItem.AssignedToName}
                                            </td>
                                        ) : (
                                            <td className="k-command-cell">
                                                {' '}
                                                <Link
                                                    to={
                                                        '/user/' +
                                                        `${props.dataItem.AssignToUniqueId}`
                                                    }
                                                >
                                                    {props.dataItem.AssignedToName}
                                                </Link>
                                            </td>
                                        )
                                    }
                                    filter={column.filter}
                                    columnMenu={(props) => (
                                        <ColumnMenu
                                            {...props}
                                            columns={stateColumns}
                                            onColumnsSubmit={onColumnsSubmit}
                                        />
                                    )}
                                />
                            ) : column.field === 'StateName' ? (
                                <Column
                                    key={idx}
                                    width={90}
                                    field={column.field}
                                    title={
                                        <Tooltip
                                            openDelay={50}
                                            position="top"
                                            anchorElement="target"
                                        >
                                            <div
                                                style={{
                                                    textAlign: 'center',
                                                }}
                                            >
                                                <span title={column.title}>{column.title}</span>
                                            </div>
                                        </Tooltip>
                                    }
                                    // cell={editCell}
                                    filter={column.filter}
                                    columnMenu={(props) => (
                                        <ColumnMenu
                                            {...props}
                                            columns={stateColumns}
                                            onColumnsSubmit={onColumnsSubmit}
                                        />
                                    )}
                                />
                            ) : column.field === 'ClaimStatusName' ? (
                                <Column
                                    key={idx}
                                    field={column.field}
                                    title={
                                        <Tooltip
                                            openDelay={100}
                                            position="top"
                                            anchorElement="target"
                                        >
                                            <div
                                                style={{
                                                    textAlign: 'center',
                                                }}
                                            >
                                                <span title={column.title}>{column.title}</span>
                                            </div>
                                        </Tooltip>
                                    }
                                    cell={statusCell}
                                    filter={column.filter}
                                    columnMenu={(props) => (
                                        <ColumnMenu
                                            {...props}
                                            columns={stateColumns}
                                            onColumnsSubmit={onColumnsSubmit}
                                        />
                                    )}
                                />
                            ) : (
                                <Column
                                    key={idx}
                                    field={column.field}
                                    title={
                                        <Tooltip
                                            openDelay={100}
                                            position="top"
                                            anchorElement="target"
                                        >
                                            <div
                                                style={{
                                                    textAlign: 'center',
                                                }}
                                            >
                                                <span title={column.title}>{column.title}</span>
                                            </div>
                                        </Tooltip>
                                    }
                                    // cell={editCell}
                                    filter={column.filter}
                                    columnMenu={(props) => (
                                        <ColumnMenu
                                            {...props}
                                            columns={stateColumns}
                                            onColumnsSubmit={onColumnsSubmit}
                                        />
                                    )}
                                />
                            )),
                    )}
                </Grid>
                {/* </ExcelExport> */}
                <ExcelExport
                    fileName={`CarrierClaimExport_${moment(new Date()).format(
                        'MM-DD-YYYY hh mm a',
                    )}`}
                    style={{ display: 'none' }}
                    ref={_export}
                    data={
                        claimList
                            ? process(claimList, {
                                  take: claimList.length + 1,
                                  skip: 0,
                                  group: dataState.group,
                                  filter: dataState.filter,
                              }).data
                            : []
                    }
                    groupable={true}
                    group={dataState.group}
                >
                    {exportGrid(true)}
                </ExcelExport>
                <GridPDFExport
                    fileName={`CarrierClaimExport_${moment(new Date()).format(
                        'MM-DD-YYYY hh mm a',
                    )}`}
                    ref={(element) => {
                        _pdfExport = element;
                    }}
                    margin="1cm"
                >
                    {exportGrid(false)}
                </GridPDFExport>
            </div>
        </>
    );
};

export default CarrierClaimList;

CarrierClaimList.propTypes = {
    claimList: PropTypes.any.isRequired,
    OnHandleChange: PropTypes.func.isRequired,
    handleReload: PropTypes.func,
};
