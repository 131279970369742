import { useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export const AlertDialog = ({ title, message, isOpen, onClose }) => {
    const handleOk = () => {
        onClose();
    };

    useEffect(() => {
        if (isOpen) {
            setTimeout(() => {
                onClose();
            }, 1250);
        }
    }, [isOpen]);

    return (
        <Dialog
            open={isOpen}
            onClose={handleOk}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">{message}</DialogContentText>
            </DialogContent>
            {/* <DialogActions>
                <Button onClick={handleOk} autoFocus>
                    OK
                </Button>
            </DialogActions> */}
        </Dialog>
    );
};
