import { useState } from 'react';
import { postData } from '../utils/network.client';
import axios from 'axios';
export const usePostData = (
    route,
    method,
    optn,

    contentType,
) => {
    const [response, setResponse] = useState({ isSubmitting: false });
    // useState < PostDataHookResponse < T >> { isSubmitting: false };

    const submitData = async (data) => {
        let resultData;
        try {
            setResponse({ isSubmitting: true });
            //   const result =await postData <T >
            const result = await postData(route, data, method, contentType, optn);

            // axios.interceptors.response.use(
            //     (response) => {

            //         return response;
            //     },
            //     (error) => {
            //         if (error.response.status === 401) {
            //             //place your reentry code

            //         }
            //         return error;
            //     },
            // );

            if (result.status === 200) {
                resultData = result.data;
                setResponse({ isSubmitting: false, result: result.data });
            } else {
                resultData = { message: 'Failed' };
                setResponse({
                    isSubmitting: false,
                    result: result.data,
                    error: { message: 'Failed' },
                });
            }
        } catch (ex) {
            setResponse({ isSubmitting: false, error: ex });
        }
        return resultData;
    };

    return { submitData, response };
};
