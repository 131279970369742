import React from 'react';
import PropTypes from 'prop-types';
import { AppUrls } from '../../core/route.url';
import { useLoadData } from '../../hooks/use-load-data.hook';
import { usePostData2 } from '../../hooks/use-post-data-2.hook';
import { useLocation, useParams } from 'react-router-dom';
import { Box, LinearProgress } from '@mui/material';
import { decryptWithAES } from '../../utils/global-functions';

const ClaimProfileStatusBar = ({ statusId, cliamProfileDetails, setLoadStatus, loadStatus }) => {
    let { id: param } = useParams();
    let decryptedParam = decryptWithAES(param?.replace(/%2F/g, '/'));
    let parsedParam = JSON.parse(decryptedParam);
    const { state } = parsedParam;
    const [value, setValue] = React.useState(0);
    const { response, submitData } = usePostData2(AppUrls.ClaimProfileStatusBar, {
        pageName: state?.page === 'claim' ? 'Claims' : 'Task',
        ClaimTaskStatusCode:
            state?.page === 'claim'
                ? ''
                : state?.taskCode === 1
                ? 'CLAIM_TASK'
                : state?.taskCode === 3
                ? 'REVIEW_TASK'
                : '',
        ClaimId:
            cliamProfileDetails != undefined && cliamProfileDetails !== null
                ? cliamProfileDetails.ClaimId
                : '',
    });

    React.useEffect(() => {
        if (loadStatus) {
            submitData({
                pageName: state?.page === 'claim' ? 'Claims' : 'Task',
                ClaimTaskStatusCode:
                    state?.page === 'claim'
                        ? ''
                        : state?.taskCode === 1
                        ? 'CLAIM_TASK'
                        : state?.taskCode === 3
                        ? 'REVIEW_TASK'
                        : '',
                ClaimId:
                    cliamProfileDetails != undefined && cliamProfileDetails !== null
                        ? cliamProfileDetails.ClaimId
                        : '',
            });
            setLoadStatus(false);
        }
    }, [loadStatus]);

    React.useEffect(() => {
        if (statusId !== undefined && state?.page === 'task' && statusId !== 0) {
            setValue(
                statusId !== null
                    ? statusId.findIndex((obj) => obj.ClaimTaskGuID === state?.taskGuId)
                    : 0,
            );
        }
    }, [statusId]);

    if (response.result === undefined) {
        return (
            <Box sx={{ width: '100%' }}>
                <LinearProgress />
            </Box>
        );
    }

    if (response.result.Data === null) {
        return (
            <Box sx={{ width: '100%' }}>
                <h2>No Data Available</h2>
            </Box>
        );
    }

    return (
        <>
            {' '}
            <ul>
                {state?.page === 'claim' ? (
                    <>
                        {statusId !== 8 ? (
                            response.result?.Data?.map((data, index) => {
                                return (
                                    <li
                                        key={index}
                                        className={
                                            data.SeqNo < statusId
                                                ? 'active'
                                                : data.SeqNo === statusId
                                                ? response.result?.Data?.length === index + 1
                                                    ? 'active'
                                                    : 'progress'
                                                : //   'progress'
                                                  ''
                                        }
                                    >
                                        {data.ClaimStatusName}
                                    </li>
                                );
                            })
                        ) : (
                            <>
                                {response.result?.Data?.map((data, index) => {
                                    return (
                                        <li
                                            key={index}
                                            className={
                                                data.SeqNo < statusId ? 'active' : ''
                                                // : data.SeqNo === statusId
                                                // ? response.result?.Data?.length === index + 1
                                                //     ? 'active'
                                                //     : 'progress'
                                                // : //   'progress'
                                                //   ''
                                            }
                                        >
                                            {data.ClaimStatusName}
                                        </li>
                                    );
                                })}
                                <li key={10} className="deleteStat">
                                    Canceled
                                </li>
                            </>
                        )}
                    </>
                ) : statusId !== 0 ? (
                    state?.taskCode === 1 ? (
                        statusId[value].StatusSeqNo !== 14 ? (
                            response.result?.Data?.map((data, index) => {
                                return (
                                    <li
                                        key={index}
                                        className={
                                            data.SeqNo < statusId[value].StatusSeqNo
                                                ? 'active'
                                                : data.SeqNo === statusId[value].StatusSeqNo
                                                ? 'active'
                                                : //   'progress'
                                                  ''
                                        }
                                    >
                                        {statusId[value].IsAltStatus &&
                                        data.SeqNo === statusId[value].StatusSeqNo
                                            ? statusId[value].ClaimTaskStatusName
                                            : data.ClaimStatusName}
                                    </li>
                                );
                            })
                        ) : (
                            <>
                                {response.result?.Data?.map((data, index) => {
                                    return (
                                        <li
                                            key={index}
                                            className={
                                                data.SeqNo < statusId[value].StatusSeqNo
                                                    ? 'active'
                                                    : data.SeqNo === statusId[value].StatusSeqNo
                                                    ? 'active'
                                                    : //   'progress'
                                                      ''
                                            }
                                        >
                                            {statusId[value].IsAltStatus &&
                                            data.SeqNo === statusId[value].StatusSeqNo
                                                ? statusId[value].ClaimTaskStatusName
                                                : data.ClaimStatusName}
                                        </li>
                                    );
                                })}
                                <li key={20} className="deleteStat">
                                    Canceled
                                </li>
                            </>
                        )
                    ) : statusId[value].StatusSeqNo !== 7 ? (
                        response.result?.Data?.map((data, index) => {
                            return (
                                <li
                                    key={index}
                                    className={
                                        data.SeqNo < statusId[value].StatusSeqNo
                                            ? 'active'
                                            : data.SeqNo === statusId[value].StatusSeqNo
                                            ? 'active'
                                            : //   'progress'
                                              ''
                                    }
                                >
                                    {statusId[value].IsAltStatus &&
                                    data.SeqNo === statusId[value].StatusSeqNo
                                        ? statusId[value].ClaimTaskStatusName
                                        : data.ClaimStatusName}
                                </li>
                            );
                        })
                    ) : (
                        <>
                            {response.result?.Data?.map((data, index) => {
                                return (
                                    <li
                                        key={index}
                                        className={
                                            data.SeqNo < statusId[value].StatusSeqNo
                                                ? 'active'
                                                : data.SeqNo === statusId[value].StatusSeqNo
                                                ? 'active'
                                                : //   'progress'
                                                  ''
                                        }
                                    >
                                        {statusId[value].IsAltStatus &&
                                        data.SeqNo === statusId[value].StatusSeqNo
                                            ? statusId[value].ClaimTaskStatusName
                                            : data.ClaimStatusName}
                                    </li>
                                );
                            })}
                            <li key={20} className="deleteStat">
                                Canceled
                            </li>
                        </>
                    )
                ) : null}
            </ul>
            {/* ------------------------------------------------------------ */}
            {/* <ul>
                {state?.page === 'claim'
                    ? response.result?.Data?.map((data, index) => {
                          return (
                              <li
                                  key={index}
                                  className={
                                      data.SeqNo < statusId
                                          ? 'active'
                                          : data.SeqNo === statusId
                                          ? response.result?.Data?.length === index + 1
                                              ? 'active'
                                              : 'progress'
                                          : //   'progress'
                                            ''
                                  }
                              >
                                  {data.ClaimStatusName}
                              </li>
                          );
                      })
                    : statusId !== 0
                    ? response.result?.Data?.map((data, index) => {
                          return (
                              <li
                                  key={index}
                                  className={
                                      data.SeqNo < statusId[value].StatusSeqNo
                                          ? 'active'
                                          : data.SeqNo === statusId[value].StatusSeqNo
                                          ? 'active'
                                          : //   'progress'
                                            ''
                                  }
                              >
                                  {statusId[value].IsAltStatus &&
                                  data.SeqNo === statusId[value].StatusSeqNo
                                      ? statusId[value].ClaimTaskStatusName
                                      : data.ClaimStatusName}
                              </li>
                          );
                      })
                    : null}
            </ul> */}
        </>
    );
};
export default ClaimProfileStatusBar;
ClaimProfileStatusBar.propTypes = {
    statusId: PropTypes.any,
    cliamProfileDetails: PropTypes.any.isRequired,
    setLoadStatus: PropTypes.func,
    loadStatus: PropTypes.bool,
};
