export const columnsForInspectionType = [
    {
        title: 'Inspection Type',
        field: 'InspectionType',
        show: true,
        filter: 'text',
    },
    {
        title: 'Inspection Description',
        field: 'InspectionDescription',
        show: true,
        filter: 'text',
    },

    {
        title: 'Active',
        field: 'IsActive',
        show: true,
        filter: 'text',
    },
    {
        title: 'Options',
        field: 'optn',
        show: true,
        filter: 'text',
    },
];

export const columnsForClaims = [
    {
        title: 'Claim',
        field: 'ClaimNumber',
        show: true,
        filter: 'text',
        minWidth: 200,
    },
    {
        title: 'Carrier',
        field: 'CarrierName',
        show: true,
        filter: 'text',
    },
    {
        title: 'Loss Type',
        field: 'TypeOfLoss',
        show: true,
        filter: 'text',
    },
    {
        title: 'Insured',
        field: 'InsuredName',
        show: true,
        filter: 'text',
    },

    {
        title: 'City',
        field: 'City',
        show: true,
        filter: 'text',
    },
    {
        title: 'State',
        field: 'StateCode',
        show: true,
        filter: 'text',
    },
    {
        title: 'Received',
        field: 'DateReceived',
        show: true,
        filter: 'date',
        format: '{0:D}',
    },
    {
        title: 'Assigned To',
        field: 'AssignedToName',
        show: true,
        filter: 'text',
    },
    {
        title: 'Reservation',
        field: 'ReservationStart',
        show: true,
        filter: 'date',
        format: '{0:D}',
    },
    {
        title: 'Accepted',
        field: 'AcceptedDate',
        show: true,
        filter: 'date',
        format: '{0:D}',
    },
    {
        title: 'Rejected',
        field: 'RejectedDate',
        show: true,
        filter: 'date',
        format: '{0:D}',
    },
    {
        title: 'Scheduled',
        field: 'ScheduledOn',
        show: true,
        filter: 'date',
        format: '{0:D}',
    },
    {
        title: 'Inspected',
        field: 'InspectedOn',
        show: true,
        filter: 'date',
        format: '{0:D}',
    },
    {
        title: 'Status',
        field: 'ClaimStatusName',
        show: true,
        filter: 'text',
    },
    {
        title: 'SLA',
        field: 'SLAStatusTemp',
        show: true,
        filter: 'text',
    },

    {
        title: 'Options',
        field: 'optn',
        show: true,
        filter: 'text',
    },

    // {
    //     title: 'Event Code',
    //     field: 'EventName',
    //     show: true,
    //     filter: 'text',
    // },
];

export const columnsForUsers = [
    {
        title: 'Username',
        field: 'UserName',
        show: true,
        filter: 'text',
    },
    {
        title: 'Name',
        field: 'UserFullName',
        show: true,
        filter: 'text',
    },
    {
        title: 'Role',
        field: 'RoleName',
        show: true,
        filter: 'text',
    },
    {
        title: 'Claims',
        field: 'AssignedClaimCount',
        show: true,
        filter: 'text',
    },
    {
        title: 'Email',
        field: 'EmailAddress',
        show: true,
        filter: 'text',
    },
    {
        title: 'Phone',
        field: 'Phone',
        show: true,
        filter: 'text',
    },
    {
        title: 'Address',
        field: 'ContactAddress',
        show: true,
        filter: 'text',
    },
    {
        title: 'City',
        field: 'City',
        show: true,
        filter: 'text',
    },
    {
        title: 'State',
        field: 'StateCode',
        show: true,
        filter: 'text',
    },
    {
        title: 'Zip',
        field: 'Zip',
        show: true,
        filter: 'text',
    },
    {
        title: 'Manager',
        field: 'ManagerName',
        show: true,
        filter: 'text',
    },
    {
        title: 'XactNet',
        field: 'XactNetAddress',
        show: true,
        filter: 'text',
    },
    {
        title: 'Allstate XactNet',
        field: 'AllStateXactNetAddress',
        show: true,
        filter: 'text',
    },
    {
        title: 'Status',
        field: 'IsActive',
        show: true,
        filter: 'text',
    },

    {
        title: 'Options',
        field: 'optn',
        show: true,
        filter: 'text',
    },
];

export const columnsForInspector = [
    {
        title: 'Username',
        field: 'UserName',
        show: true,
        filter: 'text',
    },
    {
        title: 'Name',
        field: 'UserFullName',
        show: true,
        filter: 'text',
    },
    {
        title: 'Role',
        field: 'RoleName',
        show: true,
        filter: 'text',
    },
    {
        title: 'Claims',
        field: 'AssignedClaimCount',
        show: true,
        filter: 'text',
    },
    {
        title: 'Email',
        field: 'EmailAddress',
        show: true,
        filter: 'text',
    },
    {
        title: 'Phone',
        field: 'Phone',
        show: true,
        filter: 'text',
    },
    {
        title: 'Address',
        field: 'ContactAddress',
        show: true,
        filter: 'text',
    },
    {
        title: 'City',
        field: 'City',
        show: true,
        filter: 'text',
    },
    {
        title: 'State',
        field: 'StateCode',
        show: true,
        filter: 'text',
    },
    {
        title: 'Zip',
        field: 'Zip',
        show: true,
        filter: 'text',
    },
    {
        title: 'Manager',
        field: 'ManagerName',
        show: true,
        filter: 'text',
    },
    {
        title: 'XactNet',
        field: 'XactNetAddress',
        show: true,
        filter: 'text',
    },
    {
        title: 'Allstate XactNet',
        field: 'AllStateXactNetAddress',
        show: true,
        filter: 'text',
    },
    {
        title: 'Status',
        field: 'IsActive',
        show: true,
        filter: 'text',
    },

    {
        title: 'Options',
        field: 'optns',
        show: true,
        filter: 'text',
    },
];

export const columnsAuditTrail = [
    {
        title: 'Date',
        field: 'DateOn',
        show: true,
        filter: 'date',
        format: '{0:D}',
    },
    {
        title: 'User Id',
        field: 'UserId',
        show: false,
        filter: 'numeric',
    },
    {
        title: 'User',
        field: 'UserFullName',
        show: true,
        filter: 'text',
    },
    {
        title: 'Task ',
        field: 'TaskName',
        show: true,
        filter: 'text',
    },
    {
        title: 'Description',
        field: 'APIMessage',
        show: true,
        filter: 'text',
    },

    {
        title: 'Source',
        field: 'LogSource',
        show: true,
        filter: 'text',
    },

    // {
    //     title: 'API',
    //     field: 'API',
    //     show: true,
    //     filter: 'text',
    // },
];

export const columnsForAssignmentType = [
    {
        title: 'AssignmentType Id',
        field: 'AssignmentTypeId',
        show: false,
        filter: 'numeric',
    },
    {
        title: 'Carrier Id',
        field: 'CarrierId',
        show: false,
        filter: 'numeric',
    },
    {
        title: 'Assignment Type',
        field: 'AssignmentType',
        show: true,
        filter: 'text',
    },
    {
        title: 'Assignment Description',
        field: 'AssignmentDescription',
        show: true,
        filter: 'text',
    },
    {
        title: 'optn',
        field: 'optn',
        show: true,
        filter: 'text',
    },
];
export const columnsNotification = [
    {
        title: 'Template Id',
        field: 'TemplateId',
        show: false,
        filter: 'numeric',
    },
    {
        title: 'Template',
        field: 'TemplateName',
        show: true,
        filter: 'text',
    },
    {
        title: 'NotificationCodeID ',
        field: 'NotificationCodeID',
        show: false,
        filter: 'numeric',
    },
    {
        title: 'Notification Code',
        field: 'NotificationCode',
        show: true,
        filter: 'text',
    },
    {
        title: 'Media',
        field: 'NotificationMediaDisplayName',
        show: true,
        filter: 'text',
    },
    {
        title: 'Category',
        field: 'CategoryDesc',
        show: false,
        filter: 'text',
    },
    {
        title: 'Status',
        field: 'IsActive',
        show: true,
        filter: 'boolean',
    },
    {
        title: 'Options',
        field: 'optn',
        show: true,
        filter: 'text',
    },
];
export const columnsForClaimTask = [
    {
        title: 'Task',
        field: 'ClaimTaskNumber',
        show: true,
        filter: 'text',
    },
    {
        title: 'Claim',
        field: 'ClaimNumber',
        show: true,
        filter: 'text',
    },
    {
        title: 'Type',
        field: 'ClaimTaskTypeName',
        show: true,
        filter: 'text',
    },
    // {
    //     title: 'Claim',
    //     field: 'ClaimNumber',
    //     show: true,
    //     filter: 'text',
    // },
    {
        title: 'Carrier',
        field: 'CarrierName',
        show: true,
        filter: 'text',
    },
    {
        title: 'Assigned',
        field: 'AssigneeName',
        show: true,
        filter: 'text',
    },
    {
        title: 'Status',
        field: 'ClaimTaskStatusName',
        show: true,
        filter: 'text',
    },

    {
        title: 'Due Date',
        field: 'DueDate',
        show: true,
        filter: 'date',
        format: '{0:D}',
    },
    {
        title: 'Options',
        field: 'optn',
        show: true,
        filter: 'text',
    },
];

export const columnsForClaimTaskInspectorProfile = [
    {
        title: 'Claim',
        field: 'ClaimNumber',
        show: true,
        filter: 'text',
    },
    {
        title: 'Task',
        field: 'ClaimTaskNumber',
        show: true,
        filter: 'text',
    },
    {
        title: 'Type',
        field: 'ClaimTaskTypeName',
        show: true,
        filter: 'text',
    },
    // {
    //     title: 'Claim',
    //     field: 'ClaimNumber',
    //     show: true,
    //     filter: 'text',
    // },
    {
        title: 'Carrier',
        field: 'CarrierName',
        show: true,
        filter: 'text',
    },
    {
        title: 'Assigned',
        field: 'AssigneeName',
        show: true,
        filter: 'text',
    },
    {
        title: 'Task Status',
        field: 'ClaimTaskStatusName',
        show: true,
        filter: 'text',
    },

    {
        title: 'Claim Status',
        field: 'ClaimStatusName',
        show: true,
        filter: 'text',
    },

    // {
    //     title: 'Due Date',
    //     field: 'DueDate',
    //     show: true,
    //     filter: 'date',
    //     format: '{0:D}',
    // },
    {
        title: 'Options',
        field: 'optn',
        show: true,
        filter: 'text',
    },
];

export const columnsForCarriers = [
    {
        title: 'Carrier Name',
        field: 'CarrierName',
        show: true,
        filter: 'text',
    },
    {
        title: 'XA Carrier ID',
        field: 'XACarrierId',
        show: true,
        filter: 'text',
    },
    {
        title: 'Contact Name',
        field: 'ContactName',
        show: true,
        filter: 'text',
    },
    {
        title: 'Phone',
        field: 'ContactPhone',
        show: true,
        filter: 'text',
    },
    {
        title: 'Email',
        field: 'ContactEmail',
        show: true,
        filter: 'text',
    },
    {
        title: 'Claims Source',
        field: 'ClaimSource',
        show: true,
        filter: 'text',
    },
    {
        title: 'Active',
        field: 'IsActive',
        show: true,
        filter: 'text',
    },
    {
        title: 'Options',
        field: 'optn',
        show: true,
        filter: 'text',
    },
];

export const columnsForInspectorAssignment = [
    {
        title: 'Inspector',
        field: 'FirstName',
        show: true,
        filter: 'text',
        dir: 'asc',
    },
    {
        title: 'Availability',
        field: 'Availability',
        show: true,
        filter: 'text',
        dir: 'asc',
    },
    {
        title: 'Efficiency Rating',
        field: 'EfficiencyRating',
        show: false,
        filter: 'text',
        dir: 'asc',
    },
    {
        title: 'Insured Rating',
        field: 'InsuredRating',
        show: true,
        filter: 'text',
        dir: 'asc',
    },
    {
        title: 'Time Slot',
        field: 'ReservedTimeSlot',
        show: false,
        filter: 'text',
        dir: 'asc',
    },

    {
        title: 'Assigned Jobs',
        field: 'WorkLoad',
        show: true,
        filter: 'text',
        dir: 'asc',
    },
    {
        title: 'Distance (M)',
        field: 'Distance',
        show: true,
        filter: 'text',
        dir: 'asc',
    },
    {
        title: 'Radius',
        field: 'WorkRadius',
        show: false,
        filter: 'text',
        dir: 'asc',
    },
];
export const columnsForMultiAssignment = [
    {
        title: 'Inspector',
        field: 'FirstName',
        show: true,
        filter: 'text',
        dir: 'asc',
    },
    {
        title: 'Availability',
        field: 'Availability',
        show: false,
        filter: 'text',
        dir: 'asc',
    },
    {
        title: 'Efficiency Rating',
        field: 'EfficiencyRating',
        show: false,
        filter: 'text',
        dir: 'asc',
    },
    {
        title: 'Insured Rating',
        field: 'InsuredRating',
        show: true,
        filter: 'text',
        dir: 'asc',
    },
    {
        title: 'Time Slot',
        field: 'ReservedTimeSlot',
        show: false,
        filter: 'text',
        dir: 'asc',
    },

    {
        title: 'Assigned Jobs',
        field: 'WorkLoad',
        show: true,
        filter: 'text',
        dir: 'asc',
    },
    {
        title: 'Distance (M)',
        field: 'Distance',
        show: false,
        filter: 'text',
        dir: 'asc',
    },
    {
        title: 'Radius',
        field: 'WorkRadius',
        show: false,
        filter: 'text',
        dir: 'asc',
    },
];

export const columnForSLATrack = [
    {
        title: 'SLA definition',
        field: 'SLAName',
        show: true,
        filter: 'text',
    },
    {
        title: 'SLA Time',
        field: 'SLATime',
        show: true,
        filter: 'text',
    },

    {
        title: 'Elapsed Time',
        field: 'ElapsedTime',
        show: true,
        filter: 'text',
    },
    // {
    //     title: 'Pause Duration',
    //     field: 'PausedTime',
    //     show: true,
    //     filter: 'text',
    // },
    {
        title: 'Status',
        field: 'Status',
        show: true,
        filter: 'text',
    },

    {
        title: 'Elapsed %',
        field: 'ElapsedPercentage',
        show: true,
        filter: 'text',
    },
    // {
    //     title: 'Actual Elapsed Time',
    //     field: 'slaDefinition',
    //     show: true,
    //     filter: 'text',
    // },
    {
        title: 'Time Left',
        field: 'TimeLeft',
        show: true,
        filter: 'text',
    },
    {
        title: 'Stop Time',
        field: 'SLAEndTime',
        show: true,
        filter: 'text',
    },
    {
        title: 'Breached Time',
        field: 'BreachedTime',
        show: true,
        filter: 'text',
    },
    // {
    //     title: 'Options',
    //     field: 'optn',
    //     show: true,
    //     filter: 'text',
    // },
];

export const columnForUserSLATrack = [
    {
        title: 'Claim',
        field: 'ClaimNumber',
        show: true,
        filter: 'text',
    },
    {
        title: 'SLA Time',
        field: 'SLATime',
        show: true,
        filter: 'text',
    },
    {
        title: 'SLA definition',
        field: 'SLAName',
        show: true,
        filter: 'text',
    },
    {
        title: 'Elapsed Time',
        field: 'ElapsedTime',
        show: true,
        filter: 'text',
    },

    {
        title: 'Elapsed %',
        field: 'ElapsedPercentage',
        show: true,
        filter: 'text',
    },

    {
        title: 'Time Left',
        field: 'TimeLeft',
        show: true,
        filter: 'text',
    },
    {
        title: 'Stop Time',
        field: 'SLAEndTime',
        show: true,
        filter: 'text',
    },
    {
        title: 'Breached Time',
        field: 'BreachedTime',
        show: true,
        filter: 'text',
    },
    // {
    //     title: 'Options',
    //     field: 'optn',
    //     show: true,
    //     filter: 'text',
    // },
];

export const columnForCustomQuery = [
    {
        title: 'Claim',
        field: 'ClaimNumber',
        show: true,
        filter: 'text',
        minWidth: 200,
    },
    {
        title: 'Carrier',
        field: 'CarrierName',
        show: true,
        filter: 'text',
    },
    {
        title: 'Loss Type',
        field: 'TypeOfLoss',
        show: true,
        filter: 'text',
    },
    {
        title: 'Insured',
        field: 'InsuredName',
        show: true,
        filter: 'text',
    },

    {
        title: 'State',
        field: 'StateCode',
        show: true,
        filter: 'text',
    },
    {
        title: 'Received',
        field: 'DateReceived',
        show: true,
        filter: 'text',
    },
    {
        title: 'Assigned To',
        field: 'AssignedToName',
        show: true,
        filter: 'text',
    },
    {
        title: 'Scheduled',
        field: 'ScheduledOn',
        show: true,
        filter: 'text',
    },
    {
        title: 'Inspected',
        field: 'InspectedOn',
        show: true,
        filter: 'text',
    },
    {
        title: 'Status',
        field: 'ClaimStatusName',
        show: true,
        filter: 'text',
    },
    {
        title: 'SLA',
        field: 'SLA',
        show: true,
        filter: 'text',
    },

    {
        title: 'Options',
        field: 'optn',
        show: true,
        filter: 'text',
    },
    {
        title: 'Due Date',
        field: 'DueDate',
        show: true,
        filter: 'text',
    },
];
