import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { Colors } from '../../utils/colors';
import CustomTextField from '../CustomTextField';
import { AppInputTypes } from '../../utils/constants';

const PersonalInfoQuestions = (props) => {
    const {
        personalDetails,
        onChangePersonalDetails,
        personalDetailsErrors,
        professionalDesignations,
        positionsAbleToWork,
        professionalSkillStrengths,
        states,
    } = props;
    return (
        <>
            <Grid container mt={2} p={2} sx={{ backgroundColor: Colors.WHITE }}>
                <Grid component={'h4'} color={Colors.DODGER_BLUE}>
                    Contact Info
                </Grid>
                <Grid
                    container
                    item
                    mt={1}
                    columnSpacing={2}
                    md={12}
                    rowSpacing={2}
                    sx={{ backgroundColor: Colors.WHITE }}
                >
                    <Grid sm={12} xs={12} md={6} item>
                        <CustomTextField
                            inputType={AppInputTypes.TextField}
                            fullWidth
                            size="small"
                            id="outlined-required"
                            label={'Pilot Number'}
                            value={personalDetails?.pilotNumber}
                            onChange={(e) =>
                                onChangePersonalDetails('pilotNumber', e?.target?.value)
                            }
                        />
                    </Grid>
                    <Grid sm={12} xs={12} md={6} item container columnSpacing={2} rowSpacing={2}>
                        <Grid sm={12} xs={12} md={6} item>
                            <CustomTextField
                                inputType={AppInputTypes.TextField}
                                fullWidth
                                size="small"
                                required
                                error={!!personalDetailsErrors?.telephone}
                                label={'Telephone'}
                                value={personalDetails?.telephone}
                                onChange={(e) =>
                                    onChangePersonalDetails('telephone', e?.target?.value)
                                }
                            />
                        </Grid>
                        <Grid sm={12} xs={12} md={6} item>
                            <CustomTextField
                                inputType={AppInputTypes.TextField}
                                fullWidth
                                size="small"
                                required
                                error={!!personalDetailsErrors?.email}
                                label={'Email'}
                                value={personalDetails?.email}
                                onChange={(e) => onChangePersonalDetails('email', e?.target?.value)}
                            />
                        </Grid>
                    </Grid>

                    <Grid sm={12} xs={12} md={6} item>
                        <CustomTextField
                            inputType={AppInputTypes.TextField}
                            fullWidth
                            size="small"
                            error={!!personalDetailsErrors?.firstName}
                            required
                            label={'First Name'}
                            value={personalDetails?.firstName}
                            onChange={(e) => onChangePersonalDetails('firstName', e?.target?.value)}
                        />
                    </Grid>

                    <Grid sm={12} xs={12} md={6} item container columnSpacing={2} rowSpacing={2}>
                        <Grid sm={12} xs={12} md={6} item>
                            <CustomTextField
                                inputType={AppInputTypes.TextField}
                                fullWidth
                                size="small"
                                required
                                multiline
                                error={!!personalDetailsErrors?.streetAddress}
                                label={'Street Address'}
                                value={personalDetails?.streetAddress}
                                onChange={(e) =>
                                    onChangePersonalDetails('streetAddress', e?.target?.value)
                                }
                            />
                        </Grid>
                        <Grid sm={12} xs={12} md={6} item>
                            <CustomTextField
                                inputType={AppInputTypes.TextField}
                                fullWidth
                                size="small"
                                required
                                error={!!personalDetailsErrors?.city}
                                label={'City'}
                                value={personalDetails?.city}
                                onChange={(e) => onChangePersonalDetails('city', e?.target?.value)}
                            />
                        </Grid>
                    </Grid>

                    <Grid sm={12} xs={12} md={6} item>
                        <CustomTextField
                            inputType={AppInputTypes.TextField}
                            fullWidth
                            size="small"
                            error={!!personalDetailsErrors?.lastName}
                            required
                            label={'Last Name'}
                            value={personalDetails?.lastName}
                            onChange={(e) => onChangePersonalDetails('lastName', e?.target?.value)}
                        />
                    </Grid>

                    <Grid sm={12} xs={12} md={6} item container columnSpacing={2} rowSpacing={2}>
                        <Grid sm={12} xs={12} md={6} item>
                            <CustomTextField
                                inputType={AppInputTypes.DropDown}
                                fullWidth
                                size="small"
                                select
                                required
                                error={!!personalDetailsErrors?.state}
                                variant="outlined"
                                value={personalDetails?.state}
                                label="State"
                                InputLabelProps={{ shrink: true }}
                                SelectProps={{
                                    displayEmpty: true,
                                    value: personalDetails?.state || '',
                                    onChange: (e) => {
                                        e.preventDefault();
                                        onChangePersonalDetails('state', e?.target?.value);
                                    },
                                }}
                                options={states?.map((option, optionIndex) => option?.StateName)}
                            />
                        </Grid>
                        <Grid sm={12} xs={12} md={6} item>
                            <CustomTextField
                                inputType={AppInputTypes.TextField}
                                fullWidth
                                size="small"
                                required
                                error={!!personalDetailsErrors?.zipCode}
                                label={'Zip Code'}
                                value={personalDetails?.zipCode}
                                onChange={(e) =>
                                    onChangePersonalDetails('zipCode', e?.target?.value)
                                }
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Grid
                item
                sm={12}
                xs={12}
                md={12}
                container
                rowSpacing={2}
                mt={2}
                p={2}
                sx={{ backgroundColor: Colors.WHITE }}
            >
                <Grid sm={12} xs={12} md={12} item container rowSpacing={2} columnSpacing={2}>
                    <Grid sm={12} xs={12} md={6} item>
                        <Grid item component={'h4'} mb={2} color={Colors.DODGER_BLUE}>
                            Professional Designation
                        </Grid>
                        <CustomTextField
                            inputType={AppInputTypes.DropDown}
                            fullWidth
                            size="small"
                            select
                            required
                            error={!!personalDetailsErrors?.professionalDesignation}
                            variant="outlined"
                            value={personalDetails?.professionalDesignation}
                            InputLabelProps={{ shrink: false }}
                            SelectProps={{
                                displayEmpty: true,
                                value: personalDetails?.professionalDesignation || '',
                                onChange: (e) => {
                                    e.preventDefault();
                                    onChangePersonalDetails(
                                        'professionalDesignation',
                                        e?.target?.value,
                                    );
                                },
                            }}
                            options={professionalDesignations}
                        />
                    </Grid>
                    <Grid sm={12} xs={12} md={6} item>
                        <Grid item component={'h4'} mb={2} color={Colors.DODGER_BLUE}>
                            Positions Able To Work
                        </Grid>
                        <CustomTextField
                            inputType={AppInputTypes.DropDown}
                            fullWidth
                            size="small"
                            select
                            required
                            error={!!personalDetailsErrors?.positionsAbleToWork}
                            variant="outlined"
                            value={personalDetails?.positionsAbleToWork}
                            InputLabelProps={{ shrink: false }}
                            SelectProps={{
                                displayEmpty: true,
                                value: personalDetails?.positionsAbleToWork || '',
                                onChange: (e) => {
                                    e.preventDefault();
                                    onChangePersonalDetails(
                                        'positionsAbleToWork',
                                        e?.target?.value,
                                    );
                                },
                            }}
                            options={positionsAbleToWork}
                        />
                    </Grid>
                </Grid>
            </Grid>

            <Grid container rowSpacing={1} mt={2} p={2} sx={{ backgroundColor: Colors.WHITE }}>
                <Grid item component={'h4'} color={Colors.DODGER_BLUE}>
                    Professional Skills Strengths
                </Grid>
                <Grid container item mt={1} md={12} sx={{ backgroundColor: Colors.WHITE }}>
                    <CustomTextField
                        inputType={AppInputTypes.MultiSelectDropDown}
                        error={!!personalDetailsErrors?.professionalSkillStrengths}
                        multiSelectProps={{
                            options:
                                professionalSkillStrengths?.map((item) => {
                                    return { label: item, value: item };
                                }) || [],
                            onChange: (list) => {
                                onChangePersonalDetails(
                                    'professionalSkillStrengths',
                                    list?.map((item) => item.value),
                                );
                            },
                            value: personalDetails?.professionalSkillStrengths
                                ? personalDetails?.professionalSkillStrengths?.map((item) => {
                                      return { label: item, value: item };
                                  })
                                : [],
                        }}
                    />
                </Grid>
            </Grid>
        </>
    );
};

PersonalInfoQuestions.propTypes = {
    personalDetails: PropTypes.any,
    onChangePersonalDetails: PropTypes.func,
    personalDetailsErrors: PropTypes.any,
    professionalDesignations: PropTypes.array,
    positionsAbleToWork: PropTypes.array,
    professionalSkillStrength: PropTypes.array,
    states: PropTypes.array,
};

export default PersonalInfoQuestions;
