import React from 'react';
import PropTypes from 'prop-types';
import ClaimInsuredInformationForm from './claim-insured-information-form';
import ClaimContact from './claim-contact';
import CreateIcon from '@mui/icons-material/Create';
import useUserPermissions from '../../hooks/use-user-permissions.hook';
import { useParams } from 'react-router-dom';
import { decryptWithAES } from '../../utils/global-functions';
const ClaimInsuredInformation = ({ cliamProfile, reload }) => {
    let { id: param } = useParams();
    let decryptedParam = decryptWithAES(param?.replace(/%2F/g, '/'));
    let parsedParam = JSON.parse(decryptedParam);
    const { state } = parsedParam;
    const [isClaimInsureInfoOpen, setIsClaimInsureInfoOpen] = React.useState(false);
    const [isContactOpen, setIsContactOpen] = React.useState(false);
    const { handleSetEditPermissions } = useUserPermissions();
    return (
        <>
            {' '}
            <ClaimInsuredInformationForm
                isClaimInsureInfoOpen={isClaimInsureInfoOpen}
                setIsClaimInsureInfoOpen={setIsClaimInsureInfoOpen}
                cliamProfileDetails={cliamProfile.Data}
                reload={reload}
            />
            {/* <ClaimContact
                setIsContactOpen={setIsContactOpen}
                isContactOpen={isContactOpen}
                email={cliamProfile.Data.InsuredEmail}
                phoneNumber={cliamProfile.Data.InsuredCellPhone}
                claimId={cliamProfile.Data.ClaimGuID}
                EmailToName={cliamProfile.Data.AssignedToName}
                inspectorId={null}
            /> */}
            <div className="card" style={{ position: 'relative' }}>
                {state?.page === 'claim' ? (
                    handleSetEditPermissions('Claims') ? (
                        <button
                            onClick={() => {
                                setIsClaimInsureInfoOpen(true);
                            }}
                            className="icons absoluteR"
                        >
                            <CreateIcon />
                        </button>
                    ) : null
                ) : handleSetEditPermissions('Tasks') ? (
                    <button
                        onClick={() => {
                            setIsClaimInsureInfoOpen(true);
                        }}
                        className="icons absoluteR"
                    >
                        <CreateIcon />
                    </button>
                ) : null}
                <h3>Insured Information</h3>
                <div className="grid-Row33">
                    <div className="relative">
                        {' '}
                        <b>
                            {cliamProfile.Data !== null && cliamProfile.Data !== undefined
                                ? cliamProfile.Data.InsuredName
                                : ''}
                        </b>
                        <p>
                            {cliamProfile.Data !== null && cliamProfile.Data !== undefined
                                ? cliamProfile.Data.InsuredAddress
                                : ''}
                        </p>
                        {/* <Box>
                                        <Typography component={'p'} fontWeight="600">
                                            {inspectorProfileDetails.ContactAddress}
                                        </Typography>
                                        <Typography component={'p'} fontWeight="600">
                                            {inspectorProfileDetails.City
                                                ? inspectorProfileDetails.City
                                                : ''}
                                            {inspectorProfileDetails.StateName
                                                ? ', ' + inspectorProfileDetails.StateName
                                                : ''}

                                            {inspectorProfileDetails.Zip
                                                ? ', ' + inspectorProfileDetails.Zip
                                                : ''}
                                        </Typography>
                                         
                                    </Box> */}
                        {/* <button
                            onClick={() => {
                                setIsContactOpen(true);
                            }}
                            className="p-btn btn-outline-primary fixed-btn"
                        >
                            {' '}
                            Contact Insured{' '}
                        </button> */}
                    </div>
                    <div>
                        <ul className="list-data">
                            <li>Office Phone:</li>
                            <li style={{ fontWeight: 600 }}>
                                {cliamProfile.Data !== null && cliamProfile.Data !== undefined
                                    ? cliamProfile.Data.InsuredOfficePhone
                                    : ''}
                            </li>
                            <li>Cell Phone:</li>
                            <li style={{ fontWeight: 600 }}>
                                {cliamProfile.Data !== null && cliamProfile.Data !== undefined
                                    ? cliamProfile.Data.InsuredCellPhone
                                    : ''}
                            </li>
                            <li>Alt Phone:</li>
                            <li style={{ fontWeight: 600 }}>
                                {cliamProfile.Data !== null && cliamProfile.Data !== undefined
                                    ? cliamProfile.Data.InsuredAltPhone
                                    : ''}
                            </li>
                            <li>Email:</li>
                            <li style={{ fontWeight: 600 }}>
                                {cliamProfile.Data !== null && cliamProfile.Data !== undefined
                                    ? cliamProfile.Data.InsuredEmail
                                    : ''}
                            </li>
                            <li>Preferred Contact:</li>
                            <li style={{ fontWeight: 600 }}>
                                {cliamProfile.Data !== null && cliamProfile.Data !== undefined
                                    ? cliamProfile.Data.InsuredPreferredContact
                                    : ''}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ClaimInsuredInformation;
ClaimInsuredInformation.propTypes = {
    cliamProfile: PropTypes.any.isRequired,
    reload: PropTypes.func,
};
