import {
    TextField,
    Button,
    createTheme,
    ThemeProvider,
    Container,
    CssBaseline,
    Avatar,
    Typography,
    FormControlLabel,
    Grid,
    Link,
    Checkbox,
    LinearProgress,
    CircularProgress,
} from '@mui/material';
import React from 'react';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { useForm } from 'react-hook-form';
import { usePostData } from '../../hooks/use-post-data.hook';
import { useStorage } from '../../hooks/use-storage';
import { AppUrls } from '../../core/route.url';
import { Children, useEffect, useState } from 'react';
import { PropaneSharp } from '@mui/icons-material';
import { property } from 'lodash';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../../authConfig';
import userEvent from '@testing-library/user-event';
import { useIsAuthenticated } from '@azure/msal-react';
import { LogIn } from '../../hooks/use-storage';
import { isNil, map, reduce, uniq } from 'lodash';
import { useAppContext } from '../../hooks/use-app-context.hook';
import { useLoadData2 } from '../../hooks/use-load-data-2.hook';
import { beginAuth } from '../../utils/oidcApi';
import jwt_decode from 'jwt-decode';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { parse } from 'query-string';
import { nanoid } from 'nanoid';
import { usePostDataForOneLogin } from '../../hooks/use-post-data-2.hook';

export const LoginPage = () => {
    const { dispatch } = useAppContext();
    const [authToken, setToken] = useStorage('AUTH_STATE', null);
    const logoutstate = JSON.parse(localStorage.getItem('LogOut_STATE'));
    const { instance, accounts } = useMsal();
    const [isLoadList, setIsLoadList] = React.useState(false);
    const [isLoadLoginButton, setIsLogLoginButton] = React.useState(true);
    const { submitData, response } = usePostData(AppUrls.LoginAzure);

    const { submitData: submitDatatemp, response: responsetemp } = usePostDataForOneLogin(
        AppUrls.Login,
    );
    const { submitData: submitDataForTempLogin, response: responseForTempLoginData } = usePostData(
        AppUrls.TemLOgin,
    );
    const { result: loginButtonDisplay, reload: loginButtonDisplaySubmit } = useLoadData2();
    const isAuthenticated = useIsAuthenticated();
    const history = useNavigate();
    const searchParams = useLocation();
    const paramValue = useParams();
    const tempToken =
        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCIsImN0eSI6IkpXVCJ9.eyJlbWFpbCI6ImFkbWluQGNsYWltc2NvbW1hbmQuY29tIiwiVGVuYW50SWQiOiJzdXBlcl91c3IiLCJVc2VyRnVsbE5hbWUiOiJUZW1wb3JhcnkgVXNlciBBZG1pbiIsIlVzZXJVbmlxdWVJZCI6IkJGQzlERjMzNEZFRDRGODVCNUNCNDgxOEIwRjYzMkE4IiwiUm9sZUlkIjoiMSIsIlJvbGVDb2RlIjoiU1lTX1VTUiIsIlJvbGVOYW1lIjoiU3VwZXIgYWRtaW4iLCJBbGxvd0ltcGVyc29uYXRlIjoiRmFsc2UiLCJJbXBlcnNvbmF0ZVVzZXJJZCI6IjAiLCJJbXBlcnNvbmF0ZVVzZXJOYW1lIjoiIiwiVXNlclRpbWVab25lIjoiQ2VudHJhbCBTdGFuZGFyZCBUaW1lIiwiaHR0cDovL3NjaGVtYXMueG1sc29hcC5vcmcvd3MvMjAwNS8wNS9pZGVudGl0eS9jbGFpbXMvc2lkIjoiMSIsIm5iZiI6MTY2NzM4MTUzNSwiZXhwIjoxNjY3NDY3OTM1LCJpYXQiOjE2NjczODE1MzUsImlzcyI6Imh0dHBzOi8vY2xhaW1zY29tbWFuZC51YXRkZW1vLmRldi8iLCJhdWQiOiI2YjA1MGQyZS02YTZhLTQxZmYtYmZlZi0xNzAwOGRkYWRlZmEifQ.ixOurWeUtLrE8adBMoAcCfWzoQ6SKrZBIZBpIqMQ60E';
    const location = useLocation();
    // console.log('hash', location.hash);

    // console.log(200, jwt_decode(searchParams.hash));

    useEffect(() => {
        if (isLoadLoginButton) {
            loginButtonDisplaySubmit('User/VerifySignIn');
        }
    }, [isLoadLoginButton]);

    useEffect(() => {
        if (searchParams.hash === '') {
            const state = '';
            const nonce = '';
            // if (location.pathname !== '/login') {
            //     beginAuth({ state, nonce });
            // }
        }
    }, []);

    //

    useEffect(() => {
        if (searchParams.hash !== '') {
            if (jwt_decode(searchParams.hash).email !== undefined) {
                // submitDatatemp({ Email: jwt_decode(searchParams.hash).preferred_username });
                submitDatatemp({}, JSON.stringify(searchParams.hash));
                history('/');
            }
        }
    }, [searchParams.hash]);

    useEffect(() => {
        if (responsetemp.result != undefined) {
            history('/');
            setToken(responsetemp.result.Token);
        }
    }, [responsetemp]);

    // Temp login set token
    useEffect(() => {
        if (responseForTempLoginData.result != undefined) {
            history('/');
            setToken(responseForTempLoginData.result.Token);
        }
    }, [responseForTempLoginData]);

    useEffect(() => {
        if (isAuthenticated === true && isNil(logoutstate)) {
            instance
                .acquireTokenSilent({
                    ...loginRequest,
                    account: accounts[0],
                })
                .then((response) => {
                    const bearer = `bearer ${response.accessToken}`;
                    submitData(bearer);
                });
        }
    }, [isAuthenticated]);

    useEffect(() => {
        if (response.result != undefined) {
            setToken(response.result.Token);
        }
    }, [response]);

    const LoginOneLogin = () => {
        history('/onelogin');
        const state = nanoid(32);
        const nonce = nanoid(32);
    };

    const LoginClick = async () => {
        LogIn('LogOut_STATE');
        if (isAuthenticated === true) {
            instance
                .acquireTokenSilent({
                    ...loginRequest,
                    account: accounts[0],
                })
                .then((response) => {
                    const bearer = `bearer ${response.accessToken}`;
                    submitData(bearer);
                });
        } else {
            instance.loginRedirect(loginRequest).catch((e) => {
                console.log(e);
            });
        }
    };
    //   Temp login api call
    const LoginTempClick = async () => {
        // submitDatatemp({ Email: 'admin@claimscommand.com' });

        submitDataForTempLogin(JSON.stringify(tempToken));

        setIsLoadList(true);
    };

    const theme = createTheme();

    //!isAuthenticated &&
    return (
        <>
            {response.isSubmitting ||
            responsetemp.isSubmitting ||
            loginButtonDisplay === undefined ? (
                <Box sx={{ width: '100%' }}>
                    <LinearProgress />
                </Box>
            ) : (
                <>
                    {' '}
                    <ThemeProvider theme={theme}>
                        <Container component="main" maxWidth="xs" className="login-page">
                            <CssBaseline />
                            <div className="login-container">
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
                                        <LockOutlinedIcon />
                                    </Avatar>
                                    <Typography component="h1" variant="h5">
                                        Sign in
                                    </Typography>
                                    {/* <CircularProgress /> */}{' '}
                                    {loginButtonDisplay.Show_Azure_Login === '1' ? (
                                        <Button
                                            type="button"
                                            fullWidth
                                            variant="contained"
                                            disableElevation
                                            size="large"
                                            sx={{ mt: 3, mb: 2 }}
                                            onClick={LoginClick}
                                        >
                                            Sign In
                                        </Button>
                                    ) : null}
                                    {/* {location.pathname === '/login' ? ( */}
                                    {loginButtonDisplay.Show_Temp_Login === '1' ? (
                                        <Button
                                            type="button"
                                            fullWidth
                                            variant="contained"
                                            disableElevation
                                            size="large"
                                            sx={{ mt: 3, mb: 2 }}
                                            onClick={LoginTempClick}
                                        >
                                            Temp Login
                                        </Button>
                                    ) : null}
                                    {/* ) : null} */}
                                    {/* {location.pathname === '/login' ? null : (
                                        <Box sx={{ width: '100%' }}>
                                            <LinearProgress />
                                        </Box>
                                    )} */}
                                    <Button
                                        type="button"
                                        fullWidth
                                        variant="contained"
                                        disableElevation
                                        size="large"
                                        sx={{ mt: 3, mb: 2 }}
                                        onClick={LoginOneLogin}
                                    >
                                        OneLogin
                                    </Button>
                                    {location.state !== null ? (
                                        <h3 style={{ color: 'red' }}>This Account Doesn't Exist</h3>
                                    ) : null}
                                </Box>
                            </div>
                        </Container>
                    </ThemeProvider>
                </>
            )}
        </>
    );
};
