import React from 'react';
import { AppUrls } from '../../core/route.url';
import { useLoadData } from '../../hooks/use-load-data.hook';
import CustomQueryBuilderForms from './custom-query-builder-forms';
import PropTypes from 'prop-types';
import {
    Button,
    Modal,
    Box,
    TextField,
    Breadcrumbs,
    Link,
    Typography,
    Tab,
    Tabs,
} from '@mui/material';
import CustomSaveQuery from './custom-save-query';
import { useCurrentUser } from '../../hooks/user-current-user.hook';

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography component={'span'}>{children}</Typography>
                </Box>
            )}
        </div>
    );
};

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

const CustomQueryBuilder = () => {
    const {
        isLoading: queryOperatorLoading,
        result: queryOperator,
        isError: queryOperatorError,
    } = useLoadData(AppUrls.QueryOperators);

    // const {
    //     isLoading: repoertEntityLoading,
    //     result: repoertEntity,
    //     isError: repoertEntityError,
    // } = useLoadData(AppUrls.ReportEntitie);
    const [value, setValue] = React.useState(0);
    const user = useCurrentUser();

    const a11yProps = (index) => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    };

    if (/*repoertEntityError ||*/ queryOperatorError) {
        return <div>Error Loading Data</div>;
    }

    // if (queryOperatorLoading || repoertEntityLoading) {
    //     return <div>Loading....</div>;
    // }

    const checkPermission = (menuName) => {
        if (user && user.UserMenuPermissions && user.UserMenuPermissions.length > 0) {
            const permissionView = user.UserMenuPermissions.filter((data, i) => {
                return data.MenuName === menuName;
            })[0]?.IsView;
            return permissionView !== undefined ? permissionView : true;
        }
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className="main-content">
            <Box style={{ display: 'flex', alignItems: 'flex-end', marginBottom: 15 }}>
                <Box>
                    <Typography className="title" variant="h1">
                        Query Builder
                    </Typography>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit">
                            Home
                        </Link>
                        <Link underline="hover" color="text.primary" aria-current="page">
                            Query Builder
                        </Link>
                    </Breadcrumbs>
                </Box>
            </Box>
            <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                        <Tab
                            disabled={!checkPermission('Query Builder')}
                            label="Custom Query"
                            {...a11yProps(0)}
                        />
                        <Tab
                            disabled={!checkPermission('Saved Queries')}
                            label="Saved Query"
                            {...a11yProps(1)}
                        />
                    </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                    <Box style={{ position: 'relative' }}>
                        <CustomQueryBuilderForms
                            queryOperator={queryOperator}
                            // repoertEntity={repoertEntity}
                            value={value}
                        />
                    </Box>
                </TabPanel>

                <TabPanel value={value} index={1}>
                    <Box>
                        {' '}
                        <CustomSaveQuery />
                    </Box>
                </TabPanel>
            </Box>
        </div>
    );
};

export default CustomQueryBuilder;

CustomQueryBuilder.propTypes = {};
