import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, Checkbox } from '@mui/material';
import ExpansionMenu from '../ExpansionMenu';
import SearchInput from '../SearchInput';
import { Colors } from '../../utils/colors';
import { width } from '@mui/system';

const ProfileFilter = (props) => {
    const {
        searchParams,
        onClickParamAccordion,
        onFilterSearch,
        onClickParamQuestionAccordion,
        onClickQuestionOptionCheckBox,
    } = props;

    const getFilterOptionsBySearchKey = (options, searchKey) => {
        if (!searchKey) {
            return options;
        }
        return options?.filter((x) => x.option?.toLowerCase()?.includes(searchKey?.toLowerCase()));
    };

    return (
        <Grid item>
            {searchParams.map((param, paramIndex) => {
                return (
                    <ExpansionMenu
                        key={paramIndex}
                        label={param?.Experience}
                        isVisible={param?.isVisible}
                        type={'accordion'}
                        buttonContainerStyle={styles.expansionButton}
                        onClick={() => onClickParamAccordion(paramIndex)}
                    >
                        <Grid mt={1} md={12} item>
                            <SearchInput
                                value={param?.searchKey}
                                onChange={(e) => onFilterSearch(paramIndex, e?.target?.value)}
                                onClear={() => onFilterSearch(paramIndex, '')}
                            />
                        </Grid>
                        <Grid md={12} container item>
                            {param?.Questions?.map((question, questionIndex) => {
                                return (
                                    <ExpansionMenu
                                        key={questionIndex}
                                        label={question?.fieldName}
                                        isVisible={question?.isVisible}
                                        className={'accodianLeft'}
                                        type={'accordion'}
                                        buttonContainerStyle={{
                                            background: Colors.WHITE,
                                            paddingLeft: 0,
                                        }}
                                        buttonTextStyle={{
                                            color: Colors.BLACK,
                                            fontWeight: 'unset',
                                        }}
                                        containerStyle={{
                                            paddingRight: '1px',
                                            paddingLeft: '4px',
                                        }}
                                        onClick={() =>
                                            onClickParamQuestionAccordion(paramIndex, questionIndex)
                                        }
                                    >
                                        <Grid width={'100%'} md={12} item>
                                            {getFilterOptionsBySearchKey(
                                                question?.options,
                                                param?.searchKey,
                                            )?.map((option, optionIndex) => {
                                                return (
                                                    <Grid
                                                        item
                                                        md={12}
                                                        key={optionIndex}
                                                        display={'flex'}
                                                        alignItems={'center'}
                                                        width={'100%'}
                                                        sx={{
                                                            marginBottom: '4px',
                                                        }}
                                                    >
                                                        <label
                                                            className="chekboxSidebar"
                                                            style={{
                                                                display: 'flex',
                                                                alignItems: 'start',
                                                            }}
                                                        >
                                                            <Checkbox
                                                                p={0}
                                                                type="checkbox"
                                                                checked={option?.isSelected}
                                                                onChange={() =>
                                                                    onClickQuestionOptionCheckBox(
                                                                        paramIndex,
                                                                        questionIndex,
                                                                        option?.id,
                                                                    )
                                                                }
                                                            />
                                                            {option?.option}
                                                        </label>
                                                    </Grid>
                                                );
                                            })}
                                        </Grid>
                                    </ExpansionMenu>
                                );
                            })}
                        </Grid>
                    </ExpansionMenu>
                );
            })}
            {searchParams?.length === 0 ? (
                <Grid item display={'flex'} alignItems="center" justifyContent={'center'} flex={1}>
                    <Typography>No data</Typography>
                </Grid>
            ) : null}
        </Grid>
    );
};

const styles = {
    expansionButton: {
        background: '#ebebeb',
        marginTop: 0.3,
        marginBottom: 0.3,
    },
    paramLabel: {
        color: '#747474',
        marginLeft: 10,
        fontSize: 12,
    },
};

ProfileFilter.propTypes = {
    searchParams: PropTypes.array,
    onClickParamAccordion: PropTypes.func,
    onFilterSearch: PropTypes.func,
    onClickParamQuestionAccordion: PropTypes.func,
    onClickQuestionOptionCheckBox: PropTypes.func,
};

export default ProfileFilter;
