import PropTypes from 'prop-types';
import { Card, Grid, Checkbox, Button, Box } from '@mui/material/';
import Typography from '@mui/material/Typography';

export const SelectedClaimsComponent = ({ claims, toggleClaimSelection, locateClaim }) => {
    return (
        <div style={{ maxHeight: 'calc(100vh - 467px)', overflowY: 'auto' }}>
            {claims?.map((claimData) => {
                const { id, data: claim } = claimData;
                return (
                    <Card
                        key={id}
                        className="boxBorder"
                        sx={{
                            boxShadow: 0,
                            paddingLeft: 2,
                            paddingRight: 3,
                            paddingTop: 2,
                            paddingBottom: 1,
                            marginBottom: 1,
                            position: 'relative',
                        }}
                        onClick={() => locateClaim(claimData)}
                    >
                        <svg class="slaStats" width="10" height="10">
                            <circle cx="5" cy="5" r="5" fill="#EC4D4D"></circle>
                        </svg>
                        <Grid container spacing={1}>
                            <Grid item xs={6}>
                                <Typography
                                    style={{ wordBreak: 'break-all' }}
                                    lineHeight={1}
                                    variant="h3"
                                    fontWeight={600}
                                    color={'#000'}
                                >
                                    {claim.ClaimNumber}
                                </Typography>
                                <Typography variant="caption" color={'#686666'}>
                                    Claim #
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography
                                    variant="h3"
                                    lineHeight={1}
                                    fontWeight={600}
                                    color={'#000'}
                                >
                                    {claim.InsuredName}
                                </Typography>
                                <Typography variant="h4" color={'#686666'}>
                                    Insured Name
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                {/* <Typography>{claim.InsuredAddress}</Typography> */}
                                <Typography variant="h4" lineHeight={1} fontWeight={600}>
                                    {claim.CarrierName}
                                </Typography>
                                <Typography variant="caption" color={'#686666'}>
                                    Carrier
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="h4" lineHeight={1} fontWeight={600}>
                                    {claim.ClaimStatusName}
                                </Typography>
                                <Typography variant="caption" color={'#686666'}>
                                    Status
                                </Typography>
                            </Grid>
                        </Grid>
                        <Box sx={{ display: 'flex' }}>
                            <Button
                                style={{ marginLeft: 'auto' }}
                                onClick={() => toggleClaimSelection(id)}
                            >
                                Remove
                            </Button>
                        </Box>
                    </Card>
                );
            })}
        </div>
    );
};

SelectedClaimsComponent.propTypes = {
    claims: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            position: PropTypes.shape({
                lat: PropTypes.number.isRequired,
                lng: PropTypes.number.isRequired,
            }),
            isSelected: PropTypes.bool.isRequired,
            color: PropTypes.any,
            data: PropTypes.shape({
                ClaimNumber: PropTypes.string.isRequired,
                InsuredName: PropTypes.string.isRequired,
                InsuredAddress: PropTypes.string.isRequired,
            }).isRequired,
        }).isRequired,
    ).isRequired,
};
