export const MarkerType = {
    RESOURCE: 'RESOURCE',
    CLAIM_UNASSIGNED: 'CLAIM_UNASSIGNED',
    CLAIM_ASSIGNED: 'CLAIM_ASSIGNED',
};

export const getMarkerPath = (markerType) => {
    switch (markerType) {
        case MarkerType.RESOURCE:
            return 'M13.5,36a1.666,1.666,0,0,1-1.388-.726C10.29,32.632,8.757,30.448,7.4,28.52,1.275,19.789,0,17.972,0,13.5A13.5,13.5,0,0,1,23.046,3.954,13.412,13.412,0,0,1,27,13.5c0,4.472-1.275,6.289-7.4,15.02-1.353,1.928-2.887,4.112-4.708,6.753A1.666,1.666,0,0,1,13.5,36ZM9.783,13.424a3.721,3.721,0,0,0-3.717,3.717v.464A1.4,1.4,0,0,0,7.46,19H19.54a1.4,1.4,0,0,0,1.393-1.394v-.464a3.721,3.721,0,0,0-3.716-3.717h-1.6a5.057,5.057,0,0,1-4.234,0ZM13.5,4.133a4.181,4.181,0,1,0,4.181,4.181A4.186,4.186,0,0,0,13.5,4.133Z';
        case MarkerType.CLAIM_ASSIGNED:
            return 'M13.5,36a1.666,1.666,0,0,1-1.388-.726C10.29,32.632,8.757,30.448,7.4,28.52,1.275,19.789,0,17.972,0,13.5A13.5,13.5,0,0,1,23.046,3.954,13.412,13.412,0,0,1,27,13.5c0,4.472-1.275,6.289-7.4,15.02-1.353,1.928-2.887,4.112-4.708,6.753A1.666,1.666,0,0,1,13.5,36Zm1.425-20.6h0L13.65,16.675,17.483,20.5l5.942-5.95L22.15,13.275,17.483,17.95,14.925,15.4Zm-9.35,0v1.7h6.8V15.4Zm0-3.4v1.7h10.2V12Zm0-3.4v1.7h10.2V8.6Z';
        case MarkerType.CLAIM_UNASSIGNED:
            return 'M13.5,36a1.666,1.666,0,0,1-1.388-.726C10.29,32.632,8.757,30.448,7.4,28.52,1.275,19.789,0,17.972,0,13.5A13.5,13.5,0,0,1,23.046,3.954,13.412,13.412,0,0,1,27,13.5c0,4.472-1.275,6.289-7.4,15.02-1.353,1.928-2.887,4.112-4.708,6.753A1.666,1.666,0,0,1,13.5,36ZM8.4,16.9v1.7H18.6V16.9Zm0-3.4v1.7H18.6V13.5Zm0-3.4v1.7H18.6V10.1Z';
        default:
            return 'M13.5,36a1.666,1.666,0,0,1-1.388-.726C10.29,32.632,8.757,30.448,7.4,28.52,1.275,19.789,0,17.972,0,13.5A13.5,13.5,0,0,1,23.046,3.954,13.412,13.412,0,0,1,27,13.5c0,4.472-1.275,6.289-7.4,15.02-1.353,1.928-2.887,4.112-4.708,6.753A1.666,1.666,0,0,1,13.5,36ZM8.4,16.9v1.7H18.6V16.9Zm0-3.4v1.7H18.6V13.5Zm0-3.4v1.7H18.6V10.1Z';
    }
};
