import React, { useState } from 'react';
import ClaimList from './claim-list';
import { AppUrls } from '../../core/route.url';
import { usePostData } from '../../hooks/use-post-data.hook';
import Moment from 'moment';
import ClaimSummary from './claim-summary';
import { useCurrentUser } from '../../hooks/user-current-user.hook';
import { Box, FormControl, Radio, RadioGroup, FormControlLabel, Typography } from '@mui/material';

const Claim = () => {
    const [filterList, setFilterList] = React.useState([]);
    const [initialResult, setInitialResult] = React.useState([]);
    const [isLoad, setIsLoad] = useState(true);
    const [claimType, setClaimType] = React.useState('Active');
    const { submitData, response } = usePostData(AppUrls.claimList, 'POST');
    const { submitData: getFilterSubmit, response: getFilterResponse } = usePostData(
        AppUrls.ClaimGridGet,
        'POST',
    );
    const [claimFilters, setClaimFilters] = useState({
        Carrier: '',
        ClaimStatus: '',
        EventCode: '',
        LossType: '',
        City: '',
        ZipCode: '',
        DateReceived: '',
    });
    const currentUser = useCurrentUser();
    // React.useEffect(() => {
    //     setIsLoad(true);
    // }, [claimFilters]);

    React.useEffect(() => {
        if (isLoad && currentUser.UserId !== undefined) {
            setIsLoad(false);
            submitData({
                PageNo: 1,
                // PageNo: pageNumber,
                PageSize: 500,
                SortDir: 'Ascending',
                SortExp: '',
                StartDate: null,
                EndDate: null,
                ClaimNumber: '',
                InsuredName: '',
                ClaimGuID: '',
                ClaimStatusName: claimFilters.ClaimStatus,
                EventId: claimFilters.EventCode === '' ? 0 : claimFilters.EventCode,
                CarrierId: claimFilters.Carrier === '' ? 0 : claimFilters.Carrier,
                LossTypeId: claimFilters.LossType === '' ? 0 : claimFilters.LossType,
                DateReceived: claimFilters.DateReceived === '' ? null : claimFilters.DateReceived,
                City: claimFilters.City,
                Zip: claimFilters.ZipCode,
                FilterType: claimType,
            });
        }
    }, [isLoad]);

    React.useEffect(() => {
        if (isLoad && currentUser.UserId !== undefined) {
            getFilterSubmit({
                UserCtrlFilterId: 0,
                PageName: 'CLAIM',
                UserId: currentUser.UserId,
                ControlName: 'ClAIM GRID',
                FilterDetails: '',
                ColumnFilters: '',
            });
        }
    }, [isLoad]);

    const handleReloadFilter = () => {
        if (currentUser.UserId !== undefined) {
            getFilterSubmit({
                UserCtrlFilterId: 0,
                PageName: 'CLAIM',
                UserId: currentUser.UserId,
                ControlName: 'ClAIM GRID',
                FilterDetails: '',
                ColumnFilters: '',
            });
        }
    };

    React.useEffect(() => {
        if (response.result !== undefined ? (response.result.Data !== null ? 1 : 0) : 0) {
            if (response.result.Data.length > 0) {
                response.result.Data.forEach((o) => {
                    o.InspectedOn =
                        o.InspectedOn === null
                            ? ''
                            : Moment(o.InspectedOn).format('MM-DD-YYYY hh:mm A');
                    o.ScheduledOn =
                        o.ScheduledOn === null
                            ? ''
                            : Moment(o.ScheduledOn).format('MM-DD-YYYY hh:mm A');
                    o.DateReceived =
                        o.DateReceived === null
                            ? ''
                            : Moment(o.DateReceived).format('MM-DD-YYYY hh:mm A');
                    o.ReservationStart =
                        o.ReservationStart === null
                            ? ''
                            : Moment(o.ReservationStart).format('MM-DD-YYYY hh:mm A');
                    o.AcceptedDate =
                        o.AcceptedDate === null
                            ? ''
                            : Moment(o.AcceptedDate).format('MM-DD-YYYY hh:mm A');
                    o.RejectedDate =
                        o.RejectedDate === null
                            ? ''
                            : Moment(o.RejectedDate).format('MM-DD-YYYY hh:mm A');
                });
                setFilterList(
                    response.result.Data.map((dataItem) =>
                        Object.assign(
                            {
                                selected: false,
                            },
                            dataItem,
                        ),
                    ),
                );
                setInitialResult(
                    response.result.Data.map((dataItem) =>
                        Object.assign(
                            {
                                selected: false,
                            },
                            dataItem,
                        ),
                    ),
                );
            } else {
                setFilterList([]);
                setInitialResult([]);
            }
        }
    }, [response]);

    const OnHandleChange = (searchterm, count, reset) => {
        if (reset) {
            if (count) {
                setFilterList(
                    initialResult.filter((data) => {
                        return (
                            (data.ClaimNumber &&
                                data.ClaimNumber.toLowerCase().includes(
                                    searchterm.toLocaleLowerCase(),
                                )) ||
                            (data.CarrierName &&
                                data.CarrierName.toLowerCase().includes(
                                    searchterm.toLocaleLowerCase(),
                                )) ||
                            (data.InsuredName &&
                                data.InsuredName.toLowerCase().includes(
                                    searchterm.toLocaleLowerCase(),
                                )) ||
                            (data.StateCode &&
                                data.StateCode.toLowerCase().includes(
                                    searchterm.toLocaleLowerCase(),
                                )) ||
                            (data.ClaimStatusName &&
                                data.ClaimStatusName.toLowerCase().includes(
                                    searchterm.toLocaleLowerCase(),
                                )) ||
                            (data.SLAStatusTemp &&
                                data.SLAStatusTemp.toLowerCase().includes(
                                    searchterm.toLocaleLowerCase(),
                                )) ||
                            (data.AssignedToName &&
                                data.AssignedToName.toLowerCase().includes(
                                    searchterm.toLocaleLowerCase(),
                                )) ||
                            (data.ScheduledOn && data.ScheduledOn !== ''
                                ? Moment(data.ScheduledOn)
                                      .format('MM-DD-YYYY hh:mm A')
                                      .toLowerCase()
                                      .includes(searchterm.toLocaleLowerCase())
                                : data.ScheduledOn.toLowerCase().includes(
                                      searchterm.toLocaleLowerCase(),
                                  )) ||
                            (data.InspectedOn && data.InspectedOn !== ''
                                ? Moment(data.InspectedOn)
                                      .format('MM-DD-YYYY hh:mm A')
                                      .toLowerCase()
                                      .includes(searchterm.toLocaleLowerCase())
                                : data.InspectedOn.toLowerCase().includes(
                                      searchterm.toLocaleLowerCase(),
                                  )) ||
                            (data.TypeOfLoss &&
                                data.TypeOfLoss.toLowerCase().includes(
                                    searchterm.toLocaleLowerCase(),
                                )) ||
                            (data.DateReceived && data.DateReceived !== ''
                                ? Moment(data.DateReceived)
                                      .format('MM-DD-YYYY hh:mm A')
                                      .toLowerCase()
                                      .includes(searchterm.toLocaleLowerCase())
                                : data.DateReceived.toLowerCase().includes(
                                      searchterm.toLocaleLowerCase(),
                                  )) ||
                            (data.EventName &&
                                data.EventName.toLowerCase().includes(
                                    searchterm.toLocaleLowerCase(),
                                )) ||
                            (data.City &&
                                data.City.toLowerCase().includes(searchterm.toLocaleLowerCase()))
                        );
                    }),
                );
            } else {
                setFilterList([]);
            }
        } else {
            setFilterList(initialResult);
        }
    };

    const onReload = () => {
        setIsLoad(true);
    };

    const displayList = () => {
        if (filterList.length > 0) {
            return (
                <ClaimList
                    filterList={filterList}
                    OnHandleChange={OnHandleChange}
                    setClaimFilters={setClaimFilters}
                    forUpdate={0}
                    isLoadingData={response.isSubmitting}
                    setIsLoad={setIsLoad}
                    reload={onReload}
                    getFilterResponse={getFilterResponse}
                    handleReloadFilter={handleReloadFilter}
                />
            );
        } else {
            return (
                <ClaimList
                    filterList={[]}
                    OnHandleChange={OnHandleChange}
                    setClaimFilters={setClaimFilters}
                    forUpdate={1}
                    isLoadingData={response.isSubmitting}
                    reload={onReload}
                    getFilterResponse={getFilterResponse}
                    handleReloadFilter={handleReloadFilter}
                />
            );
        }
    };
    const handleOnChange = async (e) => {
        setClaimType(e.target.value);
        setFilterList([]);
        submitData({
            PageNo: 1,
            // PageNo: pageNumber,
            PageSize: 500,
            SortDir: 'Ascending',
            SortExp: '',
            StartDate: null,
            EndDate: null,
            ClaimNumber: '',
            InsuredName: '',
            ClaimGuID: '',
            ClaimStatusName: claimFilters.ClaimStatus,
            EventId: claimFilters.EventCode === '' ? 0 : claimFilters.EventCode,
            CarrierId: claimFilters.Carrier === '' ? 0 : claimFilters.Carrier,
            LossTypeId: claimFilters.LossType === '' ? 0 : claimFilters.LossType,
            DateReceived: claimFilters.DateReceived === '' ? null : claimFilters.DateReceived,
            City: claimFilters.City,
            Zip: claimFilters.ZipCode,
            FilterType: e.target.value,
        });
    };
    return (
        <div className="main-content">
            {' '}
            <Box className="claimtaskGrid">
                <Box>
                    <Typography variant="h1" className="title m-0">
                        Claims
                    </Typography>
                </Box>
                <Box style={{ paddingBottom: 10 }}>
                    <FormControl>
                        <RadioGroup
                            row
                            aria-labelledby="radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            value={claimType}
                            onChange={handleOnChange}
                        >
                            <FormControlLabel
                                value="Active"
                                control={<Radio />}
                                label="ACTIVE CLAIMS"
                            />
                            <FormControlLabel
                                value="Completed"
                                control={<Radio />}
                                label="COMPLETED CLAIMS"
                            />
                            <FormControlLabel value="All" control={<Radio />} label="ALL CLAIMS" />
                        </RadioGroup>
                    </FormControl>
                </Box>
            </Box>
            <ClaimSummary
                filterList={initialResult}
                OnHandleChange={OnHandleChange}
                isLoadingData={response.isSubmitting}
                claimType={claimType}
            />
            {displayList()}
        </div>
    );
};

export default Claim;
Claim.propTypes = {};
