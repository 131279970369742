import React, { useState, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import {
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    Alert,
    CircularProgress,
    Modal,
} from '@mui/material';
import SelectOption from '../../components/select-option';
import { usePostData } from '../../hooks/use-post-data.hook';
import { AppUrls } from '../../core/route.url';
import PropTypes from 'prop-types';
import { usePostData2 } from '../../hooks/use-post-data-2.hook';
import SelectMulti from '../../components/select-multi';

const InspectionTypeForm = (props) => {
    const {
        register,
        handleSubmit,
        control,
        setValue,
        watch,
        formState: { errors },
    } = useForm();

    const watchAllFields = watch();

    const [savedResponse, setSaveResponse] = useState({});
    const {
        response: carrierList,

        isError: carrierError,
    } = usePostData2(AppUrls.carrierList, {
        CarrierName: '',
        CarrierUniqueId: '',
    });

    const { submitData, response } = usePostData(AppUrls.InspesctionForSave, 'POST');
    const { submitData: submitDataForGet, response: getResponse } = usePostData(
        AppUrls.InspesctionForGet,
        'POST',
    );

    React.useEffect(() => {
        setValue('inspectionType', '');
        setValue('description', '');
        setValue('active', true);
        setValue('CarrierId', '');
    }, []);

    useEffect(() => {
        if (response.result != undefined) {
            setSaveResponse(response.result);
            props.setIsLoadList(true);
            const timer = setTimeout(() => {
                setSaveResponse({});
                props.setIsLoadList(true);
                setValue('inspectionType', '');
                setValue('description', '');
                setValue('active', true);
                setValue('CarrierId', '');
                props.setIsOpen(false);
            }, 3000);
            return () => clearTimeout(timer);
        }
    }, [response]);

    React.useEffect(() => {
        if (props.idValue !== 0) {
            submitDataForGet({ InspectionTypeId: props.idValue });
        }
    }, [props.idValue]);

    React.useEffect(() => {
        if (getResponse.result != undefined) {
            setValue('inspectionType', getResponse.result.Data.InspectionType);
            setValue('description', getResponse.result.Data.InspectionDescription);
            setValue('active', getResponse.result.Data.IsActive);
            setValue('CarrierId', getResponse.result.Data.CarrierId);
        }
    }, [getResponse]);

    const onSubmit = async (data) => {
        submitData({
            IsActive: data.active,
            TenantId: 'allstate',
            InspectionTypeId: props.idValue,
            // CarrierId: data.CarrierId,
            InspectionType: data.inspectionType,
            InspectionDescription: data.description,
        });
    };

    if (carrierError) {
        return <div>Error Loading Data</div>;
    }

    return (
        <div className='main-content' >
            <h2 style={{ marginBottom: 20 }}>Inspection Type</h2>
            <Box
                component="form"
                sx={{
                    '& .MuiTextField-root': { m: 1, width: '53ch' },
                }}
                noValidate
                autoComplete="off"
                onSubmit={handleSubmit(onSubmit)}
            >
                <div>
                    <TextField
                        required
                        error={errors.inspectionType}
                        id="outlined-required"
                        label="Inspection Type"
                        defaultValue=""
                        InputLabelProps={{
                            shrink: watchAllFields.inspectionType === '' ? false : true,
                        }}
                        {...register('inspectionType', { required: true })}
                    />
                    {/* 
                    <FormControl sx={{ m: 1, width: '53ch' }}>
                        <Controller
                            control={control}
                            name="CarrierId"
                            rules={{
                                required: false,
                            }}
                            render={({ field: { onChange, onBlur, value, name } }) => (
                                <SelectMulti
                                    values={
                                        carrierList.result !== undefined
                                            ? carrierList.result?.Data?.map((e) => {
                                                  return {
                                                      value: e['CarrierId'],
                                                      label: e['CarrierName'],
                                                  };
                                              })
                                            : []
                                    }
                                    disabled={false}
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    value={value === undefined ? '' : value}
                                    // name={name || ''}
                                    nameForLablel="Carrier *"
                                    name={'Carrier *' || ''}
                                    error={errors.CarrierId}
                                />
                            )}
                        />
                    </FormControl> */}

                    <TextField
                        style={{ zIndex: 0 }}
                        id="outlined-multiline-static"
                        label="Description"
                        multiline
                        rows={4}
                        error={errors.description}
                        defaultValue=""
                        InputLabelProps={{
                            shrink: watchAllFields.description === '' ? false : true,
                        }}
                        {...register('description', { required: false })}
                    />
                </div>
                <div className="d-flex" style={{ marginTop: 20 }}>
                    <Button
                        style={{ marginLeft: 2 }}
                        onClick={() => {
                            props.setIsOpen(false);
                        }}
                        variant="outlined"
                        type="button"
                    >
                        Cancel
                    </Button>
                    <FormControlLabel
                        style={{ marginLeft: 'auto' }}
                        control={
                            <Checkbox
                                checked={
                                    watchAllFields.active !== undefined
                                        ? watchAllFields.active
                                        : false
                                }
                                {...register('active', { required: false })}
                                //   onChange={handleChange}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        }
                        label="Active"
                    />
                    <Button style={{ marginLeft: 2 }} variant="contained" type="submit">
                        {response.isSubmitting ? <CircularProgress color="inherit" /> : 'Save'}
                    </Button>
                </div>
            </Box>
            {savedResponse.IsSuccess != undefined ? (
                <Alert
                    style={{ marginTop: 15 }}
                    severity={savedResponse.IsSuccess ? 'success' : 'error'}
                >
                    {savedResponse.Message}
                </Alert>
            ) : null}
        </div>
    );
};

export default InspectionTypeForm;
InspectionTypeForm.propTypes = {
    idValue: PropTypes.number,
    setIsLoadList: PropTypes.any,
    setIsOpen: PropTypes.any,
};
