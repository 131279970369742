import React from 'react';

import { process } from '@progress/kendo-data-query';
import { Grid, GridColumn as Column, GridToolbar } from '@progress/kendo-react-grid';
import { columnsForInspectionType } from '../../components/kendoGrid/columns';
import { ColumnMenu } from '../../components/kendoGrid/customColumnMenu';
import { Button, Modal, Box, TextField, Breadcrumbs, Typography } from '@mui/material';
import InspectionTypeForm from './inspection-type-form';
import editIcon from '../../images/pencil.svg';
import deleteIcon from '../../images/trash.svg';
import PropTypes from 'prop-types';
import DeleteModal from '../../components/delete-modal';
import { Link } from 'react-router-dom';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 780,
    bgcolor: 'background.paper',
    boxShadow: 3,
    p: 3,
};

const InspectionTypeList = ({ filterList, onHandleDeleteClick, setIsLoadList, OnHandleChange }) => {
    const createDataState = (dataState) => {
        return {
            result: process(filterList.slice(0), dataState),
            dataState: dataState,
        };
    };

    const [stateColumns, setStateColumns] = React.useState(columnsForInspectionType);
    const [isOpen, setIsOpen] = React.useState(false);
    const [groupOfGrid, setGroupOfGrid] = React.useState([]);
    const [propertyValues, setPropertyValues] = React.useState({ idValue: 0, name: '' });
    const [isDeleteOpen, setIsDeleteOpen] = React.useState(false);
    const [initialState, setInitialState] = React.useState(
        createDataState({
            take: 100,
            skip: 0,
        }),
    );
    const [result, setResult] = React.useState(initialState.result);

    React.useEffect(() => {
        setInitialState(
            createDataState({
                take: 100,
                skip: 0,
                group: groupOfGrid,
            }),
        );
    }, [filterList]);

    React.useEffect(() => {
        setResult(initialState.result);
    }, [initialState]);

    const [dataState, setDataState] = React.useState(initialState.dataState);

    const dataStateChange = (event) => {
        let updatedState = createDataState(event.dataState);
        setGroupOfGrid(event.dataState.group);
        setResult(updatedState.result);
        setDataState(updatedState.dataState);
    };

    const onColumnsSubmit = (columnsState) => {
        setStateColumns(columnsState);
    };

    const handleDeleteClick = (inspectionId, name) => {
        setPropertyValues({ idValue: inspectionId, name: name });

        setIsDeleteOpen(true);
    };

    const editCell = (props) => {
        if (props.rowType === 'groupHeader') {
            return null;
        }
        return (
            <>
                <td className="k-command-cell">
                    <button
                        className="icon-btn grid-icon"
                        onClick={() => {
                            // history(`/inspection/add`, {
                            //   state: `${props.dataItem.InspectionTypeId}`,
                            // });
                            setPropertyValues({
                                idValue: props.dataItem.InspectionTypeId,
                                name: '',
                            });

                            setIsOpen(true);
                        }}
                    >
                        <img src={editIcon} title="Edit" />
                    </button>

                    <button
                        className="icon-btn grid-icon"
                        onClick={() => {
                            handleDeleteClick(
                                props.dataItem.InspectionTypeId,
                                props.dataItem.InspectionType,
                            );
                        }}
                    >
                        <img src={deleteIcon} title="Delete" />
                    </button>
                </td>
            </>
        );
    };

    const editCellForIsActive = (props) => {
        if (props.rowType === 'groupHeader') {
            return null;
        }
        return (
            <>
                <td className="k-command-cell">
                    {props.dataItem.IsActive ? 'Active' : 'Inactive'}
                </td>
            </>
        );
    };

    const handleClose = () => {
        setIsOpen(false);

        setPropertyValues({ idValue: 0, name: '' });
    };

    const handleChangeValue = (e) => {
        OnHandleChange(e.target.value);
    };

    return (
        <>
            <DeleteModal
                isDeleteOpen={isDeleteOpen}
                setIsDeleteOpen={setIsDeleteOpen}
                propertyValues={propertyValues}
                setPropertyValues={setPropertyValues}
                handleDelete={onHandleDeleteClick}
            />
            <Modal
                open={isOpen}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <InspectionTypeForm
                        idValue={propertyValues.idValue}
                        setIsLoadList={setIsLoadList}
                        setIsOpen={setIsOpen}
                    />
                </Box>
            </Modal>
            <Box style={{ display: 'flex', alignItems: 'flex-end', marginBottom: 15 }}>
                <Box>
                    <Typography className="title" variant="h1">
                        Inspection Types
                    </Typography>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link
                            to={'/'}
                            underline="hover"
                            color="inherit"
                            style={{ textDecoration: 'none' }}
                        >
                            Home
                        </Link>
                        <Link
                            to={'/inspection_type'}
                            underline="hover"
                            color="text.primary"
                            aria-current="page"
                            style={{ textDecoration: 'none' }}
                        >
                            Inspection Types
                        </Link>
                    </Breadcrumbs>
                </Box>
                <Button
                    style={{ marginLeft: 'auto' }}
                    variant="contained"
                    type="button"
                    onClick={() => {
                        setIsOpen(true);
                        setPropertyValues({ idValue: 0, name: '' });
                        // history("/inspection/add", { state: "0" });
                    }}
                >
                    Add New
                </Button>
            </Box>

            <div>
                <Grid
                    data={result}
                    {...dataState}
                    style={{
                        maxHeight: 'calc(100vh - 130px)',
                        minHeight: 'calc(100vh - 130px)',
                    }}
                    onDataStateChange={dataStateChange}
                    sortable={true}
                    pageable={{
                        buttonCount: 5,
                        info: true,
                        type: 'numeric',
                        pageSizes: [100, 200],
                        previousNext: true,
                    }}
                    groupable={true}
                    total={result.total}
                    pageSize={8}
                >
                    <GridToolbar>
                        <div className="search-wrap">
                            <div style={{ paddingLeft: 3 }}>
                                <TextField
                                    required
                                    id="outlined-required"
                                    label=""
                                    defaultValue=""
                                    size="small"
                                    onChange={(e) => handleChangeValue(e)}
                                />
                            </div>
                        </div>
                        {/* <Link to="/mapview">
                            <button title="Export to Excel" className="icon-btn map-icon">
                                <img src={mapIcon} title="View Map" />
                            </button>
                        </Link> */}
                    </GridToolbar>
                    {stateColumns.map(
                        (column, idx) =>
                            column.show &&
                            (column.field == 'optn' ? (
                                <Column
                                    width={100}
                                    key={idx}
                                    field={column.field}
                                    title={column.title}
                                    cell={editCell}
                                    filter={column.filter}
                                    columnMenu={(props) => (
                                        <ColumnMenu
                                            {...props}
                                            columns={stateColumns}
                                            onColumnsSubmit={onColumnsSubmit}
                                        />
                                    )}
                                />
                            ) : column.field == 'IsActive' ? (
                                <Column
                                    width={100}
                                    key={idx}
                                    field={column.field}
                                    title={column.title}
                                    cell={editCellForIsActive}
                                    filter={column.filter}
                                    columnMenu={(props) => (
                                        <ColumnMenu
                                            {...props}
                                            columns={stateColumns}
                                            onColumnsSubmit={onColumnsSubmit}
                                        />
                                    )}
                                />
                            ) : (
                                <Column
                                    key={idx}
                                    field={column.field}
                                    title={column.title}
                                    filter={column.filter}
                                    columnMenu={(props) => (
                                        <ColumnMenu
                                            {...props}
                                            columns={stateColumns}
                                            onColumnsSubmit={onColumnsSubmit}
                                        />
                                    )}
                                />
                            )),
                    )}
                </Grid>
            </div>
        </>
    );
};

export default InspectionTypeList;
InspectionTypeList.propTypes = {
    filterList: PropTypes.array.isRequired,
    onHandleDeleteClick: PropTypes.func.isRequired,
    setIsLoadList: PropTypes.any.isRequired,
};
