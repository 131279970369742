import { useState, useEffect } from 'react';
import {
    Grid,
    Typography,
    Avatar,
    FormControl,
    FormControlLabel,
    Select,
    MenuItem,
    InputLabel,
    TextField,
    Checkbox,
    Button,
    Link,
    Stack,
    FormGroup,
    FormLabel
} from '@mui/material/';

export const CarrierCreatePage = () => {

    return (
        <div className='main-content'>
            <Grid container marginBottom={3}>
                <Grid item xs={12} >
                    <Typography variant="h1" className="title m-0">
                        AOD Carrier Onboarding
                    </Typography>
                </Grid>
            </Grid>
            <Grid container marginBottom={3}>
                <Grid item md={4}>
                    <Avatar
                        alt="Carrier Icon"
                        src="/images/carrier-icon.svg"
                        sx={{ width: 24, height: 24 }}
                    />
                </Grid>
                <Grid item md={4}>
                    <Stack>
                        <FormControl sx={{ m: 1, minWidth: 150 }}>
                            <InputLabel id="vendortype-label">Vendor Type</InputLabel>
                            <Select
                                labelId="vendortype-label"
                                id="vendortype"
                                label="Vendor Type" >
                                <MenuItem
                                    key="AOD"
                                    value="AOD">
                                    AOD
                                </MenuItem>
                                <MenuItem
                                    key="IOD"
                                    value="IOD">
                                    IOD
                                </MenuItem>
                            </Select>
                        </FormControl>
                        <TextField sx={{ m: 1, minWidth: 150 }}
                            required
                            id="outlined-required"
                            label="Xact Carrier ID"
                            defaultValue="" />
                        <TextField sx={{ m: 1, minWidth: 150 }}
                            required
                            id="outlined-required"
                            label="Xact Carrier Name"
                            defaultValue="" />
                    </Stack>
                </Grid>
                <Grid item md={4}>
                    <Stack>
                        <TextField sx={{ m: 1, minWidth: 150 }}
                            required
                            id="outlined-required"
                            label="Point of Contact Name"
                            defaultValue="" />
                        <TextField sx={{ m: 1, minWidth: 150 }}
                            required
                            id="outlined-required"
                            label="Abbreviation"
                            defaultValue="" />
                        <TextField sx={{ m: 1, minWidth: 150 }}
                            required
                            id="outlined-required"
                            label="Xact Analysis Profile Code"
                            defaultValue="" />
                    </Stack>
                </Grid>
                <Grid item md={4}>
                    <FormControl sx={{ m: 1, minWidth: 150 }}>
                        <InputLabel id="vendortype-label">Select Template</InputLabel>
                        <Select
                            labelId="vendortype-label"
                            id="vendortype"
                            label="Select Template" >
                            <MenuItem
                                key="AOD"
                                value="AOD">
                                AOD
                            </MenuItem>
                            <MenuItem
                                key="IOD"
                                value="IOD">
                                IOD
                            </MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item md={8}>
                    <TextField sx={{ m: 1, minWidth: 300 }}
                        id="outlined-required"
                        label="Address"
                        defaultValue="" />
                </Grid>
            </Grid>
            <Grid container marginBottom={3}>
                <Grid item xs={12} >
                    <Typography variant="h5" className="title m-0">
                        Select all that Apply
                    </Typography>
                </Grid>
            </Grid>
            <Grid container marginBottom={3}>
                <Grid item md={4}>
                    <FormControl component="fieldset" variant="standard">
                        <FormLabel component="legend">Assignment Type</FormLabel>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox name="property" />
                                }
                                label="Property"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox name="auto" />
                                }
                                label="Auto"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox name="other" />
                                }
                                label="Other"
                            />
                        </FormGroup>
                    </FormControl>
                </Grid>
                <Grid item md={4}>
                    <FormControl component="fieldset" variant="standard">
                        <FormLabel component="legend">Policy Type</FormLabel>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox name="property" />
                                }
                                label="Property"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox name="auto" />
                                }
                                label="Auto"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox name="other" />
                                }
                                label="Other"
                            />
                        </FormGroup>
                    </FormControl>
                </Grid>
                <Grid item md={4}>
                    <FormControl component="fieldset" variant="standard">
                        <FormLabel component="legend">Inspection Type</FormLabel>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox name="property" />
                                }
                                label="Property"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox name="auto" />
                                }
                                label="Auto"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox name="other" />
                                }
                                label="Other"
                            />
                        </FormGroup>
                    </FormControl>
                </Grid>
            </Grid>
            <Grid container marginBottom={3}>
                <Grid item xs={12} >
                    <Typography variant="h5" className="title m-0">
                        Invoice Number format
                    </Typography>
                </Grid>
                <Grid item md={4}>

                </Grid>
                <Grid item md={4}>

                </Grid>
                <Grid item md={4}>

                </Grid>
            </Grid>
        </div>
    );
};