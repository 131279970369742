import React from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import { Grid, GridColumn, GridToolbar } from '@progress/kendo-react-grid';
import { process } from '@progress/kendo-data-query';
import PropTypes from 'prop-types';
import editIcon from '../../images/pencil.svg';
import deleteIcon from '../../images/trash.svg';
import { Box, Button, Modal, Breadcrumbs, Typography, TextField, IconButton } from '@mui/material';
import SlaForm from './sla-form';
import { Link } from 'react-router-dom';
import { GridPDFExport } from '@progress/kendo-react-pdf';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import moment from 'moment/moment';
import { Cached } from '@mui/icons-material';
import iconexcel from '../../images/file-excel.svg';
import iconpdf from '../../images/file-pdf-solid.svg';

const REPLACE_REGEX = /\s/g;

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1000,
    bgcolor: 'background.paper',
    boxShadow: 3,
    p: 3,
};

const SlaList = ({ SLAList, setSLAIsLoad, statusResponse }) => {
    const createDataStateSLA = (dataState) => {
        return {
            result: process(SLAList?.slice(0), dataState),
            dataState: dataState,
        };
    };

    const [initialStateSLA, setInitialStateSLA] = React.useState(
        createDataStateSLA({
            take: 100,
            skip: 0,
        }),
    );
    const [resultSLA, setResultSLA] = React.useState(initialStateSLA.result);
    const [isOpen, setIsOpen] = React.useState(false);
    const [idValue, setIdValue] = React.useState(0);
    const [searchValue, setSearchValue] = React.useState('');

    React.useEffect(() => {
        setInitialStateSLA(
            createDataStateSLA({
                take: 100,
                skip: 0,
            }),
        );
    }, [SLAList]);

    React.useEffect(() => {
        setResultSLA(initialStateSLA.result);
    }, [initialStateSLA]);

    const [dataStateSLA, setDataStateSLA] = React.useState(initialStateSLA.dataState);

    const dataStateSLAChange = (event) => {
        let updatedState = createDataStateSLA(event.dataState);
        setResultSLA(updatedState.result);
        setDataStateSLA(updatedState.dataState);
    };

    const _export = React.useRef(null);

    const excelExport = () => {
        if (_export.current !== null) {
            _export.current.save();
        }
    };

    let _pdfExport;

    const exportPDF = () => {
        _pdfExport.save(
            process(SLAList, {
                take: SLAList.length + 1,
                skip: 0,
                group: dataStateSLA.group,
                filter: dataStateSLA.filter,
            }).data,
        );
    };

    const editCell = (props) => {
        if (props.rowType === 'groupHeader') {
            return null;
        }
        return (
            <>
                <td className="k-command-cell">
                    <button
                        className="icon-btn grid-icon"
                        onClick={() => {
                            setIdValue(props.dataItem.SLAId);
                            setIsOpen(true);
                        }}
                    >
                        <img src={editIcon} title="Edit file" />
                    </button>

                    <button
                        className="icon-btn file-icon"
                        /* onClick={() => {
      handleDeleteClick(props.dataItem.InspectionTypeId);
    }} */
                    >
                        {/* <img src={deleteIcon} title="Delete File" /> */}
                    </button>
                </td>
            </>
        );
    };

    const reserveCell = (props) => {
        if (props.rowType === 'groupHeader') {
            return null;
        }
        return (
            <>
                <td className="k-command-cell">{props.dataItem.ReserveOnly ? 'Yes' : 'No'}</td>
            </>
        );
    };
    const handleClose = () => {
        setIsOpen(false);
    };

    const handleCreateNewClick = () => {
        setIsOpen(true);
        setIdValue(0);
    };

    const exportGrid = (value) => {
        return (
            <Grid
                data={resultSLA}
                {...dataStateSLA}
                style={{
                    maxHeight: 'calc(100vh - 131px)',
                    display: value ? 'none' : '',
                }}
                onDataStateChange={dataStateSLAChange}
                sortable={true}
                pageable={{
                    buttonCount: 5,
                    info: true,
                    type: 'numeric',
                    pageSizes: [100, 200, 500],
                    previousNext: true,
                }}
                groupable={true}
                pageSize={10}
                reorderable={true}
                resizable={true}
            >
                <GridColumn field="SLADefinition" title="SLA Definition" />
                <GridColumn field="PreferedDurationTemp" title="Prefered Duration" width="250px" />
                <GridColumn field="CutOffDurationTemp" title="Cut-Off Duration" width="250px" />
                <GridColumn field="SLAStartStatusName" title="Start Status" width="150px" />
                <GridColumn field="SLAEndStatusName" title="End Status" width="150px" />
                <GridColumn
                    field="ReserveOnly"
                    title="Reserve Only"
                    cell={reserveCell}
                    width="120px"
                />
                <GridColumn field="optn" title="Options" width="80px" cell={editCell} />
            </Grid>
        );
    };

    const hasSearchKeyExist = (messageItem) => {
        return (
            messageItem?.SLADefinition?.toLowerCase()
                .replace(REPLACE_REGEX, '')
                .includes(searchValue?.toLocaleLowerCase().replace(REPLACE_REGEX, '')) ||
            messageItem?.CutOffDurationTemp?.toLowerCase()
                .replace(REPLACE_REGEX, '')
                .includes(searchValue?.toLocaleLowerCase().replace(REPLACE_REGEX, '')) ||
            messageItem?.PreferedDurationTemp?.toLowerCase()
                .replace(REPLACE_REGEX, '')
                .includes(searchValue?.toLocaleLowerCase().replace(REPLACE_REGEX, '')) ||
            messageItem?.SLAStartStatusName?.toLowerCase()
                .replace(REPLACE_REGEX, '')
                .includes(searchValue?.toLocaleLowerCase().replace(REPLACE_REGEX, '')) ||
            messageItem?.SLAEndStatusName?.toLowerCase()
                .replace(REPLACE_REGEX, '')
                .includes(searchValue?.toLocaleLowerCase().replace(REPLACE_REGEX, '')) ||
            (messageItem?.ReserveOnly ? 'yes' : 'no')
                ?.toLowerCase()
                .replace(REPLACE_REGEX, '')
                .includes(searchValue?.toLocaleLowerCase().replace(REPLACE_REGEX, ''))
        );
    };

    const filterListBySearch = (list = []) => {
        return list?.filter((messageItem) => hasSearchKeyExist(messageItem));
    };

    const filterGroup = (array, fn) => {
        return array.reduce((r, o) => {
            var children = filterGroup(o.items || [], fn);
            if (fn(o) || children.length)
                r.push(Object.assign({}, o, children.length && { children }));
            return r;
        }, []);
    };

    const getFilteredList = (data = { data: [], total: 0 }) => {
        let filteredData =
            data?.data?.length && data?.data[0]?.hasOwnProperty('ClaimTaskStatusCode')
                ? filterListBySearch(data?.data)
                : filterGroup(data?.data ?? [], (messageItem) => hasSearchKeyExist(messageItem));
        return {
            data: filteredData,
            total: data?.total,
        };
    };

    return (
        <>
            {' '}
            <Modal
                open={isOpen}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                style={{ overflow: 'scroll' }}
            >
                <Box sx={style}>
                    <SlaForm
                        setIsOpen={setIsOpen}
                        isOpen={isOpen}
                        carrierId={0}
                        setSLAIsLoad={setSLAIsLoad}
                        idValue={idValue}
                        setIdValue={setIdValue}
                        statusResponse={statusResponse}
                    />
                </Box>
            </Modal>
            <Box style={{ display: 'flex', alignItems: 'flex-end', marginBottom: 15 }}>
                <Box>
                    <Typography className="title" variant="h1">
                        Global SLAs
                    </Typography>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link
                            to={'/'}
                            underline="hover"
                            color="inherit"
                            style={{ textDecoration: 'none' }}
                        >
                            Home
                        </Link>
                        <Link
                            to={'/sla'}
                            underline="hover"
                            color="text.primary"
                            aria-current="page"
                            style={{ textDecoration: 'none' }}
                        >
                            Global SLAs
                        </Link>
                    </Breadcrumbs>
                </Box>
                <Button
                    style={{ marginLeft: 'auto' }}
                    variant="contained"
                    type="button"
                    onClick={() => {
                        handleCreateNewClick();
                    }}
                >
                    New
                </Button>
            </Box>
            {resultSLA != undefined ? (
                <div className="audit-trail-list-grid">
                    <Grid
                        data={getFilteredList(resultSLA)}
                        {...dataStateSLA}
                        style={{
                            maxHeight: 'calc(100vh - 131px)',
                        }}
                        onDataStateChange={dataStateSLAChange}
                        sortable={true}
                        pageable={{
                            buttonCount: 5,
                            info: true,
                            type: 'numeric',
                            pageSizes: [100, 200, 500],
                            previousNext: true,
                        }}
                        groupable={true}
                        pageSize={10}
                        reorderable={true}
                        resizable={true}
                    >
                        <GridToolbar>
                            <div className="search-wrap">
                                <div style={{ paddingLeft: 3 }}>
                                    <TextField
                                        required
                                        id="outlined-required"
                                        label=""
                                        defaultValue=""
                                        size="small"
                                        value={searchValue}
                                        onChange={(e) => setSearchValue(e.target.value)}
                                    />
                                </div>
                                <IconButton
                                    onClick={() => {
                                        setSLAIsLoad();
                                    }}
                                    className="clearIcon"
                                    title="Clear All filter"
                                >
                                    <Cached />
                                </IconButton>
                            </div>
                        </GridToolbar>
                        <GridToolbar>
                            {/* {handleSetPrintPermissions('Claims') ? ( */}
                            <div>
                                <button
                                    title="Export to Excel"
                                    className="icon-btn"
                                    onClick={excelExport}
                                >
                                    <img src={iconexcel} title="Export to Excel" />
                                </button>

                                <button className="icon-btn" onClick={exportPDF}>
                                    <img src={iconpdf} title="Export to PDF" />
                                </button>
                            </div>
                            {/* ) : null} */}
                        </GridToolbar>

                        <GridColumn field="SLADefinition" title="SLA Definition" />
                        <GridColumn
                            field="PreferedDurationTemp"
                            title="Prefered Duration"
                            width="250px"
                        />
                        <GridColumn
                            field="CutOffDurationTemp"
                            title="Cut-Off Duration"
                            width="250px"
                        />
                        <GridColumn field="SLAStartStatusName" title="Start Status" width="150px" />
                        <GridColumn field="SLAEndStatusName" title="End Status" width="150px" />
                        <GridColumn
                            field="ReserveOnly"
                            title="Reserve Only"
                            cell={reserveCell}
                            width="120px"
                        />
                        <GridColumn field="optn" title="Options" width="80px" cell={editCell} />
                    </Grid>
                    <ExcelExport
                        fileName={`GlobalSLAExport_${moment(new Date()).format(
                            'MM-DD-YYYY hh mm a',
                        )}`}
                        style={{ display: 'none' }}
                        data={
                            SLAList
                                ? process(SLAList, {
                                      take: SLAList.length + 1,
                                      skip: 0,
                                      group: dataStateSLA.group,
                                      filter: dataStateSLA.filter,
                                  }).data
                                : []
                        }
                        ref={_export}
                        groupable={true}
                        group={dataStateSLA.group}
                    >
                        {exportGrid(true)}
                    </ExcelExport>
                    <GridPDFExport
                        fileName={`GlobalSLAExport_${moment(new Date()).format(
                            'MM-DD-YYYY hh mm a',
                        )}`}
                        ref={(element) => {
                            _pdfExport = element;
                        }}
                        margin="1cm"
                    >
                        {exportGrid(false)}
                    </GridPDFExport>
                </div>
            ) : (
                <>
                    <LinearProgress></LinearProgress>
                </>
            )}
        </>
    );
};
export default SlaList;

SlaList.propTypes = {
    SLAList: PropTypes.any.isRequired,
    setSLAIsLoad: PropTypes.func,
    statusResponse: PropTypes.any,
};
