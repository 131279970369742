import React from 'react';

const FooterComponent = () => {
    return (
        <>
            {' '}
            {/* footer start */}
            <footer className="footer">
                {/* <small>Version 2.01</small> */}
                <small></small> <small>© 2022 Pilot Catastrophe. All Rights Reserved.</small>{' '}
                <small>
                    Powered By<span>WBPRO</span>
                </small>{' '}
            </footer>
            {/* footer End */}
        </>
    );
};

export default FooterComponent;
