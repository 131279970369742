import React, { memo } from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    Button,
    CircularProgress,
    FormControl,
    TextField,
    Modal,
    FormControlLabel,
    Checkbox,
    FormLabel,
    FormGroup,
    Paper,
    Grid,
    Divider,
    Typography,
    LinearProgress,
    Autocomplete,
    FormHelperText,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import SelectOption from '../../components/select-option';
import { AppUrls } from '../../core/route.url';
import { usePostData } from '../../hooks/use-post-data.hook';
import { useLoadData2 } from '../../hooks/use-load-data-2.hook';
import { usePostData2 } from '../../hooks/use-post-data-2.hook';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import CustomSaveQueryForm from './custom-save-query-form';
import Moment from 'moment';
import DatePicker, { CalendarContainer } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import CustomDatePicker from '../../components/CustomDatePicker';
import CustomExecuteQuery from './custom-execute-query';
import ColumnOptionsModal from '../../components/query-builder/ColumnOptionsModal';
import { red } from '@mui/material/colors';
import { useEffect } from 'react';

const CATALYST_API_URL = process.env.REACT_APP_AUTH_REDIRECT_URI;

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const template = {
    LookupColumn: '',
    Operator: '',
    OperatorError: '',
    ColumnValue: '',
    Condition: '',
    DataType: '',
};

const CustomQueryBuilderForms = memo(({ queryOperator, repoertEntity, value }) => {
    const {
        register,
        handleSubmit,
        control,
        setValue,
        watch,
        formState: { errors },
    } = useForm({
        defaultValues: {
            test: [template],
        },
    });
    const { fields, append, remove, swap, move, insert, replace } = useFieldArray({
        control,
        name: 'test',
    });
    const watchAllFields = watch();

    const [isSaveModalOpen, setIsSaveModalOpen] = React.useState(false);
    const [isColumnOptionsModalOpen, setIsColumnOptionsModalOpen] = React.useState(false);
    const [checkedArrayField, setCheckedArrayField] = React.useState([]);
    const [filterList, setFilterList] = React.useState([]);
    const [filterData, setFilterData] = React.useState([]);
    const [filterOperator, setFilterOperator] = React.useState([]);
    const [typeOperator, setTypeOperator] = React.useState([]);
    const [apiParam, setApiParam] = React.useState({ allApiParam: [] });
    const [dropDownData, setDropDownData] = React.useState({ allDropDownData: [] });
    const { allDropDownData } = dropDownData;
    const [apiRequestData, setApiRequestData] = React.useState({});
    const { submitData: ReportEntityFieldSubmitData, response: ReportEntityFieldSave } =
        usePostData(AppUrls.ReportEntityField, 'POST');
    const { submitData: saveQueryData, response: responseSaveQueryData } = usePostData(
        AppUrls.RunQueary,
        'POST',
    );
    const [state, setState] = React.useState({ checkedArray: [] });
    const [operationlAndState, setoperationalAndState] = React.useState({
        andOperator: [],
    });
    const [operationalOrState, setoperationalOrState] = React.useState({
        orOperator: [],
    });

    const currentdate = new Date();

    const { andOperator } = operationlAndState;
    const { orOperator } = operationalOrState;
    const { allApiParam } = apiParam;

    const {
        isLoading: loading,
        result: resultData,
        isError: Error,
        reload,
        paramValue,
    } = useLoadData2();

    React.useEffect(() => {
        if (responseSaveQueryData.result !== undefined) {
            // localStorage.setItem('queary_data', JSON.stringify(responseSaveQueryData.result.Data));
            localStorage.setItem('report_name', '');
            // window.open(CATALYST_API_URL + `execute_query`);
            console.log(responseSaveQueryData.result.Data);
            setFilterList(
                responseSaveQueryData.result.Data ? responseSaveQueryData.result.Data : [],
            );
        }
    }, [responseSaveQueryData]);

    React.useEffect(() => {
        watchAllFields.test.forEach((element) => {
            if (element.LookupColumn !== '') {
                if (allApiParam.includes(element.LookupColumn)) {
                } else {
                    reload(
                        AppUrls.AllDropDownData +
                            `?param=${
                                filterData.filter((data, i) => {
                                    return data.LookupColumn === element.LookupColumn;
                                })[0].ApiParams
                            }`,
                        filterData.filter((data, i) => {
                            return data.LookupColumn === element.LookupColumn;
                        })[0].ApiParams,
                    );
                    setApiParam({
                        allApiParam: [...apiParam.allApiParam, element.LookupColumn],
                    });
                }
            }
        });
    }, [allApiParam]);

    React.useEffect(
        () => {
            if (
                watchAllFields.test.length > 0 &&
                watchAllFields.test[watchAllFields.test.length - 1].LookupColumn !== '' &&
                filterData.length > 0
            ) {
                if (
                    filterData.filter((data, i) => {
                        return (
                            data.LookupColumn ===
                            watchAllFields.test[watchAllFields.test.length - 1].LookupColumn
                        );
                    })[0]?.ApiParams !== ''
                ) {
                    setApiParam({
                        allApiParam: [
                            ...apiParam.allApiParam,
                            watchAllFields.test[watchAllFields.test.length - 1].LookupColumn,
                        ],
                    });

                    reload(
                        AppUrls.AllDropDownData +
                            `?param=${
                                filterData.filter((data, i) => {
                                    return (
                                        data.LookupColumn ===
                                        watchAllFields.test[watchAllFields.test.length - 1]
                                            .LookupColumn
                                    );
                                })[0]?.ApiParams
                            }`,
                        filterData.filter((data, i) => {
                            return (
                                data.LookupColumn ===
                                watchAllFields.test[watchAllFields.test.length - 1].LookupColumn
                            );
                        })[0]?.ApiParams,
                    );
                }
            }
        },
        // [watchAllFields.test[watchAllFields.test.length - 1].LookupColumn]
        watchAllFields.test.map((element, i) => {
            return element.LookupColumn;
        }),
    );

    React.useEffect(() => {
        if (resultData !== undefined) {
            if (resultData.Data !== undefined) {
                setDropDownData({
                    allDropDownData: [
                        ...dropDownData.allDropDownData,
                        {
                            param: paramValue,
                            result: resultData.Data,
                        },
                    ],
                });
            }
        }
    }, [resultData]);

    React.useEffect(() => {
        // if (1) {
        // replace(watchAllFields.test);
        ReportEntityFieldSubmitData();
        // }
    }, [watchAllFields.entity]);

    React.useEffect(() => {
        if (queryOperator !== undefined) {
            if (queryOperator.Data !== undefined) {
                setFilterOperator(queryOperator.Data);
            }
        }
    }, [queryOperator]);

    React.useEffect(() => {
        if (ReportEntityFieldSave.result !== undefined) {
            setFilterData(ReportEntityFieldSave.result.Data);
            remove();
            append(template);
            setCheckedArrayField([]);
            setState({
                checkedArray: ReportEntityFieldSave.result.Data?.filter(
                    (x) => x.SelectedByDefault,
                ).map((item) => item.EntityFieldId),
            });
        }
    }, [ReportEntityFieldSave]);

    React.useEffect(() => {
        if (watchAllFields.test) {
            if (watchAllFields.test.length > 0) {
                watchAllFields.test.forEach((data, i) => {
                    if (data.LookupColumn !== '') {
                        let dataType = filterData.filter((d, i) => {
                            return d.LookupColumn === data.LookupColumn;
                        })[0]?.DataType;
                        watchAllFields.test[i].DataType = dataType;
                        // setValue(`test.${i}.DataType`, dataType);
                        setTypeOperator(watchAllFields.test);
                        // filterData.filter((d, i) => {
                        //     return d.LookupColumn === data.LookupColumn;
                        // })[0]?.DataType
                        // ;
                        // console.log(
                        //     i,
                        //     watchAllFields.test[i] && watchAllFields.test[i].DataType,
                        //     256,
                        //     filterOperator?.filter((data, i) => {
                        //         return  data.OperatorId !== 7

                        //     }),
                        // );
                    }
                });
            }
        }
    }, [
        watchAllFields.test.map((element, i) => {
            return element.LookupColumn;
        }),
    ]);

    React.useEffect(() => {
        if (state.checkedArray.length > 0) {
            setCheckedArrayField(
                filterData.length > 0
                    ? filterData
                          .filter((data, i) => {
                              return state.checkedArray.includes(data.EntityFieldId);
                          })
                          .map((d, i) => {
                              return {
                                  EntityFieldId: d.EntityFieldId,
                                  EntityId: d.EntityID,
                                  FieldName: d.FieldName,
                                  FieldDisplayName: d.FieldDisplayName,
                              };
                          })
                    : [],
            );
        }
    }, [state.checkedArray]);

    const handleChange = (event) => {
        const { value, checked } = event.target;
        const { checkedArray } = state;

        // Case 1 : The user checks the box
        if (checked) {
            setState({
                checkedArray: [...checkedArray, parseInt(value)],
            });
        }

        // Case 2  : The user unchecks the box
        else {
            setState({
                checkedArray: checkedArray.filter((e) => e !== parseInt(value)),
            });
        }
    };

    const submit = (data) => {
        // setAssignedTestData(
        // data.test.forEach((element, i) => {
        //     return (element['DataType'] =
        //         filterData.length > 0
        //             ? filterData.filter((d, index) => {
        //                   return d.LookupColumn === element.LookupColumn;
        //               })[0]?.DataType
        //             : '');
        // });
        // data.test.forEach((element, i) => {
        //     return (element['ColumnValue'] =
        //         element.DataType !== 'date'
        //             ? typeof element.ColumnValue === 'string'
        //                 ? element.ColumnValue
        //                 : `${element.ColumnValue}`
        //             : element.ColumnValue !== 'Invalid date'
        //             ? Moment(element.ColumnValue).isValid()
        //                 ? `${Moment(element.ColumnValue).format('DD-MM-YYYY')}`
        //                 : `${Moment(currentdate).format('DD-MM-YYYY')}`
        //             : `${Moment(currentdate).format('DD-MM-YYYY')}`);
        // });
        if (data.test.some((x) => !x?.Operator || x?.Operator === '-1')) {
            data.test.map((element, i) => {
                setValue(
                    `test.${i}.OperatorError`,
                    !element?.Operator || element?.Operator === '-1'
                        ? 'Operator Field is mandatory.'
                        : '',
                );
            });
            return;
        }

        const DATE_FORMAT = 'DD-MM-YYYY';
        data.test.map((element, i) => {
            setValue(`test.${i}.OperatorError`, '');
            element['LookupColumn'] = element.LookupColumn === '-1' ? '' : element.LookupColumn;
            element['ColumnValue'] =
                element.DataType !== 'date'
                    ? typeof element.ColumnValue === 'string'
                        ? element.ColumnValue
                        : `${element.ColumnValue}`
                    : element.ColumnValue !== 'Invalid date'
                    ? Array.isArray(element.ColumnValue) && element.ColumnValue?.length
                        ? `${Moment(element.ColumnValue[0] ?? new Date()).format(
                              DATE_FORMAT,
                          )}##${Moment(element.ColumnValue[1] ?? new Date()).format(DATE_FORMAT)}`
                        : Moment(element.ColumnValue).isValid()
                        ? `${Moment(element.ColumnValue).format(DATE_FORMAT)}`
                        : element.ColumnValue?.length > 0
                        ? element.ColumnValue
                        : element.DataType === 'date'
                        ? element?.Operator === 'Between'
                            ? `${Moment(currentdate).format(DATE_FORMAT)}##${Moment(
                                  currentdate,
                              ).format(DATE_FORMAT)}`
                            : `${Moment(currentdate).format(DATE_FORMAT)}`
                        : ''
                    : `${Moment(currentdate).format(DATE_FORMAT)}`;
            element['DataType'] =
                filterData.length > 0
                    ? filterData.filter((d, index) => {
                          return d.LookupColumn === element.LookupColumn;
                      })[0]?.DataType
                    : '';

            return element;
        });

        setValue(`test.${data.test.length - 1}.Condition`, '');

        if (data.value !== 0) {
            setApiRequestData({
                ReportID: 0,
                EntityId: data.entity,
                EntityName:
                    repoertEntity !== undefined
                        ? repoertEntity.Data?.filter((d, i) => {
                              return d.EntityID === data.entity;
                          })[0].LookupTable
                        : '',
                ReportName: ' ',
                IsGlobal: true,
                QBFilters: data.test,
                QBFields: checkedArrayField,
                SqlQuery: '',
                IsSave: true,
            });

            setIsSaveModalOpen(true);
        } else {
            setFilterList([]);
            saveQueryData({
                ReportID: 0,
                EntityId: data.entity,
                EntityName:
                    repoertEntity !== undefined
                        ? repoertEntity.Data?.filter((d, i) => {
                              return d.EntityID === data.entity;
                          })[0].LookupTable
                        : '',
                ReportName: ' ',
                IsGlobal: true,
                QBFilters: data.test,
                QBFields: checkedArrayField,
                SqlQuery: '',
                IsSave: false,
            });
        }
    };

    const addTemplate = (logic, value) => {
        append(template);
        if (logic === 'AND') {
            setValue(`test.${value}.Condition`, logic);
            setoperationalAndState({ andOperator: [...operationlAndState.andOperator, value] });
        } else {
            setValue(`test.${value}.Condition`, logic);
            setoperationalOrState({ orOperator: [...operationalOrState.orOperator, value] });
        }
    };

    const handleRemove = (index) => {
        if (index === 0 && fields?.length === 1) {
            setValue(`test.${index}.Condition`, '');
            setValue(`test.${index}.LookupColumn`, '');
            setValue(`test.${index}.Operator`, '');
            setValue(`test.${index}.ColumnValue`, '');
            setValue(`test.${index}.DataType`, '');
        } else {
            //need to recheck
            if (andOperator.includes(index)) {
                setoperationalAndState({
                    andOperator: andOperator.filter((data, i) => {
                        return data !== index;
                    }),
                });
            }
            if (orOperator.includes(index)) {
                setoperationalOrState({
                    orOperator: orOperator.filter((data, i) => {
                        return data !== index;
                    }),
                });
            }
            //

            if (andOperator.includes(index - 1)) {
                setoperationalAndState({
                    andOperator: andOperator.filter((data, i) => {
                        return data !== index - 1;
                    }),
                });
            }
            if (orOperator.includes(index - 1)) {
                setoperationalOrState({
                    orOperator: orOperator.filter((data, i) => {
                        return data !== index - 1;
                    }),
                });
            }
            if (index === fields?.length - 1) {
                setValue(`test.${index - 1}.Condition`, '');
            }
            console.log('index', index);
            remove(index);
        }
        console.log(andOperator);
    };

    const handleChangeAll = (e) => {
        if (filterData.length > 0) {
            let array = [];
            if (e.target.checked) {
                filterData.forEach((element) => {
                    array.push(element.EntityFieldId);
                    // setState({
                    //     checkedArray: [...state.checkedArray, parseInt(element.EntityFieldId)],
                    // });
                });
                setState({
                    checkedArray: array,
                });
            } else {
                setState({
                    checkedArray: array,
                });
            }
        }
    };

    const MyContainer = ({ className, children }) => {
        return (
            <div style={{ padding: '16px', background: '#216ba5', color: '#fff' }}>
                <CalendarContainer className={className}>
                    <div style={{ background: '#f0f0f0' }}>What is your favorite day?</div>
                    <div style={{ position: 'relative' }}>{children}</div>
                </CalendarContainer>
            </div>
        );
    };

    const onOpenCloseColumnOptions = () => {
        setIsColumnOptionsModalOpen(!isColumnOptionsModalOpen);
    };

    const onSubmitColumnOptions = (array) => {
        onOpenCloseColumnOptions();
        setState({
            checkedArray: array,
        });
    };

    return (
        <div>
            <CustomSaveQueryForm
                apiRequestData={apiRequestData}
                isSaveModalOpen={isSaveModalOpen}
                setIsSaveModalOpen={setIsSaveModalOpen}
            />{' '}
            <Box component="form" noValidate autoComplete="off" onSubmit={handleSubmit(submit)}>
                <Button
                    disabled={filterData?.length === 0}
                    onClick={onOpenCloseColumnOptions}
                    variant="contained"
                >
                    Column Options
                </Button>
                {ReportEntityFieldSave.isSubmitting ? (
                    <Box sx={{ width: '100%', marginTop: 2 }}>
                        <LinearProgress />
                    </Box>
                ) : null}
                <Typography variant="h3" marginTop={3} fontWeight={700} marginBottom={2}>
                    Filters
                </Typography>
                <Box className="flexGroup">
                    {fields.map((item, index) => {
                        return (
                            <div key={item?.id} >
                                <Box
                                    className={
                                        andOperator.includes(index - 1)
                                            ? 'repeatGrid andshow'
                                            : 'repeatGrid orshow'
                                    }
                                >
                                    <FormControl size="small">
                                        <Controller
                                            control={control}
                                            name={`test.${index}.LookupColumn`}
                                            rules={{
                                                required: false,
                                            }}
                                            render={({
                                                field: { onChange, onBlur, value, name },
                                            }) => {
                                                const onChangeField = (param) => {
                                                    setValue(`test.${index}.ColumnValue`, '');
                                                    onChange(param);
                                                };
                                                return (
                                                    <SelectOption
                                                        values={
                                                            filterData?.length > 0
                                                                ? filterData?.map((e) => {
                                                                      return {
                                                                          value: e['LookupColumn'],
                                                                          label: e[
                                                                              'FieldDisplayName'
                                                                          ],
                                                                      };
                                                                  })
                                                                : []
                                                        }
                                                        disabled={false}
                                                        onChange={onChangeField}
                                                        onBlur={onBlur}
                                                        value={value === undefined ? '' : value}
                                                        name={'Select Filter ' || ''}
                                                        error={errors.LookupColumn}
                                                        showSelectOption
                                                    />
                                                );
                                            }}
                                        />
                                    </FormControl>

                                    <FormControl size="small">
                                        <Controller
                                            control={control}
                                            name={`test.${index}.Operator`}
                                            rules={{
                                                required: false,
                                            }}
                                            render={({
                                                field: { onChange, onBlur, value, name },
                                            }) => {
                                                const dataType =
                                                    filterData?.find(
                                                        (x) =>
                                                            x.LookupColumn ===
                                                            watchAllFields.test[index].LookupColumn,
                                                    )?.DataType ?? '';
                                                return (
                                                    <SelectOption
                                                        values={
                                                            dataType && dataType === 'string'
                                                                ? filterOperator
                                                                      ?.filter((data, i) => {
                                                                          return (
                                                                              data.OperatorId !==
                                                                                  7 &&
                                                                              data.OperatorId !==
                                                                                  6 &&
                                                                              data.OperatorId !== 8
                                                                          );
                                                                      })
                                                                      .map((e) => {
                                                                          return {
                                                                              value: e[
                                                                                  'OperatorCode'
                                                                              ],
                                                                              label: e[
                                                                                  'OperatorDesc'
                                                                              ],
                                                                          };
                                                                      })
                                                                : dataType && dataType === 'date'
                                                                ? filterOperator
                                                                      ?.filter((data, i) => {
                                                                          return (
                                                                              data.OperatorId !==
                                                                                  3 &&
                                                                              data.OperatorId !==
                                                                                  4 &&
                                                                              data.OperatorId !== 5
                                                                          );
                                                                      })
                                                                      .map((e) => {
                                                                          return {
                                                                              value: e[
                                                                                  'OperatorCode'
                                                                              ],
                                                                              label: e[
                                                                                  'OperatorDesc'
                                                                              ],
                                                                          };
                                                                      })
                                                                : dataType && dataType === 'boolean'
                                                                ? filterOperator
                                                                      ?.filter((data, i) => {
                                                                          return (
                                                                              data.OperatorId ===
                                                                                  1 ||
                                                                              data.OperatorId === 2
                                                                          );
                                                                      })
                                                                      .map((e) => {
                                                                          return {
                                                                              value: e[
                                                                                  'OperatorCode'
                                                                              ],
                                                                              label: e[
                                                                                  'OperatorDesc'
                                                                              ],
                                                                          };
                                                                      })
                                                                : []
                                                        }
                                                        disabled={false}
                                                        onChange={onChange}
                                                        onBlur={onBlur}
                                                        value={value === undefined ? '' : value}
                                                        name={'Select Operator ' || ''}
                                                        error={
                                                            watchAllFields.test[index]
                                                                ?.OperatorError
                                                        }
                                                        showSelectOption
                                                    />
                                                );
                                            }}
                                        />
                                        <FormHelperText
                                            className="text-danger"
                                            style={{
                                                fontSize: 10,
                                                margin: 0,
                                                color: red['500'],
                                            }}
                                        >
                                            {watchAllFields.test[index]?.OperatorError}
                                        </FormHelperText>
                                    </FormControl>

                                    {/* -------------------------------------------------------------------------------------------- */}
                                    <Box>
                                        <TextField
                                            size="small"
                                            fullWidth
                                            style={{
                                                display:
                                                    watchAllFields.test[index].LookupColumn !==
                                                        '' && filterData?.length > 0
                                                        ? filterData.filter((d, i) => {
                                                              return (
                                                                  d.LookupColumn ===
                                                                  watchAllFields.test[index]
                                                                      .LookupColumn
                                                              );
                                                          })[0]?.ControlType === 'TextBox'
                                                            ? ''
                                                            : 'none'
                                                        : 'none',
                                            }}
                                            required
                                            // error={errors.inspectionType}
                                            id="outlined-required"
                                            label={
                                                watchAllFields.test[index].LookupColumn !== '' &&
                                                filterData?.length > 0
                                                    ? filterData.filter((d, i) => {
                                                          return (
                                                              d.LookupColumn ===
                                                              watchAllFields.test[index]
                                                                  .LookupColumn
                                                          );
                                                      })[0]?.FieldDisplayName
                                                    : ''
                                            }
                                            defaultValue=""
                                            InputLabelProps={{
                                                shrink:
                                                    watchAllFields.carrier === '' ? false : true,
                                            }}
                                            {...register(
                                                watchAllFields.test[index].LookupColumn !== '' &&
                                                    filterData?.length > 0
                                                    ? filterData.filter((d, i) => {
                                                          return (
                                                              d.LookupColumn ===
                                                              watchAllFields.test[index]
                                                                  .LookupColumn
                                                          );
                                                      })[0]?.ControlType === 'TextBox'
                                                        ? `test.${index}.ColumnValue`
                                                        : 'fileld2'
                                                    : 'fileld2',

                                                { required: false },
                                            )}
                                        />

                                        <FormControl
                                            size="small"
                                            fullWidth
                                            style={{
                                                display:
                                                    watchAllFields.test[index].LookupColumn !==
                                                        '' && filterData?.length > 0
                                                        ? filterData.filter((d, i) => {
                                                              return (
                                                                  d.LookupColumn ===
                                                                  watchAllFields.test[index]
                                                                      .LookupColumn
                                                              );
                                                          })[0]?.ControlType === 'Datepicker'
                                                            ? ''
                                                            : 'none'
                                                        : 'none',
                                            }}
                                        >
                                            <Controller
                                                size="small"
                                                fullWidth
                                                control={control}
                                                name={
                                                    watchAllFields.test[index].LookupColumn !==
                                                        '' && filterData?.length > 0
                                                        ? filterData.filter((d, i) => {
                                                              return (
                                                                  d.LookupColumn ===
                                                                  watchAllFields.test[index]
                                                                      .LookupColumn
                                                              );
                                                          })[0]?.ControlType === 'Datepicker'
                                                            ? `test.${index}.ColumnValue`
                                                            : 'Field3'
                                                        : 'Field3'
                                                }
                                                rules={{
                                                    required: false,
                                                }}
                                                render={({
                                                    field: { onChange, onBlur, value, name },
                                                }) => {
                                                    // old code commented for reference
                                                    // <LocalizationProvider
                                                    //     dateAdapter={AdapterDateFns}
                                                    // >
                                                    //     <DesktopDatePicker
                                                    //         label={
                                                    //             watchAllFields.test[index]
                                                    //                 .LookupColumn !== '' &&
                                                    //             filterData.length > 0
                                                    //                 ? filterData.filter((d, i) => {
                                                    //                       return (
                                                    //                           d.LookupColumn ===
                                                    //                           watchAllFields.test[
                                                    //                               index
                                                    //                           ].LookupColumn
                                                    //                       );
                                                    //                   })[0].FieldDisplayName
                                                    //                 : ''
                                                    //         }
                                                    //         inputFormat="dd/MM/yyyy"
                                                    //         value={value}
                                                    //         onChange={onChange}
                                                    //         renderInput={(params) => (
                                                    //             <TextField
                                                    //                 size="small"
                                                    //                 {...params}
                                                    //             />
                                                    //         )}
                                                    //     />
                                                    // </LocalizationProvider>

                                                    const [start, end] = Array.isArray(value)
                                                        ? value
                                                        : [new Date(), new Date()];
                                                    return watchAllFields.test[index]?.Operator ===
                                                        'Between' ? (
                                                        <CustomDatePicker
                                                            onChange={onChange}
                                                            startDate={start}
                                                            endDate={end}
                                                            dateFormat="MM/dd/yyyy"
                                                            label={
                                                                watchAllFields.test[index]
                                                                    .LookupColumn !== '' &&
                                                                filterData?.length > 0
                                                                    ? `${
                                                                          filterData.filter(
                                                                              (d, i) => {
                                                                                  return (
                                                                                      d.LookupColumn ===
                                                                                      watchAllFields
                                                                                          .test[
                                                                                          index
                                                                                      ].LookupColumn
                                                                                  );
                                                                              },
                                                                          )[0]?.FieldDisplayName
                                                                      } (Date range)`
                                                                    : ''
                                                            }
                                                            selectsRange
                                                        />
                                                    ) : (
                                                        <CustomDatePicker
                                                            onChange={onChange}
                                                            value={value}
                                                            dateFormat="MM/dd/yyyy"
                                                            label={
                                                                watchAllFields.test[index]
                                                                    .LookupColumn !== '' &&
                                                                filterData?.length > 0
                                                                    ? filterData.filter((d, i) => {
                                                                          return (
                                                                              d.LookupColumn ===
                                                                              watchAllFields.test[
                                                                                  index
                                                                              ].LookupColumn
                                                                          );
                                                                      })[0]?.FieldDisplayName
                                                                    : ''
                                                            }
                                                        />
                                                    );
                                                }}
                                            />
                                        </FormControl>

                                        <FormControl
                                            fullWidth
                                            size="small"
                                            style={{
                                                display:
                                                    watchAllFields.test[index].LookupColumn !==
                                                        '' && filterData?.length > 0
                                                        ? filterData.filter((d, i) => {
                                                              return (
                                                                  d.LookupColumn ===
                                                                  watchAllFields.test[index]
                                                                      .LookupColumn
                                                              );
                                                          })[0]?.ControlType === 'Dropdown'
                                                            ? ''
                                                            : 'none'
                                                        : 'none',
                                            }}
                                        >
                                            <Controller
                                                fullWidth
                                                control={control}
                                                name={
                                                    watchAllFields.test[index].LookupColumn !==
                                                        '' && filterData?.length > 0
                                                        ? filterData.filter((d, i) => {
                                                              return (
                                                                  d.LookupColumn ===
                                                                  watchAllFields.test[index]
                                                                      .LookupColumn
                                                              );
                                                          })[0]?.ControlType === 'Dropdown'
                                                            ? `test.${index}.ColumnValue`
                                                            : 'Field4'
                                                        : 'Field4'
                                                }
                                                rules={{
                                                    required: false,
                                                }}
                                                render={({
                                                    field: { onChange, onBlur, value, name },
                                                }) => (
                                                    <SelectOption
                                                        values={
                                                            // allDropDownData.length>0?
                                                            // carrierList.result !== undefined
                                                            //     ? carrierList.result?.Data?.map((e) => {
                                                            //           return {
                                                            //               value: e['CarrierId'],
                                                            //               label: e['CarrierName'],
                                                            //           };
                                                            //       })
                                                            //     : []
                                                            allDropDownData.length > 0 &&
                                                            watchAllFields.test[index]
                                                                .LookupColumn !== '' &&
                                                            filterData?.length > 0
                                                                ? allDropDownData.filter(
                                                                      (data, i) => {
                                                                          return (
                                                                              data.param ===
                                                                              filterData.filter(
                                                                                  (d, i) => {
                                                                                      return (
                                                                                          d.LookupColumn ===
                                                                                          watchAllFields
                                                                                              .test[
                                                                                              index
                                                                                          ]
                                                                                              .LookupColumn
                                                                                      );
                                                                                  },
                                                                              )[0]?.ApiParams
                                                                          );
                                                                      },
                                                                  )[0] !== undefined
                                                                    ? allDropDownData
                                                                          .filter((data, i) => {
                                                                              return (
                                                                                  data.param ===
                                                                                  filterData.filter(
                                                                                      (d, i) => {
                                                                                          return (
                                                                                              d.LookupColumn ===
                                                                                              watchAllFields
                                                                                                  .test[
                                                                                                  index
                                                                                              ]
                                                                                                  .LookupColumn
                                                                                          );
                                                                                      },
                                                                                  )[0]?.ApiParams
                                                                              );
                                                                          })[0]
                                                                          .result.map((e) => {
                                                                              return {
                                                                                  //   value: e['Id'],
                                                                                  label: e[
                                                                                      'TextValue'
                                                                                  ],
                                                                                  value: e[
                                                                                      'TextValue'
                                                                                  ],
                                                                              };
                                                                          })
                                                                    : []
                                                                : []
                                                        }
                                                        disabled={false}
                                                        onChange={onChange}
                                                        onBlur={onBlur}
                                                        value={value === undefined ? '' : value}
                                                        name={
                                                            watchAllFields.test[index]
                                                                .LookupColumn !== '' &&
                                                            filterData?.length > 0
                                                                ? filterData.filter((d, i) => {
                                                                      return (
                                                                          d.LookupColumn ===
                                                                          watchAllFields.test[index]
                                                                              .LookupColumn
                                                                      );
                                                                  })[0]?.FieldDisplayName
                                                                : '' || ''
                                                        }
                                                        error={errors.CarrierId}
                                                    />
                                                )}
                                            />
                                        </FormControl>
                                    </Box>

                                    <Box className="flexButton">
                                        <Button
                                            variant={
                                                watchAllFields.test[index].Condition?.includes(
                                                    'AND',
                                                )
                                                    ? 'contained'
                                                    : 'outlined'
                                            }
                                            color="primary"
                                            type="button"
                                            disabled={index !== fields.length - 1}
                                            onClick={() => {
                                                addTemplate('AND', index);
                                                // append(template);
                                            }}
                                        >
                                            And
                                        </Button>
                                        <Button
                                            variant={
                                                watchAllFields.test[index].Condition?.includes('OR')
                                                    ? 'contained'
                                                    : 'outlined'
                                            }
                                            color="primary"
                                            type="button"
                                            disabled={index !== fields.length - 1}
                                            onClick={() => {
                                                addTemplate(' OR ', index);
                                            }}
                                        >
                                            OR
                                        </Button>
                                        <Button
                                            variant="outlined"
                                            color="error"
                                            type="button"
                                            onClick={() => handleRemove(index)}
                                        >
                                            X
                                        </Button>
                                    </Box>
                                </Box>

                                {index !== 0 ? (
                                    andOperator.includes(index) ? (
                                        andOperator.includes(index - 1) ? null : (
                                            <Box component={'h3'} marginTop={2}>
                                                AND
                                            </Box>
                                        )
                                    ) : orOperator.includes(index) ? (
                                        orOperator.includes(index - 1) ? null : (
                                            <Box component={'h3'} marginTop={2}>
                                                OR
                                            </Box>
                                        )
                                    ) : null
                                ) : null}
                            </div>
                        );
                    })}
                </Box>

                <Box style={{ display: 'flex', marginTop: 30, marginBottom: 25 }}>
                    <Button
                        style={{
                            marginLeft: 'auto',
                            backgroundColor: state.checkedArray.length > 0 ? '' : 'silver',
                        }}
                        onClick={() => {
                            setValue('value', 0);
                        }}
                        type="submit"
                        variant="contained"
                        disabled={state.checkedArray.length > 0 ? false : true}
                    >
                        {responseSaveQueryData.isSubmitting ? (
                            <CircularProgress color="inherit" />
                        ) : (
                            ' Run Query'
                        )}
                    </Button>
                    <Button
                        style={{
                            marginLeft: 15,
                            backgroundColor: state.checkedArray.length > 0 ? '' : 'silver',
                        }}
                        variant="contained"
                        type="submit  "
                        onClick={() => {
                            setValue('value', 1);
                        }}
                        disabled={state.checkedArray.length > 0 ? false : true}
                    >
                        Save Query
                    </Button>
                </Box>
            </Box>
            {filterList ? (
                filterList.Columns && filterList.Columns.length > 0 ? (
                    <CustomExecuteQuery runQueryResult={filterList} value={value} />
                ) : null
            ) : null}
            <ColumnOptionsModal
                open={isColumnOptionsModalOpen}
                onChangeVisibility={onOpenCloseColumnOptions}
                filterData={filterData}
                checkedArray={state.checkedArray}
                onSubmit={onSubmitColumnOptions}
            />
        </div>
    );
});

export default CustomQueryBuilderForms;
CustomQueryBuilderForms.propTypes = {
    queryOperator: PropTypes.any,
    repoertEntity: PropTypes.any,
    value: PropTypes.number,
};
