import React, { useState, useEffect, useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import Box from '@mui/material/Box';
import {
    Button,
    FormControl,
    Alert,
    CircularProgress,
    Typography,
    Select,
    InputLabel,
    IconButton,
    MenuItem,
    FormHelperText,
} from '@mui/material';
import SelectOption from '../../components/select-option';
import { usePostData } from '../../hooks/use-post-data.hook';
import { AppUrls } from '../../core/route.url';
import { useCurrentUser } from '../../hooks/user-current-user.hook';
import { useAppContext } from '../../hooks/use-app-context.hook';
import { useStorage } from '../../hooks/use-storage';
import { isNil, map, reduce, uniq } from 'lodash';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Login } from '@mui/icons-material/';

const User_impersonate = (props, { children }) => {
    const { handleSubmit } = useForm();
    const user = useCurrentUser();
    const [isLoad, setIsLoad] = useState(true);
    const [savedResponse, setSaveResponse] = useState([]);
    const [userName, setUserName] = useState('');
    const { dispatch } = useAppContext();
    const [authToken, setToken] = useStorage('AUTH_STATE', null);
    //  const { isAuthenticated } = useCurrentUser();
    const navigate = useNavigate();

    const { submitData: submitImpersonateList, response: impersonateList } = usePostData(
        AppUrls.GetImpersonateList + '?currentUserId=' + user.UserId,
        'GET',
    );

    useEffect(() => {
        if (userName !== '') {
            props.popOpen(true);
        }
    }, [userName]);

    useEffect(() => {
        if (isLoad) {
            setIsLoad(false);
            submitImpersonateList({});
        }
    }, [isLoad]);

    const { submitData, response } = usePostData(AppUrls.Userimpersonate, 'POST');

    const onSubmit = async () => {
        props.popOpen(true);
        submitData({
            ImpersonateUserName: userName,
            CurrentUserId: user.UserId,
            CurrentUserFullName: user.UserFullName,
        });
    };

    useEffect(() => {
        if (response.result != undefined) {
            setSaveResponse(response.result);

            const timer = setTimeout(() => {
                const currentPermissions = reduce();
                // userData.Data.UserMenuPermissions,
                // (prevValue, currentValue) => {
                //   return [
                //     ...prevValue,
                //     ...map(currentValue.permissions, (p) => p.permissionName),
                //   ];
                // },
                // []
                setToken(response.result.Token);
                dispatch({
                    // ...user,
                    ...response.result.User,
                    // currentPermissions: uniq(currentPermissions),
                    isAuthenticated: true,
                });
                props.popOpen(false);
                setSaveResponse([]);
                window.location.href = '/';
            }, 5000);
            return () => clearTimeout(timer);
        }
    }, [response]);

    const handleOnChange = (event) => {
        setUserName(
            impersonateList.result.Data !== undefined
                ? impersonateList.result.Data.filter(
                      (d) => d.UserId === parseInt(event.target.value),
                  )[0].UserName
                : '',
        );
        props.popOpen(true);
    };

    return (
        <>
            <Box component="form" autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                <Typography variant="caption">Login as an impersonate user</Typography>
                <div className="gridoth-login">
                    <FormControl fullWidth variant="standard">
                        {/* <Select fullWidth native={true} onChange={handleOnChange} defaultValue=""> */}
                        {/* <option value={0}>Select</option>
                            {impersonateList != undefined && impersonateList.result != undefined
                                ? impersonateList.result.Data.map((e,i) => {
                                      return <option key={i} value={e['UserId']}>{e['UserName']}</option>;
                                  })
                                : []} */}
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            onChange={handleOnChange}
                        >
                            <MenuItem key={1000} value={''}>
                                Select
                            </MenuItem>

                            {impersonateList != undefined && impersonateList.result != undefined
                                ? impersonateList.result.Data.map((e, i) => {
                                      return (
                                          <MenuItem key={i} value={e['UserId']}>
                                              {e['EmailAddress']}
                                          </MenuItem>
                                      );
                                  })
                                : []}
                        </Select>
                        {/* </Select> */}
                    </FormControl>
                    <Box>
                        <IconButton size="medium">
                            {response.isSubmitting ? (
                                <CircularProgress color="inherit" />
                            ) : (
                                <Login onClick={handleSubmit(onSubmit)} />
                            )}
                        </IconButton>
                    </Box>
                </div>
            </Box>
            {savedResponse.IsSuccess != undefined ? (
                <Alert
                    style={{ marginTop: 15 }}
                    severity={savedResponse.IsSuccess ? 'success' : 'error'}
                >
                    {'Successfully switched user'}
                </Alert>
            ) : null}
        </>
    );
};

export default User_impersonate;

User_impersonate.propTypes = {
    popOpen: PropTypes.any.isRequired,
};
