import React from 'react';
import { useState, useEffect } from 'react';
import { AppUrls } from '../../core/route.url';
import Inspector_claim_task from './inspector-claim-task';
import { useLocation, useParams } from 'react-router-dom';
import { useLoadData } from '../../hooks/use-load-data.hook';
import { usePostData2 } from '../../hooks/use-post-data-2.hook';
import Moment from 'moment';
import Inspector_map_tab from './inspector-map-tab';
import Inspector_profile_tab from './inspector-profile-tab';
import { styled } from '@mui/material/styles';
import CreateIcon from '@mui/icons-material/Create';
import FormControlLabel from '@mui/material/FormControlLabel';
import {
    Switch,
    Modal,
    Container,
    Rating,
    LinearProgress,
    Stack,
    Typography,
    Box,
    Card,
    Button,
    List,
    ListItem,
} from '@mui/material/';
import Inspector_profile_pic from './inspector-profile-pic';
import { parse } from 'date-fns/esm';
import ClaimContact from '../claim/claim-contact';
import InspectorSummary from './inspector-summary';
import UserAddressEditForm from './user-address-edit-form';
import { useLoadData2 } from '../../hooks/use-load-data-2.hook';
import useUserPermissions from '../../hooks/use-user-permissions.hook';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '40%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 3,
};

export const Inspector_profile = () => {
    let { id } = useParams();
    const [isContactOpen, setIsContactOpen] = useState(false);
    const [inspectorProfileDetails, setInspectorProfileDetails] = useState({});
    const [filterList, setFilterList] = useState([]);
    const [initialResult, setInitialResult] = React.useState([]);
    const [idValue, setIdValue] = React.useState(0);
    const [isOpenEdit, setIsOpenEdit] = React.useState(false);
    const { handleSetEditPermissions } = useUserPermissions();
    const { result: inspector, reload } = useLoadData2();
    const location = useLocation();
    // console.log(89, location.pathname.split('/').includes('inspector'));
    const { submitData, response } = usePostData2(AppUrls.GetUserTasks, {
        PageNo: 1,
        PageSize: 1000,
        SortDir: 'Ascending',
        SortExp: '',
        StartDate: null,
        EndDate: null,
        ClaimTaskNumber: '',
        UserUniqueId: `${id}`,
    });

    useEffect(() => {
        if (id) {
            reload(AppUrls.UserProfile + `?UserUniqueId=${id}`, '');
        }
    }, [id]);

    useEffect(() => {
        if (inspector) {
            setInspectorProfileDetails(inspector.Data);
        }
    }, [inspector]);

    useEffect(() => {
        if (response.result && response.result.IsSuccess) {
            if (response.result.Data !== null) {
                response.result.Data.forEach((o) => {
                    o.DueDate =
                        o.DueDate === null || o.DueDate === 'Invalid date' || o.DueDate === ''
                            ? ''
                            : Moment(o.DueDate).format('MM-DD-YYYY hh:mm A');

                    // o.DueDate = o.DueDate === null ? '' : Moment(o.DueDate).format('MM-DD-YYYY');
                    o.PromisedInspectionStartTime = new Date(o.PromisedInspectionStartTime);
                    o.PromisedInspectionEndTime = new Date(o.PromisedInspectionEndTime);
                    o.ShortDescription = 'Claim: ' + o.ClaimNumber;
                });
                setFilterList(response.result.Data);
                setInitialResult(response.result.Data);
            } else {
                setFilterList([]);
                setInitialResult([]);
            }
        }
    }, [response?.result]);

    const handleReload = () => {
        reload(AppUrls.UserProfile + `?UserUniqueId=${id}`, '');
    };

    if (inspector === undefined) {
        return (
            <Box sx={{ width: '100%' }}>
                <LinearProgress />
            </Box>
        );
    }
    const IOSSwitch = styled((props) => (
        <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
    ))(({ theme }) => ({
        width: 60,
        height: 26,
        padding: 0,
        '& .MuiSwitch-switchBase': {
            padding: 0,
            margin: 2,
            transitionDuration: '300ms',
            '&.Mui-checked': {
                transform: 'translateX(33px)',
                color: '#fff',
                '& + .MuiSwitch-track': {
                    backgroundColor: theme.palette.mode === 'dark' ? '#337dff' : '#337dff',
                    opacity: 1,
                    border: 0,
                },
                '&.Mui-disabled + .MuiSwitch-track': {
                    opacity: 0.5,
                },
            },
            '&.Mui-focusVisible .MuiSwitch-thumb': {
                color: '#337dff',
                border: '6px solid #fff',
            },
            '&.Mui-disabled .MuiSwitch-thumb': {
                color:
                    theme.palette.mode === 'light'
                        ? theme.palette.grey[100]
                        : theme.palette.grey[600],
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
            },
        },
        '& .MuiSwitch-thumb': {
            boxSizing: 'border-box',
            width: 22,
            height: 22,
        },
        '& .MuiSwitch-track': {
            borderRadius: 26 / 2,
            backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
            opacity: 1,
            transition: theme.transitions.create(['background-color'], {
                duration: 500,
            }),
        },
    }));

    const handleClose = () => {
        setIsOpenEdit(false);
        setIdValue(0);
    };

    const OnHandleChange = (searchterm, count, index) => {
        if (index) {
            if (count) {
                setFilterList(
                    initialResult.filter((data) => {
                        return (
                            (data.ClaimNumber &&
                                data.ClaimNumber.toLowerCase().includes(
                                    searchterm.toLocaleLowerCase(),
                                )) ||
                            (data.CarrierName &&
                                data.CarrierName.toLowerCase().includes(
                                    searchterm.toLocaleLowerCase(),
                                )) ||
                            (data.InsuredName &&
                                data.InsuredName.toLowerCase().includes(
                                    searchterm.toLocaleLowerCase(),
                                )) ||
                            (data.StateCode &&
                                data.StateCode.toLowerCase().includes(
                                    searchterm.toLocaleLowerCase(),
                                )) ||
                            (data.ClaimStatusName &&
                                data.ClaimStatusName.toLowerCase().includes(
                                    searchterm.toLocaleLowerCase(),
                                )) ||
                            (data.SLAStatusTemp &&
                                data.SLAStatusTemp.toLowerCase().includes(
                                    searchterm.toLocaleLowerCase(),
                                )) ||
                            (data.AssignedToName &&
                                data.AssignedToName.toLowerCase().includes(
                                    searchterm.toLocaleLowerCase(),
                                )) ||
                            (data.ScheduledOn &&
                                data.ScheduledOn.toLowerCase().includes(
                                    searchterm.toLocaleLowerCase(),
                                )) ||
                            (data.InspectedOn &&
                                data.InspectedOn.toLowerCase().includes(
                                    searchterm.toLocaleLowerCase(),
                                )) ||
                            (data.TypeOfLoss &&
                                data.TypeOfLoss.toLowerCase().includes(
                                    searchterm.toLocaleLowerCase(),
                                )) ||
                            (data.DateReceived &&
                                data.DateReceived.toLowerCase().includes(
                                    searchterm.toLocaleLowerCase(),
                                )) ||
                            (data.EventName &&
                                data.EventName.toLowerCase().includes(
                                    searchterm.toLocaleLowerCase(),
                                )) ||
                            (data.City &&
                                data.City.toLowerCase().includes(searchterm.toLocaleLowerCase()))
                        );
                    }),
                );
            } else {
                setFilterList([]);
            }
        } else {
            setFilterList(initialResult);
        }
    };

    return (
        <Box className="user-profile-page main-content">
            <Container className="stack-top userPro" maxWidth="false" style={{ padding: '0px' }}>
                <Modal
                    open={isOpenEdit}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <UserAddressEditForm
                            idValue={idValue}
                            setIsLoadList={false}
                            setIsOpenEdit={setIsOpenEdit}
                            handleReload={handleReload}
                        />
                    </Box>
                </Modal>
                {inspectorProfileDetails && (
                    <Stack className="card-claim-details">
                        <Typography variant="h2">
                            {inspectorProfileDetails.FirstName}&nbsp;
                            {inspectorProfileDetails.LastName}
                        </Typography>
                        {/* <Typography
                            variant="inherit"
                            style={{ wordBreak: 'break-all', paddingRight: '8px' }}
                        >
                            {inspectorProfileDetails.EmailAddress}
                        </Typography>
                        <Typography variant="inherit">
                            {inspectorProfileDetails.ContactAddress}
                        </Typography> */}
                    </Stack>
                )}
                <InspectorSummary
                    filterList={inspectorProfileDetails}
                    OnHandleChange={OnHandleChange}
                />
            </Container>
            {/* -- Stack top area end -- */}

            <Container className="grid-row" maxWidth="false" style={{ padding: '0px' }}>
                {/* -- Assigned Resource area -- */}
                <Card
                    className="insProfile gr-1"
                    sx={{
                        boxShadow: 0,
                        padding: 2,
                        minWidth: '420px',
                    }}
                >
                    {/* <h3>Assigned Resource</h3> */}
                    {inspectorProfileDetails && (
                        <>
                            <ClaimContact
                                setIsContactOpen={setIsContactOpen}
                                isContactOpen={isContactOpen}
                                email={inspectorProfileDetails.EmailAddress}
                                phoneNumber={inspectorProfileDetails.Phone}
                                EmailToName={inspectorProfileDetails.UserFullName}
                                inspectorId={inspectorProfileDetails.UserId}
                            />
                            <Box className="userProfileGrid">
                                {/* <div>
              {inspectorProfileDetails.UserImage}
              </div> */}
                                <Stack className="align-self-center relative">
                                    <Inspector_profile_pic
                                        inspectorProfileDetails={inspectorProfileDetails}
                                    ></Inspector_profile_pic>
                                </Stack>

                                <Box>
                                    <Stack sx={{ display: 'flex' }}>
                                        <Button
                                            style={{ marginLeft: 'auto', boxShadow: 'none' }}
                                            variant="contained"
                                            size="small"
                                            onClick={() => {
                                                setIsContactOpen(true);
                                            }}
                                        >
                                            Send Message
                                        </Button>
                                    </Stack>
                                    {inspectorProfileDetails && (
                                        <Typography
                                            display={'block'}
                                            fontWeight={600}
                                            component={'strong'}
                                        >
                                            {inspectorProfileDetails.FirstName}&nbsp;
                                            {inspectorProfileDetails.LastName}
                                        </Typography>
                                    )}
                                    <Typography
                                        fontWeight={500}
                                        color={'#8FBE6D'}
                                        marginBottom={'12px'}
                                        component={'span'}
                                        display={'block'}
                                        fontSize={'12px'}
                                    >
                                        <svg
                                            style={{
                                                position: 'relative',
                                                top: '2px',
                                                marginRight: '8px',
                                            }}
                                            width="14"
                                            height="14"
                                            viewBox="0 0 14 14"
                                        >
                                            <g
                                                id="Group_497"
                                                data-name="Group 497"
                                                transform="translate(-254 -172)"
                                            >
                                                <g
                                                    id="Ellipse_103"
                                                    data-name="Ellipse 103"
                                                    transform="translate(254 172)"
                                                    fill="#8fbe6d"
                                                    stroke="#dfdfdf"
                                                    stroke-width="1"
                                                >
                                                    <circle cx="7" cy="7" r="7" stroke="none" />
                                                    <circle cx="7" cy="7" r="6.5" fill="none" />
                                                </g>
                                                <g
                                                    id="MDI_account-hard-hat"
                                                    data-name="MDI / account-hard-hat"
                                                    transform="translate(257.035 174.381)"
                                                >
                                                    <g
                                                        id="Boundary"
                                                        transform="translate(-0.035 -0.381)"
                                                        fill="#fff"
                                                        stroke="rgba(0,0,0,0)"
                                                        stroke-width="1"
                                                        opacity="0"
                                                    >
                                                        <rect width="8" height="8" stroke="none" />
                                                        <rect
                                                            x="0.5"
                                                            y="0.5"
                                                            width="7"
                                                            height="7"
                                                            fill="none"
                                                        />
                                                    </g>
                                                    <path
                                                        id="Path_account-hard-hat"
                                                        data-name="Path / account-hard-hat"
                                                        d="M6.649,6.305C5.186,6.305,4,6.9,4,7.63v.662H9.3V7.63c0-.732-1.186-1.325-2.649-1.325M5.325,4.318a1.325,1.325,0,0,0,2.649,0M6.484,2a.158.158,0,0,0-.166.166v.994H5.987V2.331a1.293,1.293,0,0,0-.745,1.242s-.248.046-.248.414H8.305c-.017-.368-.248-.414-.248-.414a1.293,1.293,0,0,0-.745-1.242v.828H6.981V2.166A.156.156,0,0,0,6.815,2Z"
                                                        transform="translate(-2.743 -1.174)"
                                                        fill="#fff"
                                                    />
                                                </g>
                                            </g>
                                        </svg>
                                        {inspectorProfileDetails.RoleName}
                                    </Typography>
                                    <Typography fontSize={13} component={'p'}>
                                        {inspectorProfileDetails.Phone}
                                    </Typography>
                                    <Typography fontSize={13} component={'p'}>
                                        {inspectorProfileDetails.EmailAddress}
                                    </Typography>
                                </Box>
                            </Box>
                            <Box className="row gutter-0">
                                <Stack className="p-col">
                                    <List class="list-data3">
                                        {inspectorProfileDetails && (
                                            <>
                                                {' '}
                                                <ListItem>Employee #:</ListItem>
                                                <ListItem class="text-label">
                                                    {inspectorProfileDetails.PilotId}
                                                </ListItem>
                                                {/* <ListItem>Xactimate ID:</ListItem>
                                                <ListItem class="text-label">
                                                    {inspectorProfileDetails.EmpXaxtnetId}
                                                </ListItem> */}
                                                <ListItem>XactNet Address:</ListItem>
                                                <ListItem class="text-label">
                                                    {inspectorProfileDetails.EmpXaxtnetId}
                                                </ListItem>
                                                <ListItem>Allstate XactNet Address:</ListItem>
                                                <ListItem class="text-label">
                                                    {inspectorProfileDetails.AllStateXactNetAddress}
                                                </ListItem>
                                            </>
                                        )}
                                        <ListItem>Home Address:</ListItem>
                                        <ListItem class="text-label">
                                            <Typography fontWeight={600} component={'p'}>
                                                {inspectorProfileDetails.Home_Street}
                                            </Typography>
                                            <Typography fontWeight={600} component={'p'}>
                                                {inspectorProfileDetails.Home_City
                                                    ? inspectorProfileDetails.Home_City
                                                    : ''}
                                                {inspectorProfileDetails.Home_StateName
                                                    ? ', ' + inspectorProfileDetails.Home_StateName
                                                    : ''}
                                                {inspectorProfileDetails.Home_Zip
                                                    ? ', ' + inspectorProfileDetails.Home_Zip
                                                    : ''}
                                            </Typography>
                                            {/* <Typography fontWeight={600} component={'p'}>
                                                {inspectorProfileDetails.Home_StateName
                                                    ? inspectorProfileDetails.Home_StateName
                                                    : ''}
                                                {inspectorProfileDetails.Home_Zip
                                                    ? ' ' + inspectorProfileDetails.Home_Zip
                                                    : ''}
                                            </Typography> */}
                                        </ListItem>

                                        {/*  */}
                                        {/* <ListItem>Working Radius:</ListItem>
                                        <ListItem class="text-label" style={{ marginLeft: -10 }}>
                                            {inspectorProfileDetails.WorkRadius} Miles
                                        </ListItem> */}
                                    </List>
                                </Stack>
                            </Box>
                        </>
                    )}
                </Card>
                {/* -- Assigned Resource end -- */}

                <Card
                    className="gr-2"
                    sx={{
                        boxShadow: 0,
                        p: 2,
                    }}
                >
                    {inspectorProfileDetails && (
                        <Inspector_map_tab
                            InspectorMapDetails={inspectorProfileDetails}
                            filterList={filterList}
                        />
                    )}
                </Card>

                <Card
                    className="gr-1"
                    sx={{
                        boxShadow: 0,
                        p: 2,
                    }}
                >
                    {inspectorProfileDetails && (
                        <Box className="row gutter-0" style={{ position: 'relative' }}>
                            {location.pathname.split('/').includes('inspector') ? (
                                handleSetEditPermissions('Resources') ? (
                                    <button
                                        style={{ right: '-7px', top: '-7px' }}
                                        onClick={() => {
                                            setIdValue(inspectorProfileDetails.UserId);
                                            setIsOpenEdit(true);
                                        }}
                                        className="icons absoluteR"
                                    >
                                        <CreateIcon />
                                    </button>
                                ) : null
                            ) : handleSetEditPermissions('Users') ? (
                                <button
                                    style={{ right: '-7px', top: '-7px' }}
                                    onClick={() => {
                                        setIdValue(inspectorProfileDetails.UserId);
                                        setIsOpenEdit(true);
                                    }}
                                    className="icons absoluteR"
                                >
                                    <CreateIcon />
                                </button>
                            ) : null}
                            <Stack className="p-col">
                                <List class="list-data-2">
                                    {/* <ListItem>Employee #:</ListItem>
                                    <ListItem class="text-label">
                                        {inspectorProfileDetails.PilotId}
                                    </ListItem>
                                    <ListItem>Xactimate ID:</ListItem>
                                    <ListItem class="text-label">
                                        {inspectorProfileDetails.EmpXaxtnetId}
                                    </ListItem> */}

                                    <ListItem>Work Address:</ListItem>
                                    <Box>
                                        <Typography component={'p'} fontWeight="600">
                                            {inspectorProfileDetails.ContactAddress}
                                        </Typography>
                                        <Typography component={'p'} fontWeight="600">
                                            {inspectorProfileDetails.City
                                                ? inspectorProfileDetails.City
                                                : ''}
                                            {inspectorProfileDetails.StateName
                                                ? ', ' + inspectorProfileDetails.StateName
                                                : ''}

                                            {inspectorProfileDetails.Zip
                                                ? ', ' + inspectorProfileDetails.Zip
                                                : ''}
                                        </Typography>
                                        {/* <Typography component={'p'} fontWeight="600">
                                            {inspectorProfileDetails.StateName
                                                ? '' + inspectorProfileDetails.StateName
                                                : ''}

                                            {inspectorProfileDetails.Zip
                                                ? ', ' + inspectorProfileDetails.Zip
                                                : ''}
                                        </Typography> */}
                                    </Box>
                                    <ListItem>Working Radius:</ListItem>
                                    <ListItem class="text-label" style={{ fontWeight: 600 }}>
                                        {inspectorProfileDetails.WorkRadius} Miles
                                    </ListItem>
                                    <ListItem style={{ display: ' ' }}>Manager:</ListItem>
                                    <ListItem
                                        // style={{ display: ' ' }}
                                        class="text-label"
                                        style={{ fontWeight: 600 }}
                                    >
                                        {inspectorProfileDetails.ManagerName}
                                    </ListItem>
                                    {/* <ListItem class="text-label">
                                        {inspectorProfileDetails.Home_Street}
                                        {inspectorProfileDetails.Home_City
                                            ? ',' + inspectorProfileDetails.Home_City
                                            : ''}
                                        {inspectorProfileDetails.Home_StateName
                                            ? ',' + inspectorProfileDetails.Home_StateName
                                            : ''}
                                        {inspectorProfileDetails.Home_Zip
                                            ? ',' + inspectorProfileDetails.Home_Zip
                                            : ''}
                                    </ListItem> */}
                                </List>
                                {/* <Box sx={{ mt: 7 }}>
                                    <FormControlLabel
                                        control={<IOSSwitch sx={{ m: 1 }} defaultChecked />}
                                        label={'IOD'}
                                    />

                                    <FormControlLabel
                                        control={<IOSSwitch sx={{ m: 1, ml: 4 }} />}
                                        label="AOD"
                                    />
                                </Box> */}
                            </Stack>
                            {/* <Stack className="p-col">
                                <Box>
                                    <Typography variant="h4" fontSize={22} fontWeight={600}>
                                        {inspectorProfileDetails.EfficiencyRating}
                                        <Typography component={'span'}>/5</Typography>
                                    </Typography>
                                    <Typography component={'p'}>
                                        <Rating
                                            name="size-small"
                                            value={parseFloat(
                                                inspectorProfileDetails.EfficiencyRating,
                                            )}
                                            precision={0.5}
                                            size="small"
                                            readOnly
                                        />
                                    </Typography>
                                    <Typography display={'block'} variant="caption">
                                        258 Efficiency rating
                                    </Typography>
                                </Box>
                                <Box className="mt-1">
                                    <Typography fontSize={22} fontWeight={600} variant="h4">
                                        {inspectorProfileDetails.InsuredRating}
                                        <Typography component={'span'}>/5</Typography>
                                    </Typography>
                                    <Rating
                                        name="size-small"
                                        value={parseFloat(inspectorProfileDetails.InsuredRating)}
                                        precision={0.5}
                                        size="small"
                                        readOnly
                                    />
                                    <Typography display={'block'} variant="caption">
                                        38 Insured rating
                                    </Typography>
                                </Box>
                            </Stack> */}
                        </Box>
                    )}
                </Card>
            </Container>

            <Container className="card" maxWidth="false" style={{ marginTop: '15px' }}>
                <Typography variant="h3" fontSize={'20px'} fontWeight={'600'} marginBottom="15px">
                    Tasks
                </Typography>
                {filterList?.length > 0 ? (
                    <>
                        <Inspector_claim_task filterList={filterList} />
                    </>
                ) : (
                    <>
                        <Inspector_claim_task filterList={[]} />
                    </>
                )}
            </Container>

            <Container className="tab-area card" maxWidth="false">
                {inspectorProfileDetails && (
                    <Inspector_profile_tab
                        InspectorProfileDetails={inspectorProfileDetails}
                        userId={inspectorProfileDetails?.UserId}
                    />
                )}
            </Container>
        </Box>
    );
};

Inspector_profile.propTypes = {};
