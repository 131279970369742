import React from 'react';
import PropTypes from 'prop-types';
import { AppUrls } from '../../core/route.url';
import { Box, LinearProgress, Grid, Typography, Paper, Stack, TextField } from '@mui/material';
import { usePostData2 } from '../../hooks/use-post-data-2.hook';
import { Delete } from '@mui/icons-material/';
import Moment from 'moment';
import { usePostData } from '../../hooks/use-post-data.hook';
const ClaimHistoryTab = ({ cliamProfileDetails }) => {
    const [filterList, setFilterList] = React.useState([]);
    const [initialResult, setInitialResult] = React.useState([]);
    const [isLoadList, setIsLoadList] = React.useState(false);
    const { response, submitData } = usePostData(
        AppUrls.ClaimHistory,
        //     {
        //     ClaimId: cliamProfileDetails.ClaimId,

        // }
        'POST',
    );

    React.useEffect(() => {
        if (cliamProfileDetails.ClaimId !== undefined) {
            setIsLoadList(true);
        }
    }, [cliamProfileDetails.ClaimId]);

    React.useEffect(() => {
        if (isLoadList && cliamProfileDetails.ClaimId !== undefined) {
            setIsLoadList(false);
            submitData({
                ClaimId: cliamProfileDetails.ClaimId,
            });
        }
    }, [isLoadList]);

    React.useEffect(() => {
        if (response.result != undefined) {
            if (response.result.IsSuccess) {
                // to format the date

                setFilterList(response.result.Data);
                setInitialResult(response.result.Data);
            }
        }
    }, [response]);

    if (response.result === undefined) {
        return (
            <Box sx={{ width: '100%' }}>
                <LinearProgress />
            </Box>
        );
    }

    if (response.isError) {
        return <>Error</>;
    }

    const dat = new Date();

    const handleChangeValue = (e) => {
        setFilterList(
            initialResult.map((data, i) => {
                return {
                    ActivityDate: data.ActivityDate,
                    ClaimActivitiesList: data.ClaimActivitiesList.filter((d, index) => {
                        return (
                            (d.ClaimActivityTypeName &&
                                d.ClaimActivityTypeName.toLowerCase().includes(
                                    e.target.value.toLocaleLowerCase(),
                                )) ||
                            (d.ActivityOn &&
                                d.ActivityOn.toLowerCase().includes(
                                    e.target.value.toLocaleLowerCase(),
                                )) ||
                            (d.ActivityDate &&
                                d.ActivityDate.toLowerCase().includes(
                                    e.target.value.toLocaleLowerCase(),
                                )) ||
                            (d.ActivityDetail &&
                                d.ActivityDetail.toLowerCase().includes(
                                    e.target.value.toLocaleLowerCase(),
                                )) ||
                            (d.UserEmail &&
                                d.UserEmail.toLowerCase().includes(
                                    e.target.value.toLocaleLowerCase(),
                                )) ||
                            (d.UserFullName &&
                                d.UserFullName.toLowerCase().includes(
                                    e.target.value.toLocaleLowerCase(),
                                ))
                        );
                    }),
                };
            }),
        );
    };

    return (
        <>
            <Box style={{ position: 'relative' }}>
                {' '}
                <div style={{ position: 'absolute', right: 0, top: '-66px' }}>
                    {
                        <TextField
                            id="outlined-multiline-static"
                            placeholder="Search"
                            className="searchinput"
                            size="small"
                            style={{ position: 'relative' }}
                            onChange={(e) => handleChangeValue(e)}
                        />
                    }
                </div>
                {filterList.map((history, index) => {
                    return (
                        <Stack className="treelist" key={index}>
                            <box>
                                <Typography variant="h4">
                                    {Moment(history.ActivityDate).format('MM-DD-YYYY')}
                                    {Moment(history.ActivityDate).format('MM-DD-YYYY') ===
                                    Moment(dat).format('MM-DD-YYYY')
                                        ? ` -Today`
                                        : null}
                                </Typography>

                                {history.ClaimActivitiesList.length > 0
                                    ? history.ClaimActivitiesList.map((historyData, i) => {
                                          return (
                                              <Paper
                                                  className="historyTab"
                                                  key={i}
                                                  style={{ boxShadow: 'none' }}
                                              >
                                                  <Typography variant="caption">
                                                      {Moment(historyData.ActivityOn).format(
                                                          'hh:mm A',
                                                      )}
                                                  </Typography>
                                                  {/* <Delete /> */}
                                                  <Grid container spacing={2} m={0}>
                                                      <Grid xs={3}>
                                                          <Typography
                                                              variant="h3"
                                                              color={'black'}
                                                              style={{
                                                                  paddingTop: 0,
                                                                  fontWeight: 600,
                                                                  fontSize: '12px',
                                                              }}
                                                          >
                                                              {historyData.UserFullName}
                                                          </Typography>
                                                          <Typography fontSize={12} color={'#000'}>
                                                              {historyData.UserEmail}
                                                          </Typography>
                                                      </Grid>
                                                      <Grid xs={9}>
                                                          <Typography style={{ paddingRight: 120 }}>
                                                              {historyData.ActivityDetail}
                                                          </Typography>
                                                          <Typography className="update-status">
                                                              {historyData.ClaimActivityTypeName}
                                                          </Typography>
                                                      </Grid>
                                                  </Grid>
                                              </Paper>
                                          );
                                      })
                                    : null}
                            </box>

                            {/* <Paper className="historyTab">
                            <Typography variant="caption">1:36 PM</Typography>
                            <Delete />
                            <Grid container spacing={2} m={0}>
                                <Grid xs={4}>
                                    <Typography>Lottie H. Caffrey</Typography>
                                    <Typography>JoanneMHelms@pilotcat.com</Typography>
                                </Grid>
                                <Grid xs={6}>
                                    <Typography>
                                        Lorem Ipsum is simply dummy text of the printing and
                                        typesetting industry. Lorem Ipsum has been the industry's
                                        standard dummy text ever since the 1500s,{' '}
                                    </Typography>
                                </Grid>
                                <Grid xs={2}>
                                    <Typography>Image Uploaded</Typography>
                                </Grid>
                            </Grid>
                        </Paper> */}
                        </Stack>
                    );
                })}
            </Box>
        </>
    );
};

export default ClaimHistoryTab;

ClaimHistoryTab.propTypes = {
    cliamProfileDetails: PropTypes.any.isRequired,
};
