import { InputLabel, MenuItem, Select, Autocomplete, TextField } from '@mui/material';
import React, { useState } from 'react';

const DEFAULT_SELECT_VALUE = '-1';
function SelectOption({
    values,
    disabled,
    onChange,
    onBlur,
    value,
    name,
    error,
    showSelectOption = false,
    autoComplete = false,
}) {
    const opts = values.map((e, i) => (
        <MenuItem key={i} value={e.value}>
            {e.label}
        </MenuItem>
    ));

    let o = false;

    const actualLabel = values?.find((e) => {
        if (e.value == '0') {
            o = true;
        }
        return e.value == value;
    });

    return (
        <React.Fragment>
            <InputLabel id="demo-multiple-name-label">{name}</InputLabel>
            {
                !autoComplete ? (
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        disabled={disabled}
                        value={value ? value : DEFAULT_SELECT_VALUE}
                        label={name}
                        // name={name}
                        error={error}
                        onChange={(e) => {
                            if (e.target.value) {
                                onChange(e.target.value);
                            }
                        }}
                        defaultValue={DEFAULT_SELECT_VALUE}
                        SelectDisplayProps={{ defaultValue: DEFAULT_SELECT_VALUE }}
                        displayEmpty
                    >
                        {/* {o ? (
          ""
        ) : (
          <MenuItem key={1000000} value="sel">
            Select
          </MenuItem>
        )} */}
                        {showSelectOption ? (
                            <MenuItem key={-1} value={DEFAULT_SELECT_VALUE} selected>
                                Select
                            </MenuItem>
                        ) : null}
                        {opts}
                    </Select>
                ) : null
                // <Autocomplete
                //     key={index}
                //     options={values}
                //     getOptionLabel={(option) => option?.label ?? ''}
                //     value={value === undefined ? '' : value}
                //     id="disable-close-on-select"
                //     onChange={(event, newValue) => {
                //         onChange(newValue?.value);
                //     }}
                //     inputValue={value}
                //     onInputChange={(event, newInputValue) => {
                //         onChangeInput(newInputValue);
                //     }}
                //     // disableCloseOnSelect
                //     // selectOnFocus
                //     // autoSelect
                //     renderInput={(params) => (
                //         <TextField {...params} id="demo-simple-select" variant="outlined" />
                //     )}
                // />
            }
            {/* <span className="holder">
        {actualLabel ? actualLabel["label"] : "Select"}
      </span> */}
        </React.Fragment>
    );
}

export default SelectOption;
