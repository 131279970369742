import PropTypes from 'prop-types';
import { MapInfoClaimComponent } from './map-info-claim.component';
import { MapInfoResourceComponent } from './map-info-resource.component';

export const MapInfoWindowComponent = ({ dataType, data }) => {
    if (dataType === 'Claim') {
        return <MapInfoClaimComponent claim={data} />;
    }
    if (dataType === 'Resource') {
        return <MapInfoResourceComponent resource={data} />;
    }
    return <div>No Info Available</div>;
};

MapInfoWindowComponent.propTypes = {
    type: PropTypes.string.isRequired,
    data: PropTypes.any.isRequired,
    assignClaim: PropTypes.func,
};
