import React from 'react';
import { usePostData } from '../../hooks/use-post-data.hook';
import { AppUrls } from '../../core/route.url';
import { CircularProgress, Backdrop, Box, LinearProgress } from '@mui/material';
import NotificationList from './notification-list';

const Notification = () => {
    const { submitData, response } = usePostData(AppUrls.NotificationList, 'POST');
    const [filterList, setFilterList] = React.useState([]);
    const [isLoadList, setIsLoadList] = React.useState(false);
    React.useEffect(() => {
        setIsLoadList(true);
    }, []);

    React.useEffect(() => {
        if (isLoadList) {
            setIsLoadList(false);
            submitData({
                PageNo: 1,
                PageSize: 100,
                SortDir: '',
                SortExp: '',
                StartDate: '2022-05-01T04:54:29.010Z',
                EndDate: new Date(),

                Notification: '',
            });
        }
    }, [isLoadList, response.result]);

    React.useEffect(() => {
        if (response.result != undefined) {
            if (response.result.IsSuccess) {
                setFilterList(response.result.Data);
            }
        }
    }, [response]);
    return (
        <div className="main-content">
            {Array.isArray(filterList) && filterList?.length > 0 ? (
                <>{<NotificationList filterList={filterList} setIsLoadList={setIsLoadList} />}</>
            ) : (
                <>
                    <NotificationList filterList={[]} setIsLoadList={setIsLoadList} />
                </>
            )}
        </div>
    );
};

export default Notification;
Notification.propTypes = {};
