import {
    Alert,
    Box,
    Button,
    CircularProgress,
    FormControl,
    TextareaAutosize,
    TextField,
} from '@mui/material';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import SelectOption from '../../components/select-option';
import { AppUrls } from '../../core/route.url';
import { usePostData } from '../../hooks/use-post-data.hook';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { decryptWithAES } from '../../utils/global-functions';
const ClaimProfileNoteForm = ({
    idValue,
    setIsLoads,
    setIsOpen,
    cliamProfileDetails,
    taskResponse,
    valueData,
    handleRefreshCount,
    handleRefreshNotes,
    DocumentTypeResult,
}) => {
    const noteTypes = [
        { value: 1, label: 'Internal' },
        { value: 2, label: 'XactAnalysis' },
    ];
    const {
        register,
        handleSubmit,
        control,
        setValue,
        watch,
        formState: { errors },
    } = useForm();

    const watchAllFields = watch();
    const [isLoadData, setIsLoadData] = React.useState(false);
    const [noteTypeData, setNoteTypeData] = React.useState([]);
    const [savedResponse, setSaveResponse] = React.useState({});
    const { submitData, response } = usePostData(AppUrls.ClaimNoteSave, 'POST');
    const { submitData: submitDataForGet, response: getResponse } = usePostData(
        AppUrls.ClaimNoteGet,
        'POST',
    );

    let { id: param } = useParams();
    // const { state } = useLocation();
    let decryptedParam = decryptWithAES(param?.replace(/%2F/g, '/'));
    let parsedParam = JSON.parse(decryptedParam);
    const id = parsedParam?.state?.guId;
    const { state } = parsedParam;

    React.useEffect(() => {
        if (1) {
            setValue('noteBody', '');
            setValue('noteType', '');
            setNoteTypeData(
                DocumentTypeResult && DocumentTypeResult.Data != undefined
                    ? DocumentTypeResult.Data !== null
                        ? DocumentTypeResult.Data
                        : []
                    : [],
            );
        }
    }, []);

    React.useEffect(() => {
        if (idValue !== 0) {
            setIsLoadData(true);
        }
    }, [idValue]);

    React.useEffect(() => {
        if (isLoadData) {
            setIsLoadData(false);
            submitDataForGet({ ClaimNoteId: idValue });
        }
    }, [isLoadData]);

    const onSubmit = async (data) => {
        submitData({
            ClaimNoteId: idValue,
            NoteBody: data.noteBody,
            ClaimNoteTypeId: data.noteType,
            ClaimGuID: cliamProfileDetails.ClaimGuID,
            AddedById: 1,
            CarrierId: cliamProfileDetails.CarrierId,
            ClaimTaskId:
                state?.page === 'task'
                    ? taskResponse.result &&
                      taskResponse.result.Data &&
                      taskResponse.result.Data !== null
                        ? taskResponse.result.Data[valueData].ClaimTaskId
                        : 0
                    : 0,
            ParentNoteTypeId: state?.page === 'task' ? 2 : 1,
            CreatedBy: 0,
            UpdatedBy: 0,
            CreatedOn: '2022-06-30T07:29:19.900Z',
            IsActive: true,
        });
    };

    React.useEffect(() => {
        if (response.result != undefined) {
            setSaveResponse(response.result);
            handleRefreshCount();
            handleRefreshNotes();
            const timer = setTimeout(() => {
                setSaveResponse({});

                setValue('noteBody', '');
                setValue('noteType', '');
                setIsOpen(false);
            }, 1000);
            return () => clearTimeout(timer);
        }
    }, [response]);

    React.useEffect(() => {
        if (getResponse.result != undefined) {
            setValue('noteBody', getResponse.result.Data.NoteBody);
            setValue('noteType', getResponse.result.Data.ClaimNoteTypeId);
        }
    }, [getResponse]);

    return (
        <>
            <div>
                <h2 style={{ marginBottom: 20 }}>Claim Notes</h2>
                <Box
                    component="form"
                    sx={{
                        '& .MuiTextField-root': { m: 1, width: '53ch' },
                    }}
                    noValidate
                    autoComplete="off"
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <div>
                        <FormControl sx={{ m: 1, width: '53ch' }}>
                            <Controller
                                control={control}
                                name="noteType"
                                rules={{
                                    required: 'Note Type',
                                }}
                                render={({ field: { onChange, onBlur, value, name } }) => (
                                    <SelectOption
                                        values={
                                            // noteTypeData !== undefined
                                            //     ? noteTypeData.length > 0
                                            //         ? noteTypeData.map((data) => {
                                            //               return {
                                            //                   value: data.ClaimDocumentTypeId,
                                            //                   label: data.ClaimDocumentTypeName,
                                            //               };
                                            //           })
                                            //         : noteTypes
                                            //     : noteTypes
                                            noteTypes
                                        }
                                        disabled={idValue !== 0 ? true : false}
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        value={value === undefined ? '' : value}
                                        name={'Note Type' || ''}
                                        error={errors.CarrierId}
                                    />
                                )}
                            />
                        </FormControl>
                        {/* <FormControl sx={{ m: 1, width: '53ch' }}> */}
                        <TextField
                            error={errors.noteBody}
                            id="outlined-multiline-static"
                            label="Note"
                            multiline
                            rows={6}
                            defaultValue=""
                            InputLabelProps={{
                                shrink: watchAllFields.noteBody === '' ? false : true,
                            }}
                            {...register('noteBody', { required: true })}
                        />
                        {/* </FormControl> */}
                    </div>
                    <div className="d-flex" style={{ marginTop: 20 }}>
                        <Button
                            style={{ marginLeft: 2 }}
                            onClick={() => {
                                setIsOpen(false);
                                setValue('noteBody', '');
                            }}
                            variant="outlined"
                            type="button"
                        >
                            Cancel
                        </Button>

                        <Button style={{ marginLeft: 'auto' }} variant="contained" type="submit">
                            {response.isSubmitting ? <CircularProgress color="inherit" /> : 'Save'}
                        </Button>
                    </div>
                </Box>
                {savedResponse.IsSuccess != undefined ? (
                    <Alert
                        style={{ marginTop: 15 }}
                        severity={savedResponse.IsSuccess ? 'success' : 'error'}
                    >
                        {savedResponse.Message}
                    </Alert>
                ) : null}
            </div>
        </>
    );
};

export default ClaimProfileNoteForm;

ClaimProfileNoteForm.propTypes = {
    idValue: PropTypes.number.isRequired,
    setIsLoads: PropTypes.any,
    setIsOpen: PropTypes.any,
    cliamProfileDetails: PropTypes.any.isRequired,
    taskResponse: PropTypes.any,
    valueData: PropTypes.any,
    handleRefreshCount: PropTypes.func,
    handleRefreshNotes: PropTypes.func,
    DocumentTypeResult: PropTypes.any,
};
