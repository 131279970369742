import { useEffect, useState } from 'react';
import { getData } from '../utils/network.client';

export const useLoadData2 = (route, disable = false) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [result, setResult] = useState();
    const [paramValue, setParamValue] = useState('');
    const [dropDownData, setDropDownData] = useState({ allDropDownData: [] });
    const { allDropDownData } = dropDownData;
    //   useEffect(() => {
    //     if (!disable) {
    //       loadData();
    //     }
    //   }, [route, disable]);

    const loadData = async (route, paramValues) => {
        // console.log(50, allDropDownData);
        try {
            setIsError(false);
            setIsLoading(true);
            let result = await getData(route);

            if (result.status === 200) {
                setResult(result.data);
                setParamValue(paramValues);

                // setDropDownData({
                //     allDropDownData: [
                //         ...dropDownData.allDropDownData,
                //         {
                //             param: paramValue,
                //             result: result.data,
                //         },
                //     ],
                // });
            } else {
                setIsError(true);
            }
        } catch (ex) {
            setIsError(true);
        }
        setIsLoading(false);
    };

    return {
        isLoading,
        result,
        isError,
        reload: loadData,
        paramValue,
        allDropDownData1: allDropDownData,
    };
};
