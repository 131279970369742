import { Buffer } from 'buffer';

// const CryptoJS = require('crypto-js');
// const passphrase = '123';
// export const encryptWithAES = (text) => {
//     return CryptoJS.AES.encrypt(text, passphrase)
//         .toString()
//         ?.replace(/\//g, '%2F')
//         ?.replace(/\+/g, '%2b');
// };

// export const decryptWithAES = (ciphertext) => {
//     const bytes = CryptoJS.AES.decrypt(
//         ciphertext?.toString()?.replace(/%2F/g, '/')?.replace(/%2b/g, '+'),
//         passphrase,
//     );
//     const originalText = bytes.toString(CryptoJS.enc.Utf8);
//     return originalText;
// };

export const encryptWithAES = (text) => {
    return Buffer.from(text)
        .toString('base64')
        ?.replace(/\//g, '_')
        ?.replace(/\+/g, '.')
        ?.replace(/\=/g, '-');
};

export const decryptWithAES = (ciphertext) => {
    return Buffer.from(
        ciphertext?.replace(/\_/g, '/')?.replace(/\./g, '+')?.replace(/\-/g, '='),
        'base64',
    ).toString('ascii');
};

export const decodeHtml = (input) => {
    var doc = new DOMParser().parseFromString(input, 'text/html');
    return doc.documentElement.textContent;
};
