import React from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import { process } from '@progress/kendo-data-query';
import PropTypes from 'prop-types';
import SlaForm from '../sla/sla-form';
import editIcon from '../../images/pencil.svg';
import deleteIcon from '../../images/trash.svg';
import { Box, Button, Modal } from '@mui/material';
import { AppUrls } from '../../core/route.url';
import { usePostData2 } from '../../hooks/use-post-data-2.hook';
import { useLoadData } from '../../hooks/use-load-data.hook';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1000,
    bgcolor: 'background.paper',
    boxShadow: 3,
    p: 3,
};

const CarrierSLAList = ({ SLAList, CarrierUniqueID, carrierId, setSLAIsLoad }) => {
    const createDataStateSLA = (dataState) => {
        return {
            result: process(SLAList.slice(0), dataState),
            dataState: dataState,
        };
    };

    // const { response: statusResponse } = usePostData2(AppUrls.ClaimProfileStatusBar, {
    //     pageName: 'Task',
    //     ClaimTaskStatusCode: 'CLAIM_TASK',
    // });

    const { result: statusResponse } = useLoadData(
        AppUrls.GetClaimTaskStatuses + '?taskTypeCode=CLAIM_TASK',
    );

    const [initialStateSLA, setInitialStateSLA] = React.useState(
        createDataStateSLA({
            take: 100,
            skip: 0,
        }),
    );
    const [resultSLA, setResultSLA] = React.useState(initialStateSLA.result);
    const [isOpen, setIsOpen] = React.useState(false);
    React.useEffect(() => {
        setInitialStateSLA(
            createDataStateSLA({
                take: 100,
                skip: 0,
            }),
        );
    }, [SLAList]);

    React.useEffect(() => {
        setResultSLA(initialStateSLA.result);
    }, [initialStateSLA]);
    const [idValue, setIdValue] = React.useState(0);
    const [dataStateSLA, setDataStateSLA] = React.useState(initialStateSLA.dataState);

    const dataStateSLAChange = (event) => {
        let updatedState = createDataStateSLA(event.dataState);
        setResultSLA(updatedState.result);
        setDataStateSLA(updatedState.dataState);
    };

    const handleClose = () => {
        setIsOpen(false);
    };

    const editCell = (props) => {
        return (
            <>
                <td className="k-command-cell">
                    <button
                        className="icon-btn grid-icon"
                        onClick={() => {
                            setIdValue(props.dataItem.SLAId);
                            setIsOpen(true);
                        }}
                    >
                        <img src={editIcon} title="Edit file" />
                    </button>

                    <button
                        className="icon-btn file-icon"
                        /* onClick={() => {
      handleDeleteClick(props.dataItem.InspectionTypeId);
    }} */
                    >
                        {/* <img src={deleteIcon} title="Delete File" /> */}
                    </button>
                </td>
            </>
        );
    };

    const reserveCell = (props) => {
        if (props.rowType === 'groupHeader') {
            return null;
        }
        return (
            <>
                <td className="k-command-cell">{props.dataItem.ReserveOnly ? 'Yes' : 'No'}</td>
            </>
        );
    };

    return (
        <>
            <Modal
                open={isOpen}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                style={{ overflow: 'scroll' }}
            >
                <Box sx={style}>
                    <SlaForm
                        setIsOpen={setIsOpen}
                        isOpen={isOpen}
                        carrierId={carrierId}
                        idValue={idValue}
                        setIdValue={setIdValue}
                        setSLAIsLoad={setSLAIsLoad}
                        statusResponse={statusResponse}
                    />
                </Box>
            </Modal>
            <Box style={{ display: 'flex', width: '100%' }}>
                <Button
                    style={{
                        marginLeft: 'auto',
                        marginTop: '-30px',
                        position: 'relative',
                        top: '-38px',
                        boxShadow: 'none',
                    }}
                    variant="contained"
                    type="button"
                    onClick={() => {
                        setIsOpen(true);
                    }}
                >
                    Add New
                </Button>
            </Box>

            {resultSLA != undefined ? (
                <>
                    <Grid
                        data={resultSLA}
                        {...dataStateSLA}
                        style={{
                            maxHeight: 'calc(100vh - 131px)',
                        }}
                        onDataStateChange={dataStateSLAChange}
                        sortable={true}
                        pageable={{
                            buttonCount: 5,
                            info: true,
                            type: 'numeric',
                            pageSizes: [100, 200, 500],
                            previousNext: true,
                        }}
                        groupable={true}
                        pageSize={10}
                        resizable={true}
                    >
                        <GridColumn field="SLADefinition" title="SLA Definition" />
                        <GridColumn
                            field="PreferedDurationTemp"
                            title="Prefered Duration"
                            width="230px"
                        />
                        <GridColumn
                            field="CutOffDurationTemp"
                            title="CutOff Duration"
                            width="230px"
                        />
                        <GridColumn field="SLAStartStatusName" title="Start Status" width="150px" />
                        <GridColumn field="SLAEndStatusName" title="End Status" width="150px" />
                        <GridColumn
                            field="ReserveOnly"
                            title="Reserve Only"
                            cell={reserveCell}
                            width="120px"
                        />
                        <GridColumn field="optn" title="Options" width="80px" cell={editCell} />
                    </Grid>
                </>
            ) : (
                <>
                    <LinearProgress></LinearProgress>
                </>
            )}
        </>
    );
};
export default CarrierSLAList;

CarrierSLAList.propTypes = {
    SLAList: PropTypes.any.isRequired,
    CarrierUniqueID: PropTypes.any.isRequired,
    carrierId: PropTypes.any.isRequired,
    setSLAIsLoad: PropTypes.func,
};
