import React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import ClaimProfileTaskTab from './claim-profile-task-tab';
import ClaimProfileDocumetTab from './claim-profile-documet-tab';
import ClaimProfileNoteTab from './claim-profile-note-tab';
import { useLocation, useParams } from 'react-router-dom';
import ClaimHistoryTab from './claim-history-tab';
import SlaTrack from '../sla/sla-track';
import { decryptWithAES } from '../../utils/global-functions';
import { SignalCellularNull } from '@mui/icons-material';
import { AppUrls } from '../../core/route.url';
import { usePostData } from '../../hooks/use-post-data.hook';
import { useLoadData2 } from '../../hooks/use-load-data-2.hook';

const ClaimProfileTab = ({
    cliamProfileDetails,
    response,
    stateValue,
    noteResponse,
    handleRefreshTask,
    handleRefreshNotes,
}) => {
    const [value, setValue] = React.useState(0);
    const [valueData, setValueData] = React.useState(0);
    let { id: param } = useParams();
    let decryptedParam = decryptWithAES(param?.replace(/%2F/g, '/'));
    let parsedParam = JSON.parse(decryptedParam);
    const { state } = parsedParam;
    const [trancountdetails, settrancountdetails] = React.useState([]);

    const { submitData: submitDataCount, response: countresponse } = usePostData(
        AppUrls.GetTransactionCountByPageName,
        'POST',
    );
    const { reload, result: DocumentTypeResult } = useLoadData2();

    const [isLoadcount, setIsLoadcount] = React.useState(false);
    const [isLoadData, setIsLoadData] = React.useState(false);
    const [isLoadDocumentTypeData, setIsLoadDocumentTypeData] = React.useState(false);
    React.useEffect(() => {
        if (state?.page === 'claim') {
            if (response.result && response.result.Data && response.result.Data !== undefined) {
                setIsLoadcount(true);
            }
        } else {
            if (response.result && response.result.Data && response.result.Data !== null) {
                setIsLoadcount(true);
            }
        }
    }, [response.result]);

    React.useEffect(() => {
        if (isLoadcount) {
            setIsLoadcount(false);
            submitDataCount({
                pageName: state?.page,
                ClaimGuID: state?.guId,
                ClaimTaskId:
                    state?.page === 'claim'
                        ? 0
                        : state?.page === 'task'
                        ? response.result && response.result.Data && response.result.Data !== null
                            ? response.result.Data[valueData].ClaimTaskId
                            : 0
                        : 0,
            });
        }
    }, [isLoadcount]);

    React.useEffect(() => {
        if (cliamProfileDetails.ClaimId) {
            setIsLoadData(true);
            setIsLoadDocumentTypeData(true);
        }
    }, []);

    React.useEffect(() => {
        if (isLoadData) {
            setIsLoadData(false);
            // submitData({
            //     pageName: state?.page === 'claim' ? 'Claims' : 'Task',
            //     ClaimId: cliamProfileDetails.ClaimId,
            //     ClaimTaskUniqueId: state?.page === 'claim' ? '' : state?.taskGuId,
            // });
            handleRefreshNotes();
        }
    }, [isLoadData]);

    React.useEffect(() => {
        if (isLoadDocumentTypeData) {
            setIsLoadDocumentTypeData(false);
            reload(AppUrls.DocumentTypeGet, '');
        }
    }, [isLoadDocumentTypeData]);

    // const handleRefreshNotes = () => {
    //     submitData({
    //         pageName: state?.page === 'claim' ? 'Claims' : 'Task',
    //         ClaimId: cliamProfileDetails.ClaimId,
    //         ClaimTaskUniqueId: state?.page === 'claim' ? '' : state?.taskGuId,
    //     });
    // };

    React.useEffect(() => {
        if (response.result !== undefined && stateValue.page === 'task') {
            setValueData(
                response.result.Data !== null
                    ? response.result.Data.findIndex(
                          (obj) => obj.ClaimTaskGuID === stateValue.taskGuId,
                      )
                    : 0,
            );
        }
    }, [response.result]);

    React.useEffect(() => {
        if (countresponse.result !== undefined) {
            settrancountdetails(countresponse.result.Data);
        }
    }, [countresponse.result]);

    const handleRefreshCount = () => {
        submitDataCount({
            pageName: state?.page,
            ClaimGuID: state?.guId,
            ClaimTaskId:
                state?.page === 'claim'
                    ? 0
                    : state?.page === 'task'
                    ? response.result && response.result.Data && response.result.Data !== null
                        ? response.result.Data[valueData].ClaimTaskId
                        : 0
                    : 0,
        });
    };

    const TabPanel = (props) => {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{ p: 3 }}>
                        <Typography component={'span'}>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    };

    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
    };

    const a11yProps = (index) => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <>
            <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs
                        className="profileTabs"
                        value={value}
                        onChange={handleChange}
                        aria-label="basic tabs example"
                    >
                        <Tab
                            iconPosition="end"
                            icon={<span className="legendCount">{trancountdetails.NoteCount}</span>}
                            label={'Notes'}
                            {...a11yProps(0)}
                        />
                        <Tab
                            iconPosition="end"
                            icon={
                                <span className="legendCount">
                                    {trancountdetails.DocumentCount}
                                </span>
                            }
                            label={'Documents'}
                            {...a11yProps(1)}
                        />
                        {state?.page === 'task' ? null : (
                            <Tab
                                iconPosition="end"
                                icon={
                                    <span className="legendCount">
                                        {trancountdetails.TaskCount}
                                    </span>
                                }
                                label={'Task'}
                                {...a11yProps(2)}
                            />
                        )}
                        {state?.page === 'task' ? (
                            response.result &&
                            response.result.Data &&
                            response.result.Data !== null ? (
                                response.result.Data[valueData].ClaimTaskTypeCode !==
                                'CLAIM_TASK' ? null : (
                                    <Tab
                                        iconPosition="end"
                                        icon={
                                            <span className="legendCount">
                                                {trancountdetails.SLACount}
                                            </span>
                                        }
                                        label={'SLA'}
                                        {...a11yProps(state?.page === 'task' ? 3 - 1 : 3)}
                                    />
                                )
                            ) : (
                                <Tab
                                    iconPosition="end"
                                    icon={
                                        <span className="legendCount">
                                            {trancountdetails.SLACount}
                                        </span>
                                    }
                                    label={'SLA'}
                                    {...a11yProps(state?.page === 'task' ? 3 - 1 : 3)}
                                />
                            )
                        ) : (
                            <Tab
                                iconPosition="end"
                                icon={
                                    <span className="legendCount">{trancountdetails.SLACount}</span>
                                }
                                label={'SLA'}
                                {...a11yProps(state?.page === 'task' ? 3 - 1 : 3)}
                            />
                        )}

                        <Tab
                            label="Claim History"
                            {...a11yProps(
                                state?.page === 'task'
                                    ? response.result &&
                                      response.result.Data &&
                                      response.result.Data !== null
                                        ? response.result.Data[valueData].ClaimTaskTypeCode !==
                                          'CLAIM_TASK'
                                            ? 4 - 2
                                            : 4 - 1
                                        : null
                                    : 4,
                            )}
                        />
                    </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                    <Box style={{ position: 'relative' }}>
                        <ClaimProfileNoteTab
                            cliamProfileDetails={cliamProfileDetails}
                            taskResponse={response}
                            valueData={valueData}
                            handleRefreshCount={handleRefreshCount}
                            handleRefreshNotes={handleRefreshNotes}
                            response={noteResponse}
                            setIsLoadData={setIsLoadData}
                            DocumentTypeResult={DocumentTypeResult}
                        />
                    </Box>
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <Box>
                        <ClaimProfileDocumetTab
                            cliamProfileDetails={cliamProfileDetails}
                            taskResponse={response}
                            valueData={valueData}
                            handleRefreshCount={handleRefreshCount}
                            DocumentTypeResult={DocumentTypeResult}
                        />
                    </Box>
                </TabPanel>
                {state?.page === 'task' ? null : (
                    <TabPanel value={value} index={2}>
                        <Box>
                            <ClaimProfileTaskTab
                                cliamProfileDetails={cliamProfileDetails}
                                handleRefreshCount={handleRefreshCount}
                                handleRefreshTask={handleRefreshTask}
                                response={response}
                            />
                        </Box>
                    </TabPanel>
                )}
                {state?.page === 'task' ? (
                    response.result && response.result.Data && response.result.Data !== null ? (
                        response.result.Data[valueData].ClaimTaskTypeCode !==
                        'CLAIM_TASK' ? null : (
                            <TabPanel value={value} index={state?.page === 'task' ? 3 - 1 : 3}>
                                <Box>
                                    <SlaTrack />
                                </Box>
                            </TabPanel>
                        )
                    ) : (
                        <TabPanel value={value} index={state?.page === 'task' ? 3 - 1 : 3}>
                            <Box>
                                <SlaTrack />
                            </Box>
                        </TabPanel>
                    )
                ) : (
                    <TabPanel value={value} index={state?.page === 'task' ? 3 - 1 : 3}>
                        <Box>
                            <SlaTrack />
                        </Box>
                    </TabPanel>
                )}

                <TabPanel
                    value={value}
                    index={
                        state?.page === 'task'
                            ? response.result &&
                              response.result.Data &&
                              response.result.Data !== null
                                ? response.result.Data[valueData].ClaimTaskTypeCode !== 'CLAIM_TASK'
                                    ? 4 - 2
                                    : 4 - 1
                                : null
                            : 4
                    }
                >
                    <Box>
                        <ClaimHistoryTab cliamProfileDetails={cliamProfileDetails} />
                    </Box>
                </TabPanel>
            </Box>
        </>
    );
};

export default ClaimProfileTab;

ClaimProfileTab.propTypes = {
    cliamProfileDetails: PropTypes.any.isRequired,
    stateValue: PropTypes.any,
    handleRefreshTask: PropTypes.func,
    response: PropTypes.any,
    handleRefreshNotes: PropTypes.func,
    noteResponse: PropTypes.any,
};
