import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { TextField, Grid, InputAdornment } from '@mui/material';
import DatePicker, { CalendarContainer } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { ExpansionPanelContent } from '@progress/kendo-react-layout';
import { Reveal } from '@progress/kendo-react-animation';
import ArrowRight from '@mui/icons-material/ArrowRight';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import CalendarMonth from '@mui/icons-material/CalendarMonth';
import { Colors } from '../utils/colors';
import { useState } from 'react';
import moment from 'moment';
import { useEffect } from 'react';
import { useRef } from 'react';

const TODAY = 'Today';
const YESTERDAY = 'Yesterday';
const TOMORROW = 'Tomorrow';
const LAST_7_DAYS = 'Last 7 days';
const LAST_30_DAYS = 'Last 30 days';

const CustomDatePicker = ({
    value = new Date(),
    onChange,
    dateFormat = 'yyyy/MM/dd',
    label,
    startDate,
    endDate,
    selectsRange,
    customInput,
    showTimeSelect = false,
    showCustomDaysPicker = true,
}) => {
    const datePickerRef = useRef(null);
    const [dateSelectionOptions, setDateSelectionOptions] = useState([
        {
            label: 'Days',
            options: [
                {
                    label: TODAY,
                },
                {
                    label: YESTERDAY,
                },
                {
                    label: TOMORROW,
                },
                {
                    label: LAST_7_DAYS,
                },
                {
                    label: LAST_30_DAYS,
                },
            ],
            isOpened: true,
        },
    ]);

    const onClickAccordionOption = (index) => {
        let dateSelectionOptionsCopy = [...dateSelectionOptions];
        dateSelectionOptionsCopy[index].isOpened = !dateSelectionOptionsCopy[index].isOpened;
        setDateSelectionOptions(dateSelectionOptionsCopy);
    };

    const onClickDateSelectionOption = (subOption) => {
        onChange(subOption?.label);
    };

    const onChangeDate = (dates) => {
        onChange(dates);
    };

    const onChangeDateRange = (dates) => {
        const [start, end] = dates;
        onChange([start, end]);
    };

    useEffect(() => {
        if (!selectsRange) {
            if (moment(value).isValid()) {
                datePickerRef.current.setPreSelection(moment(value).toDate());
            } else {
                datePickerRef.current.setPreSelection(getStartDateAndEndDate().endDate);
            }
        }
    }, [value]);

    const getStartDateAndEndDate = useCallback(() => {
        let dateDetail = { startDate: null, endDate: null };
        if (value === TODAY) {
            dateDetail.startDate = moment().toDate();
            dateDetail.endDate = moment().toDate();
        } else if (value === YESTERDAY) {
            dateDetail.startDate = moment().subtract(1, 'day').toDate();
            dateDetail.endDate = moment().subtract(1, 'day').toDate();
        } else if (value === TOMORROW) {
            dateDetail.startDate = moment().add(1, 'day').toDate();
            dateDetail.endDate = moment().add(1, 'day').toDate();
        } else if (value === LAST_7_DAYS) {
            dateDetail.startDate = moment().subtract(7, 'day').toDate();
            dateDetail.endDate = moment().toDate();
        } else if (value === LAST_30_DAYS) {
            dateDetail.startDate = moment().subtract(30, 'day').toDate();
            dateDetail.endDate = moment().toDate();
        }
        return dateDetail;
    }, [value]);

    const CustomPicker = ({ className, children }) => {
        return (
            <div id="custom-date-picker-container" style={{ color: Colors.WHITE }}>
                <CalendarContainer className={`${className} calendar-container`}>
                    <div className="calendar-left-menu">
                        {dateSelectionOptions?.map((mainOption, mainOptionIndex) => {
                            return (
                                <Grid key={mainOptionIndex} xs={12} md={12} lg={12} item>
                                    <Grid
                                        item
                                        display={'flex'}
                                        alignItems={'center'}
                                        borderRadius={1}
                                        paddingTop={0.1}
                                        paddingBottom={0.1}
                                        sx={{
                                            ...styles.expansionButton,
                                        }}
                                        border={mainOption?.isOpened ? 1 : 0}
                                        onClick={() => onClickAccordionOption(mainOptionIndex)}
                                        width={'100%'}
                                    >
                                        {mainOption?.isOpened ? (
                                            <ArrowDropDown
                                                style={{
                                                    color: Colors.BLACK,
                                                    fontSize: '1.5rem',
                                                }}
                                            />
                                        ) : (
                                            <ArrowRight
                                                style={{
                                                    color: Colors.BLACK,
                                                    fontSize: '1.5rem',
                                                }}
                                            />
                                        )}
                                        <p style={{ ...styles.paramLabel }}>{mainOption?.label}</p>
                                    </Grid>
                                    <Grid md={12} item>
                                        <Reveal style={{ width: '100%' }}>
                                            {mainOption?.isOpened && (
                                                <ExpansionPanelContent
                                                    style={{
                                                        backgroundColor: Colors.WHITE,
                                                        paddingLeft: '1.5rem',
                                                    }}
                                                >
                                                    {mainOption?.options?.map(
                                                        (subOption, subOptionIndex) => {
                                                            return (
                                                                <p
                                                                    onClick={() =>
                                                                        onClickDateSelectionOption(
                                                                            subOption,
                                                                        )
                                                                    }
                                                                    key={subOptionIndex}
                                                                    style={{
                                                                        ...styles.subOptionLabel,
                                                                        fontWeight:
                                                                            subOption?.label ===
                                                                            value
                                                                                ? 'bold'
                                                                                : 'normal',
                                                                    }}
                                                                >
                                                                    {subOption?.label}
                                                                </p>
                                                            );
                                                        },
                                                    )}
                                                </ExpansionPanelContent>
                                            )}
                                        </Reveal>
                                    </Grid>
                                </Grid>
                            );
                        })}
                    </div>
                    <div style={{ position: 'relative' }}>{children}</div>
                </CalendarContainer>
            </div>
        );
    };
    return selectsRange ? (
        <DatePicker
            // ref={(c) => (datePickerRef.current = c)}
            customInput={
                customInput ?? (
                    <TextField
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <CalendarMonth style={{ fontSize: 18 }} />
                                </InputAdornment>
                            ),
                        }}
                        label={label}
                        fullWidth
                        size="small"
                    />
                )
            }
            onChange={onChangeDateRange}
            startDate={startDate}
            endDate={endDate}
            onCalendarClose={(e) => {
                if (!endDate && startDate) {
                    onChangeDateRange([startDate, startDate]);
                }
            }}
            shouldCloseOnSelect={false}
            dateFormat={dateFormat}
            disabledKeyboardNavigation
            selectsRange
            selected={startDate}
            selectsDisabledDaysInRange
        />
    ) : (
        <DatePicker
            showTimeSelect={showTimeSelect}
            ref={(c) => (datePickerRef.current = c)}
            {...(moment(value).isValid()
                ? { selected: new Date(value) }
                : {
                      value: value,
                  })}
            {...(showCustomDaysPicker && {
                calendarContainer: CustomPicker,
            })}
            customInput={
                customInput ?? (
                    <TextField
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <CalendarMonth style={{ fontSize: 18 }} />
                                </InputAdornment>
                            ),
                        }}
                        label={label}
                        fullWidth
                        size="small"
                    />
                )
            }
            onChange={onChangeDate}
            startDate={moment(value).isValid() ? value : getStartDateAndEndDate().startDate}
            endDate={moment(value).isValid() ? value : getStartDateAndEndDate().endDate}
            shouldCloseOnSelect={false}
            onKeyDown={(e) => {
                e.preventDefault();
            }}
            dateFormat={dateFormat}
            disabledKeyboardNavigation
        />
    );
};

export default CustomDatePicker;

const styles = {
    expansionButton: {
        marginTop: 0.3,
        marginBottom: 0.3,
        borderColor: '#aeaeae',
        cursor: 'pointer',
    },
    paramLabel: {
        fontSize: '0.9rem',
    },
    subOptionLabel: {
        marginTop: 4,
        marginBottom: 4,
        fontSize: '0.8rem',
        cursor: 'pointer',
    },
};

CustomDatePicker.propTypes = {
    value: PropTypes.any,
    onChange: PropTypes.func,
    dateFormat: PropTypes.string,
    label: PropTypes.string,
};
