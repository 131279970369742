import React from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import { AppUrls } from '../../core/route.url';
import { usePostData } from '../../hooks/use-post-data.hook';
import CarrierSLAList from './carrier-sla-list';
import PropTypes from 'prop-types';

const CarrierSLAData = ({ CarrierUniqueID, carrierId }) => {
    //Assign SLA List API
    const { submitData: submitDataForSLA, response: responseSLA } = usePostData(
        AppUrls.SLAListForCarrier,
        'POST',
    );

    const [isSLALoad, setSLAIsLoad] = React.useState(true);

    //Call SLA API
    React.useEffect(() => {
        if (isSLALoad == true) {
            setSLAIsLoad(false);
            submitDataForSLA({
                PageNo: 1,
                PageSize: 50,
                SortDir: 'Ascending',
                SortExp: '',
                CarrierUniqueID: CarrierUniqueID,
            });
        }
    }, [isSLALoad]);

    return (
        <>
            {responseSLA.result != undefined ? (
                <>
                    <CarrierSLAList
                        SLAList={responseSLA.result.Data}
                        CarrierUniqueID={CarrierUniqueID}
                        carrierId={carrierId}
                        setSLAIsLoad={setSLAIsLoad}
                    />
                </>
            ) : (
                <>
                    <LinearProgress />
                </>
            )}
        </>
    );
};
export default CarrierSLAData;

CarrierSLAData.propTypes = {
    CarrierUniqueID: PropTypes.any.isRequired,
    carrierId: PropTypes.any.isRequired,
};
