import { useEffect, useState } from 'react';
import { getData } from '../utils/network.client';
import axios from 'axios';
export const useLoadData = (route, disable = false) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [result, setResult] = useState();

    useEffect(() => {
        if (!disable) {
            loadData();
        }
    }, [route, disable]);
    //

    const loadData = async () => {
        try {
            setIsError(false);
            setIsLoading(true);
            const result = await getData(route);
            // axios.interceptors.response.use(
            //     (response) => {
            //         return response;
            //     },
            //     (error) => {
            //         if (error.response.status === 401) {
            //             //place your reentry code
            //             console.log('get api 401 error');
            //         }
            //         return error;
            //     },
            // );
            if (result.status === 200) {
                setResult(result.data);
            } else {
                setIsError(true);
            }
        } catch (ex) {
            setIsError(true);
        }
        setIsLoading(false);
    };

    return { isLoading, result, isError, reload: loadData };
};
