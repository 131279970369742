import React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Messages from './messages';
import SlaList from './slaList';
import UserClaimHistoryActivity from '../auditTrail/user-claim-history-activity';
import UserAuditTrail from '../auditTrail/user-audit-trail';

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography component={'span'}>{children}</Typography>
                </Box>
            )}
        </div>
    );
};

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};



const Inspector_profile_tab = (props) => {
    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <>
            <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                        <Tab label="SLA" {...a11yProps(0)} />
                        {/* <Tab label="Roles" {...a11yProps(2)} />
            <Tab label="Skills" {...a11yProps(3)} />
            <Tab label="Licenses" {...a11yProps(4)} /> */}
                        <Tab label="Messages" {...a11yProps(1)} />

                        <Tab label="  CLAIM HISTORY AND ACTIVITY " {...a11yProps(2)} />
                        <Tab label="AUDIT TRAIL" {...a11yProps(3)} />
                    </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                    {/* <Claim_profile_note_tab
            cliamProfileDetails={props.cliamProfileDetails}
          /> */}
                    <SlaList userId={props?.userId} />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    {/* <Claim_profile_documet_tab
            cliamProfileDetails={props.cliamProfileDetails}
          /> */}
                    <Messages userId={props?.userId} />
                </TabPanel>

                <TabPanel value={value} index={2}>
                    <UserClaimHistoryActivity userId={props?.userId} />
                </TabPanel>
                <TabPanel value={value} index={3}>
                    <UserAuditTrail userId={props?.userId} />
                </TabPanel>
                <TabPanel value={value} index={5}>
                    {/* <Claim_profile_task_tab
            cliamProfileDetails={props.cliamProfileDetails}
          /> */}
                </TabPanel>
                <TabPanel value={value} index={6}>
                    {/* <Claim_profile_task_tab
            cliamProfileDetails={props.cliamProfileDetails}
          /> */}
                </TabPanel>
            </Box>
        </>
    );
};

export default Inspector_profile_tab;
