import { Navigate, useNavigate } from 'react-router-dom';
import { useCurrentUser } from '../hooks/user-current-user.hook';
import { AuthorizeComponent } from './authorize.component';
import SideNavbarComponent from './sideNavbarComponent';

export const GuardedPage = ({ requiredPrermission, children, isPublic = false, url }) => {
    const user = useCurrentUser();
    const history = useNavigate();
    // if (requiredPrermission === undefined) {
    //     return <Navigate to="/home" />;
    // }

    if (
        !requiredPrermission
        //&&
        // !validateRequiredPermissions(
        //     requiredPrermission,
        //     PermissionCheckType.All,
        //     currentPermissions,
        //)
    ) {
        return <Navigate to="/unauthorized" />;
    } else {
        return (
            <>
                {/* <AuthorizeComponent isPublic={isPublic}> */}
                {!isPublic ? (
                    <>
                        <SideNavbarComponent />
                        {children}
                    </>
                ) : (
                    children
                )}
                {/* </AuthorizeComponent> */}
            </>
        );
    }
};
