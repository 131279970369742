import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { Grid, GridColumn as Column, GridToolbar } from '@progress/kendo-react-grid';
import { process } from '@progress/kendo-data-query';
import { setExpandedState, setGroupIds } from '@progress/kendo-react-data-tools';
import { ColumnMenu } from '../../components/kendoGrid/customColumnMenu';
import iconexcel from '../../images/file-excel.svg';
import iconpdf from '../../images/file-pdf-solid.svg';
import { GridPDFExport } from '@progress/kendo-react-pdf';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { encryptWithAES } from '../../utils/global-functions';
const initialDataState = {
    take: 100,
    skip: 0,
    // group: [
    //     {
    //         field: 'UnitsInStock',
    //     },
    //     {
    //         field: 'ProductName',
    //     },
    // ],
};
const aggregates = [
    {
        field: 'UnitsInStock',
        aggregate: 'sum',
    },
    {
        field: 'UnitPrice',
        aggregate: 'average',
    },
];

const processWithGroups = (data, dataState) => {
    const groups = dataState.group;

    //   if (groups) {
    //     groups.map((group) => (group.aggregates = aggregates));
    //   }

    dataState.group = groups;
    const newDataState = process(data, dataState);
    setGroupIds({
        data: newDataState.data,
        group: dataState.group,
    });
    return newDataState;
};

const CustomExecutQueryListAggregates = ({ customColumn, filterList, displayQuery }) => {
    const [executeQueryColumns, setExecuteColumns] = React.useState(customColumn);
    const history = useNavigate();
    const [dataState, setDataState] = React.useState(initialDataState);
    const [result, setResult] = React.useState(processWithGroups(filterList, initialDataState));
    const [collapsedState, setCollapsedState] = React.useState([]);

    const dataStateChange = (event) => {
        const newDataState = processWithGroups(filterList, event.dataState);
        setResult(newDataState);
        setDataState(event.dataState);
    };

    React.useEffect(() => {
        setExecuteColumns(customColumn);
    }, [customColumn]);

    const onColumnsSubmit = (columnsState) => {
        setExecuteColumns(columnsState);
    };

    const expandChange = (event) => {
        const item = event.dataItem;

        if (item.groupId) {
            const newCollapsedIds = !event.value
                ? [...collapsedState, item.groupId]
                : collapsedState.filter((groupId) => groupId !== item.groupId);
            setCollapsedState(newCollapsedIds);
        }
    };

    const cellRender = (tdElement, cellProps) => {
        if (cellProps.rowType === 'groupFooter') {
            if (cellProps.field === 'UnitPrice') {
                return (
                    <td aria-colindex={cellProps.columnIndex} role={'gridcell'}>
                        Average: {cellProps.dataItem.aggregates.UnitPrice.average}
                    </td>
                );
            } else if (cellProps.field === 'UnitsInStock') {
                return (
                    <td aria-colindex={cellProps.columnIndex} role={'gridcell'}>
                        Sum: {cellProps.dataItem.aggregates.UnitsInStock.sum}
                    </td>
                );
            }
        }

        return tdElement;
    };
    const _export = React.useRef(null);
    const excelExport = () => {
        if (_export.current !== null) {
            _export.current.save(filterList);
        }
    };

    let _pdfExport;

    const exportPDF = () => {
        _pdfExport.save(
            process(filterList, {
                take: filterList.length + 1,
                skip: 0,
                group: dataState.group,
                filter: dataState.filter,
            }).data,
        );
    };

    const newData = setExpandedState({
        data: result.data,
        collapsedIds: collapsedState,
    });

    const exportGrid = (value) => {
        return (
            <Grid
                style={{
                    maxHeight: 'calc(100vh - 193px)',
                    display: value ? 'none' : '',
                }}
                resizable={true}
                reorderable={true}
                // filterable={true}
                sortable={true}
                pageable={{
                    buttonCount: 5,
                    info: true,
                    type: 'numeric',
                    pageSizes: [100, 500, 1000],
                    previousNext: true,
                }}
                // pageSize={100}
                groupable={{
                    footer: 'visible',
                }}
                data={newData}
                onDataStateChange={dataStateChange}
                {...dataState}
                onExpandChange={expandChange}
                expandField="expanded"
                cellRender={cellRender}
                // pageSize={8}
            >
                {executeQueryColumns
                    .filter((data, i) => {
                        return data.show === true && data.field !== 'optn';
                    })
                    .map((d, index) => {
                        return <Column key={index} field={d.field} title={d.title} />;
                    })}
            </Grid>
        );
    };

    const navigateCell = (props) => {
        if (props.rowType === 'groupHeader') {
            return null;
        }

        return (
            <>
                <td className="k-command-cell hover">
                    {' '}
                    <a
                        style={{
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            display: 'block',
                            overflow: 'hidden',
                        }}
                        onClick={() => {
                            let param = {
                                state: {
                                    page: 'claim',
                                    guId: props.dataItem.ClaimGuID,
                                },
                            };
                            let encryptedParam = encryptWithAES(JSON.stringify(param));
                            history(window.open(`/${encryptedParam}`, '_blank'));
                        }}
                    >
                        <i className={props.dataItem.ClaimSLAColorIndicator} />
                        {props.dataItem.ClaimNumber}
                    </a>
                </td>
            </>
        );
    };

    return (
        <>
            <div className="claims-list-grid" style={{ marginBottom: '10px' }}>
                <Grid
                    style={{
                        maxHeight: 'calc(100vh - 193px)',
                    }}
                    resizable={true}
                    reorderable={true}
                    // filterable={true}
                    sortable={true}
                    pageable={{
                        buttonCount: 5,
                        info: true,
                        type: 'numeric',
                        pageSizes: [100, 500, 1000],
                        previousNext: true,
                    }}
                    // pageSize={100}
                    groupable={{
                        footer: 'visible',
                    }}
                    data={newData}
                    onDataStateChange={dataStateChange}
                    {...dataState}
                    onExpandChange={expandChange}
                    expandField="expanded"
                    cellRender={cellRender}
                    total={result.total}
                >
                    <GridToolbar>
                        <div className="search-wrap">
                            {displayQuery && (
                                <>
                                    <b style={{ fontSize: 14 }} className="m-0">
                                        <p style={{ marginRight: '5px' }}> Filtered by:</p>
                                    </b>
                                    <p style={{ fontSize: 14 }}>{displayQuery}</p>
                                </>
                            )}
                        </div>
                    </GridToolbar>
                    <GridToolbar>
                        <div>
                            <button
                                title="Export to Excel"
                                className="icon-btn"
                                onClick={excelExport}
                            >
                                <img src={iconexcel} title="Export to Excel" />
                            </button>

                            <button className="icon-btn" onClick={exportPDF}>
                                <img src={iconpdf} title="Export to PDF" />
                            </button>
                        </div>
                    </GridToolbar>
                    {executeQueryColumns.map(
                        (column, idx) =>
                            column.show &&
                            (column.field === 'ClaimNumber' ? (
                                <Column
                                    width={executeQueryColumns?.length > 16 ? 110 : 'auto'}
                                    // minResizableWidth={100}
                                    key={idx}
                                    field={column.field}
                                    title={column.title}
                                    cell={navigateCell}
                                    filter={column.filter}
                                    columnMenu={(props) => (
                                        <ColumnMenu
                                            {...props}
                                            columns={executeQueryColumns}
                                            onColumnsSubmit={onColumnsSubmit}
                                        />
                                    )}
                                />
                            ) : (
                                <Column
                                    width={executeQueryColumns?.length > 16 ? 110 : 'auto'}
                                    // minResizableWidth={100}
                                    key={idx}
                                    field={column.field}
                                    title={column.title}
                                    // cell={editCell}
                                    filter={column.filter}
                                    columnMenu={(props) => (
                                        <ColumnMenu
                                            {...props}
                                            columns={executeQueryColumns}
                                            onColumnsSubmit={onColumnsSubmit}
                                        />
                                    )}
                                />
                            )),
                    )}
                </Grid>
                <ExcelExport
                    fileName={`CustomQueryExport_${moment(new Date()).format(
                        'MM-DD-YYYY hh mm a',
                    )}`}
                    style={{ display: 'none' }}
                    // data={result !== undefined && result.data !== undefined ? result.data : []}
                    // ref={_export}
                    data={
                        filterList
                            ? process(filterList, {
                                  take: filterList.length + 1,
                                  skip: 0,
                                  group: dataState.group,
                                  filter: dataState.filter,
                              }).data
                            : []
                    }
                    ref={_export}
                    groupable={true}
                    group={dataState.group}
                >
                    {exportGrid(true)}
                </ExcelExport>
                <GridPDFExport
                    fileName={`CustomQueryExport_${moment(new Date()).format(
                        'MM-DD-YYYY hh mm a',
                    )}`}
                    ref={(element) => {
                        _pdfExport = element;
                    }}
                    margin="1cm"
                >
                    {exportGrid(false)}
                </GridPDFExport>
            </div>
        </>
    );
};

export default CustomExecutQueryListAggregates;

CustomExecutQueryListAggregates.propTypes = {
    customColumn: PropTypes.array.isRequired,
    filterList: PropTypes.array.isRequired,
};
