import React from 'react';
import PropTypes from 'prop-types';
import { Alert, Box, Button, CircularProgress, TextField, Modal, FormControl } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import Moment from 'moment';
import ClaimTaskForm from './claim-task-form';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material/';
import IconButton from '@mui/material/IconButton';
import { useNavigate, useParams } from 'react-router-dom';
import { usePostData } from '../../hooks/use-post-data.hook';
import { AppUrls } from '../../core/route.url';
import { decryptWithAES, encryptWithAES } from '../../utils/global-functions';
import { Controller, useForm } from 'react-hook-form';
import SelectMulti from '../../components/select-multi';
import useUserPermissions from '../../hooks/use-user-permissions.hook';
import { useState } from 'react';
import editIcon from '../../images/pencil.svg';
import CustomDatePicker from '../../components/CustomDatePicker';
import moment from 'moment';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 520,
    bgcolor: 'background.paper',
    boxShadow: 3,
    p: 3,
};

const ClaimProfileTaskDetails = ({
    stateValue,
    cliamProfileDetails,
    response,
    setIsClaimTaskOpen,
    reload,
    isClaimTaskOpen,
    handleRefreshTask,
    fileReviewer,
    handleRefreshNotes,
    isAssigned,
    claimGuID,
    reloadClaimTaskList,
}) => {
    const {
        register,
        handleSubmit,
        control,
        setValue: setValues,
        watch,
        formState: { errors },
    } = useForm();
    const {
        register: register1,
        handleSubmit: handleSubmit1,
        control: control1,
        setValue: setValues1,
        watch: watch1,
        formState: { errors1 },
    } = useForm({
        defaultValues: {
            fileReviewerName: '',
        },
    });

    const [value, setValue] = React.useState(0);
    const [isLoad, setIsLoad] = React.useState(true);
    const [isModalOpen, setIsModalOpen] = React.useState(0);
    const [isAssignSelectbox, setIsAssignSelectbox] = React.useState(false);
    const [savedResponse, setSaveResponse] = React.useState({});
    const history = useNavigate();
    let { id: param } = useParams();
    let decryptedParam = decryptWithAES(param?.replace(/%2F/g, '/'));
    let parsedParam = JSON.parse(decryptedParam);
    const id = parsedParam?.state?.guId;
    const { state } = parsedParam;
    const { submitData, response: slaTrackResult } = usePostData(AppUrls.SlaTractList, 'POST');
    const { handleSetSpecialPermissions } = useUserPermissions();
    const { submitData: submitDataForFileClose, response: fileCloseResponse } = usePostData(
        AppUrls.ClaimTaskFileClose,
        'POST',
    );

    const { submitData: submitNoteData, response: noteResponse } = usePostData(
        AppUrls.ClaimNoteGetByPage,
        'POST',
    );

    const { submitData: updateReviewTask, response: updateReviewTaskResponse } = usePostData(
        AppUrls.ClaimUpdateReviewTask,
        'POST',
    );

    const { submitData: updateReservation, response: updateReservationResponse } = usePostData(
        AppUrls.UpdateReservation,
        'POST',
    );

    const [reservationDateEditModalOpen, setReservationDateEditModal] = useState(false);
    const [selectedReservationDate, setSelectedReservationDate] = useState(null);
    const [updateReservationLoading, setUpdateReservationLoading] = useState(false);

    const watchAllFields = watch();
    const watchAllFields1 = watch1();
    React.useEffect(() => {
        if (isLoad) {
            setIsLoad(false);
            submitData({
                ClaimGuID: id,
                // PageNo: pageNumber,
            });
        }
    }, [isLoad]);

    React.useEffect(() => {
        if (updateReservationLoading && updateReservationResponse?.result?.IsSuccess) {
            setReservationDateEditModal(false);
            reloadClaimTaskList();
            reload();
        }
    }, [updateReservationResponse]);

    React.useEffect(() => {
        if (watchAllFields1.fileReviewerName !== '') {
            updateReviewTask({
                AssigneeId: watchAllFields1.fileReviewerName,
                AssigneeName:
                    fileReviewer !== undefined
                        ? fileReviewer.Data !== undefined
                            ? fileReviewer.Data.filter((data) => {
                                  return data.UserId === watchAllFields1.fileReviewerName;
                              })[0].UserFullName
                            : ''
                        : '',
                Type: 'assigned',
                ClaimTaskGuID: response.result.Data[value].ClaimTaskGuID,
            });
            setIsAssignSelectbox(false);
        }
    }, [watchAllFields1.fileReviewerName]);

    React.useEffect(() => {
        if (response.result !== undefined && stateValue.page === 'task') {
            let index =
                response.result.Data !== null
                    ? response.result.Data.findIndex(
                          (obj) => obj.ClaimTaskGuID === stateValue.taskGuId,
                      )
                    : 0;
            setValue(index);
            setSelectedReservationDate(
                response.result.Data[index].ReservationStart
                    ? new Date(response.result.Data[index].ReservationStart)
                    : null,
            );
        }
    }, [response.result]);

    React.useEffect(() => {
        if (fileCloseResponse.result != undefined) {
            setSaveResponse(fileCloseResponse.result);

            const timer = setTimeout(() => {
                setSaveResponse({});
                setValues('note', '');
                handleRefreshTask();
                handleRefreshNotes();
                reload();
                setIsModalOpen(false);
            }, 1000);
            return () => clearTimeout(timer);
        }
    }, [fileCloseResponse]);

    React.useEffect(() => {
        if (updateReviewTaskResponse.result != undefined) {
            handleRefreshTask();
            handleRefreshNotes();
            reload();
        }
    }, [updateReviewTaskResponse]);

    if (response.result === undefined) {
        return (
            <Box sx={{ width: '100%' }}>
                <LinearProgress />
            </Box>
        );
    }

    if (response.result.Data === null) {
        return (
            <Box sx={{ width: '100%' }}>
                <h2>No Data Available</h2>
            </Box>
        );
    }

    const handleModalClose = () => {
        setIsClaimTaskOpen(false);
    };

    const handleClose = () => {
        setIsModalOpen(false);
        setValues('note', '');
    };

    const handleTaskValuesUpdate = (code) => {
        updateReviewTask({
            AssigneeId: 0,
            AssigneeName: '',
            Type: code,
            ClaimTaskGuID: response.result.Data[value].ClaimTaskGuID,
        });
    };

    const onSubmit = async (data) => {
        submitDataForFileClose({
            claimguid: id,
            carrierId:
                response.result.Data[value].CarrierId !== null
                    ? response.result.Data[value].CarrierId
                    : 0,
            claimId: cliamProfileDetails.ClaimId !== null ? cliamProfileDetails.ClaimId : 0,

            NoteDesc: data.note,
        });
    };

    return (
        <>
            <ClaimTaskForm
                value={value}
                resultData={response.result.Data}
                handleModalClose={handleModalClose}
                reload={reload}
                isClaimTaskOpen={isClaimTaskOpen}
            />
            <Modal
                open={reservationDateEditModalOpen}
                onClose={() => {
                    setReservationDateEditModal(false);
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            >
                <Box sx={{ bgcolor: 'background.paper', padding: 3, minWidth: '400px' }}>
                    <p style={{ marginBottom: 30 }}>Update Reservation</p>
                    <CustomDatePicker
                        onChange={(date) => setSelectedReservationDate(date)}
                        value={selectedReservationDate}
                        dateFormat="MM-dd-yyyy hh:mm a"
                        label={'Reservation Date Time'}
                        showTimeSelect
                        showCustomDaysPicker={false}
                        defaultNull
                    />
                    <Button
                        onClick={() => {
                            if (selectedReservationDate) {
                                setUpdateReservationLoading(true);
                                // console.log(
                                //     'selectedReservationDate',
                                //     Moment(selectedReservationDate).format('MM-DD-YYYY hh:mm A'),
                                // );
                                // console.log(
                                //     '11',
                                //     {Moment(selectedReservationDate).format('MM-DD-YYYY hh:mm A')},
                                // );
                                updateReservation({
                                    ReservationStart: `${Moment(selectedReservationDate).format(
                                        'MM-DD-YYYY hh:mm A',
                                    )}`,
                                    ClaimGuID: claimGuID,
                                });
                            }
                        }}
                        sx={{ mt: 3 }}
                        fullWidth
                        variant="contained"
                    >
                        Submit
                    </Button>
                </Box>
            </Modal>
            <Modal
                open={isModalOpen}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div>
                        <h2 style={{ marginBottom: 20 }}> Close Claim</h2>
                        <Box
                            component="form"
                            sx={{
                                '& .MuiTextField-root': { m: 1, width: '53ch' },
                            }}
                            noValidate
                            autoComplete="off"
                            onSubmit={handleSubmit(onSubmit)}
                        >
                            <div>
                                {/* <FormControl sx={{ m: 1, width: '53ch' }}> */}
                                <TextField
                                    error={errors.note}
                                    id="outlined-multiline-static"
                                    label="Note"
                                    multiline
                                    rows={4}
                                    defaultValue=""
                                    InputLabelProps={{
                                        shrink: watchAllFields.note === '' ? false : true,
                                    }}
                                    {...register('note', { required: true })}
                                />
                                {/* </FormControl> */}
                            </div>
                            <div className="d-flex" style={{ marginTop: 20 }}>
                                <Button
                                    style={{ marginLeft: 2 }}
                                    onClick={() => {
                                        setIsModalOpen(false);
                                        setValues('note', '');
                                    }}
                                    variant="outlined"
                                    type="button"
                                >
                                    Cancel
                                </Button>

                                <Button
                                    style={{ marginLeft: 'auto' }}
                                    variant="contained"
                                    type="submit"
                                >
                                    {fileCloseResponse.isSubmitting ? (
                                        <CircularProgress color="inherit" />
                                    ) : (
                                        'Save'
                                    )}
                                </Button>
                            </div>
                        </Box>
                        {savedResponse.IsSuccess != undefined ? (
                            <Alert
                                style={{ marginTop: 15 }}
                                severity={savedResponse.IsSuccess ? 'success' : 'error'}
                            >
                                {savedResponse.Message}
                            </Alert>
                        ) : null}
                    </div>
                </Box>
            </Modal>
            <div className="card col-7" style={{ position: 'relative' }}>
                {stateValue.page === 'task' ? null : (
                    <Box className="iconContainer">
                        <Box>
                            {value !== 0 ? (
                                <IconButton
                                    size="medium"
                                    onClick={() => {
                                        setValue(value - 1);
                                    }}
                                >
                                    <KeyboardArrowLeft />
                                </IconButton>
                            ) : null}
                        </Box>
                        <Box>
                            {value + 1 === response.result.Data.length ? null : (
                                <IconButton
                                    size="medium"
                                    onClick={() => {
                                        setValue(value + 1);
                                    }}
                                >
                                    <KeyboardArrowRight />
                                </IconButton>
                            )}
                        </Box>
                    </Box>
                )}
                <h3>
                    Task ({value + 1}
                    {stateValue.page !== 'task'
                        ? response.result.Data !== null
                            ? '/' + response.result.Data.length
                            : null
                        : null}
                    )
                </h3>

                <label>
                    {' '}
                    {/* <a
                        style={{}}
                        onClick={() => {
                            let param = {
                                state: {
                                    page: 'task',
                                    taskGuId: response.result.Data[value].ClaimTaskGuID,
                                    taskCode: response.result.Data[value].ClaimTaskTypeId,
                                    guId: response.result.Data[value].ClaimGuID,
                                },
                            };
                            let encryptedParam = encryptWithAES(JSON.stringify(param));
                            history(window.open(`/claimtask/${encryptedParam}`, '_blank'));
                        }}
                    > */}
                    <span
                        style={{
                            color: '#337dff',
                        }}
                    >
                        <a
                            style={{
                                cursor: stateValue.page !== 'task' ? 'pointer' : ' ',
                            }}
                            onClick={
                                stateValue.page !== 'task'
                                    ? () => {
                                          let param = {
                                              state: {
                                                  page: 'task',
                                                  taskGuId:
                                                      response.result.Data[value].ClaimTaskGuID,
                                                  taskCode:
                                                      response.result.Data[value].ClaimTaskTypeId,
                                                  guId: response.result.Data[value].ClaimGuID,
                                              },
                                          };
                                          let encryptedParam = encryptWithAES(
                                              JSON.stringify(param),
                                          );
                                          history(
                                              window.open(`/claimtask/${encryptedParam}`, '_blank'),
                                          );
                                      }
                                    : () => {}
                            }
                        >
                            {' '}
                            {response.result.Data[value].ClaimTaskNumber}{' '}
                        </a>{' '}
                        ({response.result.Data[value].ClaimTaskTypeName}
                        {stateValue.page === 'task' ? (
                            <>
                                {' '}
                                of&nbsp;
                                <a
                                    style={{
                                        cursor: stateValue.page === 'task' ? 'pointer' : ' ',
                                    }}
                                    onClick={
                                        stateValue.page === 'task'
                                            ? () => {
                                                  let param = {
                                                      state: {
                                                          page: 'claim',
                                                          guId: response.result.Data[value]
                                                              .ClaimGuID,
                                                      },
                                                  };
                                                  let encryptedParam = encryptWithAES(
                                                      JSON.stringify(param),
                                                  );
                                                  history(
                                                      window.open(`/${encryptedParam}`, '_blank'),
                                                  );
                                              }
                                            : () => {}
                                    }
                                >
                                    {' '}
                                    {response.result.Data[value].ClaimNumber}{' '}
                                </a>
                            </>
                        ) : null}
                        )
                    </span>{' '}
                    - {response.result.Data[value].ClaimTaskStatusName}
                    {/* </a> */}
                </label>

                <p>
                    <i className="gray-text">
                        Last Updated:{' '}
                        {response.result.Data[value].LastUpdatedDate !== null
                            ? Moment(response.result.Data[value].LastUpdatedDate).format(
                                  'MM-DD-YYYY hh:mm A',
                              )
                            : null}
                    </i>
                </p>
                {/* <label>{response.result.Data[value].ClaimTaskStatusName}</label> */}
                <div className="stack-task mt-1">
                    {/* column  */}
                    {response.result.Data[value].ClaimTaskTypeCode === 'CLAIM_TASK' ||
                    response.result.Data[value].ClaimTaskTypeCode === 'REVIEW_TASK' ? null : (
                        <div>
                            <label>Description</label>
                            <p>{response.result.Data[value].ShortDescription} </p>
                        </div>
                    )}

                    {response.result.Data[value].ClaimTaskTypeCode === 'REVIEW_TASK' ? (
                        <>
                            {/* column  */}
                            {cliamProfileDetails.EnableFileClose ? (
                                <></>
                            ) : // <Button
                            //     style={{
                            //         position: 'absolute',
                            //         right: '94px',
                            //         top: '9px',
                            //         boxShadow: 'none',
                            //     }}
                            //     variant="contained"
                            //     type="submit"
                            //     size="small"
                            //     onClick={() => {
                            //         setIsModalOpen(true);
                            //     }}
                            // >
                            //     Close Claim
                            // </Button>
                            null}
                            <div>
                                <label>
                                    Reviewer Assigned{' '}
                                    <span style={{ color: 'black' }}>
                                        {' '}
                                        {response.result.Data[value].AssigneeName !== null
                                            ? ' - ' + response.result.Data[value].AssigneeName
                                            : null}
                                    </span>
                                </label>

                                {/* {-name} */}
                                <p>
                                    {' '}
                                    {response.result.Data[value].ReviewerAssignedTime !== null
                                        ? Moment(
                                              response.result.Data[value].ReviewerAssignedTime,
                                          ).format('MM-DD-YYYY hh:mm A')
                                        : null}
                                </p>
                                {response.result.Data[value].ReviewerAssignedTime === null ? (
                                    <div>
                                        {handleSetSpecialPermissions('FILE_REVIEW') !==
                                        undefined ? (
                                            handleSetSpecialPermissions('FILE_REVIEW') ? (
                                                <Button
                                                    style={{ marginTop: 3, cursor: 'pointer' }}
                                                    variant="contained"
                                                    type="submit"
                                                    size="small"
                                                    onClick={() => {
                                                        setIsAssignSelectbox(true);
                                                    }}
                                                >
                                                    Assign
                                                </Button>
                                            ) : null
                                        ) : null}

                                        {isAssignSelectbox ? (
                                            <Box
                                                component="form"
                                                sx={{
                                                    '& .MuiTextField-root': { m: 1, width: '53ch' },
                                                }}
                                                noValidate
                                                autoComplete="off"
                                                onSubmit={handleSubmit1(onSubmit)}
                                            >
                                                <FormControl
                                                    style={{ paddingLeft: 130, margin: -32 }}
                                                    sx={{ m: 1, width: 413 }}
                                                >
                                                    <Controller
                                                        control={control1}
                                                        name="fileReviewerName"
                                                        rules={{
                                                            required: 'Please select Inspector ',
                                                        }}
                                                        render={({
                                                            field: {
                                                                onChange,
                                                                onBlur,
                                                                value,
                                                                name,
                                                            },
                                                        }) => (
                                                            <SelectMulti
                                                                fullWidth
                                                                values={
                                                                    fileReviewer !== undefined
                                                                        ? fileReviewer.Data !==
                                                                          undefined
                                                                            ? fileReviewer.Data.map(
                                                                                  (e) => {
                                                                                      return {
                                                                                          value: e[
                                                                                              'UserId'
                                                                                          ],
                                                                                          label: e[
                                                                                              'UserFullName'
                                                                                          ],
                                                                                      };
                                                                                  },
                                                                              )
                                                                            : []
                                                                        : []
                                                                }
                                                                onChange={onChange}
                                                                onBlur={onBlur}
                                                                value={
                                                                    value === undefined ? '' : value
                                                                }
                                                                name={name || ''}
                                                                multi={false}
                                                            />
                                                        )}
                                                    />
                                                </FormControl>
                                            </Box>
                                        ) : null}
                                    </div>
                                ) : (
                                    <div>
                                        {handleSetSpecialPermissions('FILE_REVIEW') !==
                                        undefined ? (
                                            handleSetSpecialPermissions('FILE_REVIEW') ? (
                                                <Button
                                                    style={{ marginTop: 3, cursor: 'pointer' }}
                                                    variant="contained"
                                                    type="submit"
                                                    size="small"
                                                    onClick={() => {
                                                        setIsAssignSelectbox(true);
                                                    }}
                                                >
                                                    REASSIGN
                                                </Button>
                                            ) : null
                                        ) : null}

                                        {isAssignSelectbox ? (
                                            <Box
                                                component="form"
                                                sx={{
                                                    '& .MuiTextField-root': { m: 1, width: '53ch' },
                                                }}
                                                noValidate
                                                autoComplete="off"
                                                onSubmit={handleSubmit1(onSubmit)}
                                            >
                                                <FormControl
                                                    style={{ paddingLeft: 130, margin: -32 }}
                                                    sx={{ m: 1, width: 413 }}
                                                >
                                                    <Controller
                                                        control={control1}
                                                        name="fileReviewerName"
                                                        rules={{
                                                            required: 'Please select Inspector ',
                                                        }}
                                                        render={({
                                                            field: {
                                                                onChange,
                                                                onBlur,
                                                                value,
                                                                name,
                                                            },
                                                        }) => (
                                                            <SelectMulti
                                                                fullWidth
                                                                values={
                                                                    fileReviewer !== undefined
                                                                        ? fileReviewer.Data !==
                                                                          undefined
                                                                            ? fileReviewer.Data.map(
                                                                                  (e) => {
                                                                                      return {
                                                                                          value: e[
                                                                                              'UserId'
                                                                                          ],
                                                                                          label: e[
                                                                                              'UserFullName'
                                                                                          ],
                                                                                      };
                                                                                  },
                                                                              )
                                                                            : []
                                                                        : []
                                                                }
                                                                onChange={onChange}
                                                                onBlur={onBlur}
                                                                value={
                                                                    value === undefined ? '' : value
                                                                }
                                                                name={name || ''}
                                                                multi={false}
                                                            />
                                                        )}
                                                    />
                                                </FormControl>
                                            </Box>
                                        ) : null}
                                    </div>
                                )}
                            </div>

                            <div>
                                <label>Reviewer Accepted</label>
                                {response.result.Data[value].ReviewerAssignedTime !== null &&
                                response.result.Data[value].ReviewerAcceptedTime === null ? (
                                    <div>
                                        {handleSetSpecialPermissions('FILE_REVIEW') !==
                                        undefined ? (
                                            handleSetSpecialPermissions('FILE_REVIEW') ? (
                                                <Button
                                                    style={{}}
                                                    variant="contained"
                                                    type="submit"
                                                    size="small"
                                                    onClick={() =>
                                                        handleTaskValuesUpdate('accepted')
                                                    }
                                                >
                                                    complete
                                                </Button>
                                            ) : null
                                        ) : null}
                                    </div>
                                ) : null}
                                <p>
                                    {' '}
                                    {response.result.Data[value].ReviewerAcceptedTime !== null
                                        ? Moment(
                                              response.result.Data[value].ReviewerAcceptedTime,
                                          ).format('MM-DD-YYYY hh:mm A')
                                        : null}
                                </p>
                            </div>

                            <div>
                                <label>Estimate Reviewed</label>
                                {response.result.Data[value].EstimateReviewedTime === null &&
                                response.result.Data[value].ReviewerAcceptedTime !== null ? (
                                    <div>
                                        {handleSetSpecialPermissions('FILE_REVIEW') !==
                                        undefined ? (
                                            handleSetSpecialPermissions('FILE_REVIEW') ? (
                                                <Button
                                                    style={{}}
                                                    variant="contained"
                                                    type="submit"
                                                    size="small"
                                                    onClick={() =>
                                                        handleTaskValuesUpdate('reviewed')
                                                    }
                                                >
                                                    complete
                                                </Button>
                                            ) : null
                                        ) : null}
                                    </div>
                                ) : null}
                                <p>
                                    {' '}
                                    {response.result.Data[value].EstimateReviewedTime !== null
                                        ? Moment(
                                              response.result.Data[value].EstimateReviewedTime,
                                          ).format('MM-DD-YYYY hh:mm A')
                                        : null}
                                </p>
                            </div>

                            <div>
                                <label> QA Approved </label>
                                {response.result.Data[value].QAApprovedTime === null &&
                                response.result.Data[value].EstimateReviewedTime !== null &&
                                response.result.Data[value].FileClosedTime === null &&
                                response.result.Data[value].QARejectedTime === null ? (
                                    <div>
                                        {handleSetSpecialPermissions('FILE_REVIEW') !==
                                        undefined ? (
                                            handleSetSpecialPermissions('FILE_REVIEW') ? (
                                                <Button
                                                    style={{}}
                                                    variant="contained"
                                                    type="submit"
                                                    size="small"
                                                    onClick={() =>
                                                        handleTaskValuesUpdate('approved')
                                                    }
                                                >
                                                    complete
                                                </Button>
                                            ) : null
                                        ) : null}
                                    </div>
                                ) : null}
                                <p>
                                    {' '}
                                    {response.result.Data[value].QAApprovedTime !== null
                                        ? Moment(response.result.Data[value].QAApprovedTime).format(
                                              'MM-DD-YYYY hh:mm A',
                                          )
                                        : null}
                                </p>
                            </div>

                            <div>
                                <label> QA Rejected</label>
                                {response.result.Data[value].QARejectedTime === null &&
                                response.result.Data[value].EstimateReviewedTime !== null &&
                                response.result.Data[value].FileClosedTime === null ? (
                                    <div>
                                        {handleSetSpecialPermissions('FILE_REVIEW') !==
                                        undefined ? (
                                            handleSetSpecialPermissions('FILE_REVIEW') ? (
                                                <Button
                                                    style={{}}
                                                    variant="contained"
                                                    type="submit"
                                                    size="small"
                                                    onClick={() =>
                                                        handleTaskValuesUpdate('rejected')
                                                    }
                                                >
                                                    complete
                                                </Button>
                                            ) : null
                                        ) : null}
                                    </div>
                                ) : null}
                                <p>
                                    {' '}
                                    {response.result.Data[value].QARejectedTime !== null
                                        ? Moment(response.result.Data[value].QARejectedTime).format(
                                              'MM-DD-YYYY hh:mm A',
                                          )
                                        : null}
                                </p>
                            </div>

                            <div>
                                <label> File Closed </label>
                                <p>
                                    {' '}
                                    {response.result.Data[value].FileClosedTime !== null
                                        ? Moment(response.result.Data[value].FileClosedTime).format(
                                              'MM-DD-YYYY hh:mm A',
                                          )
                                        : null}
                                </p>
                            </div>
                        </>
                    ) : response.result.Data[value].ClaimTaskTypeCode === 'GENERAL_TASK' ? (
                        <></>
                    ) : (
                        <>
                            {cliamProfileDetails.EnableFileClose ? (
                                <></>
                            ) : // <Button
                            //     style={{
                            //         position: 'absolute',
                            //         right: '94px',
                            //         top: '9px',
                            //         boxShadow: 'none',
                            //     }}
                            //     variant="contained"
                            //     type="submit"
                            //     size="small"
                            //     onClick={() => {
                            //         setIsModalOpen(true);
                            //     }}
                            // >
                            //     Close Claim
                            // </Button>
                            null}
                            <div>
                                <label>Attempted Contact Times</label>
                                <ul className="row-status">
                                    {response.result.Data[value].ClaimTaskContactAttempts.length > 0
                                        ? response.result.Data[value].ClaimTaskContactAttempts.map(
                                              (data, i) => {
                                                  return (
                                                      <React.Fragment key={i}>
                                                          {data.IsContactSuccessful === true ? (
                                                              <>
                                                                  <li>{data.ContactAttempNotes}</li>
                                                                  <li>
                                                                      <i>
                                                                          {' '}
                                                                          {data.ContactedDate !==
                                                                          null
                                                                              ? Moment(
                                                                                    data.ContactedDate,
                                                                                ).format(
                                                                                    'MM-DD-YYYY hh:mm A',
                                                                                )
                                                                              : null}
                                                                          {' (Success)'}
                                                                      </i>
                                                                  </li>
                                                              </>
                                                          ) : (
                                                              <>
                                                                  {' '}
                                                                  <li>{data.ContactAttempNotes}</li>
                                                                  <li>
                                                                      <i>
                                                                          {' '}
                                                                          {data.ContactedDate !==
                                                                          null
                                                                              ? Moment(
                                                                                    data.ContactedDate,
                                                                                ).format(
                                                                                    'MM-DD-YYYY hh:mm A',
                                                                                )
                                                                              : null}
                                                                          {' (Failed)'}
                                                                      </i>
                                                                  </li>
                                                              </>
                                                          )}
                                                      </React.Fragment>
                                                      //   <React.Fragment key={i}>
                                                      //       <label>{data.ContactAttempNotes}</label>
                                                      //       <p>Success</p>
                                                      //   </React.Fragment>
                                                  );
                                              },
                                          )
                                        : null}
                                    {/* {response.result.Data[value].ClaimTaskContactAttempts.length > 0
                                        ? response.result.Data[
                                              value
                                          ].ClaimTaskContactAttempts.filter((data) => {
                                              return data.IsContactSuccessful === false;
                                          }).map((data, i) => {
                                              return (
                                                  <React.Fragment key={i}>
                                                      <li>{data.ContactAttempNotes}</li>
                                                      <li>
                                                          <i>
                                                              {' '}
                                                              {data.ContactedDate !== null
                                                                  ? Moment(
                                                                        data.ContactedDate,
                                                                    ).format('MM-DD-YYYY hh:mm A')
                                                                  : null}
                                                              {' (Failed)'}
                                                          </i>
                                                      </li>
                                                  </React.Fragment>
                                              );
                                          })
                                        : null} */}
                                </ul>
                            </div>
                            <div className="col2-grid-task">
                                {/* <div>
                                    {response.result.Data[value].ClaimTaskContactAttempts.length > 0
                                        ? response.result.Data[
                                              value
                                          ].ClaimTaskContactAttempts.filter((data) => {
                                              return data.IsContactSuccessful === true;
                                          }).map((data, i) => {
                                              return (
                                                  <React.Fragment key={i}>
                                                      <label>{data.ContactAttempNotes}</label>
                                                      <p>
                                                          {' '}
                                                          {data.ContactedDate !== null
                                                              ? Moment(data.ContactedDate).format(
                                                                    'MM-DD-YYYY hh:mm A',
                                                                )
                                                              : null}
                                                      </p>
                                                  </React.Fragment>
                                              );
                                          })
                                        : null}
                                </div> */}
                                <div>
                                    <label>Accepted Assignment</label>
                                    <p>
                                        {' '}
                                        {response.result.Data[value].TaskAcceptedDate !== null
                                            ? Moment(
                                                  response.result.Data[value].TaskAcceptedDate,
                                              ).format('MM-DD-YYYY hh:mm A')
                                            : null}
                                    </p>
                                </div>
                                <div></div>

                                <div>
                                    <div className="d-flex" style={{ alignItems: 'flex-start' }}>
                                        <div>
                                            <label>Reservation Start Time</label>
                                            <p>
                                                {response.result.Data[value].ReservationStart !==
                                                null
                                                    ? Moment(
                                                          response.result.Data[value]
                                                              .ReservationStart,
                                                      ).format('MM-DD-YYYY hh:mm A')
                                                    : null}
                                            </p>
                                        </div>
                                        {!response.result.Data[value]?.AssigneeId && (
                                            <button
                                                style={{
                                                    marginLeft: '10px',
                                                    cursor: 'pointer',
                                                    borderWidth: 0,
                                                    background: 'unset',
                                                }}
                                                onClick={() => {
                                                    setReservationDateEditModal(
                                                        !reservationDateEditModalOpen,
                                                    );
                                                    setSelectedReservationDate(
                                                        response.result.Data[value].ReservationStart
                                                            ? new Date(
                                                                  response.result.Data[
                                                                      value
                                                                  ].ReservationStart,
                                                              )
                                                            : null,
                                                    );
                                                }}
                                            >
                                                <img src={editIcon} title="Edit" />
                                            </button>
                                        )}
                                    </div>
                                </div>

                                <div>
                                    <label>Reservation End Time</label>
                                    <p>
                                        {response.result.Data[value].ReservationEnd !== null
                                            ? Moment(
                                                  response.result.Data[value].ReservationEnd,
                                              ).format('MM-DD-YYYY hh:mm A')
                                            : null}
                                    </p>
                                </div>

                                <div>
                                    <label>Promised Inspection Start Time</label>
                                    <p>
                                        {response.result.Data[value].PromisedInspectionStartTime !==
                                        null
                                            ? Moment(
                                                  response.result.Data[value]
                                                      .PromisedInspectionStartTime,
                                              ).format('MM-DD-YYYY hh:mm A')
                                            : null}
                                    </p>
                                </div>

                                <div>
                                    <label>Promised Inspection End Time</label>
                                    <p>
                                        {response.result.Data[value].PromisedInspectionEndTime !==
                                        null
                                            ? Moment(
                                                  response.result.Data[value]
                                                      .PromisedInspectionEndTime,
                                              ).format('MM-DD-YYYY hh:mm A')
                                            : null}
                                    </p>
                                </div>

                                <div>
                                    <label> Inspection Start Time</label>
                                    <p>
                                        {response.result.Data[value].InspectionActualStartTime !==
                                        null
                                            ? Moment(
                                                  response.result.Data[value]
                                                      .InspectionActualStartTime,
                                              ).format('MM-DD-YYYY hh:mm A')
                                            : null}
                                    </p>
                                </div>

                                <div>
                                    <label> Inspection Complete Time</label>
                                    <p>
                                        {' '}
                                        {response.result.Data[value].InspectionActualEndTime !==
                                        null
                                            ? Moment(
                                                  response.result.Data[value]
                                                      .InspectionActualEndTime,
                                              ).format('MM-DD-YYYY hh:mm A')
                                            : null}
                                    </p>
                                </div>

                                <div>
                                    <label>Estimate Upload Time</label>
                                    <p>
                                        {' '}
                                        {response.result.Data[value].EstimateUploadTime !== null
                                            ? Moment(
                                                  response.result.Data[value].EstimateUploadTime,
                                              ).format('MM-DD-YYYY hh:mm A')
                                            : null}
                                    </p>
                                </div>

                                {/* <div>
                                    <label>Confirmed Time</label>
                                    <p>
                                        {' '}
                                        {response.result.Data[value].ConfirmedTime !== null
                                            ? Moment(
                                                  response.result.Data[value].ConfirmedTime,
                                              ).format('MM-DD-YYYY hh:mm A')
                                            : null}
                                    </p>
                                </div> */}

                                {/* <div>
                                    <label>Xact Complete</label>
                                    <p>
                                        {' '}
                                        {response.result.Data[value].XactCompleteTime !== null
                                            ? Moment(
                                                  response.result.Data[value].XactCompleteTime,
                                              ).format('MM-DD-YYYY hh:mm A')
                                            : null}
                                    </p>
                                </div> */}
                            </div>
                        </>
                    )}
                </div>
                {/* Task SLA Start  */}
                {response.result.Data[value].ClaimTaskTypeCode === 'CLAIM_TASK' ? (
                    <>
                        <h3 className="d-flex tasksla-title">
                            Task SLA{' '}
                            {/* <a href="/" style={{ color: '#337dff' }} className="ml-auto">
                        View All
                    </a> */}
                        </h3>
                        <div>
                            <div className="task-sla-grid">
                                {/* Task Grid title row  */}
                                <div className="grid-row title-grid gray-text">
                                    <div>SLA Definition</div>
                                    <div>Status</div>
                                    <div>Time Remaining</div>
                                    <div>Breach Time</div>
                                </div>
                                {/* Task Grid title row end  */}
                                {/* Task Grid item row  */}
                                {slaTrackResult.result !== undefined
                                    ? slaTrackResult.result.Data !== null
                                        ? slaTrackResult.result.Data.map((data, index) => {
                                              return (
                                                  <div className="grid-row">
                                                      <div>
                                                          <i
                                                              className={
                                                                  data.Status === 'Breached'
                                                                      ? 'red-circle'
                                                                      : data.Status ===
                                                                        'On Schedule'
                                                                      ? 'green-circle'
                                                                      : 'yellow-circle'
                                                              }
                                                          />
                                                          {data.SLAName}
                                                      </div>

                                                      <div> {data.Status}</div>
                                                      <div> {data.TimeLeft}</div>
                                                      <div>
                                                          {data.BreachedTime &&
                                                              Moment(data.BreachedTime).format(
                                                                  'MM-DD-YYYY hh:mm A',
                                                              )}{' '}
                                                      </div>
                                                  </div>
                                              );
                                          })
                                        : null
                                    : null}

                                {/* Task Grid item row end */}
                                {/* Task Grid item row start  */}
                                {/* <div className="grid-row">
                            <div>
                                <i className="green-circle" />
                                Contact Insured{' '}
                            </div>
                            <div>Complete</div>
                            <div>--</div>
                            <div>05-06-22 02:30 PM</div>
                        </div> */}
                                {/* Task Grid item row end */}
                                {/* Task Grid item row  */}

                                {/* Task Grid item row  end*/}
                            </div>
                        </div>
                        {/* Task SLA Start  */}
                    </>
                ) : null}
            </div>
        </>
    );
};

export default ClaimProfileTaskDetails;
ClaimProfileTaskDetails.propTypes = {
    stateValue: PropTypes.any,
    cliamProfileDetails: PropTypes.any.isRequired,
    response: PropTypes.any,
    setIsClaimTaskOpen: PropTypes.func,
    reload: PropTypes.func,
    isClaimTaskOpen: PropTypes.bool,
    handleRefreshTask: PropTypes.func,
    fileReviewer: PropTypes.any,
    handleRefreshNotes: PropTypes.any,
};
