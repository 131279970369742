import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import { AppUrls } from '../../core/route.url';
import { usePostData } from '../../hooks/use-post-data.hook';
import { Button, Alert, CircularProgress } from '@mui/material';
import PropTypes from 'prop-types';

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

const RolesPermissionList = (props) => {
    const { rolePermissionList } = props;
    const [tableRow, setTableRow] = React.useState([]);
    const [savedResponse, setSaveResponse] = React.useState({});

    let parrentArray = [];
    const [isLoad, setIsLoad] = React.useState(false);
    const [rolesPermissionList, setRolesPermissonList] = React.useState(
        rolePermissionList.UserRoleMenuPermissions !== undefined
            ? rolePermissionList.UserRoleMenuPermissions
            : [],
    );

    React.useEffect(() => {
        setIsLoad(true);
    }, []);

    React.useEffect(() => {
        if (isLoad) {
            setIsLoad(false);
            permissionList();
        }
    }, [isLoad]);

    const { submitData, response } = usePostData(AppUrls.SaveUserRole, 'POST');

    React.useEffect(() => {
        if (response.result != undefined) {
            setSaveResponse(response.result);

            const timer = setTimeout(() => {
                setSaveResponse({});
            }, 3000);
            return () => clearTimeout(timer);
        }
    }, [response]);

    const handleSaveClick = async () => {
        submitData({
            IsActive: true,
            RoleId: rolePermissionList.RoleId === undefined ? 0 : rolePermissionList.RoleId,
            RoleName: rolePermissionList.RoleName === undefined ? 0 : rolePermissionList.RoleName,
            RoleCode: rolePermissionList.RoleCode === undefined ? 0 : rolePermissionList.RoleCode,
            UserRoleMenuPermissions: rolesPermissionList,
            UserRoleMenuSpecialPermissions: [
                {
                    RoleSpecialPerMapId: 0,
                    RoleId: 0,
                    SpecialPermissionId: 0,
                    HasAccess: true,
                    MenuId: 0,
                    MenuName: '',
                    PermissionCode: '',
                    PermissionDesc: '',
                },
            ],
        });
    };

    const subMenuList = (menuId, menuName) => {
        rolesPermissionList
            .filter((data) => data.ParentMenu === menuId)
            .forEach((element) => {
                parrentArray.push(
                    <TableRow
                        key={element.MenuId}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        className="subTitle"
                    >
                        <TableCell component="th" scope="row">
                            {element.MenuName}
                        </TableCell>
                        <TableCell align="right">
                            <Checkbox
                                onClick={(e) =>
                                    subMenuChange(e, element.MenuId, element.ParentMenu)
                                }
                                checked={element.IsView}
                                {...label}
                                value={1}
                            />
                        </TableCell>
                        <TableCell align="right">
                            <Checkbox
                                onClick={(e) =>
                                    subMenuChange(e, element.MenuId, element.ParentMenu)
                                }
                                checked={element.IsAdd}
                                {...label}
                                value={2}
                            />
                        </TableCell>
                        <TableCell align="right">
                            <Checkbox
                                onClick={(e) =>
                                    subMenuChange(e, element.MenuId, element.ParentMenu)
                                }
                                checked={element.IsEdit}
                                {...label}
                                value={3}
                            />
                        </TableCell>
                        <TableCell align="right">
                            <Checkbox
                                onClick={(e) =>
                                    subMenuChange(e, element.MenuId, element.ParentMenu)
                                }
                                checked={element.IsDelete}
                                {...label}
                                value={4}
                            />
                        </TableCell>
                        <TableCell align="right">
                            <Checkbox
                                onClick={(e) =>
                                    subMenuChange(e, element.MenuId, element.ParentMenu)
                                }
                                checked={element.IsPrint}
                                {...label}
                                value={5}
                            />
                        </TableCell>
                    </TableRow>,
                );
                subMenuList(element.MenuId, element.MenuName);
            });
    };

    const permissionList = () => {
        if (rolesPermissionList.length > 0) {
            setTableRow([]);

            parrentArray.splice(0, parrentArray.length);

            rolesPermissionList
                .filter((data) => data.ParentMenu === 0)
                .forEach((element) => {
                    parrentArray.push(
                        <TableRow
                            key={element.MenuId}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            className="mainTitle"
                        >
                            <TableCell component="th" scope="row">
                                {element.MenuName}
                            </TableCell>
                            <TableCell align="right">
                                <Checkbox
                                    value={1}
                                    onClick={(e) => constParentChange(e, element.MenuId)}
                                    checked={element.IsView ? true : false}
                                    // defaultchecked={element.IsView}
                                    {...label}
                                />
                            </TableCell>
                            <TableCell align="right">
                                <Checkbox
                                    value={2}
                                    onChange={(e) => constParentChange(e, element.MenuId)}
                                    checked={element.IsAdd}
                                    {...label}
                                />
                            </TableCell>
                            <TableCell align="right">
                                <Checkbox
                                    value={3}
                                    onChange={(e) => constParentChange(e, element.MenuId)}
                                    checked={element.IsEdit}
                                    {...label}
                                />
                            </TableCell>
                            <TableCell align="right">
                                <Checkbox
                                    value={4}
                                    onChange={(e) => constParentChange(e, element.MenuId)}
                                    checked={element.IsDelete}
                                    {...label}
                                />
                            </TableCell>
                            <TableCell align="right">
                                <Checkbox
                                    value={5}
                                    onChange={(e) => constParentChange(e, element.MenuId)}
                                    checked={element.IsPrint}
                                    {...label}
                                />
                            </TableCell>
                        </TableRow>,
                    );
                    subMenuList(element.MenuId, element.MenuName);
                });

            setTableRow([]);
            setTableRow(parrentArray);
            // parrentArray.splice(0, parrentArray0)
        }
    };

    ////console.log(88, tableRow);

    const constParentChange = async (e, menuId) => {
        if (1) {
            switch (parseInt(e.target.value)) {
                case 1:
                    var index = rolesPermissionList.findIndex((obj) => obj.MenuId === menuId);
                    if (e.target.checked) {
                        rolesPermissionList[index].IsView = e.target.checked;

                        setRolesPermissonList(rolesPermissionList);

                        rolesPermissionList
                            .filter((data) => data.ParentMenu === menuId)
                            .forEach((element) => {
                                element.IsView = e.target.checked;
                                // element.IsAdd = false;
                                // element.IsEdit = false;
                                // element.IsDelete = false;
                                // element.IsPrint = false;
                            });
                    } else {
                        rolesPermissionList[index].IsView = e.target.checked;
                        rolesPermissionList[index].IsAdd = false;
                        rolesPermissionList[index].IsEdit = false;
                        rolesPermissionList[index].IsDelete = false;
                        rolesPermissionList[index].IsPrint = false;
                        rolesPermissionList
                            .filter((data) => data.ParentMenu === menuId)
                            .forEach((element) => {
                                element.IsView = e.target.checked;
                                element.IsAdd = false;
                                element.IsEdit = false;
                                element.IsDelete = false;
                                element.IsPrint = false;
                            });
                    }

                    setRolesPermissonList([]);
                    setRolesPermissonList(rolesPermissionList);
                    setIsLoad(true);
                    return;
                case 2:
                    var index = rolesPermissionList.findIndex((obj) => obj.MenuId === menuId);

                    if (e.target.checked) {
                        rolesPermissionList[index].IsView = e.target.checked;
                        rolesPermissionList[index].IsAdd = e.target.checked;

                        setRolesPermissonList(rolesPermissionList);

                        rolesPermissionList
                            .filter((data) => data.ParentMenu === menuId)
                            .forEach((element) => {
                                element.IsView = e.target.checked;
                                element.IsAdd = e.target.checked;
                            });
                    } else {
                        rolesPermissionList[index].IsAdd = e.target.checked;
                        rolesPermissionList[index].IsEdit = false;
                        rolesPermissionList[index].IsDelete = false;
                        rolesPermissionList[index].IsPrint = false;
                        rolesPermissionList
                            .filter((data) => data.ParentMenu === menuId)
                            .forEach((element) => {
                                element.IsAdd = e.target.checked;
                                element.IsEdit = false;
                                element.IsDelete = false;
                                element.IsPrint = false;
                            });
                    }
                    //console.log(2, rolesPermissionList);
                    setRolesPermissonList([]);
                    setRolesPermissonList(rolesPermissionList);
                    setIsLoad(true);
                    return;
                case 3:
                    //console.log("inner", 3);
                    var index = rolesPermissionList.findIndex((obj) => obj.MenuId === menuId);
                    //console.log("inner", index);
                    if (e.target.checked) {
                        rolesPermissionList[index].IsView = e.target.checked;
                        rolesPermissionList[index].IsAdd = e.target.checked;
                        rolesPermissionList[index].IsEdit = e.target.checked;
                        setRolesPermissonList(rolesPermissionList);
                        // ////console.log(rolesPermissionList);
                        rolesPermissionList
                            .filter((data) => data.ParentMenu === menuId)
                            .forEach((element) => {
                                element.IsView = e.target.checked;
                                element.IsAdd = e.target.checked;
                                element.IsEdit = e.target.checked;
                            });
                    } else {
                        rolesPermissionList[index].IsEdit = e.target.checked;
                        rolesPermissionList[index].IsDelete = false;
                        rolesPermissionList[index].IsPrint = false;
                        rolesPermissionList
                            .filter((data) => data.ParentMenu === menuId)
                            .forEach((element) => {
                                element.IsEdit = e.target.checked;
                                element.IsDelete = false;
                                element.IsPrint = false;
                            });
                    }
                    //console.log(2, rolesPermissionList);
                    setRolesPermissonList([]);
                    setRolesPermissonList(rolesPermissionList);
                    setIsLoad(true);
                    return;
                case 4:
                    //console.log("inner", 4);
                    var index = rolesPermissionList.findIndex((obj) => obj.MenuId === menuId);
                    //console.log("inner", index);
                    if (e.target.checked) {
                        rolesPermissionList[index].IsView = e.target.checked;
                        rolesPermissionList[index].IsAdd = e.target.checked;
                        rolesPermissionList[index].IsEdit = e.target.checked;
                        rolesPermissionList[index].IsDelete = e.target.checked;
                        setRolesPermissonList(rolesPermissionList);
                        // ////console.log(rolesPermissionList);
                        rolesPermissionList
                            .filter((data) => data.ParentMenu === menuId)
                            .forEach((element) => {
                                element.IsView = e.target.checked;
                                element.IsAdd = e.target.checked;
                                element.IsEdit = e.target.checked;
                                element.IsDelete = e.target.checked;
                            });
                    } else {
                        rolesPermissionList[index].IsDelete = e.target.checked;
                        rolesPermissionList[index].IsPrint = false;
                        rolesPermissionList
                            .filter((data) => data.ParentMenu === menuId)
                            .forEach((element) => {
                                element.IsDelete = e.target.checked;
                                element.IsPrint = false;
                            });
                    }
                    //console.log(2, rolesPermissionList);
                    setRolesPermissonList([]);
                    setRolesPermissonList(rolesPermissionList);
                    setIsLoad(true);
                    return;
                case 5:
                    //console.log("inner", 5);
                    var index = rolesPermissionList.findIndex((obj) => obj.MenuId === menuId);
                    //console.log("inner", index);
                    if (e.target.checked) {
                        rolesPermissionList[index].IsPrint = e.target.checked;
                        setRolesPermissonList(rolesPermissionList);
                        // ////console.log(rolesPermissionList);
                        rolesPermissionList
                            .filter((data) => data.ParentMenu === menuId)
                            .forEach((element) => {
                                element.IsPrint = e.target.checked;
                            });
                    } else {
                        rolesPermissionList[index].IsPrint = e.target.checked;
                        rolesPermissionList
                            .filter((data) => data.ParentMenu === menuId)
                            .forEach((element) => {
                                element.IsPrint = e.target.checked;
                            });
                    }
                    //console.log(2, rolesPermissionList);
                    setRolesPermissonList([]);
                    setRolesPermissonList(rolesPermissionList);
                    setIsLoad(true);
                    return;
                default:
                    return 'foo';
            }
        }
    };

    // submenu function

    const subMenuChange = async (e, menuId, parentId) => {
        //console.log(55, e.target.value);
        if (1) {
            switch (parseInt(e.target.value)) {
                case 1:
                    //console.log("inner", 1);
                    var index = rolesPermissionList.findIndex((obj) => obj.MenuId === menuId);

                    if (e.target.checked) {
                        rolesPermissionList[index].IsView = e.target.checked;
                        rolesPermissionList
                            .filter((data) => data.ParentMenu === menuId)
                            .forEach((element) => {
                                element.IsView = e.target.checked;
                            });
                    } else {
                        ////console.log(index);
                        rolesPermissionList[index].IsView = e.target.checked;
                        rolesPermissionList[index].IsAdd = false;
                        rolesPermissionList[index].IsEdit = false;
                        rolesPermissionList[index].IsDelete = false;
                        rolesPermissionList[index].IsPrint = false;
                        setRolesPermissonList(rolesPermissionList);
                        // ////console.log(rolesPermissionList);
                        rolesPermissionList
                            .filter((data) => data.ParentMenu === menuId)
                            .forEach((element) => {
                                element.IsView = e.target.checked;
                                element.IsAdd = false;
                                element.IsEdit = false;
                                element.IsDelete = false;
                                element.IsPrint = false;
                            });
                    }
                    //console.log(2, rolesPermissionList);
                    setRolesPermissonList([]);
                    reverseCheckOrUncheckParent(
                        e,
                        menuId,
                        parentId,
                        rolesPermissionList[index].MenuId,
                    );
                    setRolesPermissonList(rolesPermissionList);
                    setIsLoad(true);
                    return;
                case 2:
                    //console.log("inner", 2);
                    var index = rolesPermissionList.findIndex((obj) => obj.MenuId === menuId);
                    //console.log("inner", index);
                    if (e.target.checked) {
                        rolesPermissionList[index].IsView = e.target.checked;
                        rolesPermissionList[index].IsAdd = e.target.checked;
                        rolesPermissionList
                            .filter((data) => data.ParentMenu === menuId)
                            .forEach((element) => {
                                element.IsView = e.target.checked;
                                element.IsAdd = e.target.checked;
                            });
                    } else {
                        rolesPermissionList[index].IsAdd = e.target.checked;
                        rolesPermissionList[index].IsEdit = false;
                        rolesPermissionList[index].IsDelete = false;
                        rolesPermissionList[index].IsPrint = false;
                        setRolesPermissonList(rolesPermissionList);
                        // ////console.log(rolesPermissionList);
                        rolesPermissionList
                            .filter((data) => data.ParentMenu === menuId)
                            .forEach((element) => {
                                element.IsView = e.target.checked;
                                element.IsAdd = e.target.checked;
                                element.IsEdit = false;
                                element.IsDelete = false;
                                element.IsPrint = false;
                            });
                    }
                    //console.log(2, rolesPermissionList);
                    setRolesPermissonList([]);
                    reverseCheckOrUncheckParent(
                        e,
                        menuId,
                        parentId,
                        rolesPermissionList[index].MenuId,
                    );
                    setRolesPermissonList(rolesPermissionList);
                    setIsLoad(true);
                    return;
                case 3:
                    //console.log("inner", 3);
                    var index = rolesPermissionList.findIndex((obj) => obj.MenuId === menuId);
                    //console.log("inner", index);
                    if (e.target.checked) {
                        rolesPermissionList[index].IsView = e.target.checked;
                        rolesPermissionList[index].IsAdd = e.target.checked;
                        rolesPermissionList[index].IsEdit = e.target.checked;
                        rolesPermissionList
                            .filter((data) => data.ParentMenu === menuId)
                            .forEach((element) => {
                                element.IsView = e.target.checked;
                                element.IsAdd = e.target.checked;
                                element.IsEdit = e.target.checked;
                            });
                    } else {
                        rolesPermissionList[index].IsEdit = e.target.checked;
                        rolesPermissionList[index].IsDelete = false;
                        rolesPermissionList[index].IsPrint = false;
                        setRolesPermissonList(rolesPermissionList);
                        // ////console.log(rolesPermissionList);
                        rolesPermissionList
                            .filter((data) => data.ParentMenu === menuId)
                            .forEach((element) => {
                                element.IsView = e.target.checked;
                                element.IsAdd = e.target.checked;
                                element.IsEdit = e.target.checked;
                                element.IsDelete = false;
                                element.IsPrint = false;
                            });
                    }
                    //console.log(2, rolesPermissionList);
                    setRolesPermissonList([]);
                    reverseCheckOrUncheckParent(
                        e,
                        menuId,
                        parentId,
                        rolesPermissionList[index].MenuId,
                    );
                    setRolesPermissonList(rolesPermissionList);
                    setIsLoad(true);
                    return;
                case 4:
                    //console.log("inner", 4);
                    var index = rolesPermissionList.findIndex((obj) => obj.MenuId === menuId);
                    //console.log("inner", index);
                    if (e.target.checked) {
                        rolesPermissionList[index].IsView = e.target.checked;
                        rolesPermissionList[index].IsAdd = e.target.checked;
                        rolesPermissionList[index].IsEdit = e.target.checked;
                        rolesPermissionList[index].IsDelete = e.target.checked;
                        rolesPermissionList
                            .filter((data) => data.ParentMenu === menuId)
                            .forEach((element) => {
                                element.IsView = e.target.checked;
                                element.IsAdd = e.target.checked;
                                element.IsEdit = e.target.checked;
                                element.IsDelete = e.target.checked;
                            });
                    } else {
                        rolesPermissionList[index].IsDelete = e.target.checked;
                        rolesPermissionList[index].IsPrint = false;
                        setRolesPermissonList(rolesPermissionList);
                        // ////console.log(rolesPermissionList);
                        rolesPermissionList
                            .filter((data) => data.ParentMenu === menuId)
                            .forEach((element) => {
                                element.IsView = e.target.checked;
                                element.IsAdd = e.target.checked;
                                element.IsEdit = e.target.checked;
                                element.IsDelete = e.target.checked;
                                element.IsPrint = false;
                            });
                    }
                    //console.log(2, rolesPermissionList);
                    setRolesPermissonList([]);
                    reverseCheckOrUncheckParent(
                        e,
                        menuId,
                        parentId,
                        rolesPermissionList[index].MenuId,
                    );
                    setRolesPermissonList(rolesPermissionList);
                    setIsLoad(true);
                    return;
                case 5:
                    //console.log("inner", 5);
                    var index = rolesPermissionList.findIndex((obj) => obj.MenuId === menuId);
                    //console.log("inner", index);
                    if (e.target.checked) {
                        rolesPermissionList[index].IsPrint = e.target.checked;
                        rolesPermissionList
                            .filter((data) => data.ParentMenu === menuId)
                            .forEach((element) => {
                                element.IsPrint = e.target.checked;
                            });
                    } else {
                        rolesPermissionList[index].IsPrint = e.target.checked;
                        setRolesPermissonList(rolesPermissionList);
                        // ////console.log(rolesPermissionList);
                        rolesPermissionList
                            .filter((data) => data.ParentMenu === menuId)
                            .forEach((element) => {
                                element.IsPrint = e.target.checked;
                            });
                    }
                    //console.log(2, rolesPermissionList);
                    setRolesPermissonList([]);
                    reverseCheckOrUncheckParent(
                        e,
                        menuId,
                        parentId,
                        rolesPermissionList[index].MenuId,
                    );
                    setRolesPermissonList(rolesPermissionList);
                    setIsLoad(true);
                    return;
                default:
                    return 'foo';
            }
        }
    };

    // for click submenu and uncheck parent

    // const reverseCheckOrUncheckParent = async (e, menuId, parentId, currentMenuId) => {
    //     switch (parseInt(e.target.value)) {
    //         case 1:
    //             var index = rolesPermissionList
    //                 .filter((data) => data.ParentMenu === 0)
    //                 .findIndex((obj) => obj.MenuId === parentId);
    //             // console.log(1000, index);
    //             // console.log(
    //             //     1001,
    //             //     rolesPermissionList
    //             //         .filter((data) => data.ParentMenu === rolesPermissionList[index].MenuId)
    //             //         .filter((data) => data.MenuId !== currentMenuId),
    //             // );
    //             if (e.target.checked) {
    //                 if (
    //                     rolesPermissionList
    //                         .filter((data) => data.ParentMenu === rolesPermissionList[index].MenuId)
    //                         .filter((data) => data.MenuId !== currentMenuId).length > 0
    //                 ) {
    //                     alternative(e, menuId, parentId, currentMenuId, index);
    //                 } else {
    //                     rolesPermissionList[index].IsView = e.target.checked;
    //                     rolesPermissionList[index].IsAdd = false;
    //                     rolesPermissionList[index].IsEdit = false;
    //                     rolesPermissionList[index].IsDelete = false;
    //                     rolesPermissionList[index].IsPrint = false;
    //                 }
    //             } else {
    //                 //console.log(1005, ",--------");
    //                 rolesPermissionList[index].IsView = false;
    //                 rolesPermissionList[index].IsAdd = false;
    //                 rolesPermissionList[index].IsEdit = false;
    //                 rolesPermissionList[index].IsDelete = false;
    //                 rolesPermissionList[index].IsPrint = false;
    //             }

    //             return;
    //         case 2:
    //             var index = rolesPermissionList
    //                 .filter((data) => data.ParentMenu === 0)
    //                 .findIndex((obj) => obj.MenuId === parentId);
    //             //console.log(1000, index);

    //             if (e.target.checked) {
    //                 if (
    //                     rolesPermissionList
    //                         .filter((data) => data.ParentMenu === rolesPermissionList[index].MenuId)
    //                         .filter((data) => data.MenuId !== currentMenuId).length > 0
    //                 ) {
    //                     alternative(e, menuId, parentId, currentMenuId, index);

    //                     // }
    //                 } else {
    //                     rolesPermissionList[index].IsView = e.target.checked;
    //                     rolesPermissionList[index].IsAdd = e.target.checked;
    //                     rolesPermissionList[index].IsEdit = false;
    //                     rolesPermissionList[index].IsDelete = false;
    //                     rolesPermissionList[index].IsPrint = false;
    //                 }
    //             } else {
    //                 //console.log(1005, ",--------");

    //                 rolesPermissionList[index].IsAdd = false;
    //                 rolesPermissionList[index].IsEdit = false;
    //                 rolesPermissionList[index].IsDelete = false;
    //                 rolesPermissionList[index].IsPrint = false;
    //             }

    //             return;
    //         case 3:
    //             var index = rolesPermissionList
    //                 .filter((data) => data.ParentMenu === 0)
    //                 .findIndex((obj) => obj.MenuId === parentId);
    //             //console.log(1000, index);

    //             if (e.target.checked) {
    //                 if (
    //                     rolesPermissionList
    //                         .filter((data) => data.ParentMenu === rolesPermissionList[index].MenuId)
    //                         .filter((data) => data.MenuId !== currentMenuId).length > 0
    //                 ) {
    //                     alternative(e, menuId, parentId, currentMenuId, index);

    //                     // }
    //                 } else {
    //                     rolesPermissionList[index].IsView = e.target.checked;
    //                     rolesPermissionList[index].IsAdd = e.target.checked;
    //                     rolesPermissionList[index].IsEdit = e.target.checked;
    //                     rolesPermissionList[index].IsDelete = false;
    //                     rolesPermissionList[index].IsPrint = false;
    //                 }
    //             } else {
    //                 //console.log(1005, ",--------");

    //                 rolesPermissionList[index].IsEdit = false;
    //                 rolesPermissionList[index].IsDelete = false;
    //                 rolesPermissionList[index].IsPrint = false;
    //             }

    //             return;
    //         case 4:
    //             var index = rolesPermissionList
    //                 .filter((data) => data.ParentMenu === 0)
    //                 .findIndex((obj) => obj.MenuId === parentId);
    //             //console.log(1000, index);

    //             if (e.target.checked) {
    //                 if (
    //                     rolesPermissionList
    //                         .filter((data) => data.ParentMenu === rolesPermissionList[index].MenuId)
    //                         .filter((data) => data.MenuId !== currentMenuId).length > 0
    //                 ) {
    //                     alternative(e, menuId, parentId, currentMenuId, index);

    //                     // }
    //                 } else {
    //                     rolesPermissionList[index].IsView = e.target.checked;
    //                     rolesPermissionList[index].IsAdd = e.target.checked;
    //                     rolesPermissionList[index].IsEdit = e.target.checked;
    //                     rolesPermissionList[index].IsDelete = e.target.checked;
    //                     rolesPermissionList[index].IsPrint = false;
    //                 }
    //             } else {
    //                 //console.log(1005, ",--------");

    //                 rolesPermissionList[index].IsDelete = false;
    //                 rolesPermissionList[index].IsPrint = false;
    //             }

    //             return;
    //         case 5:
    //             var index = rolesPermissionList
    //                 .filter((data) => data.ParentMenu === 0)
    //                 .findIndex((obj) => obj.MenuId === parentId);
    //             //console.log(1000, index);

    //             if (e.target.checked) {
    //                 if (
    //                     rolesPermissionList
    //                         .filter((data) => data.ParentMenu === rolesPermissionList[index].MenuId)
    //                         .filter((data) => data.MenuId !== currentMenuId).length > 0
    //                 ) {
    //                     alternative(e, menuId, parentId, currentMenuId, index);

    //                     // }
    //                 } else {
    //                     rolesPermissionList[index].IsPrint = e.target.checked;
    //                 }
    //             } else {
    //                 //console.log(1005, ",--------");

    //                 rolesPermissionList[index].IsPrint = false;
    //             }

    //             return;
    //     }
    // };

    const reverseCheckOrUncheckParent = async (e, menuId, parentId, currentMenuId) => {
        switch (parseInt(e.target.value)) {
            case 1:
                var index = rolesPermissionList
                    .filter((data) => data.ParentMenu === 0)
                    .findIndex((obj) => obj.MenuId === parentId);

                if (e.target.checked) {
                    if (
                        rolesPermissionList
                            .filter((data) => data.ParentMenu === rolesPermissionList[index].MenuId)
                            .filter((data) => data.MenuId !== currentMenuId).length > 0
                    ) {
                        // alternative(e, menuId, parentId, currentMenuId, index);
                        rolesPermissionList[index].IsView = e.target.checked;
                        // rolesPermissionList[index].IsAdd = false;
                        // rolesPermissionList[index].IsEdit = false;
                        // rolesPermissionList[index].IsDelete = false;
                        // rolesPermissionList[index].IsPrint = false;
                    } else {
                        rolesPermissionList[index].IsView = e.target.checked;
                        rolesPermissionList[index].IsAdd = false;
                        rolesPermissionList[index].IsEdit = false;
                        rolesPermissionList[index].IsDelete = false;
                        rolesPermissionList[index].IsPrint = false;
                    }
                } else {
                    //console.log(1005, ",--------");
                    // rolesPermissionList[index].IsView = false;
                    // rolesPermissionList[index].IsAdd = false;
                    // rolesPermissionList[index].IsEdit = false;
                    // rolesPermissionList[index].IsDelete = false;
                    // rolesPermissionList[index].IsPrint = false;
                    alternative(e, menuId, parentId, currentMenuId, index);
                }

                return;
            case 2:
                var index = rolesPermissionList
                    .filter((data) => data.ParentMenu === 0)
                    .findIndex((obj) => obj.MenuId === parentId);
                //console.log(1000, index);

                if (e.target.checked) {
                    if (
                        rolesPermissionList
                            .filter((data) => data.ParentMenu === rolesPermissionList[index].MenuId)
                            .filter((data) => data.MenuId !== currentMenuId).length > 0
                    ) {
                        rolesPermissionList[index].IsView = e.target.checked;
                        rolesPermissionList[index].IsAdd = e.target.checked;
                        // rolesPermissionList[index].IsEdit = false;
                        // rolesPermissionList[index].IsDelete = false;
                        // rolesPermissionList[index].IsPrint = false;
                        // }
                    } else {
                        rolesPermissionList[index].IsView = e.target.checked;
                        rolesPermissionList[index].IsAdd = e.target.checked;
                        rolesPermissionList[index].IsEdit = false;
                        rolesPermissionList[index].IsDelete = false;
                        rolesPermissionList[index].IsPrint = false;
                    }
                } else {
                    //console.log(1005, ",--------");

                    alternative(e, menuId, parentId, currentMenuId, index);
                }

                return;
            case 3:
                var index = rolesPermissionList
                    .filter((data) => data.ParentMenu === 0)
                    .findIndex((obj) => obj.MenuId === parentId);
                //console.log(1000, index);

                if (e.target.checked) {
                    if (
                        rolesPermissionList
                            .filter((data) => data.ParentMenu === rolesPermissionList[index].MenuId)
                            .filter((data) => data.MenuId !== currentMenuId).length > 0
                    ) {
                        rolesPermissionList[index].IsView = e.target.checked;
                        rolesPermissionList[index].IsAdd = e.target.checked;
                        rolesPermissionList[index].IsEdit = e.target.checked;
                        // rolesPermissionList[index].IsDelete = false;
                        // rolesPermissionList[index].IsPrint = false;
                        // }
                    } else {
                        rolesPermissionList[index].IsView = e.target.checked;
                        rolesPermissionList[index].IsAdd = e.target.checked;
                        rolesPermissionList[index].IsEdit = e.target.checked;
                        rolesPermissionList[index].IsDelete = false;
                        rolesPermissionList[index].IsPrint = false;
                    }
                } else {
                    //console.log(1005, ",--------");
                    alternative(e, menuId, parentId, currentMenuId, index);
                    // rolesPermissionList[index].IsEdit = false;
                    // rolesPermissionList[index].IsDelete = false;
                    // rolesPermissionList[index].IsPrint = false;
                }

                return;
            case 4:
                var index = rolesPermissionList
                    .filter((data) => data.ParentMenu === 0)
                    .findIndex((obj) => obj.MenuId === parentId);
                //console.log(1000, index);

                if (e.target.checked) {
                    if (
                        rolesPermissionList
                            .filter((data) => data.ParentMenu === rolesPermissionList[index].MenuId)
                            .filter((data) => data.MenuId !== currentMenuId).length > 0
                    ) {
                        rolesPermissionList[index].IsView = e.target.checked;
                        rolesPermissionList[index].IsAdd = e.target.checked;
                        rolesPermissionList[index].IsEdit = e.target.checked;
                        rolesPermissionList[index].IsDelete = e.target.checked;
                        // rolesPermissionList[index].IsPrint = false;
                        // }
                    } else {
                        rolesPermissionList[index].IsView = e.target.checked;
                        rolesPermissionList[index].IsAdd = e.target.checked;
                        rolesPermissionList[index].IsEdit = e.target.checked;
                        rolesPermissionList[index].IsDelete = e.target.checked;
                        rolesPermissionList[index].IsPrint = false;
                    }
                } else {
                    //console.log(1005, ",--------");
                    alternative(e, menuId, parentId, currentMenuId, index);
                    // rolesPermissionList[index].IsDelete = false;
                    // rolesPermissionList[index].IsPrint = false;
                }

                return;
            case 5:
                var index = rolesPermissionList
                    .filter((data) => data.ParentMenu === 0)
                    .findIndex((obj) => obj.MenuId === parentId);
                //console.log(1000, index);

                if (e.target.checked) {
                    if (
                        rolesPermissionList
                            .filter((data) => data.ParentMenu === rolesPermissionList[index].MenuId)
                            .filter((data) => data.MenuId !== currentMenuId).length > 0
                    ) {
                        rolesPermissionList[index].IsPrint = e.target.checked;
                        // }
                    } else {
                        rolesPermissionList[index].IsPrint = e.target.checked;
                    }
                } else {
                    //console.log(1005, ",--------");
                    alternative(e, menuId, parentId, currentMenuId, index);
                    // rolesPermissionList[index].IsPrint = false;
                }

                return;
        }
    };

    // for alternative check

    const alternative = (e, menuId, parentId, currentMenuId, index) => {
        switch (parseInt(e.target.value)) {
            case 1:
                if (
                    rolesPermissionList
                        .filter((data) => data.ParentMenu === rolesPermissionList[index].MenuId)
                        .filter((data) => data.MenuId !== currentMenuId && data.IsView === false)
                        .length ===
                    rolesPermissionList
                        .filter((data) => data.ParentMenu === rolesPermissionList[index].MenuId)
                        .filter((data) => data.MenuId !== currentMenuId).length
                ) {
                    rolesPermissionList[index].IsView = false;
                    rolesPermissionList[index].IsAdd = false;
                    rolesPermissionList[index].IsEdit = false;
                    rolesPermissionList[index].IsDelete = false;
                    rolesPermissionList[index].IsPrint = false;
                } else {
                    rolesPermissionList[index].IsView = true;
                    handleParentCheckOrUnCheckerBasedOnSubMenu(currentMenuId, index);
                    // rolesPermissionList[index].IsAdd = false;
                    // rolesPermissionList[index].IsEdit = false;
                    // rolesPermissionList[index].IsDelete = false;
                    // rolesPermissionList[index].IsPrint = false;
                }
                return;
            case 2:
                if (
                    rolesPermissionList
                        .filter((data) => data.ParentMenu === rolesPermissionList[index].MenuId)
                        .filter((data) => data.MenuId !== currentMenuId && data.IsAdd === false)
                        .length ===
                    rolesPermissionList
                        .filter((data) => data.ParentMenu === rolesPermissionList[index].MenuId)
                        .filter((data) => data.MenuId !== currentMenuId).length
                ) {
                    rolesPermissionList[index].IsAdd = false;
                    rolesPermissionList[index].IsEdit = false;
                    rolesPermissionList[index].IsDelete = false;
                    rolesPermissionList[index].IsPrint = false;
                } else {
                    rolesPermissionList[index].IsAdd = true;
                    rolesPermissionList[index].IsView = true;
                    // rolesPermissionList[index].IsEdit = false;
                    // rolesPermissionList[index].IsDelete = false;
                    // rolesPermissionList[index].IsPrint = false;
                    handleParentCheckOrUnCheckerBasedOnSubMenu(currentMenuId, index);
                }

                return;
            case 3:
                if (
                    rolesPermissionList
                        .filter((data) => data.ParentMenu === rolesPermissionList[index].MenuId)
                        .filter((data) => data.MenuId !== currentMenuId && data.IsEdit === false)
                        .length ===
                    rolesPermissionList
                        .filter((data) => data.ParentMenu === rolesPermissionList[index].MenuId)
                        .filter((data) => data.MenuId !== currentMenuId).length
                ) {
                    rolesPermissionList[index].IsEdit = false;
                    rolesPermissionList[index].IsDelete = false;
                    rolesPermissionList[index].IsPrint = false;
                } else {
                    rolesPermissionList[index].IsAdd = true;
                    rolesPermissionList[index].IsView = true;
                    rolesPermissionList[index].IsEdit = true;
                    // rolesPermissionList[index].IsDelete = false;
                    // rolesPermissionList[index].IsPrint = false;
                    handleParentCheckOrUnCheckerBasedOnSubMenu(currentMenuId, index);
                }

                return;
            case 4:
                if (
                    rolesPermissionList
                        .filter((data) => data.ParentMenu === rolesPermissionList[index].MenuId)
                        .filter((data) => data.MenuId !== currentMenuId && data.IsDelete === false)
                        .length ===
                    rolesPermissionList
                        .filter((data) => data.ParentMenu === rolesPermissionList[index].MenuId)
                        .filter((data) => data.MenuId !== currentMenuId).length
                ) {
                    rolesPermissionList[index].IsDelete = false;
                    rolesPermissionList[index].IsPrint = false;
                } else {
                    rolesPermissionList[index].IsAdd = true;
                    rolesPermissionList[index].IsView = true;
                    rolesPermissionList[index].IsEdit = true;
                    rolesPermissionList[index].IsDelete = true;
                    handleParentCheckOrUnCheckerBasedOnSubMenu(currentMenuId, index);
                    rolesPermissionList[index].IsPrint = false;
                }

                return;
            case 5:
                if (
                    rolesPermissionList
                        .filter((data) => data.ParentMenu === rolesPermissionList[index].MenuId)
                        .filter((data) => data.MenuId !== currentMenuId && data.IsPrint === false)
                        .length ===
                    rolesPermissionList
                        .filter((data) => data.ParentMenu === rolesPermissionList[index].MenuId)
                        .filter((data) => data.MenuId !== currentMenuId).length
                ) {
                    rolesPermissionList[index].IsPrint = false;
                } else {
                    rolesPermissionList[index].IsPrint = true;
                }

                return;
        }
    };

    const handleParentCheckOrUnCheckerBasedOnSubMenu = (currentMenuId, index) => {
        if (
            rolesPermissionList
                .filter((data) => data.ParentMenu === rolesPermissionList[index].MenuId)
                .filter((data) => data.MenuId !== currentMenuId && data.IsView === false).length ===
            rolesPermissionList
                .filter((data) => data.ParentMenu === rolesPermissionList[index].MenuId)
                .filter((data) => data.MenuId !== currentMenuId).length
        ) {
            rolesPermissionList[index].IsView = false;
        }

        if (
            rolesPermissionList
                .filter((data) => data.ParentMenu === rolesPermissionList[index].MenuId)
                .filter((data) => data.MenuId !== currentMenuId && data.IsAdd === false).length ===
            rolesPermissionList
                .filter((data) => data.ParentMenu === rolesPermissionList[index].MenuId)
                .filter((data) => data.MenuId !== currentMenuId).length
        ) {
            rolesPermissionList[index].IsAdd = false;
        }
        if (
            rolesPermissionList
                .filter((data) => data.ParentMenu === rolesPermissionList[index].MenuId)
                .filter((data) => data.MenuId !== currentMenuId && data.IsEdit === false).length ===
            rolesPermissionList
                .filter((data) => data.ParentMenu === rolesPermissionList[index].MenuId)
                .filter((data) => data.MenuId !== currentMenuId).length
        ) {
            rolesPermissionList[index].IsEdit = false;
        }
        if (
            rolesPermissionList
                .filter((data) => data.ParentMenu === rolesPermissionList[index].MenuId)
                .filter((data) => data.MenuId !== currentMenuId && data.IsDelete === false)
                .length ===
            rolesPermissionList
                .filter((data) => data.ParentMenu === rolesPermissionList[index].MenuId)
                .filter((data) => data.MenuId !== currentMenuId).length
        ) {
            rolesPermissionList[index].IsDelete = false;
        }
    };

    return (
        <>
            <div className="grid-cover">
                {rolePermissionList.RoleId !== undefined ? (
                    <TableContainer component={Paper} elevation={1}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table" size="small">
                            <TableHead>
                                <TableRow className="titleTable">
                                    <TableCell>Menu Name</TableCell>
                                    <TableCell align="right">View</TableCell>
                                    <TableCell align="right">Add&nbsp;</TableCell>
                                    <TableCell align="right">Edit&nbsp;</TableCell>
                                    <TableCell align="right">Delete&nbsp;</TableCell>
                                    <TableCell align="right">Print&nbsp;</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {tableRow !== undefined || tableRow.length > 0
                                    ? tableRow.map((data, index) => {
                                          return data;
                                      })
                                    : null}
                            </TableBody>
                        </Table>
                    </TableContainer>
                ) : (
                    <Box sx={{ width: '100%' }}>
                        <LinearProgress />
                    </Box>
                )}
            </div>
            <div className="d-flex">
                <Button
                    className="saveBtn"
                    variant="contained"
                    size="large"
                    onClick={() => handleSaveClick()}
                >
                    {response.isSubmitting ? <CircularProgress color="inherit" /> : 'Save'}
                </Button>{' '}
                {savedResponse.IsSuccess != undefined ? (
                    <Alert
                        style={{ marginTop: 15 }}
                        severity={savedResponse.IsSuccess ? 'success' : 'error'}
                    >
                        {savedResponse.IsSuccess ? 'Role Saved Successfully' : 'Error'}
                    </Alert>
                ) : null}
            </div>
        </>
    );
};

export default RolesPermissionList;
RolesPermissionList.propTypes = {
    rolePermissionList: PropTypes.array.isRequired,
};
