import React, { useEffect } from 'react';
import { useLoadData2 } from '../../hooks/use-load-data-2.hook';
import { beginAuth } from '../../utils/oidcApi';
import jwt_decode from 'jwt-decode';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { parse } from 'query-string';
import { nanoid } from 'nanoid';
import { LinearProgress } from '@mui/material';
import { usePostData } from '../../hooks/use-post-data.hook';
import { useStorage } from '../../hooks/use-storage';
import { AppUrls } from '../../core/route.url';
import { usePostDataForOneLogin } from '../../hooks/use-post-data-2.hook';
const OneLogin = () => {
    const searchParams = useLocation();
    const history = useNavigate();
    const [authToken, setToken] = useStorage('AUTH_STATE', null);
    // const { submitData: submitDatatemp, response: responsetemp } = usePostData(AppUrls.Login);
    const { submitData: submitDatatemp, response: responsetemp } = usePostDataForOneLogin(
        AppUrls.Login,
    );

    useEffect(() => {
        if (searchParams.hash === '') {
            const state = nanoid(32);
            const nonce = nanoid(32);
            // if (location.pathname !== '/login') {

            beginAuth({ state, nonce });
            // }
        }
    }, []);

    useEffect(() => {
        if (searchParams.hash !== '') {
            if (jwt_decode(searchParams.hash).email !== undefined) {
                // submitDatatemp({ Email: jwt_decode(searchParams.hash).preferred_username });
                // submitDatatemp(JSON.stringify(searchParams.hash));
                submitDatatemp({}, JSON.stringify(searchParams.hash));
            }
        }
    }, [searchParams.hash]);

    useEffect(() => {
        if (responsetemp.result != undefined) {
            history('/');
            setToken(responsetemp.result.Token);
        } else if (responsetemp.error !== undefined) {
            history('/', {
                state: {
                    error: 'InvalidUser  ',
                },
            });
        }
    }, [responsetemp]);

    return (
        <div>
            <LinearProgress />{' '}
        </div>
    );
};

export default OneLogin;
