import React from 'react';
import PropTypes from 'prop-types';
import { usePostData2 } from '../../hooks/use-post-data-2.hook';
import { usePostData } from '../../hooks/use-post-data.hook';
import { AppUrls } from '../../core/route.url';
import { useEffect } from 'react';
import { Grid, GridColumn, GridToolbar } from '@progress/kendo-react-grid';
import { LinearProgress, TextField } from '@mui/material';
import { groupBy, process } from '@progress/kendo-data-query';
import { useState } from 'react';
import moment from 'moment';
import { setExpandedState } from '@progress/kendo-react-data-tools';
import { decodeHtml } from '../../utils/global-functions';

const REPLACE_REGEX = /\s/g;
const DEFAULT_PAGE = 100;

const Messages = (props) => {
    const { userId } = props || {};
    const { submitData, response } = usePostData2(AppUrls.GetMessagesByUser, {
        userId,
    });

    const MessageList = ({ messageList }) => {
        const [searchValue, setSearchValue] = useState('');

        const createDataStateMessages = (dataState) => {
            return {
                result: process(messageList?.slice(0), dataState),
                dataState: dataState,
            };
        };
        const [initialStateMessages, setInitialStateMessages] = React.useState(
            createDataStateMessages({
                take: DEFAULT_PAGE,
                skip: 0,
            }),
        );
        const [resultMessages, setResultMessages] = React.useState(initialStateMessages.result);
        React.useEffect(() => {
            setInitialStateMessages(
                createDataStateMessages({
                    take: DEFAULT_PAGE,
                    skip: 0,
                }),
            );
        }, [messageList]);

        React.useEffect(() => {
            setResultMessages(initialStateMessages.result);
        }, [initialStateMessages]);

        const [dataStateMessages, setDataStateMessages] = React.useState(
            initialStateMessages.dataState,
        );

        const dataStateMessagesChange = (event) => {
            let updatedState = createDataStateMessages(event.dataState);
            setResultMessages(updatedState.result);
            setDataStateMessages(updatedState.dataState);
        };

        const hasSearchKeyExist = (messageItem) => {
            return (
                messageItem.ClaimNumber?.toLowerCase()
                    .replace(REPLACE_REGEX, '')
                    .includes(searchValue?.toLocaleLowerCase().replace(REPLACE_REGEX, '')) ||
                messageItem.CreatedOn?.toLowerCase()
                    .replace(REPLACE_REGEX, '')
                    .includes(searchValue?.toLocaleLowerCase().replace(REPLACE_REGEX, '')) ||
                messageItem.MsgTypeName?.toLowerCase()
                    .replace(REPLACE_REGEX, '')
                    .includes(searchValue?.toLocaleLowerCase().replace(REPLACE_REGEX, '')) ||
                messageItem.MsgBody?.toLowerCase()
                    .replace(REPLACE_REGEX, '')
                    .includes(searchValue?.toLocaleLowerCase().replace(REPLACE_REGEX, ''))
            );
        };

        const filterList = (list = []) => {
            return list?.filter((messageItem) => hasSearchKeyExist(messageItem));
        };

        const filterGroup = (array, fn) => {
            return array.reduce((r, o) => {
                var children = filterGroup(o.items || [], fn);
                if (fn(o) || children.length)
                    r.push(Object.assign({}, o, children.length && { children }));
                return r;
            }, []);
        };

        const getFilteredList = (data = { data: [], total: 0 }) => {
            let filteredData =
                data?.data?.length && data?.data[0]?.hasOwnProperty('MsgId')
                    ? filterList(data?.data)
                    : filterGroup(data?.data ?? [], (messageItem) =>
                          hasSearchKeyExist(messageItem),
                      );
            return {
                data: filteredData,
                total: data?.total,
            };
        };

        const htmlDecodeCell = (props) => {
            return <div>{decodeHtml(props?.dataItem?.MsgBody)}</div>;
        };

        return (
            <>
                {dataStateMessages != undefined ? (
                    <>
                        <Grid
                            data={getFilteredList(resultMessages)}
                            {...dataStateMessages}
                            style={{
                                maxHeight: 'calc(100vh - 131px)',
                            }}
                            onDataStateChange={dataStateMessagesChange}
                            sortable={true}
                            pageable={{
                                buttonCount: 5,
                                info: true,
                                type: 'numeric',
                                pageSizes: [25, 50, 100, 200],
                                previousNext: true,
                            }}
                            groupable={true}
                            pageSize={DEFAULT_PAGE}
                            total={resultMessages.total}
                            selectable={{
                                enabled: true,
                                drag: false,
                                cell: false,
                                mode: 'multiple',
                            }}
                        >
                            <GridToolbar>
                                <div className="search-wrap">
                                    <div style={{ paddingLeft: 3 }}>
                                        <TextField
                                            required
                                            id="outlined-required"
                                            label=""
                                            defaultValue=""
                                            size="small"
                                            onChange={(e) => setSearchValue(e.target.value)}
                                            value={searchValue}
                                        />
                                    </div>
                                </div>
                            </GridToolbar>
                            <GridColumn groupable field="ClaimNumber" title="Claim number" />
                            <GridColumn groupable format={'{0:D}'} field="SendOn" title="Date" />
                            <GridColumn groupable field="MsgTypeName" title="Type" />
                            <GridColumn
                                cell={htmlDecodeCell}
                                groupable
                                field="MsgBody"
                                title="Message"
                            />
                        </Grid>
                    </>
                ) : (
                    <>
                        <LinearProgress></LinearProgress>
                    </>
                )}
            </>
        );
    };

    const [messageList, setMessageList] = useState([]);
    React.useEffect(() => {
        if (response.result != undefined) {
            if (response.result.IsSuccess) {
                // to format the date
                response.result.Data.forEach((o) => {
                    o.SendOn = moment(o.SendOn).format('MM-DD-YYYY hh:mm A');
                });
                setMessageList(response.result.Data);
            }
        }
    }, [response]);

    return (
        <div>
            {response.result != undefined ? (
                <MessageList messageList={messageList} />
            ) : (
                <LinearProgress></LinearProgress>
            )}
        </div>
    );
};

Messages.propTypes = {
    userId: PropTypes.string,
};

export default Messages;
