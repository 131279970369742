import React from 'react';
import PropTypes from 'prop-types';
import { Box, LinearProgress } from '@mui/material';

import { AppUrls } from '../../core/route.url';

const TaskSummary = ({
    filterList,
    OnHandleChange,
    OnHandleRiskBreachChange,
    taskType,
    dashboardStyle,
    isLoadingData
}) => {
    const [active, setActive] = React.useState();

    /*const { reload, result } = useLoadData2();

    React.useEffect(() => {
        if (isLoadingData) {
            reload(AppUrls.TaskStatusCountList, '');
        }
    }, [isLoadingData]);*/

    if (filterList === undefined) {
        return (
            <Box sx={{ width: '100%' }}>
                <LinearProgress />
            </Box>
        );
    }

    const handleChangeValue = (value, count, index) => {
        OnHandleChange(value, count, active === index ? 0 : 1);

        setActive(active === index ? '' : index);
    };

    const handleChangeRiskBreach = (mainvalue, subvalue, count) => {
        OnHandleRiskBreachChange(mainvalue, subvalue, count);
    };
    return (
        <>
            {' '}
            <section className="claim-dashboard-header">
                <div className={dashboardStyle}>
                    {/* list */}
                    {filterList.length > 0
                        ? filterList.map((data, index) => {
                              return (
                                  <div className={`block-list ${active === index ? 'active' : ''}`}>
                                      <div
                                          onClick={() =>
                                              handleChangeValue(data.Name, data.Count, index)
                                          }
                                          style={{ cursor: 'pointer' }}
                                      >
                                          <h3>{data.Count}</h3>
                                          <label>{data.Name}</label>
                                      </div>
                                      {taskType === 'CLAIM_TASK' && (
                                          <div>
                                              <div
                                                  className="yellow-stat"
                                                  onClick={() =>
                                                      handleChangeRiskBreach(
                                                          data.Name,
                                                          'Risk',
                                                          data.RiskCount,
                                                      )
                                                  }
                                                  style={{ cursor: 'pointer' }}
                                              >
                                                  {data.RiskCount}
                                              </div>
                                              <div
                                                  className="red-stat"
                                                  onClick={() =>
                                                      handleChangeRiskBreach(
                                                          data.Name,
                                                          'Breached',
                                                          data.BreachedCount,
                                                      )
                                                  }
                                                  style={{ cursor: 'pointer' }}
                                              >
                                                  {data.BreachedCount}
                                              </div>
                                          </div>
                                      )}
                                  </div>
                              );
                          })
                        : null}

                    {/* list */}
                </div>
            </section>
        </>
    );
};

export default TaskSummary;
TaskSummary.propTypes = {
    filterList: PropTypes.array,
    OnHandleChange: PropTypes.func.isRequired,
    OnHandleRiskBreachChange: PropTypes.func.isRequired,
    taskType: PropTypes.any.isRequired,
    dashboardStyle: PropTypes.any.isRequired,
    isLoadingData: PropTypes.bool
};
