import { Marker } from '@react-google-maps/api';
import { useState } from 'react';
export const MapMarkerComponent = ({
    index,
    position,
    clusterer,
    markerType,
    color,
    isSelected,
    id,
    dataType,
    data,
    getMarker,
    handleMarkerClick,
    showMarkerInfo,
}) => {
    const [infoPointer, setInfoPointer] = useState(null);

    return (
        <>
            <Marker
                key={index}
                position={position}
                clusterer={clusterer}
                icon={getMarker(markerType, color, isSelected)}
                onClick={() => handleMarkerClick(id, dataType, data)}
                onMouseOver={() => {
                    setInfoPointer(
                        setTimeout(() => {
                            showMarkerInfo(position, dataType, data);
                        }, 600),
                    );
                }}
                onMouseOut={() => {
                    if (infoPointer) {
                        clearTimeout(infoPointer);
                        setInfoPointer(null);
                    }
                }}
            />
        </>
    );
};
