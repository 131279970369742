import React from 'react';
import PropTypes from 'prop-types';
import { Alert, Box, Button, CircularProgress, Modal, TextField } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { Divider } from '@mui/material';
import { useForm } from 'react-hook-form';
import { AppUrls } from '../../core/route.url';
import { usePostData } from '../../hooks/use-post-data.hook';
import useUserPermissions from '../../hooks/use-user-permissions.hook';
import { useNavigate } from 'react-router-dom';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 520,
    bgcolor: 'background.paper',
    boxShadow: 3,
    p: 3,
};
const ClaimCancelDelete = ({
    cliamProfileDetails,
    reload,
    handleReloadStatus,
    handleRefreshTask,
    handleRefreshNotes,
    handleCloseModal,
    setLoadStatus,
}) => {
    const {
        register,
        handleSubmit,
        control,
        setValue,
        watch,
        formState: { errors },
    } = useForm();

    const watchAllFields = watch();
    const [isDialogOpen, setIsDailogOpen] = React.useState(false);
    const [dailogValue, setDialogValue] = React.useState(0);
    const [savedResponse, setSaveResponse] = React.useState({});
    const [isOpen, setIsOpen] = React.useState(false);
    const { handleSetSpecialPermissions } = useUserPermissions();
    const { submitData, response } = usePostData(AppUrls.CancelClaimOrDelete, 'POST');
    const history = useNavigate();
    React.useEffect(() => {
        if (response.result != undefined) {
            setSaveResponse(response.result);
            reload();
            handleRefreshTask();
            handleReloadStatus();
            handleRefreshNotes();
            setLoadStatus(true);
            const timer = setTimeout(() => {
                setSaveResponse({});
                handleCloseModal();
                if (dailogValue === 1) {
                    history('/');
                }

                setValue('reason', '');

                setIsOpen(false);
            }, 2000);
            return () => clearTimeout(timer);
        }
    }, [response]);

    const handleClose = () => {
        setIsOpen(true);
    };

    const handleConForm = () => {
        setIsDailogOpen(false);
        setIsOpen(true);
    };

    const handleCancelButtonClick = () => {
        setIsOpen(true);
        setDialogValue(0);
    };

    const handleDeleteButtonClick = () => {
        setIsOpen(true);
        setDialogValue(1);
    };

    const onSubmit = async (data) => {
        submitData({
            ClaimGuID:
                cliamProfileDetails != undefined && cliamProfileDetails !== null
                    ? cliamProfileDetails.ClaimGuID
                    : '',
            CancelReason: data.reason,
            IsDelete: dailogValue === 0 ? false : true,
        });
    };

    return (
        <>
            <Modal
                open={isOpen}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div>
                        <h2 style={{ marginBottom: 20 }}>
                            {dailogValue === 0 ? 'Cancel Claim' : 'Delete Claim'}
                        </h2>
                        <Box
                            component="form"
                            sx={{
                                '& .MuiTextField-root': { m: 1, width: '53ch' },
                            }}
                            noValidate
                            autoComplete="off"
                            onSubmit={handleSubmit(onSubmit)}
                        >
                            <div>
                                {/* <FormControl sx={{ m: 1, width: '53ch' }}> */}
                                {dailogValue === 0 ? (
                                    <>
                                        <p style={{ marginBottom: 15, display: 'block' }}>
                                            Clicking Cancel will cancel this claim in Claims Command
                                            and move it to the Cancelled state. If you wish to
                                            proceed, please enter the reason below and click Cancel
                                            Claim.
                                        </p>
                                    </>
                                ) : (
                                    <>
                                        <p style={{ marginBottom: 15, display: 'block' }}>
                                            Clicking Delete will delete this claim from Claims
                                            Command and hide the claim and associated tasks from all
                                            views. If you wish to proceed, please enter the reason
                                            below and click Delete Claim.
                                        </p>
                                    </>
                                )}
                                <TextField
                                    style={{ padding: 0, width: '100%', margin: 0 }}
                                    error={errors.reason}
                                    id="outlined-multiline-static"
                                    label="Reason"
                                    fullWidth
                                    multiline
                                    rows={8}
                                    defaultValue=""
                                    InputLabelProps={{
                                        shrink: watchAllFields.reason === '' ? false : true,
                                    }}
                                    {...register('reason', { required: true })}
                                />
                                {/* </FormControl> */}
                            </div>
                            <div className="d-flex" style={{ marginTop: 20 }}>
                                <Button
                                    style={{ marginLeft: 2 }}
                                    onClick={() => {
                                        setIsOpen(false);
                                        setValue('noteBody', '');
                                    }}
                                    variant="outlined"
                                    type="button"
                                >
                                    Cancel
                                </Button>

                                <Button
                                    style={{ marginLeft: 'auto' }}
                                    variant="contained"
                                    type="submit"
                                >
                                    {response.isSubmitting ? (
                                        <CircularProgress color="inherit" />
                                    ) : dailogValue === 0 ? (
                                        'Cancel Claim'
                                    ) : (
                                        'Delete Claim'
                                    )}
                                </Button>
                            </div>
                        </Box>
                        {savedResponse.IsSuccess != undefined ? (
                            <Alert
                                style={{ marginTop: 15 }}
                                severity={savedResponse.IsSuccess ? 'success' : 'error'}
                            >
                                {savedResponse.Message}
                            </Alert>
                        ) : null}
                    </div>
                </Box>
            </Modal>
            {/* <div>
                <Dialog
                    open={isDialogOpen}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    {/* <DialogTitle id="alert-dialog-title">
          {"Use Google's location service?"}
        </DialogTitle> */}
            {/* <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Do you want to {dailogValue === 0 ? 'cancel' : 'delete'}{' '}
                            {cliamProfileDetails != undefined && cliamProfileDetails !== null
                                ? cliamProfileDetails.ClaimNumber
                                : null}
                        </DialogContentText>
                    </DialogContent>
                    <Divider />
                    <DialogActions>
                        <Button onClick={handleClose}>No</Button>
                        <Button onClick={handleConForm} autoFocus>
                            Yes
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>   */}
            {cliamProfileDetails != undefined && cliamProfileDetails !== null ? (
                cliamProfileDetails.ClaimStatusCode === 'CANCEL' ? null : (
                    <>
                        {handleSetSpecialPermissions('CAN_CLM') !== undefined ? (
                            handleSetSpecialPermissions('CAN_CLM') ? (
                                <Button
                                    variant="contained"
                                    style={{ marginLeft: 674 }}
                                    onClick={handleCancelButtonClick}
                                >
                                    Cancel Claim
                                </Button>
                            ) : null
                        ) : null}
                        {handleSetSpecialPermissions('DEL_CLM') !== undefined ? (
                            handleSetSpecialPermissions('DEL_CLM') ? (
                                <Button
                                    variant="contained"
                                    style={{ marginLeft: 'auto' }}
                                    onClick={handleDeleteButtonClick}
                                >
                                    Delete Claim
                                </Button>
                            ) : null
                        ) : null}
                    </>
                )
            ) : null}
        </>
    );
};
export default ClaimCancelDelete;
ClaimCancelDelete.propTypes = {
    cliamProfileDetails: PropTypes.any.isRequired,
    reload: PropTypes.func.isRequired,
    handleReloadStatus: PropTypes.func.isRequired,
    handleRefreshTask: PropTypes.func.isRequired,
    handleRefreshNotes: PropTypes.func.isRequired,
    handleCloseModal: PropTypes.func.isRequired,
    setLoadStatus: PropTypes.func,
};
