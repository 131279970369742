import React from 'react';
import PropTypes from 'prop-types';
import CustomSavedQueryList from './custom-saved-query-list';
import { AppUrls } from '../../core/route.url';
import { useLoadData } from '../../hooks/use-load-data.hook';

const CustomSaveQuery = (props) => {
    const {
        isLoading: Loading,
        result: customQueryList,
        isError: Error,
    } = useLoadData(AppUrls.GetAllCustomQueryList);
    const [filterList, setFilterList] = React.useState([]);
    // console.log(119, customQueryList);

    React.useEffect(() => {
        if (customQueryList !== undefined) {
            setFilterList(customQueryList.Data);
        }
    }, [customQueryList]);

    const displayList = () => {
        if (filterList.length > 0) {
            return <CustomSavedQueryList filterList={filterList} />;
        } else {
            return <CustomSavedQueryList filterList={[]} />;
        }
    };

    return <div>{displayList()}</div>;
};
export default CustomSaveQuery;
CustomSaveQuery.propTypes = {};
