import React from 'react';
import check from './../../images/check.svg';
import claimType from './../../images/claim-type.svg';
import startDate from './../../images/start-date.svg';
import endDate from './../../images/end-date.svg';
import close from './../../images/close.svg';
import pencilIcon from './../../images/pencil.svg';
import trashIcon from './../../images/trash.svg';
import { AppUrls } from '../../core/route.url';
import PropTypes from 'prop-types';
import Moment from 'moment';
import LinearProgress from '@mui/material/LinearProgress';
import { usePostData2 } from '../../hooks/use-post-data-2.hook';
import { useParams } from 'react-router-dom';
import { Button, Modal, Box, Typography, TextField } from '@mui/material';
import ClaimProfileTaskForm from './claim-profile-task-form';
import { decryptWithAES } from '../../utils/global-functions';
import { Tooltip } from '@progress/kendo-react-tooltip';
import useUserPermissions from '../../hooks/use-user-permissions.hook';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 480,
    bgcolor: 'background.paper',
    boxShadow: 3,
    p: 3,
};

const ClaimProfileTaskTab = ({
    cliamProfileDetails,
    handleRefreshCount,
    handleRefreshTask,
    response,
}) => {
    // let { id } = useParams();
    let { id: param } = useParams();
    let decryptedParam = decryptWithAES(param?.replace(/%2F/g, '/'));
    let parsedParam = JSON.parse(decryptedParam);
    const id = parsedParam?.state?.guId;
    const [isOpen, setIsOpen] = React.useState(false);
    const [idValue, setIdValue] = React.useState('');
    const [filterList, setFilterList] = React.useState([]);
    const [isLoad, setIsLoad] = React.useState(true);
    const [initialResult, setInitialResult] = React.useState([]);
    const { handleSetAddPermissions } = useUserPermissions();
    // const { response, submitData } = usePostData2(AppUrls.ClaimTaskList, {
    //     PageNo: 1,
    //     PageSize: 200,
    //     SortDir: 'Ascending',
    //     SortExp: '',
    //     StartDate: null,
    //     EndDate: null,
    //     ClaimTaskNumber: '',
    //     // ClaimId: cliamProfileDetails.ClaimId,
    //     ClaimGuID: id,
    // });

    React.useEffect(() => {
        if (response.result !== undefined && isLoad) {
            setIsLoad(false);
            setFilterList(response.result.Data !== null ? response.result.Data : []);
            setInitialResult(response.result.Data !== null ? response.result.Data : []);
        }
    }, [response]);

    if (response.result === undefined) {
        return (
            <Box sx={{ width: '100%' }}>
                <LinearProgress />
            </Box>
        );
    }

    const reloadTakData = () => {
        // submitData({
        //     PageNo: 1,
        //     PageSize: 200,
        //     SortDir: 'Ascending',
        //     SortExp: '',
        //     StartDate: null,
        //     EndDate: null,
        //     ClaimTaskNumber: '',
        //     // ClaimId: cliamProfileDetails.ClaimId,
        //     ClaimGuID: id,
        //     ClaimTaskTypeCode: '',
        // });
    };

    const handleClose = () => {
        setIsOpen(false);
        setIdValue('');
    };

    const handleChangeValue = (e) => {
        setFilterList(
            initialResult.filter((data) => {
                return (
                    (data.ClaimTaskNumber &&
                        data.ClaimTaskNumber.toLowerCase().includes(
                            e.target.value.toLocaleLowerCase(),
                        )) ||
                    (data.AssigneeName &&
                        data.AssigneeName.toLowerCase().includes(
                            e.target.value.toLocaleLowerCase(),
                        )) ||
                    (data.ClaimTaskTypeName &&
                        data.ClaimTaskTypeName.toLowerCase().includes(
                            e.target.value.toLocaleLowerCase(),
                        )) ||
                    (data.AddedUserEmail &&
                        data.AddedUserEmail.toLowerCase().includes(
                            e.target.value.toLocaleLowerCase(),
                        )) ||
                    (data.ShortDescription &&
                        data.ShortDescription.toLowerCase().includes(
                            e.target.value.toLocaleLowerCase(),
                        )) ||
                    (data.ClaimTaskStatusName &&
                        data.ClaimTaskStatusName.toLowerCase().includes(
                            e.target.value.toLocaleLowerCase(),
                        )) ||
                    (data.GenTaskStartTime &&
                        Moment(data.GenTaskStartTime)
                            .format('MM-DD-YYYY hh:mm A')
                            .toLowerCase()
                            .includes(e.target.value.toLocaleLowerCase())) ||
                    (data.AppointmentCreatedOn &&
                        Moment(data.AppointmentCreatedOn)
                            .format('MM-DD-YYYY hh:mm A')
                            .toLowerCase()
                            .includes(e.target.value.toLocaleLowerCase())) ||
                    (data.DueDate &&
                        Moment(data.DueDate)
                            .format('MM-DD-YYYY hh:mm A')
                            .toLowerCase()
                            .includes(e.target.value.toLocaleLowerCase()))
                );
            }),
        );
    };

    return (
        <div style={{ position: 'relative' }}>
            {' '}
            <Modal
                open={isOpen}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <ClaimProfileTaskForm
                        idValue={idValue}
                        taskDetails={
                            response.result.Data !== null && idValue !== ''
                                ? response.result.Data.length > 0
                                    ? response.result.Data.filter((data, i) => {
                                          return data.ClaimTaskGuID === idValue;
                                      })[0]
                                    : 0
                                : 0
                        }
                        reloadTakData={reloadTakData}
                        setIsOpen={setIsOpen}
                        cliamProfileDetails={cliamProfileDetails}
                        handleRefreshCount={handleRefreshCount}
                        handleRefreshTask={handleRefreshTask}
                    />
                </Box>
            </Modal>
            <Box style={{ position: 'absolute', right: '0', top: '-66px' }}>
                {
                    <TextField
                        id="outlined-multiline-static"
                        defaultValue=""
                        onChange={(e) => handleChangeValue(e)}
                        placeholder="Search"
                        className="searchinput"
                        size="small"
                        style={{ position: 'relative', right: 15, top: '-1px' }}
                    />
                }
                {handleSetAddPermissions('Claims') ? (
                    <Button
                        style={{ boxShadow: 'none' }}
                        size="small"
                        variant="contained"
                        type="button"
                        onClick={() => {
                            setIsOpen(true);
                            setIdValue('');
                            // history("/inspection/add", { state: "0" });
                        }}
                    >
                        Add New
                    </Button>
                ) : null}
            </Box>
            {filterList !== null ? (
                filterList.length > 0 ? (
                    filterList.map((data, index) => {
                        return (
                            <div className="tasks" key={index}>
                                {/* Tabbed task list start */}
                                <div className="tasks-card">
                                    <div>
                                        <div className="d-flex relative">
                                            <div>
                                                <i className="green-circle" />
                                            </div>
                                            <div>
                                                <h4>{data.ClaimTaskNumber}</h4>
                                                <p className="gray-text">{data.AssigneeName}</p>
                                                <img
                                                    alt=" "
                                                    src={check}
                                                    className="task-status-mark"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div
                                            className="task-processs"
                                            style={{ alignItems: 'center' }}
                                        >
                                            <div>
                                                <Tooltip
                                                    openDelay={100}
                                                    position="top"
                                                    anchorElement="target"
                                                >
                                                    <i>
                                                        <img
                                                            src={claimType}
                                                            title="Claim Type"
                                                            alt=""
                                                        />
                                                    </i>
                                                    {data.ClaimTaskTypeName}{' '}
                                                </Tooltip>
                                            </div>
                                            <div>
                                                <Tooltip
                                                    openDelay={100}
                                                    position="top"
                                                    anchorElement="target"
                                                >
                                                    <i>
                                                        <img
                                                            src={startDate}
                                                            alt=""
                                                            title="Start Date"
                                                        />
                                                    </i>
                                                    {data.ClaimTaskTypeId === 2
                                                        ? data.GenTaskStartTime &&
                                                          Moment(data.GenTaskStartTime).format(
                                                              'MM-DD-YYYY',
                                                          )
                                                        : data.AppointmentCreatedOn &&
                                                          Moment(data.AppointmentCreatedOn).format(
                                                              'MM-DD-YYYY',
                                                          )}
                                                </Tooltip>
                                            </div>

                                            {data.ClaimTaskTypeName === 'Base claim task' ? null : (
                                                <div>
                                                    {data.DueDate && (
                                                        <Tooltip
                                                            openDelay={100}
                                                            position="top"
                                                            anchorElement="target"
                                                        >
                                                            <i>
                                                                <img
                                                                    src={endDate}
                                                                    alt=""
                                                                    title="Due Date"
                                                                />
                                                            </i>
                                                            {data.DueDate &&
                                                                Moment(data.DueDate).format(
                                                                    'MM-DD-YYYY',
                                                                )}
                                                        </Tooltip>
                                                    )}
                                                </div>
                                            )}

                                            <div>
                                                <p className="decliner">{data.ShortDescription}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div
                                            className="d-flex gutter-1"
                                            style={{ justifyContent: 'right' }}
                                        >
                                            <div>
                                                <p className="no-wrap">
                                                    {data.ClaimTaskStatusName}
                                                </p>
                                                <div className="progress">
                                                    <div style={{ width: '20%' }} />
                                                </div>
                                            </div>
                                            {data.ClaimTaskTypeId === 2 ? (
                                                <button
                                                    className="icons"
                                                    onClick={() => {
                                                        setIdValue(data.ClaimTaskGuID);
                                                        setIsOpen(true);
                                                    }}
                                                >
                                                    <img alt=" " src={pencilIcon} />
                                                </button>
                                            ) : null}
                                            {/* <button className="icons">
                                                <img alt=" " src={pencilIcon} />
                                            </button>
                                            <button className="icons">
                                                <img alt=" " src={trashIcon} />
                                            </button> */}
                                        </div>
                                    </div>
                                </div>
                                {/* Tabbed task list end */}
                            </div>
                        );
                    })
                ) : (
                    <Box sx={{ width: '100%' }}>
                        <LinearProgress />
                    </Box>
                )
            ) : (
                'No Records'
            )}
        </div>
    );
};

export default ClaimProfileTaskTab;
ClaimProfileTaskTab.propTypes = {
    cliamProfileDetails: PropTypes.any.isRequired,
    handleRefreshCount: PropTypes.func,
    handleRefreshTask: PropTypes.func,
    response: PropTypes.any,
};
