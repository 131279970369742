import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash/isEmpty';
import { useLocation, useNavigate } from 'react-router-dom';
import { parse } from 'query-string';
import jwtDecode from 'jwt-decode';
import { LoadingIndicator } from '../../components/loading-indicator.component';
import { usePostData2 } from '../../hooks/use-post-data-2.hook';
import { AppUrls } from '../../core/route.url';
import { useState } from 'react';
import { useStorage } from '../../hooks/use-storage';
import { beginAuth } from '../../utils/oidcApi';
import { nanoid } from 'nanoid';

const Auth = (props) => {
    const location = useLocation();
    const history = useNavigate();
    const [loginLoading, setLoginLoading] = useState(false);
    const [authToken, setToken] = useStorage('AUTH_STATE', null);
    const { submitData: submitDataTemp, response: responseTemp } = usePostData2(AppUrls.Login);

    const hash = location.hash;
    const response = parse(hash);

    useEffect(() => {
        if (response && Object.keys(response).length) {
            if (!response?.error && !responseTemp?.result?.IsSuccess && !loginLoading) {
                let data = jwtDecode(response.id_token);
                submitDataTemp({ Email: data?.preferred_username });
                setLoginLoading(true);
            } else {
                console.log(response?.error);
            }
        } else {
            const state = nanoid(32);
            const nonce = nanoid(32);
            beginAuth({ state, nonce });
        }
    }, [response]);

    useEffect(() => {
        if (responseTemp?.result?.IsSuccess && responseTemp?.result?.Token) {
            setToken(responseTemp?.result?.Token);
            setLoginLoading(false);
            history('/');
        }
    }, [responseTemp]);

    return <LoadingIndicator isLoading={true} />;
};

Auth.propTypes = {};

export default Auth;
