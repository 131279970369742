import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Tooltip } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Colors } from '../../utils/colors';

const SelectedFilterParams = (props) => {
    const { searchParams, onClearSelectedParamOption } = props;

    const getSelectedSearchParamOptions = () => {
        let selectedOptions = searchParams.filter((x) =>
            x.Questions.some((x) => x.options?.some((x) => x.isSelected)),
        );
        let searchParamsWithSelectedOptions = selectedOptions?.map((param) => {
            let options = [];
            param?.Questions?.forEach((question) => {
                question?.options
                    ?.filter((x) => x.isSelected)
                    ?.forEach((option) => {
                        options.push({
                            label: option?.option,
                            isSelected: option?.isSelected,
                            order: option?.order,
                            questionLabel: question?.fieldName,
                        });
                    });
            });

            return {
                id: param?.ExperienceID,
                label: param?.Experience,
                selectedOptions: options,
            };
        });
        return searchParamsWithSelectedOptions;
    };

    return getSelectedSearchParamOptions().map((param, paramIndex) => {
        return (
            <Grid key={paramIndex} item m={1} ml={0}>
                <Grid item component={'p'} sx={{ color: '#545454' }}>
                    {param?.label}
                </Grid>
                <Grid item container mt={1}>
                    {param?.selectedOptions
                        ?.sort((a, b) => a.order - b.order)
                        ?.map((selectedOption, selectedOptionIndex) => {
                            return (
                                <Tooltip
                                    key={selectedOptionIndex}
                                    arrow
                                    title={selectedOption?.questionLabel}
                                    placement="top"
                                >
                                    <Grid mb={1} sx={{ marginRight: '5px' }}>
                                        <Grid
                                            item
                                            container
                                            direction="row"
                                            justifyContent="center"
                                            alignItems="center"
                                            sx={{
                                                background: Colors.WHITE,
                                                padding: '3px',
                                                boxShadow: '3px 3px 16px 3px #e9e9e9',
                                            }}
                                            borderRadius={10}
                                        >
                                            <Grid
                                                component={'p'}
                                                item
                                                pr={1}
                                                pl={1}
                                                sx={{
                                                    color: '#808080',
                                                }}
                                            >
                                                {selectedOption?.label}
                                            </Grid>
                                            <Grid
                                                // component={'button'}
                                                item
                                                pr={1}
                                                display="flex"
                                                justifyContent="center"
                                                alignItems="center"
                                                onClick={() =>
                                                    onClearSelectedParamOption(
                                                        param,
                                                        selectedOption,
                                                    )
                                                }
                                            >
                                                <CloseIcon color={'#808080'} fontSize="small" />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Tooltip>
                            );
                        })}
                </Grid>
            </Grid>
        );
    });
};

SelectedFilterParams.propTypes = {
    searchParams: PropTypes.array,
    onClearSelectedParamOption: PropTypes.func,
};

export default SelectedFilterParams;
