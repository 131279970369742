import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { usePostData } from '../../hooks/use-post-data.hook';
import { Alert, Box, Button, CircularProgress, TextField, Modal, Breadcrumbs } from '@mui/material';
import { AppUrls } from '../../core/route.url';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 480,
    bgcolor: 'background.paper',
    boxShadow: 3,
    p: 3,
};

const ClaimTaskForm = ({ value, resultData, handleModalClose, reload, isClaimTaskOpen }) => {
    const {
        register,
        handleSubmit,
        control,
        setValue,
        watch,
        formState: { errors },
    } = useForm();

    const watchAllFields = watch();

    const [savedResponse, setSaveResponse] = useState({});

    const { submitData, response } = usePostData(AppUrls.ClaimInfoSave, 'POST');

    useEffect(() => {
        if (response.result != undefined) {
            setSaveResponse(response.result);
            reload();
            const timer = setTimeout(() => {
                setSaveResponse({});

                handleModalClose();
            }, 1000);
            return () => clearTimeout(timer);
        }
    }, [response]);

    React.useEffect(() => {
        if (resultData != undefined && resultData !== null) {
            setValue('description', resultData[value].ShortDescription);
        }
    }, [resultData]);

    const onSubmit = async (data) => {
        submitData({
            IsActive: true,
        });
    };

    // if (carrierError) {
    //     return <div>Error Loading Data</div>;
    // }
    const handleClose = () => {
        handleModalClose();
    };
    return (
        <>
            {' '}
            <Modal
                open={isClaimTaskOpen}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <h2 style={{ marginBottom: 20 }}>Claim Task</h2>
                    <Box
                        component="form"
                        sx={{
                            '& .MuiTextField-root': { m: 1, width: '53ch' },
                        }}
                        noValidate
                        autoComplete="off"
                        onSubmit={handleSubmit(onSubmit)}
                    >
                        <div>
                            <TextField
                                required
                                disabled
                                error={errors.description}
                                id="outlined-required"
                                label="Description"
                                defaultValue=""
                                InputLabelProps={{
                                    shrink: watchAllFields.description === '' ? false : true,
                                }}
                                {...register('description', { required: true })}
                            />
                        </div>
                        <div className="d-flex" style={{ marginTop: 20 }}>
                            <Button
                                style={{ marginLeft: 2 }}
                                onClick={() => {
                                    handleClose();
                                    setValue('noteBody', '');
                                }}
                                variant="outlined"
                                type="button"
                            >
                                Cancel
                            </Button>

                            <Button
                                style={{ marginLeft: 'auto' }}
                                variant="contained"
                                type="submit"
                            >
                                {response.isSubmitting ? (
                                    <CircularProgress color="inherit" />
                                ) : (
                                    'Save'
                                )}
                            </Button>
                        </div>
                    </Box>
                    {savedResponse.IsSuccess != undefined ? (
                        <Alert
                            style={{ marginTop: 15 }}
                            severity={savedResponse.IsSuccess ? 'success' : 'error'}
                        >
                            {savedResponse.Message}
                        </Alert>
                    ) : null}
                </Box>
            </Modal>
        </>
    );
};

export default ClaimTaskForm;
ClaimTaskForm.propTypes = {
    value: PropTypes.number,
    resultData: PropTypes.any,
    handleModalClose: PropTypes.func,
    reload: PropTypes.func,
    isClaimTaskOpen: PropTypes.bool,
};
