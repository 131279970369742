import React from 'react';
import { usePostData } from '../../hooks/use-post-data.hook';
import { AppUrls } from '../../core/route.url';
import InspectionTypeList from './inspection-type-list';
import { CircularProgress, Backdrop } from '@mui/material';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';

const Inspection = () => {
    const { submitData, response } = usePostData(AppUrls.InspesctionForList, 'POST');
    const { submitData: submitDataForDelete, response: responseForDelete } = usePostData(
        AppUrls.InspesctionForDelete,
        'Delete',
    );

    const [isLoadList, setIsLoadList] = React.useState(true);
    const [filterList, setFilterList] = React.useState([]);
    const [initialResult, setInitialResult] = React.useState([]);

    React.useEffect(() => {
        if (isLoadList) {
            setIsLoadList(false);
            submitData({
                PageNo: 1,
                PageSize: 10,
                SortDir: '',
                SortExp: '',
                StartDate: '2022-05-01T04:54:29.010Z',
                EndDate: '2022-06-08T04:54:29.010Z',
                InspectionType: '',
                InspectionDescription: '',
                CarrierId: 1,
            });
        }
    }, [isLoadList, responseForDelete.result]);

    React.useEffect(() => {
        if (responseForDelete.result != undefined) {
            if (responseForDelete.result.IsSuccess) {
                setIsLoadList(true);
            }
        }
    }, [responseForDelete]);

    React.useEffect(() => {
        if (response.result !== undefined ? (response.result.Data !== null ? 1 : 0) : 0) {
            if (response.result.Data.length > 0) {
                setFilterList(response.result.Data);
                setInitialResult(response.result.Data);
            } else {
                setFilterList([]);
                setInitialResult([]);
            }
        }
    }, [response]);

    const onHandleDeleteClick = (inspectionId) => {
        submitDataForDelete({
            InspectionTypeId: inspectionId,
            UpdatedOn: '2022-06-09T06:42:48.202Z',
            UpdatedBy: 1,
        });
    };

    const OnHandleChange = (searchterm) => {
        setFilterList(
            initialResult.filter((data) => {
                return (
                    (data.InspectionType &&
                        data.InspectionType.toLowerCase().includes(
                            searchterm.toLocaleLowerCase(),
                        )) ||
                    (data.InspectionDescription &&
                        data.InspectionDescription.toLowerCase().includes(
                            searchterm.toLocaleLowerCase(),
                        ))
                );
            }),
        );
    };

    return (
        <div className='main-content'>
            {filterList.length > 0 ? (
                <>
                    <InspectionTypeList
                        filterList={filterList}
                        onHandleDeleteClick={onHandleDeleteClick}
                        setIsLoadList={setIsLoadList}
                        OnHandleChange={OnHandleChange}
                    />
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={responseForDelete.isSubmitting}
                        // onClick={handleClose}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                </>
            ) : (
                <>
                   <InspectionTypeList
                        filterList={[]}
                        onHandleDeleteClick={onHandleDeleteClick}
                        setIsLoadList={setIsLoadList}
                        OnHandleChange={OnHandleChange}
                    />
                </>
            )}
        </div>
    );
};

export default Inspection;
Inspection.propTypes = {};
