import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { AppUrls } from '../../core/route.url';
import { usePostData } from '../../hooks/use-post-data.hook';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import { useNavigate } from 'react-router-dom';
import { Button, Breadcrumbs, Typography } from '@mui/material';
import { usePostData2 } from '../../hooks/use-post-data-2.hook';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { process } from '@progress/kendo-data-query';
import { Link } from 'react-router-dom';
const RoleList = () => {
    const navigate = useNavigate();

    const [roleList, setRoleList] = React.useState([]);

    const { response } = usePostData2(AppUrls.RoleList, {
        RoleId: 0,
        RoleName: '',
        RoleCode: '',
    });

    React.useEffect(() => {
        if (response.result != undefined) {
            setRoleList(response.result.Data);
        }
    }, [response]);

    // const handleClick = (id) => {
    //     navigate('/role/permissions', { state: { test: `${id}` } });
    // };

    const createDataState = (dataState) => {
        return {
            result: process(roleList, dataState),
            dataState: dataState,
        };
    };

    const [initialState, setInitialState] = React.useState(
        createDataState({
            take: 100,
            skip: 0,
        }),
    );

    const [result, setResult] = React.useState(initialState.result);

    React.useEffect(() => {
        setInitialState(
            createDataState({
                take: 100,
                skip: 0,
            }),
        );
    }, [roleList]);

    React.useEffect(() => {
        setResult(initialState.result);
    }, [initialState]);

    const [dataState, setDataState] = React.useState(initialState.dataState);

    const dataStateChange = (event) => {
        let updatedState = createDataState(event.dataState);
        setResult(updatedState.result);
        setDataState(updatedState.dataState);
    };

    const navigateCell = (props) => {
        if (props.rowType === 'groupHeader') {
            return null;
        }

        return (
            <>
                <td className="k-command-cell hover">
                    {' '}
                    <a
                        onClick={() => {
                            navigate(`/role/permissions`, {
                                state: {
                                    test: `${props.dataItem.RoleId}`,
                                },
                            });
                        }}
                    >
                        {props.dataItem.RoleName}
                    </a>
                </td>
            </>
        );
    };

    return (
        <div className="main-content">
            <Box style={{ display: 'flex', alignItems: 'flex-end', marginBottom: 15 }}>
                <Box>
                    <Typography className="title" variant="h1">
                        Roles
                    </Typography>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link
                            to={'/'}
                            underline="hover"
                            color="inherit"
                            style={{ textDecoration: 'none' }}
                        >
                            Home
                        </Link>
                        <Link
                            to={'/role'}
                            underline="hover"
                            color="text.primary"
                            aria-current="page"
                            style={{ textDecoration: 'none' }}
                        >
                            Roles
                        </Link>
                    </Breadcrumbs>
                </Box>
                <Button
                    className="ml-auto"
                    variant="contained"
                    type="button"
                    onClick={() => {
                        navigate('/role/permissions', { state: { test: `` } });
                    }}
                >
                    Add New
                </Button>
            </Box>

            {roleList.length > 0 ? (
                <Grid
                    style={{
                        minHeight: 'calc(100vh - 135px)',
                        maxHeight: 'calc(100vh - 135px)',
                    }}
                    data={result}
                    {...dataState}
                    onDataStateChange={dataStateChange}
                    sortable={true}
                    pageable={{
                        buttonCount: 5,
                        info: true,
                        type: 'numeric',
                        pageSizes: [100, 200],
                        previousNext: true,
                    }}
                    groupable={true}
                    pageSize={10}
                >
                    <Column key={1} cell={navigateCell} field="RoleName" title="Roles" />
                    <Column key={1} field="RoleCode" title="Role Code" />
                </Grid>
            ) : (
                // <TableContainer component={Paper}>
                //     <Table sx={{ minWidth: 250 }} aria-label="simple table">
                //         <TableHead>
                //             <TableRow>
                //                 <TableCell> Roles</TableCell>
                //                 <TableCell>Role Code</TableCell>
                //             </TableRow>
                //         </TableHead>
                //         <TableBody>
                //             {roleList.map((data, index) => {
                //                 return (
                //                     <TableRow key={index}>
                //                         <TableCell
                //                             onClick={() => handleClick(data.RoleId)}
                //                             style={{ color: '#337dff', cursor: 'pointer' }}
                //                         >
                //                             {data.RoleName}
                //                         </TableCell>
                //                         <TableCell>{data.RoleCode}</TableCell>
                //                     </TableRow>
                //                 );
                //             })}
                //         </TableBody>
                //     </Table>
                // </TableContainer>
                <Box sx={{ width: '100%' }}>
                    <LinearProgress />
                </Box>
            )}
        </div>
    );
};

export default RoleList;
RoleList.propTypes = {};
