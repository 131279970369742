import PropTypes from 'prop-types';
import { Card, Grid, Typography, Box, Button } from '@mui/material';

export const ResourceCardComponent = ({
    resourceData,
    toggleResourceSelection,
    locateResource,
}) => {
    const {
        id,
        data: resource,
        color: { fillColor },
    } = resourceData;
    return (
        <>
            <Card
                key={id}
                className="boxBorder"
                style={{
                    position: 'relative',
                }}
                sx={{
                    boxShadow: 0,
                    paddingLeft: 5,
                    paddingRight: 3,
                    paddingTop: 1,
                    paddingBottom: 1,
                    marginBottom: 1,
                }}
                onClick={() => locateResource(resourceData)}
            >
                <svg
                    width="17"
                    className="userMapixon"
                    viewBox="0 0 27 36"
                    style={{ marginTop: '7px' }}
                >
                    <path
                        d="M13.5,36a1.666,1.666,0,0,1-1.388-.726C10.29,32.632,8.757,30.448,7.4,28.52,1.275,19.789,0,17.972,0,13.5A13.5,13.5,0,0,1,23.046,3.954,13.412,13.412,0,0,1,27,13.5c0,4.472-1.275,6.289-7.4,15.02-1.353,1.928-2.887,4.112-4.708,6.753A1.666,1.666,0,0,1,13.5,36ZM9.783,13.424a3.721,3.721,0,0,0-3.717,3.717v.464A1.4,1.4,0,0,0,7.46,19H19.54a1.4,1.4,0,0,0,1.393-1.394v-.464a3.721,3.721,0,0,0-3.716-3.717h-1.6a5.057,5.057,0,0,1-4.234,0ZM13.5,4.133a4.181,4.181,0,1,0,4.181,4.181A4.186,4.186,0,0,0,13.5,4.133Z"
                        fill={fillColor}
                    />
                </svg>
                <Grid container>
                    <Grid item xs={8}>
                        <Typography
                            style={{ wordBreak: 'break-all' }}
                            variant="h3"
                            fontWeight={600}
                            color={'#000'}
                            marginTop={1}
                        >
                            {resource.ResourceName}
                        </Typography>
                        <Typography
                            style={{ wordBreak: 'break-all' }}
                            variant="caption"
                            sx={{ color: '#000', display: 'block', fontSize: '12px' }}
                        >
                            {resource.EmailAddress}
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography
                            align="right"
                            variant="h3"
                            fontWeight={600}
                            sx={{ color: '#8F9398' }}
                        >
                            {resource.EfficiencyRating && resource.EfficiencyRating + '/5'}
                        </Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Typography
                                sx={{
                                    marginLeft: 'auto',
                                    paddingRight: 1,
                                    color: '#000',
                                    fontSize: '13px',
                                }}
                                variant="caption"
                            >
                                Assigned
                            </Typography>
                            <Typography sx={{ fontWeight: 'bold' }} variant="inherit">
                                {resource.AssignedClaims}
                            </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Typography
                                sx={{
                                    marginLeft: 'auto',
                                    paddingRight: 1,
                                    color: '#000',
                                    fontSize: '13px',
                                }}
                                variant="caption"
                            >
                                In Progress
                            </Typography>
                            <Typography sx={{ fontWeight: 'bold' }} variant="inherit">
                                1
                            </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Typography
                                sx={{
                                    marginLeft: 'auto',
                                    paddingRight: 1,
                                    color: '#000',
                                    fontSize: '13px',
                                }}
                                variant="caption"
                            >
                                Out of SLA
                            </Typography>
                            <Typography sx={{ fontWeight: 'bold', color: 'red' }} variant="inherit">
                                1
                            </Typography>
                        </Box>
                        <Box sx={{ display: 'flex' }}>
                            <Button
                                style={{ marginLeft: 'auto' }}
                                onClick={() => toggleResourceSelection(id)}
                            >
                                Remove
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Card>
        </>
    );
};

ResourceCardComponent.propTypes = {
    resourceData: PropTypes.shape({
        id: PropTypes.number.isRequired,
        position: PropTypes.shape({
            lat: PropTypes.number.isRequired,
            lng: PropTypes.number.isRequired,
        }),
        isSelected: PropTypes.bool,
        color: PropTypes.any,
        data: PropTypes.shape({
            ResourceName: PropTypes.string.isRequired,
            EmailAddress: PropTypes.string.isRequired,
        }).isRequired,
    }).isRequired,
    locateResource: PropTypes.func,
};
