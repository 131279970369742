import React from 'react';
import pencilIcon from './../../images/pencil.svg';
import trashIcon from './../../images/trash.svg';
import { AppUrls } from '../../core/route.url';
import { usePostData } from '../../hooks/use-post-data.hook';
import Moment from 'moment';
import { FormControl, MenuItem, IconButton } from '@mui/material/';
import LinearProgress from '@mui/material/LinearProgress';
import {
    Button,
    Modal,
    Box,
    Typography,
    Backdrop,
    CircularProgress,
    TextField,
    Paper,
    Popper,
    Fade,
} from '@mui/material';
import { usePostData2 } from '../../hooks/use-post-data-2.hook';
import PropTypes from 'prop-types';
import ClaimProfileNoteForm from './claim-profile-note-form';
import { useLocation, useParams } from 'react-router-dom';
import DeleteModal from '../../components/delete-modal';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { decryptWithAES } from '../../utils/global-functions';
import useUserPermissions from '../../hooks/use-user-permissions.hook';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 520,
    bgcolor: 'background.paper',
    boxShadow: 3,
    p: 3,
};
const ClaimProfileNoteTab = ({
    cliamProfileDetails,
    taskResponse,
    valueData,
    handleRefreshCount,
    handleRefreshNotes,
    response,
    setIsLoadData,
    DocumentTypeResult,
}) => {
    let { id: param } = useParams();
    let decryptedParam = decryptWithAES(param?.replace(/%2F/g, '/'));
    let parsedParam = JSON.parse(decryptedParam);
    const id = parsedParam?.state?.guId || parsedParam?.state?.taskGuId;
    const { state } = parsedParam;
    const [isLoad, setIsLoad] = React.useState(true);
    // const [isLoadData, setIsLoadData] = React.useState(false);
    const [isLoadFilter, setIsLoadFilter] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [filterList, setFilterList] = React.useState([]);
    const [initialResult, setInitialResult] = React.useState([]);
    const { handleSetAddPermissions, handleSetEditPermissions, handleSetDeletePermissions } =
        useUserPermissions();
    // const { submitData, response } = usePostData(AppUrls.ClaimNoteGetByPage, 'POST');

    const { submitData: submitDataForDelete, response: responseForDelete } = usePostData(
        AppUrls.ClaimNoteDelete,
        'Delete',
    );

    const [isOpen, setIsOpen] = React.useState(false);
    // const [open, setOpen] = React.useState(false);

    const [propertyValues, setPropertyValues] = React.useState({ idValue: 0, name: '' });
    const [isDeleteOpen, setIsDeleteOpen] = React.useState(false);
    // React.useEffect(() => {
    //     if (cliamProfileDetails.ClaimId) {
    //         setIsLoadData(true);
    //     }
    // }, []);

    // React.useEffect(() => {
    //     if (isLoadData) {
    //         setIsLoad(true);
    //         setIsLoadData(false);
    //         submitData({
    //             pageName: state?.page === 'claim' ? 'Claims' : 'Task',
    //             ClaimId: cliamProfileDetails.ClaimId,
    //             ClaimTaskUniqueId: state?.page === 'claim' ? '' : state?.taskGuId,
    //         });
    //     }
    // }, [isLoadData]);

    React.useEffect(() => {
        if (response.result !== undefined && isLoad) {
            setIsLoad(false);
            setFilterList(response.result.Data !== null ? response.result.Data : []);
            setInitialResult(response.result.Data !== null ? response.result.Data : []);
        }
    }, [response]);

    const handleChangeValue = (e) => {
        setFilterList(
            initialResult.filter((data) => {
                return (
                    (data.NoteBody &&
                        data.NoteBody.toLowerCase().includes(e.target.value.toLocaleLowerCase())) ||
                    (data.NoteType &&
                        data.NoteType.toLowerCase().includes(e.target.value.toLocaleLowerCase())) ||
                    (data.AddedByFirstName &&
                        data.AddedByFirstName.toLowerCase().includes(
                            e.target.value.toLocaleLowerCase(),
                        )) ||
                    (data.AddedUserEmail &&
                        data.AddedUserEmail.toLowerCase().includes(
                            e.target.value.toLocaleLowerCase(),
                        )) ||
                    (data.AddedDateTemp &&
                        Moment(data.AddedDateTemp)
                            .format('MM-DD-YYYY hh:mm A')
                            .toLowerCase()
                            .includes(e.target.value.toLocaleLowerCase()))
                );
            }),
        );
    };

    // console.log(filterList);

    React.useEffect(() => {
        if (responseForDelete.result != undefined) {
            if (responseForDelete.result.IsSuccess) {
                setIsLoadData(true);
                handleRefreshCount();
            }
        }
    }, [responseForDelete]);

    const handleClose = () => {
        setIsOpen(false);

        setPropertyValues({ idValue: 0, name: '' });
    };

    const onHandleDeleteClick = (Id) => {
        submitDataForDelete({
            ClaimNoteId: Id,
            ParentNoteTypeId: 1,
        });
    };

    const handleDeleteClick = (noteId, name) => {
        setPropertyValues({ idValue: noteId, name: name });

        setIsDeleteOpen(true);
    };

    if (response.result === undefined) {
        return (
            <Box sx={{ width: '100%' }}>
                <LinearProgress />
            </Box>
        );
    }

    const onFilterChange = () => {};
    const handleDateReceivedChange = () => {};

    const handleClick = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const open = Boolean(anchorEl);
    const idData = open ? 'simple-popper' : undefined;

    return (
        <>
            {' '}
            <DeleteModal
                isDeleteOpen={isDeleteOpen}
                setIsDeleteOpen={setIsDeleteOpen}
                propertyValues={propertyValues}
                setPropertyValues={setPropertyValues}
                handleDelete={onHandleDeleteClick}
            />
            <Modal
                open={isOpen}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <ClaimProfileNoteForm
                        idValue={propertyValues.idValue}
                        setIsLoads={setIsLoadData}
                        setIsOpen={setIsOpen}
                        cliamProfileDetails={cliamProfileDetails}
                        taskResponse={taskResponse}
                        valueData={valueData}
                        handleRefreshCount={handleRefreshCount}
                        handleRefreshNotes={handleRefreshNotes}
                        DocumentTypeResult={DocumentTypeResult}
                    />
                </Box>
            </Modal>
            {/* <Box style={{ position: 'absolute', right: 0, top: '-63px', marginRight: 84 }}>
                <div>
                    <button aria-describedby={idData} type="button" onClick={handleClick}>
                        Toggle Popper
                    </button>
                    <Popper id={idData} open={open} anchorEl={anchorEl}>
                        <Box sx={{ border: 1, p: 1, bgcolor: 'background.paper' }}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DesktopDatePicker
                                    label="Date Received"
                                    inputFormat="dd/MM/yyyy"
                                    defaultValue={null}
                                    customStyles={{
                                        dateInput: {
                                            backgroundColor: '#B0B0B000',
                                            borderTopColor: '#B0B0B000',
                                            borderBottomColor: '#B0B0B000',
                                            borderTopWidth: 0,
                                            borderBottomWidth: 0,
                                        },
                                    }}
                                    value={
                                        
                                        null
                                    }
                                    onChange={handleDateReceivedChange}
                                    renderInput={(params) => (
                                        <TextField
                                            style={{ borderBlockColor: 'black', width: '100%' }}
                                            size="small"
                                            {...params}
                                        />
                                    )}
                                />
                            </LocalizationProvider>
                            <FormControl fullWidth>
                                <TextField
                                    className="bg-White"
                                    size="small"
                                    id="select-LossType"
                                    select
                                    label="Loss Type"
                                    name="LossType"
                                    value={
                                        ''
                                        
                                    }
                                    onChange={onFilterChange}
                                >
                                    <MenuItem key={1000} value={0}>
                                        Select
                                    </MenuItem>
                                  
                                </TextField>
                            </FormControl>
                        </Box>
                    </Popper>
                </div>

              
                 <Button
                    style={{ boxShadow: 'none' }}
                    size="small"
                    variant="contained"
                    type="button"
                     
                >
                    ok
                </Button>

            </Box> */}
            <Box style={{ position: 'absolute', right: 0, top: '-63px' }}>
                {
                    <TextField
                        id="outlined-multiline-static"
                        placeholder="Search"
                        defaultValue=""
                        onChange={(e) => handleChangeValue(e)}
                        className="searchinput"
                        size="small"
                        style={{ position: 'relative', right: 15, top: '-1px' }}
                    />
                }
                {state?.page === 'claim' ? (
                    handleSetAddPermissions('Claims') ? (
                        <Button
                            style={{ boxShadow: 'none' }}
                            size="small"
                            variant="contained"
                            type="button"
                            onClick={() => {
                                setIsOpen(true);

                                setPropertyValues({ idValue: 0, name: '' });
                                // history("/inspection/add", { state: "0" });
                            }}
                        >
                            Add New
                        </Button>
                    ) : null
                ) : handleSetAddPermissions('Tasks') ? (
                    <Button
                        style={{ boxShadow: 'none' }}
                        size="small"
                        variant="contained"
                        type="button"
                        onClick={() => {
                            setIsOpen(true);

                            setPropertyValues({ idValue: 0, name: '' });
                            // history("/inspection/add", { state: "0" });
                        }}
                    >
                        Add New
                    </Button>
                ) : null}
            </Box>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={responseForDelete.isSubmitting}
                // onClick={handleClose}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <div className="note">
                {filterList.length > 0
                    ? filterList.map((data, index) => {
                          if (data.ClaimNoteTypeId == 2) {
                              return (
                                  <fieldset className="xat" key={index}>
                                      <legend>
                                          {data.NoteType}
                                          {state?.page !== 'claim'
                                              ? //   `(${data.ClaimTaskId})`
                                                null
                                              : null}
                                      </legend>
                                      <Box className="note-card">
                                          <Box>
                                              <div>
                                                  <Typography variant="h4">
                                                      {data.AddedByFirstName}
                                                  </Typography>
                                                  <Typography
                                                      variant="caption"
                                                      className="gray-text"
                                                  >
                                                      {data.AddedUserEmail}
                                                  </Typography>
                                              </div>
                                          </Box>
                                          <Box>
                                              {/* <Typography>{data.NoteBody}</Typography> */}
                                              <div
                                                  dangerouslySetInnerHTML={{
                                                      __html: data.NoteBody,
                                                  }}
                                              />
                                          </Box>
                                          <Box>
                                              <Typography
                                                  style={{ display: 'block' }}
                                                  marginBottom={1}
                                                  variant="caption"
                                                  className="gray-text no-wrap"
                                              >
                                                  {Moment(data.AddedDateTemp).format(
                                                      'MM-DD-YYYY hh:mm A',
                                                  )}
                                              </Typography>
                                              <Box
                                                  className="d-flex gutter-1"
                                                  style={{ justifyContent: 'right' }}
                                              >
                                                  {/* <button
                                                        onClick={() => {
                                                            setIdValue(data.ClaimNoteId);
                                                            setIsOpen(true);
                                                        }}
                                                        className="icons"
                                                    >
                                                        <img alt=" " src={pencilIcon} />
                                                    </button>
                                                    <button
                                                        onClick={() => {
                                                            handleDeleteClick(data.ClaimNoteId);
                                                        }}
                                                        className="icons"
                                                    >
                                                        <img alt=" " src={trashIcon} />
                                                    </button> */}
                                              </Box>
                                          </Box>
                                      </Box>
                                  </fieldset>
                              );
                          } else if (data.ClaimNoteTypeId == 3) {
                              return (
                                  <fieldset className="xat" key={index}>
                                      <legend>
                                          {data.NoteType}
                                          {state?.page !== 'claim'
                                              ? //   `(${data.ClaimTaskId})`
                                                null
                                              : null}
                                      </legend>
                                      <Box className="note-card">
                                          <Box>
                                              <div>
                                                  <Typography variant="h4">
                                                      {data.AddedByFirstName}
                                                  </Typography>
                                                  <Typography
                                                      variant="caption"
                                                      className="gray-text"
                                                  >
                                                      {data.AddedUserEmail}
                                                  </Typography>
                                              </div>
                                          </Box>
                                          <Box>
                                              {/* <Typography>{data.NoteBody}</Typography> */}
                                              <div
                                                  dangerouslySetInnerHTML={{
                                                      __html: data.NoteBody,
                                                  }}
                                              />
                                          </Box>
                                          <Box>
                                              <Typography
                                                  style={{ display: 'block' }}
                                                  marginBottom={1}
                                                  variant="caption"
                                                  className="gray-text no-wrap"
                                              >
                                                  {Moment(data.AddedDateTemp).format(
                                                      'MM-DD-YYYY hh:mm A',
                                                  )}
                                              </Typography>
                                              <Box
                                                  className="d-flex gutter-1"
                                                  style={{ justifyContent: 'right' }}
                                              >
                                                  {/* <button
                                                      onClick={() => {
                                                          setIdValue(data.ClaimNoteId);
                                                          setIsOpen(true);
                                                      }}
                                                      className="icons"
                                                  >
                                                      <img alt=" " src={pencilIcon} />
                                                  </button>
                                                  <button
                                                      onClick={() => {
                                                          handleDeleteClick(data.ClaimNoteId);
                                                      }}
                                                      className="icons"
                                                  >
                                                      <img alt=" " src={trashIcon} />
                                                  </button> */}
                                              </Box>
                                          </Box>
                                      </Box>
                                  </fieldset>
                              );
                          } else {
                              return (
                                  <fieldset className="intern" key={index}>
                                      <legend>
                                          {data.NoteType}{' '}
                                          {state?.page !== 'claim'
                                              ? null
                                              : //    `(${data.ClaimTaskId})`
                                                null}
                                      </legend>
                                      <Box className="note-card">
                                          <Box>
                                              <Typography variant="h4">
                                                  {data.AddedByFirstName}
                                              </Typography>
                                              <Typography variant="caption" className="gray-text">
                                                  {' '}
                                                  {data.AddedUserEmail}
                                              </Typography>
                                          </Box>
                                          <Box>
                                              <Typography>
                                                  {data.NoteBody.split('\n').length > 0
                                                      ? data.NoteBody.split('\n').map((data, i) => {
                                                            return (
                                                                <>
                                                                    <span>{data}</span>
                                                                    <br />
                                                                </>
                                                            );
                                                        })
                                                      : data.NoteBody}
                                              </Typography>
                                          </Box>
                                          <Box>
                                              <Typography
                                                  style={{ display: 'block' }}
                                                  marginBottom={1}
                                                  variant="caption"
                                                  className="gray-text no-wrap"
                                              >
                                                  {Moment(data.AddedDateTemp).format(
                                                      'MM-DD-YYYY hh:mm A',
                                                  )}
                                              </Typography>
                                              <Box
                                                  className="d-flex gutter-1"
                                                  style={{ justifyContent: 'right' }}
                                              >
                                                  {state?.page === 'claim' ? (
                                                      handleSetEditPermissions('Claims') ? (
                                                          <button
                                                              onClick={() => {
                                                                  setPropertyValues({
                                                                      idValue: data.ClaimNoteId,
                                                                      name: '',
                                                                  });
                                                                  setIsOpen(true);
                                                              }}
                                                              className="icons"
                                                          >
                                                              <img alt=" " src={pencilIcon} />
                                                          </button>
                                                      ) : null
                                                  ) : handleSetEditPermissions('Tasks') ? (
                                                      <button
                                                          onClick={() => {
                                                              setPropertyValues({
                                                                  idValue: data.ClaimNoteId,
                                                                  name: '',
                                                              });
                                                              setIsOpen(true);
                                                          }}
                                                          className="icons"
                                                      >
                                                          <img alt=" " src={pencilIcon} />
                                                      </button>
                                                  ) : null}
                                                  {state?.page === 'claim' ? (
                                                      handleSetDeletePermissions('Claims') ? (
                                                          <button
                                                              onClick={() => {
                                                                  handleDeleteClick(
                                                                      data.ClaimNoteId,
                                                                      data.NoteType,
                                                                  );
                                                              }}
                                                              className="icons"
                                                          >
                                                              <img alt=" " src={trashIcon} />
                                                          </button>
                                                      ) : null
                                                  ) : handleSetDeletePermissions('Tasks') ? (
                                                      <button
                                                          onClick={() => {
                                                              handleDeleteClick(
                                                                  data.ClaimNoteId,
                                                                  data.NoteType,
                                                              );
                                                          }}
                                                          className="icons"
                                                      >
                                                          <img alt=" " src={trashIcon} />
                                                      </button>
                                                  ) : null}
                                              </Box>
                                          </Box>
                                      </Box>
                                  </fieldset>
                              );
                          }
                      })
                    : 'No Records'}
                {/* Tabbed note list for Xat start */}

                {/* Tabbed note list for Xat end */}
                {/* Tabbed note list for Intern start */}

                {/* Tabbed note list for Intern end */}
            </div>
        </>
    );
};

export default ClaimProfileNoteTab;
ClaimProfileNoteTab.propTypes = {
    cliamProfileDetails: PropTypes.any.isRequired,
    taskResponse: PropTypes.any,
    valueData: PropTypes.any,
    handleRefreshCount: PropTypes.func,
    handleRefreshNotes: PropTypes.func,
    response: PropTypes.any,
    setIsLoadData: PropTypes.func,
    DocumentTypeResult: PropTypes.any,
};
