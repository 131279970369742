import { BrowserRouter as Router } from 'react-router-dom';
import { RouterView } from './core/router.view.component';
import { AppContextProvider } from './core/app.context';
import { AuthorizeComponent } from './core/authorize.component';
import SideNavbarComponent from './core/sideNavbarComponent';
import FooterComponent from './core/footerComponent';
import '@progress/kendo-theme-default/dist/all.css';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { msalConfig } from './authConfig';
import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from '@mui/material/styles';
import { LoadScript } from '@react-google-maps/api';
import { GOOGLE_MAPS_API_KEY } from './utils/constants';
const theme = createTheme({
    typography: {
        fontSize: '13px',
        fontFamily: ['Noto Sans', 'sans-serif'].join(','),
    },
});

function App() {
    const msalInstance = new PublicClientApplication(msalConfig);

    return (
        <ThemeProvider theme={theme}>
            <LoadScript googleMapsApiKey={GOOGLE_MAPS_API_KEY} libraries={['places']}>
                <AppContextProvider>
                    <MsalProvider instance={msalInstance}>
                        {/* <AuthorizeComponent> */}
                        <Router>
                            <main className="main">
                                {/* <SideNavbarComponent /> */}
                                <RouterView />
                            </main>
                            <FooterComponent />
                        </Router>
                        {/* </AuthorizeComponent> */}
                    </MsalProvider>
                </AppContextProvider>
            </LoadScript>
        </ThemeProvider>
    );
}

export default App;
