import PropTypes from 'prop-types';

import React, { useState } from 'react';

import { AppUrls } from '../../core/route.url';
import { usePostData } from '../../hooks/use-post-data.hook';
import Moment from 'moment';
import SlaTrackList from './sla-track-list';
import { useParams } from 'react-router-dom';
import { decryptWithAES } from '../../utils/global-functions';

const SlaTrack = () => {
    const [filterList, setFilterList] = React.useState([]);
    const [initialResult, setInitialResult] = React.useState([]);
    const [isLoad, setIsLoad] = useState(true);
    // let { id } = useParams();
    let { id: param } = useParams();
    let decryptedParam = decryptWithAES(param?.replace(/%2F/g, '/'));
    let parsedParam = JSON.parse(decryptedParam);
    const id = parsedParam?.state?.guId;

    const { submitData, response } = usePostData(AppUrls.SlaTractAllList, 'POST');
    const [claimFilters, setClaimFilters] = useState({
        Carrier: '',
        ClaimStatus: '',
        EventCode: '',
        LossType: '',
        City: '',
        ZipCode: '',
        DateReceived: '',
    });

    React.useEffect(() => {
        setIsLoad(true);
    }, []);

    React.useEffect(() => {
        if (isLoad) {
            setIsLoad(false);
            submitData({
                ClaimGuID: id,
                // PageNo: pageNumber,
            });
        }
    }, [isLoad]);

    React.useEffect(() => {
        if (response.result !== undefined ? (response.result.Data !== null ? 1 : 0) : 0) {
            if (response.result.Data.length > 0) {
                response.result.Data.forEach((o) => {
                    o.BreachedTime =
                        o.BreachedTime === null
                            ? ''
                            : Moment(o.BreachedTime).format('MM-DD-YYYY hh:mm A');
                    o.SLAEndTime =
                        o.SLAEndTime === null
                            ? ''
                            : Moment(o.SLAEndTime).format('MM-DD-YYYY hh:mm A');
                });
                setFilterList(
                    response.result.Data.map((dataItem) =>
                        Object.assign(
                            {
                                selected: false,
                            },
                            dataItem,
                        ),
                    ),
                );
                setInitialResult(
                    response.result.Data.map((dataItem) =>
                        Object.assign(
                            {
                                selected: false,
                            },
                            dataItem,
                        ),
                    ),
                );
            } else {
                setFilterList([]);
                setInitialResult([]);
            }
        }
    }, [response]);

    const OnHandleChange = (searchterm) => {
        setFilterList(
            initialResult.filter((data) => {
                return (
                    (data.SLAName &&
                        data.SLAName.toLowerCase().includes(searchterm.toLocaleLowerCase())) ||
                    (data.ElapsedTime &&
                        data.ElapsedTime.toLowerCase().includes(searchterm.toLocaleLowerCase())) ||
                    (data.Status &&
                        data.Status.toLowerCase().includes(searchterm.toLocaleLowerCase())) ||
                    (data.TimeLeft &&
                        data.TimeLeft.toLowerCase().includes(searchterm.toLocaleLowerCase())) ||
                    (data.SLAEndTime &&
                        data.SLAEndTime.toLowerCase().includes(searchterm.toLocaleLowerCase())) ||
                    (data.BreachedTime &&
                        data.BreachedTime.toLowerCase().includes(searchterm.toLocaleLowerCase())) ||
                    (data.SLATime &&
                        data.SLATime.toLowerCase().includes(searchterm.toLocaleLowerCase())) ||
                    (data.BreachedTime &&
                        data.BreachedTime.toLowerCase().includes(searchterm.toLocaleLowerCase()))
                );
            }),
        );
    };

    const displayList = () => {
        if (filterList.length > 0) {
            return (
                <SlaTrackList
                    filterList={filterList}
                    OnHandleChange={OnHandleChange}
                    // setClaimFilters={setClaimFilters}
                    forUpdate={0}
                    isLoadingData={response.isSubmitting}
                    setIsLoad={setIsLoad}
                />
            );
        } else {
            return (
                <SlaTrackList
                    filterList={[]}
                    OnHandleChange={OnHandleChange}
                    // setClaimFilters={setClaimFilters}
                    forUpdate={1}
                    isLoadingData={response.isSubmitting}
                />
            );
        }
    };

    return <div className="main-content"> {displayList()}</div>;
};

export default SlaTrack;
