import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { ExpansionPanelContent } from '@progress/kendo-react-layout';
import { Colors } from '../utils/colors';
import { Reveal } from '@progress/kendo-react-animation';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

const ExpansionMenu = (props) => {
    const {
        onClick,
        isVisible,
        children,
        label,
        type = 'checkbox-list',
        buttonContainerStyle,
        containerStyle,
        buttonTextStyle,
        accordionIconSize,
    } = props;
    return type === 'checkbox-list' ? (
        <Grid xs={12} md={12} lg={12} item>
            <Grid
                item
                display={'flex'}
                sx={{
                    marginTop: 1,
                    marginBottom: 1,
                    ...buttonContainerStyle,
                }}
            >
                <input
                    type="checkbox"
                    checked={isVisible}
                    onChange={onClick}
                    className="questionnaire-checkbox"
                />
                <label
                    style={{
                        color: isVisible ? Colors.DODGER_BLUE : '#7a7a7a',

                        fontWeight: 'bold',
                    }}
                >
                    {label}
                </label>
            </Grid>
            <Grid md={12} item>
                <Reveal style={{ width: '100%', marginTop: '10px' }}>
                    {isVisible && (
                        <ExpansionPanelContent style={{ backgroundColor: Colors.WHITE }}>
                            {children}
                        </ExpansionPanelContent>
                    )}
                </Reveal>
            </Grid>
        </Grid>
    ) : type === 'accordion' ? (
        <Grid sx={{ ...containerStyle }} xs={12} md={12} lg={12} item>
            <Grid
                item
                display={'flex'}
                justifyContent={'space-between'}
                alignItems={'center'}
                p={1}
                borderRadius={2}
                sx={{
                    ...buttonContainerStyle,
                }}
                onClick={onClick}
                width={'100%'}
            >
                <label
                    style={{
                        color: isVisible ? '#337dff' : 'unset',
                        fontWeight: 800,
                        ...buttonTextStyle,
                    }}
                >
                    {label}
                </label>
                {isVisible ? (
                    <ExpandLessIcon
                        fontSize={accordionIconSize || ''}
                        style={{ color: buttonTextStyle?.color || Colors.BLACK }}
                    />
                ) : (
                    <ExpandMoreIcon
                        fontSize={accordionIconSize || ''}
                        style={{ color: buttonTextStyle?.color || Colors.BLACK }}
                    />
                )}
            </Grid>
            <Grid md={12} item>
                <Reveal style={{ width: '100%' }}>
                    {isVisible && (
                        <ExpansionPanelContent style={{ backgroundColor: Colors.WHITE }}>
                            {children}
                        </ExpansionPanelContent>
                    )}
                </Reveal>
            </Grid>
        </Grid>
    ) : null;
};

ExpansionMenu.propTypes = {
    onClick: PropTypes.func,
    isVisible: PropTypes.bool,
    children: PropTypes.any,
    label: PropTypes.string,
    type: PropTypes.string,
    buttonContainerStyle: PropTypes.any,
    containerStyle: PropTypes.any,
    buttonTextStyle: PropTypes.any,
    accordionIconSize: PropTypes.string,
};

export default ExpansionMenu;
