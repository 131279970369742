import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import { TextField, FormControl, MenuItem, Button, Stack, IconButton } from '@mui/material/';
import { useLoadData } from '../../hooks/use-load-data.hook';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import RefreshIcon from '@mui/icons-material/Refresh';

export const MapViewFilter = ({ currentFilters, setClaimFilters, onApplyFilters }) => {
    const {
        isLoading: lossTypeLoading,
        result: lossTypeResult,
        isError: lossTypeError,
    } = useLoadData('MapView/GetAllLossTypes');
    const {
        isLoading: eventCodeLoading,
        result: eventCodeResult,
        isError: eventCodeError,
    } = useLoadData('EventCode');
    const {
        isLoading: claimStatusLoading,
        result: claimStatusResult,
        isError: claimStatusError,
    } = useLoadData('ClaimStatus');
    const {
        isLoading: carrierLoading,
        result: carrierResult,
        isError: carrierError,
    } = useLoadData('Carrier/GetAll');

    const { ClaimNumber, CarrierId, ClaimStatusId, EventId, LossType, DateReceived } =
        currentFilters ? currentFilters : {};

    if (lossTypeError || eventCodeError || carrierError || claimStatusError) {
        return <div>Error Loading Data</div>;
    }

    if (lossTypeLoading || eventCodeLoading || claimStatusLoading || carrierLoading) {
        return <div>Loading....</div>;
    }

    const clearFilter = () => {
        setClaimFilters({
            ClaimNumber: '',
            CarrierId: '',
            ClaimStatusId: '',
            EventId: '',
            LossType: '',
            DateReceived: '',
        });
    };

    const handleDateReceivedChange = (newValue) => {
        updateFilterState('DateReceived', new Date(newValue.setHours(12)));
    };

    const onFilterChange = (event) => {
        updateFilterState(event.target.name, event.target.value);
    };

    const updateFilterState = (key, value) => {
        setClaimFilters({ ...currentFilters, [key]: value });
    };

    return (
        <>
            <Grid>
                <TextField
                    className="bg-White"
                    label="Claim Search"
                    size="small"
                    fullWidth
                    id="outlined-basic"
                    variant="outlined"
                    name="ClaimNumber"
                    value={ClaimNumber}
                    onChange={onFilterChange}
                />
            </Grid>
            <Grid>
                <FormControl fullWidth>
                    <TextField
                        className="bg-White"
                        size="small"
                        id="select-carrier"
                        select
                        label="Carrier"
                        name="CarrierId"
                        value={CarrierId}
                        onChange={onFilterChange}
                    >
                        {carrierResult?.Data?.map((carrier) => {
                            return (
                                <MenuItem key={carrier.CarrierId} value={carrier.CarrierId}>
                                    {carrier.CarrierName}
                                </MenuItem>
                            );
                        })}
                    </TextField>
                </FormControl>
            </Grid>
            {/* <Grid>
                <FormControl fullWidth>
                    <TextField
                        className="bg-White"
                        size="small"
                        id="select-EventCode"
                        select
                        label="Event Code"
                        name="EventId"
                        value={EventId}
                        onChange={onFilterChange}
                    >
                        {eventCodeResult?.Data?.map((eventCode) => {
                            return (
                                <MenuItem key={eventCode.EventCodeId} value={eventCode.EventCodeId}>
                                    {eventCode.EventCode}
                                </MenuItem>
                            );
                        })}
                    </TextField>
                </FormControl>
            </Grid> */}
            <Grid>
                <FormControl fullWidth>
                    <TextField
                        className="bg-White"
                        size="small"
                        id="select-ClaimsStatus"
                        select
                        label="Claims Status"
                        name="ClaimStatusId"
                        value={ClaimStatusId}
                        onChange={onFilterChange}
                    >
                        {claimStatusResult?.Data?.map((claimStatus) => {
                            return (
                                <MenuItem
                                    key={claimStatus.ClaimStatusId}
                                    value={claimStatus.ClaimStatusId}
                                >
                                    {claimStatus.ClaimStatusName}
                                </MenuItem>
                            );
                        })}
                    </TextField>
                </FormControl>
            </Grid>
            <Grid>
                <FormControl fullWidth>
                    <TextField
                        className="bg-White"
                        size="small"
                        id="select-LossType"
                        select
                        label="Loss Type"
                        name="LossType"
                        value={LossType}
                        onChange={onFilterChange}
                    >
                        {lossTypeResult?.Data?.map((lossType) => {
                            return (
                                <MenuItem key={lossType.LossTypeId} value={lossType.LossType}>
                                    {lossType.LossType}
                                </MenuItem>
                            );
                        })}
                    </TextField>
                </FormControl>
            </Grid>
            <Grid>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDatePicker
                        label="Date Received"
                        inputFormat="MM/dd/yyyy"
                        value={DateReceived !== '' ? DateReceived : null}
                        onChange={handleDateReceivedChange}
                        renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                    />
                </LocalizationProvider>
            </Grid>
            <Grid style={{ display: 'none' }}>
                <Button
                    fullWidth
                    variant="contained"
                    style={{ boxShadow: 'none' }}
                    onClick={onApplyFilters}
                >
                    Search
                </Button>
            </Grid>
            <Grid>
                <IconButton style={{ padding: 0 }} onClick={clearFilter}>
                    <RefreshIcon style={{ fontSize: 35 }}></RefreshIcon>
                </IconButton>
            </Grid>
        </>
    );
};

MapViewFilter.propTypes = {
    currentFilters: PropTypes.shape({
        ClaimNumber: PropTypes.any,
        CarrierId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        EventId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        ClaimStatusId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        LossType: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        DateReceived: PropTypes.any,
    }),
    setClaimFilters: PropTypes.func.isRequired,
};
