import { useCallback, useState } from 'react';
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';

import { GOOGLE_MAPS_API_KEY } from '../../utils/constants';
import PropTypes from 'prop-types';

export const ClaimMap = ({ position, index }) => {
    const [map, setMap] = useState(null);

    const onLoad = useCallback(
        function callback(map) {
            const bounds = new window.google.maps.LatLngBounds(position);
            map.fitBounds(bounds);
            setMap(map);
        },
        [position],
    );

    const mapOptions = {
        minZoom: 3,
        maxZoom: 17,
        zoom: 7,
        disableDefaultUI: false,
    };
    const containerStyle = {
        width: '100%',
        height: index === 0 ? '250px' : '400px',
    };
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: GOOGLE_MAPS_API_KEY,
    });
    const onUnmount = useCallback(function callback(map) {
        setMap(null);
    }, []);

    return isLoaded ? (
        <GoogleMap
            mapContainerStyle={containerStyle}
            options={mapOptions}
            zoom={12}
            onLoad={onLoad}
            onUnmount={onUnmount}
        >
            <Marker key={'claimmarker'} position={position} />
        </GoogleMap>
    ) : (
        <div className="main-content">Loading...</div>
    );
};
ClaimMap.propTypes = {
    position: PropTypes.shape({
        lat: PropTypes.number.isRequired,
        lng: PropTypes.number.isRequired,
    }),
    index: PropTypes.number,
};
