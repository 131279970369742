import React from 'react';
import PropTypes from 'prop-types';
import { Grid, GridColumn as Column, GridToolbar } from '@progress/kendo-react-grid';
import { process } from '@progress/kendo-data-query';
import { AppUrls } from '../../core/route.url';
import { usePostData } from '../../hooks/use-post-data.hook';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Typography, Breadcrumbs, Link, Box, TextField } from '@mui/material/';
import iconexcel from '../../images/file-excel.svg';
import iconpdf from '../../images/file-pdf-solid.svg';
import { GridPDFExport } from '@progress/kendo-react-pdf';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import moment from 'moment';

const CATALYST_API_URL = process.env.REACT_APP_AUTH_REDIRECT_URI;
const REPLACE_REGEX = /\s/g;

const CustomSavedQueryList = ({ filterList }) => {
    const [reportName, setReportName] = React.useState('');
    const [search, setSearch] = React.useState('');
    const history = useNavigate();
    const { state, pathname } = useLocation();
    const { submitData, response } = usePostData(
        AppUrls.ExecuteReport + `?report_name=${reportName}`,
        'POST',
    );

    const createDataState = (dataState) => {
        return {
            result: process(filterList.slice(0), dataState),
            dataState: dataState,
        };
    };

    const [initialState, setInitialState] = React.useState(
        createDataState({
            take: 100,
            skip: 0,
        }),
    );

    const [result, setResult] = React.useState(initialState.result);

    React.useEffect(() => {
        setInitialState(
            createDataState({
                take: 100,
                skip: 0,
            }),
        );
    }, [filterList]);

    // console.log(3000, response);

    React.useEffect(() => {
        if (response.result !== undefined) {
            localStorage.setItem('queary_data', JSON.stringify(response.result.Data));
            localStorage.setItem('report_name', reportName);
            window.open(CATALYST_API_URL + `execute_query`);
            // history(`/execute_query`, {
            //     state: {
            //         executeQuerydata: JSON.stringify(response.result.Data),
            //     },
            // });

            // window.open(
            //     CATALYST_API_URL +
            //         `execute_query` +
            //         `?data=${JSON.stringify(response.result.Data)}`,
            //  {
            //     state: {
            //         executeQuerydata: JSON.stringify(response.result.Data),
            //     },
            // }
            // );
        }
    }, [response]);

    React.useEffect(() => {
        setResult(initialState.result);
    }, [initialState]);

    React.useEffect(() => {
        if (reportName !== '') {
            submitData();
        }
    }, [reportName]);

    const [dataState, setDataState] = React.useState(initialState.dataState);

    const dataStateChange = (event) => {
        let updatedState = createDataState(event.dataState);
        setResult(updatedState.result);
        setDataState(updatedState.dataState);
    };

    const handleRunQueary = (name) => {
        setReportName(name);
    };

    const navigateCell = (props) => {
        if (props.rowType === 'groupHeader') {
            return null;
        }

        return (
            <>
                <td className="k-command-cell hover">
                    {' '}
                    <Button
                        style={{ boxShadow: 'none' }}
                        size={'small'}
                        variant="contained"
                        onClick={() => {
                            handleRunQueary(`${props.dataItem.ReportName}`);
                        }}
                    >
                        RUN
                    </Button>
                </td>
            </>
        );
    };

    // console.log(120, filterList);

    const _export = React.useRef(null);

    const excelExport = () => {
        if (_export.current !== null) {
            _export.current.save(filterList);
        }
    };

    let _pdfExport;

    const exportPDF = () => {
        _pdfExport.save(filterList);
    };

    const exportGrid = (value) => {
        return (
            <Grid
                style={{
                    maxHeight: 'calc(100vh - 193px)',
                    minHeight: 'calc(100vh - 155px)',
                    display: value ? 'none' : '',
                }}
                data={result}
                {...dataState}
                onDataStateChange={dataStateChange}
                // sortable={true}
                pageable={{
                    buttonCount: 5,
                    info: true,
                    type: 'numeric',
                    pageSizes: [100, 200],
                    previousNext: true,
                }}
                // groupable={true}
                pageSize={100}
                resizable={true}
            >
                <Column key={1} field="ReportName" title="Query Name" />
                <Column key={2} field="SqlQuery" title="Query" width={800} />
            </Grid>
        );
    };

    const hasSearchKeyExist = (messageItem) => {
        return (
            messageItem?.ReportName?.toLowerCase()
                .replace(REPLACE_REGEX, '')
                .includes(search?.toLocaleLowerCase().replace(REPLACE_REGEX, '')) ||
            messageItem?.SqlQuery?.toLowerCase()
                .replace(REPLACE_REGEX, '')
                .includes(search?.toLocaleLowerCase().replace(REPLACE_REGEX, ''))
        );
    };

    const filterListBySearch = (list = []) => {
        return list?.filter((messageItem) => hasSearchKeyExist(messageItem));
    };

    const filterGroup = (array, fn) => {
        return array.reduce((r, o) => {
            var children = filterGroup(o.items || [], fn);
            if (fn(o) || children.length)
                r.push(Object.assign({}, o, children.length && { children }));
            return r;
        }, []);
    };

    const getFilteredList = (data = { data: [], total: 0 }) => {
        let filteredData =
            data?.data?.length && data?.data[0]?.hasOwnProperty('ReportID')
                ? filterListBySearch(data?.data)
                : filterGroup(data?.data ?? [], (messageItem) => hasSearchKeyExist(messageItem));
        return {
            data: filteredData,
            total: data?.total,
        };
    };

    return (
        <>
            <Box style={{ marginBottom: 15 }}>
                <Typography className="title" variant="h1" marginBottom={1}>
                    Saved Queries
                </Typography>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link
                        to={'/'}
                        underline="hover"
                        color="inherit"
                        style={{ textDecoration: 'none' }}
                    >
                        Home
                    </Link>
                    <Link
                        to={'/saved_query'}
                        underline="hover"
                        color="text.primary"
                        aria-current="page"
                        style={{ textDecoration: 'none' }}
                    >
                        Saved Queries
                    </Link>
                </Breadcrumbs>
            </Box>
            <div className="claims-list-grid" style={{ marginBottom: '10px' }}>
                <Grid
                    style={{
                        maxHeight: 'calc(100vh - 193px)',
                        minHeight: 'calc(100vh - 155px)',
                    }}
                    data={getFilteredList(result)}
                    {...dataState}
                    onDataStateChange={dataStateChange}
                    // sortable={true}
                    pageable={{
                        buttonCount: 5,
                        info: true,
                        type: 'numeric',
                        pageSizes: [100, 200],
                        previousNext: true,
                    }}
                    // groupable={true}
                    pageSize={100}
                    resizable={true}
                >
                    <GridToolbar>
                        <div className="search-wrap">
                            <div>
                                <TextField
                                    required
                                    id="outlined-required"
                                    label=""
                                    defaultValue=""
                                    size="small"
                                    value={search}
                                    onChange={(e) => setSearch(e?.target.value)}
                                />
                            </div>
                        </div>
                    </GridToolbar>
                    <GridToolbar>
                        <div>
                            <button
                                title="Export to Excel"
                                className="icon-btn"
                                onClick={excelExport}
                            >
                                <img src={iconexcel} title="Export to Excel" />
                            </button>

                            <button className="icon-btn" onClick={exportPDF}>
                                <img src={iconpdf} title="Export to PDF" />
                            </button>
                        </div>
                    </GridToolbar>
                    <Column key={1} field="ReportName" title="Query Name" width={180} />
                    <Column key={2} field="SqlQuery" title="Query" />
                    <Column key={3} cell={navigateCell} field="optn" width={120} title="Options" />
                </Grid>
                <ExcelExport
                    fileName={`CustomSavedQueryExport_${moment(new Date()).format(
                        'MM-DD-YYYY hh mm a',
                    )}`}
                    style={{ display: 'none' }}
                    data={result !== undefined && result.data !== undefined ? result.data : []}
                    ref={_export}
                >
                    {exportGrid(true)}
                </ExcelExport>
                <GridPDFExport
                    fileName={`CustomSavedQueryExport_${moment(new Date()).format(
                        'MM-DD-YYYY hh mm a',
                    )}`}
                    ref={(element) => {
                        _pdfExport = element;
                    }}
                    margin="1cm"
                >
                    {exportGrid(false)}
                </GridPDFExport>
            </div>
        </>
    );
};
export default CustomSavedQueryList;

CustomSavedQueryList.propTypes = {
    filterList: PropTypes.array.isRequired,
};
