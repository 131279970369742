import React, { useState, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import Box from "@mui/material/Box";
import {
  Button,
  FormControl,
  Alert,
  CircularProgress,
} from "@mui/material";
import SelectOption from "../../components/select-option";
import { usePostData } from "../../hooks/use-post-data.hook";
import { AppUrls } from "../../core/route.url";
import PropTypes from 'prop-types';

const UserForm = (props) => {
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm();

  const [isLoad, setIsLoad] = useState(false);
  const [savedResponse, setSaveResponse] = useState([]);

  const { submitData: submitUserRoleList, response: userRoleList } = usePostData(
    AppUrls.UserRoleList,
    "GET"
  );
  useEffect(() => {
    setIsLoad(true);
    if (isLoad) {
      submitUserRoleList({
      });
    }
  }, [isLoad]);

  const { submitData, response } = usePostData(
    AppUrls.UserRoleAssignmentSave,
    "POST"
  );

  let roles = [];

  const onSubmit = async (data) => {
    for (let item of props.idValues) {
      roles.push({
        RoleId: data.Role,
        UserId: item,
        AssignmentId: 0
      })
    }
    submitData(roles);
  };

  useEffect(() => {
    if (response.result != undefined) {
      setSaveResponse(response.result);
      props.setIsLoadList(true);
      const timer = setTimeout(() => {
        setSaveResponse([]);
        props.setIsLoadList(true);
        setValue("RoleId", "");
        props.setIsOpen(false);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [response]);

  return (
    <div>
      <h3 className="mb-2">User Role</h3>
      <Box
        component="form"
        sx={{
          "& .MuiTextField-root": { m: 1, width: "35ch" },
        }}

        autoComplete="off"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div>
          <FormControl sx={{ m: 1, width: 413 }} >
            <Controller
              control={control}
              name="Role"
              rules={{
                required: "Please select Role ",
              }}
              render={({ field: { onChange, onBlur, value, name } }) => (
                <SelectOption
                  fullWidth
                  values={
                    userRoleList != undefined && userRoleList.result != undefined
                      ? userRoleList.result.Data.map((e) => {
                        return {
                          value: e["RoleId"],
                          label: e["RoleName"],
                        };
                      })
                      : []
                  }
                  disabled={false}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value === 0 ? "" : value}
                  name={name || ""}
                  error={errors.Role}
                />
              )}
            />
          </FormControl>

        </div>
        <div className="d-flex model-footer">
          <Button style={{ marginRight: 'auto' }} variant="outlined"
            onClick={() => {
              props.setIsOpen(false);
            }}>
            Close
          </Button>
          <Button style={{ marginLeft: 2 }} variant="contained" type="submit">
            {response.isSubmitting ? (
              <CircularProgress color="inherit" />
            ) : (
              "Save"
            )}
          </Button>
        </div>
      </Box>
      {savedResponse.IsSuccess != undefined ? (
        <Alert style={{ marginTop: 15 }} severity={savedResponse.IsSuccess ? "success" : "error"}>
          {savedResponse.Message}
        </Alert>
      ) : null}
    </div>
  );
};

export default UserForm;

UserForm.propTypes = {
  idValues: PropTypes.array.isRequired,
  setIsLoadList: PropTypes.any.isRequired,
  setIsOpen: PropTypes.any.isRequired,
  //setSelectedState: PropTypes.any.isRequired,
};
