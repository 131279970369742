import React from 'react';
import { useCurrentUser } from './user-current-user.hook';

const useUserPermissions = () => {
    const user = useCurrentUser();

    const handleSetViewPermissions = (menuName) => {
        if (user && user.UserMenuPermissions && user.UserMenuPermissions.length > 0) {
            const permissionView = user.UserMenuPermissions.filter((data, i) => {
                return data.MenuName === menuName;
            })[0]?.IsView;
            return permissionView;
        }
    };

    const handleSetAddPermissions = (menuName) => {
        if (user && user.UserMenuPermissions && user.UserMenuPermissions.length > 0) {
            const permissionAdd = user.UserMenuPermissions.filter((data, i) => {
                return data.MenuName === menuName;
            })[0]?.IsAdd;
            return permissionAdd;
        }
    };

    const handleSetEditPermissions = (menuName) => {
        if (user && user.UserMenuPermissions && user.UserMenuPermissions.length > 0) {
            const permissionEdit = user.UserMenuPermissions.filter((data, i) => {
                return data.MenuName === menuName;
            })[0]?.IsEdit;
            return permissionEdit;
        }
    };
    const handleSetDeletePermissions = (menuName) => {
        if (user && user.UserMenuPermissions && user.UserMenuPermissions.length > 0) {
            const permissionDelete = user.UserMenuPermissions.filter((data, i) => {
                return data.MenuName === menuName;
            })[0]?.IsDelete;
            return permissionDelete;
        }
    };

    const handleSetPrintPermissions = (menuName) => {
        if (user && user.UserMenuPermissions && user.UserMenuPermissions.length > 0) {
            const permissionPrint = user.UserMenuPermissions.filter((data, i) => {
                return data.MenuName === menuName;
            })[0]?.IsPrint;
            return permissionPrint;
        }
    };

    const handleSetSpecialPermissions = (menuName) => {
        if (user && user.UserSpecialPermissions && user.UserSpecialPermissions.length > 0) {
            const specialPermission = user.UserSpecialPermissions.filter((data, i) => {
                return data.PermissionCode === menuName;
            })[0]?.HasAccess;
            return specialPermission;
        }
    };

    return {
        handleSetViewPermissions,
        handleSetAddPermissions,
        handleSetEditPermissions,
        handleSetDeletePermissions,
        handleSetPrintPermissions,
        handleSetSpecialPermissions,
    };
};

export default useUserPermissions;
