import React from 'react';
import { usePostData } from '../../hooks/use-post-data.hook';
import { AppUrls } from '../../core/route.url';
import AuditTrailList from './auditTrailList';
import PropTypes from 'prop-types';
import { CircularProgress, Backdrop, TextField } from '@mui/material';
import {
    IntlProvider,
    load,
    LocalizationProvider,
    loadMessages,
    IntlService,
} from '@progress/kendo-react-intl';
import moment from 'moment/moment';
import { useCurrentUser } from '../../hooks/user-current-user.hook';
import UserAduditTrailList from './user-adudit-trail-list';
import UserClaimHistoryActivityList from './user-claim-history-activity-list';

const UserClaimHistoryActivity = ({ userId }) => {
    // const user = useCurrentUser();
    const { submitData, response } = usePostData(AppUrls.AuditTrailForUserClaims, 'POST');
    const [filterList, setFilterList] = React.useState([]);
    const [isLoadList, setIsLoadList] = React.useState(false);
    const DATE_FORMAT = 'yyyy-mm-ddThh:mm:ss.SSS';
    const intl = new IntlService('en');
    const [initialResult, setInitialResult] = React.useState([]);

    React.useEffect(() => {
        setIsLoadList(true);
    }, []);

    React.useEffect(() => {
        if (isLoadList && userId !== undefined) {
            setIsLoadList(false);
            submitData({
                Userid: userId,
            });
        }
    }, [isLoadList]);

    React.useEffect(() => {
        if (response.result != undefined) {
            if (response.result.IsSuccess) {
                // to format the date

                setFilterList(response.result.Data);
                setInitialResult(response.result.Data);
            }
        }
    }, [response]);

    const handleChangeValue = (e) => {
        setFilterList(
            initialResult.map((data, i) => {
                return {
                    ActivityDate: data.ActivityDate,
                    ClaimActivitiesList: data.ClaimActivitiesList.filter((d, index) => {
                        return (
                            (d.ClaimActivityTypeName &&
                                d.ClaimActivityTypeName.toLowerCase().includes(
                                    e.target.value.toLocaleLowerCase(),
                                )) ||
                            (d.DateOn &&
                                d.DateOn.toLowerCase().includes(
                                    e.target.value.toLocaleLowerCase(),
                                )) ||
                            (d.ClaimNumber &&
                                d.ClaimNumber.toLowerCase().includes(
                                    e.target.value.toLocaleLowerCase(),
                                )) ||
                            (d.ActivityDetail &&
                                d.ActivityDetail.toLowerCase().includes(
                                    e.target.value.toLocaleLowerCase(),
                                )) ||
                            (d.UserEmail &&
                                d.UserEmail.toLowerCase().includes(
                                    e.target.value.toLocaleLowerCase(),
                                )) ||
                            (d.UserFullName &&
                                d.UserFullName.toLowerCase().includes(
                                    e.target.value.toLocaleLowerCase(),
                                ))
                        );
                    }),
                };
            }),
        );
    };

    return (
        <div className="main-content">
            <div style={{ display: 'flex', justifyContent: 'right', marginTop: '-45px' }}>
                <TextField
                    style={{ position: 'relative', top: '-27px', right: '-8px' }}
                    required
                    id="outlined-required"
                    label=""
                    defaultValue=""
                    placeholder="Search"
                    size="small"
                    onChange={(e) => handleChangeValue(e)}
                />
            </div>
            {filterList.length > 0 ? (
                <>
                    {<UserClaimHistoryActivityList filterList={filterList} />}
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        // onClick={handleClose}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                </>
            ) : (
                <>
                    <UserClaimHistoryActivityList filterList={[]} />
                    {/* <CircularProgress color="inherit" /> */}
                </>
            )}
        </div>
    );
};

export default UserClaimHistoryActivity;
UserClaimHistoryActivity.propTypes = {
    userId: PropTypes.string,
};
