import React, { useEffect, useState } from 'react';
import { Box, Button, Grid } from '@mui/material';
import {
    AppInputTypes,
    AppRegex,
    DateFormats,
    ExperienceCodes,
    LovCodes,
} from '../../utils/constants';
import moment from 'moment';
import { AppUrls } from '../../core/route.url';
import { useLoadData } from '../../hooks/use-load-data.hook';
import { usePostData2 } from '../../hooks/use-post-data-2.hook';
import { LoadingIndicator } from '../../components/loading-indicator.component';
import { Colors } from '../../utils/colors';
import ModalDialog from '../../components/modalDialog';
import PersonalInfoQuestions from '../../components/questionnaire/PersonalInfoQuestions';
import ExperienceQuestions from '../../components/questionnaire/ExperienceQuestions';

const Questionnaire = (props) => {
    const [personalDetails, setPersonalDetails] = useState({
        pilotNumber: '',
        firstName: '',
        lastName: '',
        telephone: '',
        email: '',
        streetAddress: '',
        city: '',
        state: '',
        zipCode: '',
        //dropdown
        professionalDesignation: '',
        //multiselect
        professionalSkillStrengths: '',
        //dropdown
        positionsAbleToWork: '',
    });
    const [personalDetailsErrors, setPersonalDetailsErrors] = useState({});
    const [adjusterExperienceQuestionDetail, setAdjusterExperienceQuestionDetail] = useState([]);
    const [showSuccessDialog, setShowSuccessDialog] = useState(false);

    const { result: stateResult } = useLoadData(AppUrls.GetAllStates);
    const { response: createQuestionnaireResponse, submitData } = usePostData2(
        AppUrls.PostQuestionnaire,
    );
    const { response: getQuestionnaireByEXPCodesResponse, submitData: getQuestionnaireByEXPCodes } =
        usePostData2(AppUrls.GetQuestionnaire);
    const { response: lovListResult, submitData: getLOVsByLovCodes } = usePostData2(
        AppUrls.GetAllLOVs,
    );

    const getQuestionnaire = () => {
        getQuestionnaireByEXPCodes({
            ExpCodes: [
                ExperienceCodes.AUTOA,
                ExperienceCodes.CMDA,
                ExperienceCodes.CMDFA,
                ExperienceCodes.CMDFR,
                ExperienceCodes.INSDA,
                ExperienceCodes.NFIFA,
                ExperienceCodes.PTYFA,
            ],
            ExpIDs: [1, 2, 3, 4, 5, 6, 7],
        });
    };

    useEffect(() => {
        setShowSuccessDialog(false);
        getLOVsByLovCodes({
            LovCodes: [LovCodes.PFDN, LovCodes.PFSK, LovCodes.PATW],
            LovIDs: [1, 2, 3],
        });
        getQuestionnaire();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const { result } = createQuestionnaireResponse || {};
        const { IsSuccess } = result || {};
        if (IsSuccess) {
            setShowSuccessDialog(true);
            setPersonalDetails({
                pilotNumber: '',
                firstName: '',
                lastName: '',
                telephone: '',
                email: '',
                streetAddress: '',
                city: '',
                state: '',
                zipCode: '',
                //dropdown
                professionalDesignation: '',
                //multiselect
                professionalSkillStrengths: '',
                //dropdown
                positionsAbleToWork: '',
            });
            getQuestionnaire();
        }
    }, [createQuestionnaireResponse?.result]);

    useEffect(() => {
        if (getQuestionnaireByEXPCodesResponse?.result) {
            const questionnaire = getQuestionnaireByEXPCodesResponse?.result?.Data;
            if (questionnaire?.length) {
                // for time being
                questionnaire.map((experience) => {
                    experience.Questions.map((question) => {
                        question.IsRequired = true;
                        return question;
                    });
                    return experience;
                });
                //
                questionnaire[0].visible = true;
                setAdjusterExperienceQuestionDetail(questionnaire);
            }
        }
    }, [getQuestionnaireByEXPCodesResponse?.result]);

    const lovList = lovListResult?.result?.Data;
    let professionalDesignations =
        lovList?.find((x) => x.LovCode === LovCodes.PFDN)?.List?.split(',') || [];
    let professionalSkillStrengths =
        lovList?.find((x) => x.LovCode === LovCodes.PFSK)?.List?.split(',') || [];
    let positionsAbleToWork =
        lovList?.find((x) => x.LovCode === LovCodes.PATW)?.List?.split(',') || [];

    const onClickExperience = (experienceId) => {
        let adjusterExperienceQuestionDetailCopy = [...adjusterExperienceQuestionDetail];
        let findExperienceById = adjusterExperienceQuestionDetailCopy.find(
            (x) => x.ExperienceID === experienceId,
        );
        if (findExperienceById) {
            findExperienceById.visible = !findExperienceById.visible;
            setAdjusterExperienceQuestionDetail(adjusterExperienceQuestionDetailCopy);
        }
    };

    const checkFormValid = () => {
        let personalInfoErrors = [];
        let experienceErrors = [];
        //Personal Info validation
        let optionalFields = ['pilotNumber'];
        personalDetails &&
            Object.keys(personalDetails).map((personalItemKey) => {
                let personalItemState = personalDetails[personalItemKey];
                if (!optionalFields.some((x) => x === personalItemKey)) {
                    if (!personalItemState) {
                        personalInfoErrors.push(personalItemKey);
                        setPersonalDetailsErrors((prevState) => ({
                            ...prevState,
                            [personalItemKey]: 'Required',
                        }));
                    } else if (
                        personalItemKey === 'email' &&
                        !AppRegex.Email.test(personalItemState)
                    ) {
                        personalInfoErrors.push(personalItemKey);
                        setPersonalDetailsErrors((prevState) => ({
                            ...prevState,
                            [personalItemKey]: 'Enter a valid email',
                        }));
                    } else {
                        personalInfoErrors = personalInfoErrors.filter(
                            (x) => x !== personalItemKey,
                        );
                        setPersonalDetailsErrors((prevState) => ({
                            ...prevState,
                            [personalItemKey]: '',
                        }));
                    }
                }
                return personalItemKey;
            });

        //Experience Detail validation
        let adjusterExperienceQuestionDetailCopy = [...adjusterExperienceQuestionDetail];
        adjusterExperienceQuestionDetailCopy
            .filter((x) => x.visible)
            .map((experience, experienceIndex) => {
                let mandatoryQuestions = experience.Questions.filter((x) => x.IsRequired);
                mandatoryQuestions.map((question, questionIndex) => {
                    if (
                        (question.fieldType === AppInputTypes.TextField ||
                            question.fieldType === AppInputTypes.TextArea ||
                            question.fieldType === AppInputTypes.DropDown ||
                            question.fieldType === AppInputTypes.MultiSelectDropDown ||
                            question.fieldType === AppInputTypes.FileUpload) &&
                        !question?.values?.length
                    ) {
                        if (!!question?.parentId) {
                            let checkParentQuestionAnswered = experience.Questions?.some(
                                (x) => x.LovID === question?.parentId && !x?.values?.length,
                            );
                            if (!checkParentQuestionAnswered) {
                                experienceErrors.push(question?.fieldName);
                                question.fieldError = 'Required';
                            } else {
                                experienceErrors = experienceErrors.filter(
                                    (x) => x !== question?.fieldName,
                                );
                                question.fieldError = '';
                            }
                        } else {
                            experienceErrors.push(question?.fieldName);
                            question.fieldError = 'Required';
                        }
                    } else if (question.fieldType === AppInputTypes.FromToDatePicker) {
                        if (!question.values || !!!question?.values[0]) {
                            let fromDate =
                                question?.values?.length && question?.values[0]
                                    ? question?.values[0]
                                    : moment().format(DateFormats.MM_DD_YY_WITH_SLASH);
                            question.values = [
                                fromDate,
                                moment().format(DateFormats.MM_DD_YY_WITH_SLASH),
                            ];
                        }
                        if (!question?.values || !!!question?.values[1]) {
                            let toDate =
                                question?.values?.length && question?.values[1]
                                    ? question?.values[1]
                                    : moment().format(DateFormats.MM_DD_YY_WITH_SLASH);
                            question.values = [
                                moment().format(DateFormats.MM_DD_YY_WITH_SLASH),
                                toDate,
                            ];
                        }
                    } else {
                        experienceErrors = experienceErrors.filter(
                            (x) => x !== question?.fieldName,
                        );
                        question.fieldError = '';
                    }
                    return question;
                });
                return experience;
            });
        setAdjusterExperienceQuestionDetail(adjusterExperienceQuestionDetailCopy);
        return experienceErrors?.length === 0 && personalInfoErrors?.length === 0;
    };

    const convertExperienceToPayload = (experienceItem) => {
        let questions = [];
        experienceItem?.Questions?.forEach((questionItem) => {
            if (questionItem?.fieldType === AppInputTypes.FromToDatePicker) {
                questions.push({
                    QuestionId: questionItem?.LovID,
                    QuestionAnswer: questionItem?.values?.toString(),
                });
            } else {
                questionItem?.values.forEach((ValueItem) => {
                    questions.push({
                        QuestionId: questionItem?.LovID,
                        QuestionAnswer: ValueItem,
                    });
                });
            }
        });
        return questions;
    };

    const onClickSave = () => {
        if (checkFormValid()) {
            const {
                city: City,
                email: Email,
                firstName: FirstName,
                lastName: LastName,
                pilotNumber: PilotNumber,
                positionsAbleToWork: PositionsAbleToWork,
                professionalDesignation: ProfessionalDesignation,
                professionalSkillStrengths: ProfessionalSkillStrengths,
                state: State,
                streetAddress: StreetAddress,
                telephone: Telephone,
                zipCode: Zip,
            } = personalDetails;
            let payload = {
                PersonalInfo: {
                    City,
                    Email,
                    FirstName,
                    LastName,
                    PilotNumber,
                    PositionsAbleToWork,
                    ProfessionalDesignation,
                    ProfessionalSkillStrengths,
                    State,
                    StreetAddress,
                    Telephone,
                    Zip,
                    AddlComments: '',
                    StateLicenses: '',
                    FileUploadPath: '',
                    TWIACertification: '',
                    OtherCertifications: '',
                    ClaimsProgramsProficiency: '',
                    FileName: null,
                    FileInfo: null,
                },
                ExperienceQuestionDetail: adjusterExperienceQuestionDetail
                    .filter((x) => x.visible)
                    .map((experienceItem) => {
                        return {
                            ExperienceID: experienceItem?.ExperienceID,
                            DetailInfo: convertExperienceToPayload(experienceItem),
                        };
                    }),
            };
            submitData(payload);
        }
    };

    const onChangeExperienceQuestionField = ({
        value,
        experienceId,
        questionIndex,
        //if field type is FromToDatePicker
        dateType = '',
    }) => {
        let adjusterExperienceQuestionDetailCopy = [...adjusterExperienceQuestionDetail];
        let findExperienceById = adjusterExperienceQuestionDetailCopy.find(
            (x) => x.ExperienceID === experienceId,
        );

        if (findExperienceById) {
            let questionField = findExperienceById.Questions[questionIndex];
            if (
                questionField.fieldType === AppInputTypes.TextField ||
                questionField.fieldType === AppInputTypes.TextArea ||
                questionField.fieldType === AppInputTypes.DropDown
            ) {
                questionField.values = [value];
            } else if (questionField.fieldType === AppInputTypes.MultiSelectDropDown) {
                questionField.values = value;
            } else if (questionField.fieldType === AppInputTypes.FromToDatePicker) {
                if (dateType === 'from') {
                    questionField.values[0] = moment(value).format(DateFormats.MM_DD_YY_WITH_SLASH);
                } else if (dateType === 'to') {
                    questionField.values[1] = moment(value).format(DateFormats.MM_DD_YY_WITH_SLASH);
                }
            } else if (questionField.fieldType === AppInputTypes.FileUpload) {
                questionField.values = [value];
            }
            setAdjusterExperienceQuestionDetail(adjusterExperienceQuestionDetailCopy);
        }
    };

    const onChangePersonalDetails = (key, value) => {
        setPersonalDetails((prevState) => ({
            ...prevState,
            [key]: value,
        }));
    };

    const onClickTillNow = ({ experienceId, questionIndex }) => {
        let adjusterExperienceQuestionDetailCopy = [...adjusterExperienceQuestionDetail];
        let findExperienceById = adjusterExperienceQuestionDetailCopy.find(
            (x) => x.ExperienceID === experienceId,
        );

        if (findExperienceById) {
            let questionField = findExperienceById.Questions[questionIndex];
            let isTillNow = !questionField.isTillNow;
            questionField.isTillNow = isTillNow;
            if (isTillNow) {
                if (!Array.isArray(questionField.values)) {
                    questionField.values = [
                        moment().format(DateFormats.MM_DD_YY_WITH_SLASH),
                        moment().format(DateFormats.MM_DD_YY_WITH_SLASH),
                    ];
                } else {
                    questionField.values[1] = moment().format(DateFormats.MM_DD_YY_WITH_SLASH);
                }
            }
            setAdjusterExperienceQuestionDetail(adjusterExperienceQuestionDetailCopy);
        }
    };

    const renderSaveCancelContainer = () => {
        return (
            <Grid item container pt={1} pb={2} display={'flex'} sx={styles.saveCancelContainer}>
                <Button size="small" variant="outlined" component="label" onClick={() => {}}>
                    Cancel
                </Button>
                <Button size="small" variant="contained" component="label" onClick={onClickSave}>
                    Save
                </Button>
            </Grid>
        );
    };

    const renderMainHeader = () => {
        return (
            <Box p={2} display="flex" sx={styles.mainHeaderContainer}>
                <h2 style={{ color: Colors.DODGER_BLUE }}>Resume Questionnaire</h2>
                <Button size="small" variant="contained" component="label" onClick={onClickSave}>
                    Save
                </Button>
            </Box>
        );
    };

    return (
        <Box
            id={'questionnaire'}
            p={2}
            sx={{ position: 'relative' }}
            width={'100%'}
            display="block"
            className='main-content'
        >
            <LoadingIndicator
                isLoading={
                    getQuestionnaireByEXPCodesResponse?.isLoading ||
                    lovListResult?.isLoading ||
                    createQuestionnaireResponse?.isLoading
                }
            />
            {renderMainHeader()}
            <PersonalInfoQuestions
                onChangePersonalDetails={onChangePersonalDetails}
                personalDetails={personalDetails}
                personalDetailsErrors={personalDetailsErrors}
                professionalDesignations={professionalDesignations}
                positionsAbleToWork={positionsAbleToWork}
                professionalSkillStrengths={professionalSkillStrengths}
                states={stateResult?.Data}
            />
            <ExperienceQuestions
                adjusterExperienceQuestionDetail={adjusterExperienceQuestionDetail}
                onChangeExperienceQuestionField={onChangeExperienceQuestionField}
                onClickExperience={onClickExperience}
                onClickTillNow={onClickTillNow}
            />
            {renderSaveCancelContainer()}
            <ModalDialog
                title={'Resume created'}
                okText={'Ok'}
                description={'Your resume created successfully'}
                open={showSuccessDialog}
                onClickOk={() => setShowSuccessDialog(false)}
                onClose={() => setShowSuccessDialog(false)}
            />
        </Box>
    );
};

export default Questionnaire;

const styles = {
    saveCancelContainer: {
        borderRadius: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    mainHeaderContainer: {
        borderRadius: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingLeft: 0,
        paddingRight: 0,
    },
};
