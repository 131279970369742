import React, { useState, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import {
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    Alert,
    CircularProgress,
    Modal,
    Skeleton,
    Grid,
} from '@mui/material';
import SelectOption from '../../components/select-option';
import { useLoadData } from '../../hooks/use-load-data.hook';
import { usePostData } from '../../hooks/use-post-data.hook';
import { AppUrls } from '../../core/route.url';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { LoadingIndicator } from '../../components/loading-indicator.component';

const AssignmentTypeForm = (props) => {
    const history = useNavigate();
    const {
        register,
        handleSubmit,
        control,
        setValue,
        watch,
        formState: { errors },
    } = useForm();

    const watchAllFields = watch();
    const [savedResponse, setSaveResponse] = useState({});

    const { submitData, response } = usePostData(AppUrls.AssignmentTypeForSave, 'POST');

    const { submitData: submitDataForGet, response: getResponse } = usePostData(
        AppUrls.AssignmentTypeForGet,
        'POST',
    );
    const location = useLocation();

    const onSubmit = async (data) => {
        submitData({
            IsActive: data.active,
            AssignmentTypeId: props.idValue,
            AssignmentType: data.assignmentType,
            AssignmentDescription: data.description,
        });
    };

    useEffect(() => {
        if (response.result != undefined) {
            setSaveResponse(response.result);

            props.setIsLoadList(true);
            const timer = setTimeout(() => {
                setSaveResponse({});
                props.setIsLoadList(true);
                setValue('assignmentType', '');
                setValue('description', '');
                setValue('active', true);
                props.setIsOpen(false);
            }, 3000);
            return () => clearTimeout(timer);
        }
    }, [response]);

    React.useEffect(() => {
        if (1) {
            setValue('assignmentType', '');
            setValue('description', '');
            setValue('active', false);
        }
    }, []);

    React.useEffect(() => {
        if (props.idValue !== 0) {
            submitDataForGet({ AssignmentTypeId: props.idValue });
        }
    }, [props.idValue]);

    React.useEffect(() => {
        if (getResponse.result != undefined) {
            setValue('assignmentType', getResponse.result.Data.AssignmentType);
            setValue('description', getResponse.result.Data.AssignmentDescription);
            setValue('active', getResponse.result.Data.IsActive);
        }
    }, [getResponse]);

    return (
        <div className="main-content">
            <LoadingIndicator isLoading={getResponse.isSubmitting} />
            <h2 style={{ marginBottom: 20 }}>Assignment Type</h2>

            <Box
                component="form"
                sx={{
                    '& .MuiTextField-root': { m: 1, width: '54ch' },
                }}
                noValidate
                autoComplete="off"
                onSubmit={handleSubmit(onSubmit)}
            >
                <div>
                    {getResponse.isSubmitting ? (
                        <Grid item sx={{ height: 90, marginBottom: '-35px' }}>
                            <Skeleton sx={{ height: 90 }} />
                        </Grid>
                    ) : (
                        <TextField
                            required
                            fullWidth
                            error={errors.assignmentType}
                            id="outlined-required"
                            label="Assignment Type"
                            defaultValue=""
                            InputLabelProps={{
                                shrink: watchAllFields.assignmentType === '' ? false : true,
                            }}
                            {...register('assignmentType', { required: true })}
                        />
                    )}

                    {getResponse.isSubmitting ? (
                        <Grid item sx={{ height: 180, marginTop: '-35px' }}>
                            <Skeleton sx={{ height: 180 }} animation="wave" />
                        </Grid>
                    ) : (
                        <TextField
                            fullWidth
                            id="outlined-multiline-static"
                            label="Description"
                            multiline
                            rows={4}
                            error={errors.description}
                            defaultValue=""
                            InputLabelProps={{
                                shrink: watchAllFields.description === '' ? false : true,
                            }}
                            {...register('description', { required: false })}
                        />
                    )}
                </div>
                {getResponse.isSubmitting ? (
                    <Grid item sx={{ height: 90, marginTop: '-30px' }}>
                        <Skeleton sx={{ height: 90 }} animation={'wave'} />
                    </Grid>
                ) : (
                    <div className="d-flex" style={{ marginTop: 20 }}>
                        <Button
                            style={{ marginLeft: 2 }}
                            variant="outlined"
                            type="button"
                            onClick={() => {
                                props.setIsOpen(false);
                                props.setPropertyValues({ idValue: 0, name: '' });
                            }}
                        >
                            Cancel
                        </Button>

                        <FormControlLabel
                            style={{ marginLeft: 'auto' }}
                            control={
                                <Checkbox
                                    checked={
                                        watchAllFields.active !== undefined
                                            ? watchAllFields.active
                                            : false
                                    }
                                    {...register('active', { required: false })}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                            }
                            label="Active"
                        />
                        <Button style={{ marginLeft: 2 }} variant="contained" type="submit">
                            {response.isSubmitting ? <CircularProgress color="inherit" /> : 'Save'}
                        </Button>
                    </div>
                )}
            </Box>
            {savedResponse.IsSuccess != undefined ? (
                <Alert
                    style={{ marginTop: 15 }}
                    severity={savedResponse.IsSuccess ? 'success' : 'error'}
                >
                    {savedResponse.Message}
                </Alert>
            ) : null}
        </div>
    );
};
export default AssignmentTypeForm;
