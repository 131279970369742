import React, { useState } from 'react';
import pencilIcon from './../../images/pencil.svg';
import trashIcon from './../../images/trash.svg';
import pdfFile from './../../images/file-pdf.svg';
import docFile from './../../images/document.svg';
import xlFile from './../../images/file-excel.svg';
import imageFile from './../../images/image.svg';
import checKCircleOutline from './../../images/check-circle-outline.svg';
import fileDownload from './../../images/file-download.svg';
import { AppUrls } from '../../core/route.url';
import { usePostData } from '../../hooks/use-post-data.hook';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import {
    Button,
    CircularProgress,
    Modal,
    TextField,
    FormControl,
    Alert,
    Typography,
    Backdrop,
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import File_upload from '../../components/file_upload';
import { usePostData2 } from '../../hooks/use-post-data-2.hook';
import PropTypes from 'prop-types';
import { useLocation, useParams } from 'react-router-dom';
import SelectOption from '../../components/select-option';
import Moment from 'moment';
import DeleteModal from '../../components/delete-modal';
import { decryptWithAES } from '../../utils/global-functions';
import useUserPermissions from '../../hooks/use-user-permissions.hook';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 520,
    bgcolor: 'background.paper',
    boxShadow: 3,
    p: 3,
};

const documentTypes = [
    { value: 'XA', label: 'XactAnalysis' },
    { value: 'Internal', label: 'Internal' },
];

const ClaimProfileDocumetTab = ({
    cliamProfileDetails,
    taskResponse,
    valueData,
    handleRefreshCount,
    DocumentTypeResult,
}) => {
    let { id: param } = useParams();
    let decryptedParam = decryptWithAES(param?.replace(/%2F/g, '/'));
    let parsedParam = JSON.parse(decryptedParam);
    const { state } = parsedParam;
    const {
        register,
        handleSubmit,
        watch,
        control,
        setValue,
        formState: { errors },
    } = useForm();

    const { response, submitData } = usePostData2(AppUrls.ClaimDocumentList, {
        ParentType: state.page,
        ClaimId: cliamProfileDetails.ClaimId,
        ClaimTaskId:
            state?.page === 'task'
                ? taskResponse.result &&
                  taskResponse.result.Data &&
                  taskResponse.result.Data !== null
                    ? taskResponse.result.Data[valueData].ClaimTaskId
                    : 0
                : 0,
    });

    const { submitData: fileSubmit, response: fileSumbitRes } = usePostData(
        AppUrls.ClaimDocumentFileSave,
        'POST',
        {},
        'multipart/form-data',
    );

    const [isOpen, setIsOpen] = React.useState(false);

    const [filename1, setFileName1] = useState('');
    const [fileValidation, setFileValidation] = useState('');
    const [savedResponse, setSaveResponse] = React.useState({});
    const [obj1, setObj1] = useState({});
    const [propertyValues, setPropertyValues] = React.useState({ idValue: 0, name: '' });
    const [isDeleteOpen, setIsDeleteOpen] = React.useState(false);
    const [filterList, setFilterList] = React.useState([]);
    const [isLoad, setIsLoad] = React.useState(true);
    const [initialResult, setInitialResult] = React.useState([]);
    const [documentTypeData, setDocumentTypeData] = React.useState([]);
    const {
        handleSetAddPermissions,
        handleSetDeletePermissions,
        handleSetEditPermissions,
        handleSetPrintPermissions,
    } = useUserPermissions();
    const { submitData: submitDownlod, response: responseDownload } = usePostData(
        AppUrls.ClaimDocumentDownload,
        'POST',
    );

    const { submitData: submitDataForDelete, response: responseForDelete } = usePostData(
        AppUrls.ClaimDocumentDelete + `?ClaimDocumentId=${propertyValues.idValue}`,
        'Delete',
    );

    const { submitData: submitDataForUpdate, response: responseForUpdate } = usePostData(
        AppUrls.ClaimDocumentUpdate,
        'POST',
    );

    let fileArry = [];

    React.useEffect(() => {
        if (1) {
            setDocumentTypeData(
                DocumentTypeResult && DocumentTypeResult.Data != undefined
                    ? DocumentTypeResult.Data !== null
                        ? DocumentTypeResult.Data
                        : []
                    : [],
            );
        }
    }, []);

    React.useEffect(() => {
        if (
            fileSumbitRes.result !== undefined ||
            responseForDelete.result !== undefined ||
            responseForUpdate.result !== undefined
        ) {
            setSaveResponse(
                fileSumbitRes.result !== undefined
                    ? fileSumbitRes.result
                    : responseForUpdate.result !== undefined
                    ? responseForUpdate.result
                    : {},
            );
            setPropertyValues({ idValue: 0, name: '' });
            submitData({
                ParentType: state.page,
                ClaimId: cliamProfileDetails.ClaimId,
            });
            handleRefreshCount();
            const timer = setTimeout(() => {
                setSaveResponse({});
                setObj1({});
                setIsOpen(false);
                setFileName1('');
                setValue('documentType', '');
                setValue('comments', '');
                setFileValidation('');
            }, 1000);

            return () => clearTimeout(timer);
        }
    }, [fileSumbitRes, responseForDelete, responseForUpdate]);

    React.useEffect(() => {
        if (response.result !== undefined && isLoad) {
            setIsLoad(false);
            setFilterList(response.result.Data !== null ? response.result.Data : []);
            setInitialResult(response.result.Data !== null ? response.result.Data : []);
        }
    }, [response]);

    React.useEffect(() => {
        if (responseDownload.result != undefined) {
            const downloadLink = document.createElement('a');
            downloadLink.href = responseDownload.result.Data.DocumentFile;
            downloadLink.download = responseDownload.result.Data.DocumentName;
            downloadLink.click();
        }
    }, [responseDownload.result]);

    // React.useEffect(() => {
    //     if (idForDelete !== 0) {
    //         submitDataForDelete({});
    //     }
    // }, [idForDelete]);
    const watchAllFields = watch();
    const handleClose = () => {
        setSaveResponse({});
        setObj1({});
        setIsOpen(false);
        setFileName1('');
        setValue('documentType', '');
        setValue('comments', '');
        setFileValidation('');
    };

    const onSubmit = async (data) => {
        // console.log(data);
        if (propertyValues.idValue !== 0) {
            submitDataForUpdate({
                ClaimDocumentId: propertyValues.idValue.toString(),
                Comments: data.comments,
                UpdatedBy: 0,
            });
        } else {
            if (obj1.file != undefined) {
                const formData = new FormData();

                formData.append('File', obj1.file);
                formData.append('ClaimDocumentTypeId', data.documentType);
                formData.append('CarrierId', cliamProfileDetails.CarrierId);
                formData.append('ClaimId', cliamProfileDetails.ClaimId);
                formData.append(
                    'ClaimTaskId',
                    state?.page === 'task'
                        ? taskResponse.result &&
                          taskResponse.result.Data &&
                          taskResponse.result.Data !== null
                            ? taskResponse.result.Data[valueData].ClaimTaskId
                            : ''
                        : '',
                );
                formData.append('ParentType', state?.page === 'claim' ? 'Claim' : 'Task');
                formData.append('Comments', String(data.comments));
                formData.append('DocumentType', String(data.documentType));
                fileSubmit(formData);
            } else {
                setFileValidation('file not uploaded');
            }
        }
    };

    const handleDrop = (files) => {
        if (files[0].type != undefined) {
            if (files.length > 0) {
                fileArry.push(files[0]);
                setObj1((obj1) => ({ ...obj1, ['file']: files[0] }));

                setFileName1(files[0].name);
                setFileValidation('');
            }
            // }
        }
    };

    const onDownload = async (event) => {
        const claimDocumentId = event.currentTarget.id;
        submitDownlod({
            ClaimDocumentId: parseInt(claimDocumentId),
        });
    };

    if (response.result === undefined) {
        return (
            <Box sx={{ width: '100%' }}>
                <LinearProgress />
            </Box>
        );
    }

    const onHandleDeleteClick = (id) => {
        // setIdForDelete(id);
        submitDataForDelete({});
    };

    const handleDeleteClick = (inspectionId, name) => {
        setPropertyValues({ idValue: inspectionId, name: name });

        setIsDeleteOpen(true);
    };

    const handleEditClick = (documentId, name, comment, documentTypeId) => {
        setFileName1(name);

        setValue('comments', comment);
        setValue('documentType', documentTypeId);
        setPropertyValues({ idValue: documentId, name: '' });
        setIsOpen(true);
    };

    const handleChangeValue = (e) => {
        setFilterList(
            initialResult.filter((data) => {
                return (
                    (data.DocumentType &&
                        data.DocumentType.toLowerCase().includes(
                            e.target.value.toLocaleLowerCase(),
                        )) ||
                    (data.DocumentName &&
                        data.DocumentName.toLowerCase().includes(
                            e.target.value.toLocaleLowerCase(),
                        )) ||
                    (data.UserFullName &&
                        data.UserFullName.toLowerCase().includes(
                            e.target.value.toLocaleLowerCase(),
                        )) ||
                    (data.AddedUserEmail &&
                        data.AddedUserEmail.toLowerCase().includes(
                            e.target.value.toLocaleLowerCase(),
                        )) ||
                    (data.Comments &&
                        data.Comments.toLowerCase().includes(e.target.value.toLocaleLowerCase())) ||
                    (data.CreatedOn &&
                        Moment(data.CreatedOn)
                            .format('MM-DD-YYYY hh:mm A')
                            .toLowerCase()
                            .includes(e.target.value.toLocaleLowerCase()))
                );
            }),
        );
    };

    return (
        <>
            {' '}
            <DeleteModal
                isDeleteOpen={isDeleteOpen}
                setIsDeleteOpen={setIsDeleteOpen}
                propertyValues={propertyValues}
                setPropertyValues={setPropertyValues}
                handleDelete={onHandleDeleteClick}
            />
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={responseForDelete.isSubmitting}
                // onClick={handleClose}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Modal
                open={isOpen}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div>
                        <h2 style={{ marginBottom: 20 }}>Document Upload</h2>
                        <Box
                            component="form"
                            sx={{
                                '& .MuiTextField-root': { m: 1, width: '53ch' },
                            }}
                            noValidate
                            autoComplete="off"
                            onSubmit={handleSubmit(onSubmit)}
                        >
                            <FormControl sx={{ m: 1, width: '53ch' }}>
                                <Controller
                                    control={control}
                                    name="documentType"
                                    rules={{
                                        required: true,
                                    }}
                                    render={({ field: { onChange, onBlur, value, name } }) => (
                                        <SelectOption
                                            values={
                                                // documentTypeData !== undefined
                                                //     ? documentTypeData.length > 0
                                                //         ? documentTypeData.map((data) => {
                                                //               return {
                                                //                   value: data.ClaimDocumentTypeId,
                                                //                   label: data.ClaimDocumentTypeName,
                                                //               };
                                                //           })
                                                //         : documentTypes
                                                //     : documentTypes
                                                documentTypes
                                            }
                                            disabled={propertyValues.idValue !== 0 ? true : false}
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            value={value === undefined ? '' : value}
                                            name={'Document Type' || ''}
                                            error={errors.documentType}
                                        />
                                    )}
                                />
                            </FormControl>
                            <TextField
                                required
                                error={errors.comments}
                                id="outlined-required"
                                label="Comments"
                                multiline
                                rows={6}
                                defaultValue=""
                                InputLabelProps={{
                                    shrink: watchAllFields.comments === '' ? false : true,
                                }}
                                {...register('comments', { required: true })}
                            />
                            <div style={{ textAlign: 'center', marginTop: 12 }}>
                                <label htmlFor="contained-button-file">
                                    <File_upload
                                        accept=".pdf,.jpg,.jpeg,.png,  "
                                        onChange={(file) => {
                                            handleDrop([file]);
                                        }}
                                        name="Click here to upload document"
                                        disabled={propertyValues.idValue !== 0 ? true : false}
                                    />
                                    <span>{filename1}</span><br></br>
                                    <span style={{ color: 'red' }}>{fileValidation}</span><br></br>
                                    <span>Notes: Dispatch accepts only PDF and Images </span>
                                </label>
                            </div>
                            <div className="d-flex" style={{ marginTop: 20 }}>
                                <Button
                                    style={{ marginLeft: 2 }}
                                    onClick={() => {
                                        setIsOpen(false);
                                    }}
                                    variant="outlined"
                                    type="button"
                                >
                                    Cancel
                                </Button>

                                <Button
                                    style={{ marginLeft: 'auto' }}
                                    variant="contained"
                                    type="submit"
                                >
                                    {fileSumbitRes.isSubmitting ||
                                    responseForUpdate.isSubmitting ? (
                                        <CircularProgress color="inherit" />
                                    ) : (
                                        'Save'
                                    )}
                                </Button>
                            </div>
                            {savedResponse.message !== undefined ? (
                                <Alert
                                    style={{ marginTop: 15 }}
                                    severity={savedResponse.IsSuccess ? 'success' : 'error'}
                                >
                                    {savedResponse.Message}
                                </Alert>
                            ) : null}
                        </Box>
                    </div>
                </Box>
            </Modal>
            <div className="Document">
                <div style={{ position: 'absolute', right: 0, top: '-66px' }}>
                    {
                        <TextField
                            id="outlined-multiline-static"
                            placeholder="Search"
                            defaultValue=""
                            onChange={(e) => handleChangeValue(e)}
                            className="searchinput"
                            size="small"
                            style={{ position: 'relative', right: 15, top: '-1px' }}
                        />
                    }
                    {state?.page === 'claim' ? (
                        handleSetAddPermissions('Claims') ? (
                            <Button
                                style={{
                                    boxShadow: 'none',
                                }}
                                variant="contained"
                                size="small"
                                type="button"
                                onClick={() => {
                                    setIsOpen(true);
                                    setPropertyValues({ idValue: 0, name: '' });
                                    setFileName1('');
                                    setValue('documentType', '');
                                    setFileValidation('');
                                    setValue('comments', '');
                                    setSaveResponse({});
                                    setObj1({});
                                }}
                            >
                                Upload Document
                            </Button>
                        ) : null
                    ) : handleSetAddPermissions('Tasks') ? (
                        <Button
                            style={{
                                boxShadow: 'none',
                            }}
                            variant="contained"
                            size="small"
                            type="button"
                            onClick={() => {
                                setIsOpen(true);
                                setPropertyValues({ idValue: 0, name: '' });
                                setFileName1('');
                                setValue('documentType', '');
                                setFileValidation('');
                                setValue('comments', '');
                                setSaveResponse({});
                                setObj1({});
                            }}
                        >
                            Upload Document
                        </Button>
                    ) : null}
                </div>

                {/* Tabbed Document list start */}
                {responseForDelete.isSubmitting ? (
                    <Box sx={{ width: '100%' }}>
                        <LinearProgress />
                    </Box>
                ) : null}
                {filterList !== null
                    ? filterList.length > 0
                        ? filterList.map((data, index) => {
                              if (data.DocumentType === 'XA') {
                                  return (
                                      <fieldset className="xat">
                                          <legend>XactAnalysis</legend>
                                          <div className="document-card">
                                              <div>
                                                  <div className="d-flex gutter-1 relative item-center">
                                                      <div>
                                                          <i>
                                                              {data.MimeType ===
                                                              'application/vnd.openxmlformats-officedocument.word' ? (
                                                                  <img
                                                                      width={18}
                                                                      alt=" "
                                                                      src={docFile}
                                                                  />
                                                              ) : data.MimeType ===
                                                                'application/vnd.openxmlformats-officedocument.spre' ? (
                                                                  <img alt=" " src={xlFile} />
                                                              ) : data.MimeType === 'image/png' ? (
                                                                  <img
                                                                      width={18}
                                                                      alt=" "
                                                                      src={imageFile}
                                                                  />
                                                              ) : (
                                                                  <img alt=" " src={pdfFile} />
                                                              )}
                                                          </i>
                                                      </div>
                                                      <div>
                                                          <h4>{data.DocumentName}</h4>
                                                          <Typography
                                                              fontSize={12}
                                                              variant="caption"
                                                              className="gray-text"
                                                          >
                                                              {data.UserFullName}
                                                          </Typography>
                                                          <i className="status-mark">
                                                              <img
                                                                  alt=" "
                                                                  src={checKCircleOutline}
                                                              />{' '}
                                                          </i>{' '}
                                                      </div>
                                                  </div>
                                              </div>
                                              <div>{data.Comments}</div>
                                              <div>
                                                  <div
                                                      className="d-flex gutter-1"
                                                      style={{ justifyContent: 'right' }}
                                                  >
                                                      <p className="gray-text no-wrap">
                                                          {Moment(data.CreatedOn).format(
                                                              'MM-DD-YYYY hh:mm A',
                                                          )}
                                                      </p>
                                                      {/* <button
                                                        className="icons"
                                                        onClick={() => {
                                                            handleEditClick(
                                                                data.ClaimDocumentId,
                                                                data.DocumentName,
                                                                data.Comments,
                                                                data.DocumentType,
                                                            );
                                                        }}
                                                    >
                                                        <img alt=" " src={pencilIcon} />
                                                    </button> */}
                                                      {state?.page === 'claim' ? (
                                                          handleSetPrintPermissions('Claims') ? (
                                                              <button
                                                                  className="icons"
                                                                  onClick={onDownload}
                                                                  id={data.ClaimDocumentId}
                                                              >
                                                                  <img alt=" " src={fileDownload} />
                                                              </button>
                                                          ) : null
                                                      ) : handleSetPrintPermissions('Tasks') ? (
                                                          <button
                                                              className="icons"
                                                              onClick={onDownload}
                                                              id={data.ClaimDocumentId}
                                                          >
                                                              <img alt=" " src={fileDownload} />
                                                          </button>
                                                      ) : null}
                                                      {/* <button
                                                        onClick={() => {
                                                            handleDeleteClick(
                                                                data.ClaimDocumentId,
                                                                data.DocumentName,
                                                            );
                                                        }}
                                                        className="icons"
                                                    >
                                                        <img alt=" " src={trashIcon} />
                                                    </button> */}
                                                  </div>
                                              </div>
                                          </div>
                                      </fieldset>
                                  );
                              } else {
                                  return (
                                      <fieldset className="intern">
                                          <legend>Internal</legend>
                                          <div className="document-card">
                                              <div>
                                                  <div className="d-flex gutter-1 relative item-center">
                                                      <div>
                                                          <i>
                                                              {data.MimeType ===
                                                              'application/vnd.openxmlformats-officedocument.word' ? (
                                                                  <img
                                                                      width={18}
                                                                      alt=" "
                                                                      src={docFile}
                                                                  />
                                                              ) : data.MimeType ===
                                                                'application/vnd.openxmlformats-officedocument.spre' ? (
                                                                  <img alt=" " src={xlFile} />
                                                              ) : data.MimeType === 'image/png' ? (
                                                                  <img
                                                                      width={18}
                                                                      alt=" "
                                                                      src={imageFile}
                                                                  />
                                                              ) : (
                                                                  <img alt=" " src={pdfFile} />
                                                              )}
                                                          </i>
                                                      </div>
                                                      <div>
                                                          <h4>{data.DocumentName}</h4>
                                                          <Typography
                                                              fontSize={12}
                                                              variant="caption"
                                                              className="gray-text"
                                                          >
                                                              {data.UserFullName}
                                                          </Typography>
                                                          <i className="status-mark">
                                                              <img
                                                                  alt=" "
                                                                  src={checKCircleOutline}
                                                              />{' '}
                                                          </i>{' '}
                                                      </div>
                                                  </div>
                                              </div>
                                              <div>{data.Comments}</div>
                                              <div>
                                                  <div
                                                      className="d-flex gutter-1"
                                                      style={{ justifyContent: 'right' }}
                                                  >
                                                      <p className="gray-text no-wrap">
                                                          {Moment(data.CreatedOn).format(
                                                              'MM-DD-YYYY hh:mm A',
                                                          )}
                                                      </p>
                                                      {state?.page === 'claim' ? (
                                                          handleSetEditPermissions('Claims') ? (
                                                              <button
                                                                  className="icons"
                                                                  onClick={() => {
                                                                      handleEditClick(
                                                                          data.ClaimDocumentId,
                                                                          data.DocumentName,
                                                                          data.Comments,
                                                                          data.DocumentType,
                                                                      );
                                                                  }}
                                                              >
                                                                  <img alt=" " src={pencilIcon} />
                                                              </button>
                                                          ) : null
                                                      ) : handleSetEditPermissions('Tasks') ? (
                                                          <button
                                                              className="icons"
                                                              onClick={() => {
                                                                  handleEditClick(
                                                                      data.ClaimDocumentId,
                                                                      data.DocumentName,
                                                                      data.Comments,
                                                                      data.DocumentType,
                                                                  );
                                                              }}
                                                          >
                                                              <img alt=" " src={pencilIcon} />
                                                          </button>
                                                      ) : null}
                                                      {state?.page === 'claim' ? (
                                                          handleSetPrintPermissions('Claims') ? (
                                                              <button
                                                                  className="icons"
                                                                  onClick={onDownload}
                                                                  id={data.ClaimDocumentId}
                                                              >
                                                                  <img alt=" " src={fileDownload} />
                                                              </button>
                                                          ) : null
                                                      ) : handleSetPrintPermissions('Tasks') ? (
                                                          <button
                                                              className="icons"
                                                              onClick={onDownload}
                                                              id={data.ClaimDocumentId}
                                                          >
                                                              <img alt=" " src={fileDownload} />
                                                          </button>
                                                      ) : null}
                                                      {state?.page === 'claim' ? (
                                                          handleSetDeletePermissions('Claims') ? (
                                                              <button
                                                                  onClick={() => {
                                                                      handleDeleteClick(
                                                                          data.ClaimDocumentId,
                                                                          data.DocumentName,
                                                                      );
                                                                  }}
                                                                  className="icons"
                                                              >
                                                                  <img alt=" " src={trashIcon} />
                                                              </button>
                                                          ) : null
                                                      ) : handleSetDeletePermissions('Tasks') ? (
                                                          <button
                                                              onClick={() => {
                                                                  handleDeleteClick(
                                                                      data.ClaimDocumentId,
                                                                      data.DocumentName,
                                                                  );
                                                              }}
                                                              className="icons"
                                                          >
                                                              <img alt=" " src={trashIcon} />
                                                          </button>
                                                      ) : null}
                                                  </div>
                                              </div>
                                          </div>
                                      </fieldset>
                                  );
                              }
                          })
                        : 'No Records'
                    : 'No Records'}

                {/* Tabbed Document list end */}
            </div>
        </>
    );
};

export default ClaimProfileDocumetTab;
ClaimProfileDocumetTab.propTypes = {
    cliamProfileDetails: PropTypes.any.isRequired,
    taskResponse: PropTypes.any,
    valueData: PropTypes.any,
    handleRefreshCount: PropTypes.func,
    DocumentTypeResult: PropTypes.any,
};
