import PropTypes from 'prop-types';
import { Box, Stack, Typography, Button } from '@mui/material/';
import { Room } from '@mui/icons-material/';

export const MapInfoResourceComponent = ({ resource }) => {
    // const navigate = useNavigate();

    const navigateToResourceDetailPage = () => {
        if (resource.UserGuID) {
            // navigate(`/user/${resource.UserGuID}`, {
            //     state: {
            //         page: 'task',
            //         taskGuId: resource.UserGuID,
            //     },
            // });
            window.open(`/user/${resource.UserGuID}`, '_blank');
        }
    };

    if (!resource) {
        return <Typography variant="h6">No Info</Typography>;
    }

    return (
        <Box padding={1}>
            <Box className="clmPopGrid-3">
                {resource.EmailAddress && (
                    <Stack>
                        <Typography
                            variant="h3"
                            color={'#000'}
                            fontWeight={600}
                            marginBottom={'10px'}
                        >
                            {resource.ResourceName ? resource.ResourceName : resource.UserFullName}
                        </Typography>
                        <Typography variant="inherit">{resource.EmailAddress}</Typography>
                    </Stack>
                )}
                {/* {resource.EfficiencyRating && (
                    <Stack>
                        {resource.EfficiencyRating}
                        <Typography variant="h3" fontWeight={600} color={'#000'}>
                            4.3/<Typography variant="caption">5</Typography>
                        </Typography>
                        <Rating name="simple-controlled" size="small" readOnly />
                        <Typography variant="caption">258 Efficiency rating</Typography>
                    </Stack>
                )} */}
                {/* <Stack>
                    {resource.InsuredRating}
                    <Typography variant="h6">
                        4.3/
                        <Typography color={'#000'} variant="caption">
                            5
                        </Typography>
                    </Typography>
                    <Rating name="simple-controlled" size="small" readOnly />
                    <Typography variant="caption">38 Insured rating</Typography>
                </Stack> */}
                <Stack style={{ display: 'flex', alignItems: 'flex-start' }}>
                    <Button
                        style={{ marginLeft: 'auto', marginBottom: 10, boxShadow: 'none' }}
                        variant="contained"
                        size="small"
                        onClick={navigateToResourceDetailPage}
                    >
                        More
                    </Button>{' '}
                    {/* <Button
                        style={{ marginLeft: 'auto' }}
                        variant="outlined"
                        size="small"
                        onClick={() => assignClaim(resource.ResourceId)}
                    >
                        Assign Selected Claims
                    </Button> */}
                </Stack>
            </Box>

            <Box className={'clmPopGrid-all'}>
                {resource.ContactAddress && (
                    <Stack>
                        <Typography
                            variant="h4"
                            style={{ display: 'flex', alignItems: 'flex-start' }}
                        >
                            <Room style={{ color: '#A3CAED' }} />
                            <p>{resource.ContactAddress}</p>
                        </Typography>
                    </Stack>
                )}
                {resource.License && (
                    <Stack>
                        <Typography variant="h4">{resource.License}</Typography>
                        <Typography variant="caption">License</Typography>
                    </Stack>
                )}
                {resource.Skill && (
                    <Stack>
                        <Typography variant="h4">{resource.Skill}</Typography>
                        <Typography variant="caption">Skill</Typography>
                    </Stack>
                )}
                <Stack>
                    <Typography variant="h4">{resource.AssignedClaims}</Typography>
                    <Typography variant="caption">Assigned Claims</Typography>
                </Stack>
                {resource.WorkRadius && resource.WorkRadius > 0 && (
                    <Stack>
                        <Typography variant="h4">{resource.WorkRadius} miles</Typography>
                        <Typography variant="caption">WorkRadius</Typography>
                    </Stack>
                )}
            </Box>
        </Box>
    );
};

MapInfoResourceComponent.propTypes = {
    resource: PropTypes.any.isRequired,
    assignClaim: PropTypes.func,
};
