import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    Alert,
    Box,
    Button,
    CircularProgress,
    FormControl,
    TextField,
    Modal,
    Checkbox,
    FormControlLabel,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import SelectOption from '../../components/select-option';
import { AppUrls } from '../../core/route.url';
import { usePostData } from '../../hooks/use-post-data.hook';
import { useLoadData } from '../../hooks/use-load-data.hook';
import { usePostData2 } from '../../hooks/use-post-data-2.hook';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import Moment from 'moment';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 520,
    bgcolor: 'background.paper',
    boxShadow: 3,
    p: 3,
};
const ClaimProfileTaskForm = ({
    idValue,
    reloadTakData,
    cliamProfileDetails,
    setIsOpen,
    taskDetails,
    handleRefreshCount,
    handleRefreshTask,
}) => {
    const {
        register,
        handleSubmit,
        control,
        setValue,
        watch,
        formState: { errors },
    } = useForm();

    const [isLoadData, setIsLoadData] = React.useState(false);

    const { response: carrierList, isError: carrierError } = usePostData2(AppUrls.carrierList, {
        CarrierName: '',
        CarrierUniqueId: '',
    });

    const watchAllFields = watch();

    const [savedResponse, setSaveResponse] = useState({});
    const [isLoad, setLoad] = useState(false);

    const { submitData, response } = usePostData(AppUrls.ClaimGeneralTaskSave, 'POST');

    React.useEffect(() => {
        if (isLoad) {
            setLoad(false);
            handleRefreshTask();
            // handleRefreshCount();
        }
    }, [isLoad]);

    useEffect(() => {
        if (response.result != undefined) {
            setSaveResponse(response.result);
            reloadTakData();
            setLoad(true);
            const timer = setTimeout(() => {
                setSaveResponse({});

                setIsOpen(false);
            }, 1000);
            return () => clearTimeout(timer);
        }
    }, [response]);

    React.useEffect(() => {
        if (taskDetails !== 0) {
            setValue('CarrierId', taskDetails.CarrierId);
            setValue('generalTaskStatus', taskDetails.ClaimTaskStatusId);
            // setValue('assignee', );
            setValue('startDate', taskDetails.GenTaskStartTime);
            setValue('dueDate', taskDetails.DueDate);
            setValue(
                'shortDescription',
                taskDetails.ShortDescription === null ? '' : taskDetails.ShortDescription,
            );

            setValue('active', taskDetails.IsActive);
        }
    }, [taskDetails]);

    // if (inspectionTypeError || assignmentTypeError || perilError) {
    //     return <div>Error Loading Data</div>;
    // }

    // if (inspectionTypeLoading || assignmentTypeLoading || perilLoading) {
    //     return <div>Loading....</div>;
    // }
    const currentdate = new Date();
    const generalTaskStatus = [
        { value: 1, label: 'New' },
        { value: 2, label: 'In Progress' },
        { value: 3, label: 'Complete' },
    ];

    const onSubmit = async (data) => {
        submitData({
            IsActive: true,
            TenantId: 'string',
            ClaimTaskGuID: idValue,

            ClaimGuID: cliamProfileDetails.ClaimGuID,
            ClaimTaskStatusId: data.generalTaskStatus !== undefined ? data.generalTaskStatus : 0,
            ClaimTaskStatusName:
                data.generalTaskStatus !== undefined
                    ? generalTaskStatus.filter((d) => {
                          return d.value === data.generalTaskStatus;
                      })[0].label
                    : '',
            DueDate:
                data.dueDate === 'Invalid date' || data.dueDate === undefined
                    ? currentdate
                    : typeof data.dueDate === 'string'
                    ? data.dueDate
                    : new Date(data.dueDate.setHours(12)),
            // new Date(data.dueDate.setHours(12)),
            // data.dueDate !== 'Invalid date'
            //     ? `${Moment(currentdate).format('DD-MM-YYYY')}`
            //     : data.dueDate,
            GenTaskStartTime:
                data.dueDate === 'Invalid date' || data.startDate === undefined
                    ? currentdate
                    : typeof data.startDate === 'string'
                    ? data.startDate
                    : new Date(data.startDate.setHours(12)),
            // new Date(data.startDate.setHours(12)),
            // data.startDate !== 'Invalid date'
            //     ? `${Moment(currentdate).format('DD-MM-YYYY')}`
            //     : data.startDate,
            ShortDescription: data.shortDescription,
        });
    };

    // if (carrierError) {
    //     return <div>Error Loading Data</div>;
    // }
    // const handleClose = () => {
    //     setIsClaimInfoOpen(false);
    // };

    return (
        <>
            <Box sx={style}>
                <h2 style={{ marginBottom: 20 }}>General Task Information</h2>
                <Box
                    component="form"
                    fullWidth
                    noValidate
                    autoComplete="off"
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <div>
                        {/* <FormControl sx={{ m: 1, width: '53ch' }}>
                            <Controller
                                control={control}
                                name="CarrierId"
                                rules={{
                                    required: true,
                                }}
                                render={({ field: { onChange, onBlur, value, name } }) => (
                                    <SelectOption
                                        values={
                                            carrierList.result !== undefined
                                                ? carrierList.result?.Data?.map((e) => {
                                                      return {
                                                          value: e['CarrierId'],
                                                          label: e['CarrierName'],
                                                      };
                                                  })
                                                : []
                                        }
                                        disabled={false}
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        value={value === undefined ? '' : value}
                                        // name={name || ''}
                                        name={'Carrier ' || ''}
                                        error={errors.CarrierId}
                                    />
                                )}
                            />
                        </FormControl> */}
                        <FormControl sx={{ mb: 2 }} fullWidth>
                            <Controller
                                fullWidth
                                control={control}
                                name="generalTaskStatus"
                                rules={{
                                    required: false,
                                }}
                                render={({ field: { onChange, onBlur, value, name } }) => (
                                    <SelectOption
                                        values={generalTaskStatus.map((e) => {
                                            return {
                                                value: e['value'],
                                                label: e['label'],
                                            };
                                        })}
                                        disabled={false}
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        value={value === undefined ? '' : value}
                                        // name={name || ''}
                                        name={'Status ' || ''}
                                        error={errors.generalTaskStatus}
                                    />
                                )}
                            />
                        </FormControl>
                        {/* <FormControl sx={{ m: 1, width: '53ch' }}>
                            <Controller
                                control={control}
                                name="assignee"
                                rules={{
                                    required: false,
                                }}
                                render={({ field: { onChange, onBlur, value, name } }) => (
                                    <SelectOption
                                        values={[]}
                                        disabled={false}
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        value={value === undefined ? '' : value}
                                        // name={name || ''}
                                        name={'Assignee ' || ''}
                                        error={errors.assignee}
                                    />
                                )}
                            />
                        </FormControl> */}
                        <FormControl fullWidth sx={{ mb: 2 }}>
                            <Controller
                                fullWidth
                                control={control}
                                name="startDate"
                                rules={{
                                    required: false,
                                }}
                                render={({ field: { onChange, onBlur, value, name } }) => (
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DesktopDatePicker
                                            label="Start Date"
                                            inputFormat="MM/dd/yyyy"
                                            value={value !== '' ? value : ''}
                                            onChange={onChange}
                                            renderInput={(params) => (
                                                <TextField fullWidth size="small" {...params} />
                                            )}
                                        />
                                    </LocalizationProvider>
                                )}
                            />
                        </FormControl>

                        <FormControl fullWidth sx={{ mb: 2 }}>
                            <Controller
                                fullWidth
                                control={control}
                                name="dueDate"
                                rules={{
                                    required: false,
                                }}
                                render={({ field: { onChange, onBlur, value, name } }) => (
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DesktopDatePicker
                                            label="Due Date"
                                            inputFormat=" MM/dd/yyyy"
                                            value={value !== '' ? value : null}
                                            onChange={onChange}
                                            renderInput={(params) => (
                                                <TextField fullWidth size="small" {...params} />
                                            )}
                                        />
                                    </LocalizationProvider>
                                )}
                            />
                        </FormControl>

                        <TextField
                            fullWidth
                            error={errors.shortDescription}
                            id="outlined-multiline-static"
                            label="Short Description"
                            multiline
                            rows={4}
                            defaultValue=""
                            InputLabelProps={{
                                shrink: watchAllFields.shortDescription === '' ? false : true,
                            }}
                            {...register('shortDescription', { required: true })}
                        />
                    </div>
                    <div className="d-flex" style={{ marginTop: 20 }}>
                        <Button
                            style={{ marginLeft: 2 }}
                            onClick={() => {
                                setIsOpen(false);
                                setValue('noteBody', '');
                            }}
                            variant="outlined"
                            type="button"
                        >
                            Cancel
                        </Button>
                        {/* <FormControlLabel
                            style={{ marginLeft: 'auto' }}
                            control={
                                <Checkbox
                                    checked={
                                        watchAllFields.active !== undefined
                                            ? watchAllFields.active
                                            : false
                                    }
                                    {...register('active', { required: false })}
                                    //   onChange={handleChange}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                            }
                            label="Active"
                        /> */}

                        <Button style={{ marginLeft: 'auto' }} variant="contained" type="submit">
                            {response.isSubmitting ? <CircularProgress color="inherit" /> : 'Save'}
                        </Button>
                    </div>
                </Box>
                {savedResponse.IsSuccess != undefined ? (
                    <Alert
                        style={{ marginTop: 15 }}
                        severity={savedResponse.IsSuccess ? 'success' : 'error'}
                    >
                        {savedResponse.Message}
                    </Alert>
                ) : null}
            </Box>
        </>
    );
};

export default ClaimProfileTaskForm;
ClaimProfileTaskForm.propTypes = {
    idValue: PropTypes.string,
    cliamProfileDetails: PropTypes.any.isRequired,
    setIsOpen: PropTypes.func,
    reloadTakData: PropTypes.func,
    taskDetails: PropTypes.any,
    handleRefreshCount: PropTypes.func,
    handleRefreshTask: PropTypes.func,
};
