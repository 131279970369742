import React from 'react';
import PropTypes from 'prop-types';
import { Box, LinearProgress, Breadcrumbs, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { useLoadData2 } from '../../hooks/use-load-data-2.hook';
import { AppUrls } from '../../core/route.url';
import { usePostData } from '../../hooks/use-post-data.hook';
const ClaimSummary = ({ filterList, OnHandleChange, isLoadingData, claimType }) => {
    const [active, setActive] = React.useState();

    const { response: result, submitData } = usePostData(AppUrls.claimsStatusCountList, 'POST');

    React.useEffect(() => {
        if (isLoadingData) {
            submitData({ FilterType: claimType });
        }
    }, [isLoadingData]);

    React.useEffect(() => {
        if (isLoadingData) {
            setActive('');
        }
    }, [isLoadingData]);

    if (result.result === undefined) {
        return (
            <Box sx={{ width: '100%' }}>
                <LinearProgress />
            </Box>
        );
    }

    const handleChangeValue = (value, count, index) => {
        OnHandleChange(value, count, active === index ? 0 : 1);
        setActive(active === index ? '' : index);
    };
    return (
        <>
            {' '}
            <section className="claim-dashboard-header">
                {/*<h1 className="title">Claims</h1>*/}
                {/* <Box>
                    <Typography variant="h1" className="title m-0">
                        Claims
                    </Typography>
                </Box> */}
                <div className="dashboard-status-block rightNone">
                    {/* list */}
                    {result.result.Data && result.result.Data
                        ? result.result.Data.length > 0
                            ? result.result.Data.map((data, index) => {
                                  return (
                                      <div
                                          className={`block-list ${
                                              active === index ? 'active' : ''
                                          }`}
                                          onClick={() =>
                                              handleChangeValue(data.Name, data.Count, index)
                                          }
                                          style={{ cursor: 'pointer' }}
                                      >
                                          <div>
                                              <h3>{data.Count}</h3>
                                              <label>{data.Name}</label>
                                          </div>
                                          <div>
                                              <div className="yellow-stat"></div>
                                              <div className="red-stat"></div>
                                          </div>
                                      </div>
                                  );
                              })
                            : null
                        : null}

                    {/* list */}
                </div>
            </section>
        </>
    );
};

export default ClaimSummary;
ClaimSummary.propTypes = {
    filterList: PropTypes.array,
    OnHandleChange: PropTypes.func.isRequired,
    isLoadingData: PropTypes.bool,
    claimType: PropTypes.string,
};
