import React from 'react';
import PropTypes from 'prop-types';
import { Box, LinearProgress } from '@mui/material';

const InspectorSummary = ({ filterList, OnHandleChange }) => {
    const [active, setActive] = React.useState();
    if (filterList.ClaimsStatusInfo === undefined) {
        return (
            <Box sx={{ width: '100%' }}>
                <LinearProgress />
            </Box>
        );
    }
    if (filterList.ClaimsStatusInfo === null) {
        return null;
    }
    const handleChangeValue = (value, count, index) => {
        OnHandleChange(value, count, active === index ? 0 : 1);
        setActive(active === index ? '' : index);
    };
    return (
        <>
            {' '}
            <section className="claim-dashboard-header">
                {/* <h1 className="title">Claims</h1> */}
                <div className="dashboard-status-block taskStat inspTop noRightcolor">
                    {/* list */}
                    {filterList.ClaimsStatusInfo.map((data, index) => {
                        return (
                            <div
                                className={`block-list ${active === index ? 'active' : ''}`}
                                onClick={() => handleChangeValue(data.Name, data.Count, index)}
                                style={{ cursor: 'pointer' }}
                            >
                                <div>
                                    <h3>{data.Count}</h3>
                                    <label>{data.Name}</label>
                                </div>
                            </div>
                        );
                    })}

                    {/* list */}
                </div>
            </section>
        </>
    );
};

export default InspectorSummary;
InspectorSummary.propTypes = {
    filterList: PropTypes.array,
    OnHandleChange: PropTypes.func.isRequired,
};
