import React from 'react';
import { usePostData } from '../../hooks/use-post-data.hook';
import { AppUrls } from '../../core/route.url';
import AuditTrailList from './auditTrailList';
import LinearProgress from '@mui/material/LinearProgress';
import { CircularProgress, Backdrop } from '@mui/material';
import {
    IntlProvider,
    load,
    // LocalizationProvider,
    loadMessages,
    IntlService,
} from '@progress/kendo-react-intl';
import moment from 'moment/moment';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Stack, Grid, Box } from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
const AuditTrail = () => {
    const { submitData, response } = usePostData(AppUrls.AuditTrail, 'POST');
    const [filterList, setFilterList] = React.useState([]);
    const [isLoadList, setIsLoadList] = React.useState(false);
    const [fromDatevalue, setFromDateValue] = React.useState(
        moment(new Date()).subtract(7, 'd').format('YYYY-MM-DD'),
    );
    const [toDatevalue, setToDateValue] = React.useState(new Date());
    const DATE_FORMAT = 'yyyy-mm-ddThh:mm:ss.SSS';
    const intl = new IntlService('en');
    React.useEffect(() => {
        setIsLoadList(true);
    }, []);

    React.useEffect(() => {
        if (isLoadList) {
            setIsLoadList(false);
            submitData({
                // PageNo: 1,
                // PageSize: 100,
                // SortDir: '',
                // SortExp: '',
                // StartDate: '2022-05-01T04:54:29.010Z',
                // EndDate: '2022-06-08T04:54:29.010Z',
                // TaskName: '',
                TaskName: '',
                StartDate: '2022-09-30T10:06:50.427Z',
                EndDate: null,
            });
        }
    }, [isLoadList]);

    React.useEffect(() => {
        if (response.result != undefined) {
            if (response.result.IsSuccess) {
                // to format the date
                response.result.Data.forEach((o) => {
                    o.DateOn = moment(o.DateOn).format('MM-DD-YYYY hh:mm A'); //intl.parseDate(o.DateOn ? o.DateOn : new Date(), DATE_FORMAT);
                });
                setFilterList(response.result.Data);
            }
        }
    }, [response]);

    const handleDateFromChange = (newValue) => {
        if (moment(toDatevalue).isAfter(newValue)) {
            setFromDateValue(newValue);
            submitData({
                TaskName: '',
                StartDate: new Date(newValue.setHours(12)),

                EndDate:
                    typeof toDatevalue === 'string'
                        ? new Date(toDatevalue)
                        : new Date(toDatevalue.setHours(12)),
            });
        } else {
            setFromDateValue(newValue);
        }
    };

    const handleDateToChange = (newValue) => {
        if (moment(fromDatevalue).isBefore(newValue)) {
            setToDateValue(newValue);
            submitData({
                TaskName: '',
                StartDate:
                    typeof fromDatevalue === 'string'
                        ? new Date(fromDatevalue)
                        : new Date(fromDatevalue.setHours(12)),
                EndDate: new Date(newValue.setHours(12)),
            });
        } else {
            setToDateValue(newValue);
        }
    };

    return (
        <div className="main-content">
            <Grid item xs={6} sx={{ display: 'flex' }}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Stack direction="row" sx={{ marginLeft: 'auto' }} spacing={2}>
                        <DesktopDatePicker
                            label="From"
                            inputFormat="dd/MM/yyyy"
                            value={fromDatevalue}
                            onChange={handleDateFromChange}
                            renderInput={(params) => (
                                <TextField
                                    size="small"
                                    {...params}
                                    error={
                                        moment(moment(fromDatevalue).format('YYYY-MM-DD')).isSame(
                                            moment(toDatevalue).format('YYYY-MM-DD'),
                                        )
                                            ? false
                                            : !moment(toDatevalue).isAfter(fromDatevalue)
                                    }
                                />
                            )}
                            maxDate={new Date()}
                        />
                        <DesktopDatePicker
                            label="To"
                            inputFormat="dd/MM/yyyy"
                            value={toDatevalue}
                            onChange={handleDateToChange}
                            renderInput={(params) => (
                                <TextField
                                    size="small"
                                    {...params}
                                    error={
                                        moment(moment(fromDatevalue).format('YYYY-MM-DD')).isSame(
                                            moment(toDatevalue).format('YYYY-MM-DD'),
                                        )
                                            ? false
                                            : !moment(fromDatevalue).isBefore(toDatevalue)
                                    }
                                />
                            )}
                            // minDate={fromDatevalue}
                        />
                    </Stack>
                </LocalizationProvider>
            </Grid>
            {response.isSubmitting ? (
                <Box sx={{ width: '100%' }}>
                    <LinearProgress />
                </Box>
            ) : null}
            {filterList.length > 0 ? (
                <>
                    {<AuditTrailList filterList={filterList} reload={() => setIsLoadList(true)} />}
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        // onClick={handleClose}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                </>
            ) : (
                <>
                    <AuditTrailList filterList={[]} reload={() => setIsLoadList(true)} />
                    {/* <CircularProgress color="inherit" /> */}
                </>
            )}
        </div>
    );
};

export default AuditTrail;
