import React from 'react';
import { AppUrls } from '../../core/route.url';
import { usePostData } from '../../hooks/use-post-data.hook';
import cameraIcon from '../../images/camera-plus.svg';
import { CircularProgress, Link, Box } from '@mui/material';
import PropTypes from 'prop-types';
const CarrierProfilePicView = ({ carrierdetails }) => {
    const { submitData: PostFile, response: getResponsePost } = usePostData(
        AppUrls.CarrierProfilePicSave,
        'POST',
        {},
        'multipart/form-data',
    );

    const [uploadFile, setUploadFile] = React.useState({});
    const hiddenFileInput = React.useRef(null);

    React.useEffect(() => {
        if (carrierdetails.CarrierImage === undefined) {
            setUploadFile(require('../../images/no-image.png'));
        } 
        else if (carrierdetails.CarrierImage === "") {
            setUploadFile(require('../../images/no-image.png'));
        }
       else {
            setUploadFile(carrierdetails.CarrierImage);
        }
    }, [carrierdetails.CarrierImage]);

    function handleBrowseClick() {
        hiddenFileInput.current.click();
    }

    function handleFile(e) {
        e.preventDefault();
        if (e.target.files[0].type !== undefined) {
            setUploadFile(URL.createObjectURL(e.target.files[0]));
        }
        const formData = new FormData();
        formData.append('Image', e.target.files[0]);
        formData.append('FileName', 'Img');
        formData.append('CarrierUniqueId', carrierdetails.CarrierUniqueId);
        PostFile(formData);
    }

    return (
        <>
            <Box className="relative">
                <figure>
                    <img src={uploadFile} width="92" height="92" alt="" />
                </figure>
                <Link component="button" className="add-image" onClick={handleBrowseClick}>
                    {getResponsePost.isSubmitting ? (
                        <>
                            <CircularProgress color="inherit" />
                        </>
                    ) : (
                        <>
                            <img id="carrierlogo" src={cameraIcon} alt="" />
                        </>
                    )}
                </Link>
            </Box>
            <input
                ref={hiddenFileInput}
                type="file"
                onChange={(e) => handleFile(e)}
                style={{ display: 'none' }}
                accept="image/*"
            />
            {/*<form  onSubmit={handleSubmit(sendImage)}>
              
               <input className="btn btn-primary" type="submit" value="Save"/>
</form>*/}
        </>
    );
};
export default CarrierProfilePicView;

CarrierProfilePicView.propTypes = {
    carrierdetails: PropTypes.any,
};
