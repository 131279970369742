import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    Alert,
    Box,
    Button,
    CircularProgress,
    FormControl,
    TextField,
    Modal,
    Checkbox,
    FormControlLabel,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';

import { AppUrls } from '../../core/route.url';
import { usePostData } from '../../hooks/use-post-data.hook';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 480,
    bgcolor: 'background.paper',
    boxShadow: 3,
    p: 3,
};

const CustomSaveQueryForm = ({ isSaveModalOpen, setIsSaveModalOpen, apiRequestData }) => {
    const {
        register,
        handleSubmit,
        control,
        setValue,
        watch,
        formState: { errors },
    } = useForm();
    const { submitData: saveQueryData, response: responseSaveQueryData } = usePostData(
        AppUrls.ReportQuerySave,
        'POST',
    );
    const [savedResponse, setSaveResponse] = useState({});

    // console.log(456, responseSaveQueryData);

    useEffect(() => {
        if (responseSaveQueryData.result != undefined) {
            setSaveResponse(responseSaveQueryData.result);

            const timer = setTimeout(() => {
                setIsSaveModalOpen(false);
                setValue('reportName', '');
                setValue('globel', false);
                setSaveResponse({});
            }, 1000);
            return () => clearTimeout(timer);
        }
    }, [responseSaveQueryData]);

    const watchAllFields = watch();
    const onSubmit = async (data) => {
        saveQueryData({
            ReportID: 0,
            EntityId: apiRequestData.EntityId,
            EntityName: apiRequestData.EntityName,

            ReportName: data.reportName,
            IsGlobal: data.globel,
            QBFilters: apiRequestData.QBFilters,
            QBFields: apiRequestData.QBFields,
            SqlQuery: '',
            IsSave: true,
        });
    };
    const handleClose = () => {
        setIsSaveModalOpen(false);
    };
    return (
        <div>
            {' '}
            <Modal
                open={isSaveModalOpen}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <h2 style={{ marginBottom: 20 }}>Save Query </h2>
                    <Box
                        component="form"
                        noValidate
                        autoComplete="off"
                        onSubmit={handleSubmit(onSubmit)}
                    >
                        <Box className="claim-info-poup">
                            <TextField
                                required
                                error={errors.inspectionType}
                                id="outlined-required"
                                label="Name of the report"
                                defaultValue=""
                                InputLabelProps={{
                                    shrink: watchAllFields.reportName === '' ? false : true,
                                }}
                                {...register('reportName', { required: false })}
                            />
                            <FormControlLabel
                                style={{ marginLeft: 'auto' }}
                                control={
                                    <Checkbox
                                        checked={
                                            watchAllFields.globel !== undefined
                                                ? watchAllFields.globel
                                                : false
                                        }
                                        {...register('globel', { required: false })}
                                        //   onChange={handleChange}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                }
                                label="Make Global"
                            />
                        </Box>
                        <div className="d-flex" style={{ marginTop: 20 }}>
                            <Button
                                style={{ marginLeft: 2 }}
                                onClick={() => {
                                    setIsSaveModalOpen(false);
                                }}
                                variant="outlined"
                                type="button"
                            >
                                Cancel
                            </Button>

                            <Button
                                style={{ marginLeft: 'auto' }}
                                variant="contained"
                                type="submit"
                            >
                                {responseSaveQueryData.isSubmitting ? (
                                    <CircularProgress color="inherit" />
                                ) : (
                                    'Save'
                                )}
                            </Button>
                        </div>
                    </Box>
                    {savedResponse.IsSuccess != undefined ? (
                        <Alert
                            style={{ marginTop: 15 }}
                            severity={savedResponse.IsSuccess ? 'success' : 'error'}
                        >
                            {savedResponse.Message}
                        </Alert>
                    ) : null}
                </Box>
            </Modal>
        </div>
    );
};

export default CustomSaveQueryForm;

CustomSaveQueryForm.propTypes = {
    isSaveModalOpen: PropTypes.bool,
    setIsSaveModalOpen: PropTypes.func,
    apiRequestData: PropTypes.any,
};
