import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Box, Stack, FormControlLabel, Checkbox, FormGroup } from '@mui/material';
import TextField from '@mui/material/TextField';
import { useLocation } from 'react-router-dom';
import { Button, Alert, Divider, FormControl, CircularProgress, Typography } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import SelectOption from '../../components/select-option';
import SelectMulti from '../../components/select-multi';
import { Editor, EditorTools, EditorUtils, ProseMirror } from '@progress/kendo-react-editor';
import { AppUrls } from '../../core/route.url';
import { useLoadData } from '../../hooks/use-load-data.hook';
import { usePostData } from '../../hooks/use-post-data.hook';
import PropTypes from 'prop-types';
import { typography } from '@mui/system';
import CustomTextField from '../../components/CustomTextField';
import { AppInputTypes } from '../../utils/constants';
import { useLoadData2 } from '../../hooks/use-load-data-2.hook';
const {
    Bold,
    Italic,
    Underline,
    Strikethrough,
    Subscript,
    Superscript,
    AlignLeft,
    AlignCenter,
    AlignRight,
    AlignJustify,
    Indent,
    Outdent,
    OrderedList,
    UnorderedList,
    Undo,
    Redo,
    FontSize,
    FontName,
    FormatBlock,
    Link,
    Unlink,
    InsertImage,
    ViewHtml,
    InsertTable,
    AddRowBefore,
    AddRowAfter,
    AddColumnBefore,
    AddColumnAfter,
    DeleteRow,
    DeleteColumn,
    DeleteTable,
    MergeCells,
    SplitCell,
} = EditorTools;

const ManageNotification = ({
    notificationValues,
    setIsOpen,
    setIsLoadList,
    setNotificationValues,
}) => {
    const { submitData, response: notificationSaveRes } = usePostData(
        AppUrls.NotificationSave,
        'POST',
    );

    const location = useLocation();
    const editor = React.createRef();

    const [isLoad, setIsLoad] = React.useState(false);
    const [assignedResourceState, setAssignedResourceState] = React.useState(false);
    const [resourseMangerState, setResourseMangerState] = React.useState(false);
    const [isLoadNotification, setIsLoadNotification] = React.useState(false);
    const [savedResponse, setSaveResponse] = React.useState({});
    const {
        isLoading,
        isError,
        reload,
        result: notificationCode,
    } = useLoadData(AppUrls.NotificationCodeList, true);

    const { reload: getNotification, result: notificationDetails } = useLoadData(
        AppUrls.GetNotification + `?TemplateGroupId=${notificationValues.TemplateGroupId}`,
        true,
    );
    const { result: carrierList } = useLoadData(AppUrls.GetAllCarrier);

    // const EventTrigger = [
    //     { value: 'TASKCHANGE', label: 'Claim Task status change' },
    //     { value: 'SLABREACH', label: 'SLA Breach' },
    //     { value: 'SLAKICKOFF', label: 'SLA Kicks off' },
    //     { value: 'CLAIMCANCL', label: 'Claim cancelled' },
    // ];
    const { result: taskStatusList } = useLoadData(
        AppUrls.GetClaimTaskStatuses + '?taskTypeCode=CLAIM_TASK',
    );
    const { result: userRoleList } = useLoadData(AppUrls.UserRoleList);
    const { result: sLAsList, reload: loadSLAList } = useLoadData2();

    React.useEffect(() => {
        if (notificationValues.TemplateGroupId !== 0) {
            setIsLoadNotification(true);
        }
    }, [notificationValues.TemplateGroupId]);

    React.useEffect(() => {
        if (isLoadNotification) {
            setIsLoadNotification(false);
            getNotification();
        }
    }, [isLoadNotification]);

    const { reload: reloadNotificationMedia, result: notificationMedia } = useLoadData(
        AppUrls.NotificationMedia,
        true,
    );

    React.useEffect(() => {
        setIsLoad(true);
    }, []);

    React.useEffect(() => {
        if (isLoad) {
            reload();
            reloadNotificationMedia();
            setIsLoad(false);
        }
    }, [isLoad]);

    React.useEffect(() => {
        if (notificationSaveRes.result !== undefined) {
            setSaveResponse(notificationSaveRes.result);
            setIsLoadList(true);
            const timer = setTimeout(() => {
                setSaveResponse({});
                setIsLoadList(true);
                setValue('title', '');
                setValue('Event', '');
                setValue('Media', '');
                setValue('TaskStatus', '');
                setValue('SLA', '');
                setValue('assignedResource', '');
                setValue('resourceManager', '');
                setValue('recipientUserRoles', '');
                setValue('others', '');
                setValue('email', '');
                setValue('homeOwner', '');
                setValue('active', '');
                setValue('Carrier', '');
                setValue('Role', '');
                if (editor.current) {
                    const view = editor.current.view;
                    EditorUtils.setHtml(view, '<p></p>');
                }

                setValue('Code', '');
                setIsOpen(false);
            }, 3000);
            return () => clearTimeout(timer);
        }
    }, [notificationSaveRes]);

    React.useEffect(() => {
        if (notificationDetails != undefined) {
            const carrierIdList = notificationDetails.Data.CarrierIds?.split(',')?.map((x) =>
                parseInt(x),
            );
            if (carrierIdList?.length) {
                loadSLAList(`${AppUrls.GetAllSLAs}?carrierId=${carrierIdList[0]}`);
            }
            setValue('Carrier', carrierIdList);
            setValue('title', notificationDetails.Data.TemplateName);
            setValue('Event', notificationDetails.Data.NotificationCodeID);
            setValue('Media', notificationDetails.Data.NotificationMediaID);
            setValue('TaskStatus', notificationDetails.Data.TaskStatusCode);
            setValue('SLA', notificationDetails.Data.SLAID);
            setValue('Role', notificationDetails.Data.RecipientRolesCSV);
            setValue('assignedResource', notificationDetails.Data.SendToInspector);
            setValue('resourceManager', notificationDetails.Data.SendToManager);
            setValue('recipientUserRoles', notificationDetails.Data.RecipientRoles);
            setValue('others', notificationDetails.Data.Others);
            setValue('email', notificationDetails.Data.OtherRecipients);
            setValue('homeOwner', notificationDetails.Data.HomeOwner);
            setValue('active', notificationDetails.Data.IsActive);
            if (editor.current) {
                const view = editor.current.view;
                EditorUtils.setHtml(view, notificationDetails.Data.TemplateDesc);
            }
        }
    }, [notificationDetails]);

    const {
        register,
        handleSubmit,
        control,
        setValue,
        watch,
        formState: { errors },
    } = useForm();

    const watchAllFields = watch();

    console.log(
        67,
        (watchAllFields.resourceManager !== undefined &&
            watchAllFields.Media !== 1 &&
            watchAllFields.Media !== 5) ||
            watchAllFields.Event !== 1
            ? watchAllFields.resourceManager
            : false,
    );

    React.useEffect(() => {
        setAssignedResourceState(
            (watchAllFields.assignedResource !== undefined &&
                watchAllFields.Media !== 4 &&
                watchAllFields.Media !== 5) ||
                watchAllFields.Event !== 1
                ? watchAllFields.assignedResource
                : false,
        );
    }, [
        (watchAllFields.assignedResource !== undefined &&
            watchAllFields.Media !== 4 &&
            watchAllFields.Media !== 5) ||
        watchAllFields.Event !== 1
            ? watchAllFields.assignedResource
            : false,
    ]);
    React.useEffect(() => {
        setResourseMangerState(
            (watchAllFields.resourceManager !== undefined &&
                watchAllFields.Media !== 1 &&
                watchAllFields.Media !== 5) ||
                watchAllFields.Event !== 1
                ? watchAllFields.resourceManager
                : false,
        );
    }, [
        (watchAllFields.resourceManager !== undefined &&
            watchAllFields.Media !== 1 &&
            watchAllFields.Media !== 5) ||
        watchAllFields.Event !== 1
            ? watchAllFields.resourceManager
            : false,
    ]);

    const onSubmit = async (data) => {
        const nmText = notificationMedia.Data.find((dt) => dt.NotificationMediaID == data.Media);
        if (editor.current) {
            const view = editor.current.view;
            submitData({
                CarrierIds: data.Carrier.toString(),
                CarrierNames: carrierList.Data.filter((d) => data.Carrier.includes(d.CarrierId))
                    .map((data) => {
                        return data.CarrierName;
                    })
                    .toString(),
                NotificationCodeID: data.Event,
                NotificationMediaID: data.Media,
                NotificationMediaDisplayName: nmText.NotificationMediaDisplayName
                    ? nmText.NotificationMediaDisplayName
                    : '',
                TemplateName: data.title,
                TemplateDesc: EditorUtils.getHtml(view.state),
                PlainTemplate: EditorUtils.getHtml(view.state)
                    .replace(/<[^>]*>/g, '')
                    .replace('&nbsp;', ''),
                TaskStatusCode:
                    watchAllFields.Event !== 2 && watchAllFields.Event !== 3 ? data.TaskStatus : '',
                SLAID: watchAllFields.Event > 1 ? data.SLA : 0,
                SendToInspector:
                    watchAllFields.assignedResource === true ? data.assignedResource : false,
                SendToManager:
                    watchAllFields.resourceManager === true ? data.resourceManager : false,
                RecipientRoles:
                    watchAllFields.recipientUserRoles === true ? data.recipientUserRoles : false,
                RecipientRolesCSV:
                    watchAllFields.recipientUserRoles === true ? data.Role.toString() : '',
                Others: watchAllFields.others === true ? data.others : false,
                OtherRecipients: watchAllFields.others === true ? data.email : '',
                HomeOwner: data.homeOwner,
                IsActive: data.active,
                TemplateGroupId:
                    notificationValues.TemplateGroupId !== 0
                        ? notificationValues.TemplateGroupId
                        : '',
                NotificationCode:
                    notificationCode !== undefined
                        ? notificationCode.Data.filter(
                              (d) => d.NotificationCodeID === data.Event,
                          )[0].NotificationCodeDesc
                        : '',
            });
        }
    };

    const isSubmitButtonEnabled = () => {
        return watchAllFields?.Event && watchAllFields?.Carrier?.length && watchAllFields?.Media;
    };

    return (
        <>
            {' '}
            <Stack component="form" noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                <Typography variant="h2" marginBottom={2} fontWeight={700}>
                    Add Notification
                </Typography>
                <Box className="grid-cl-2">
                    <FormControl size="small">
                        <Controller
                            control={control}
                            name="Event"
                            rules={{
                                required: true,
                            }}
                            render={({ field: { onChange, onBlur, value, name } }) => {
                                const onChangeEvent = (event) => {
                                    setValue('Carrier', []);
                                    onChange(event);
                                };
                                return (
                                    <SelectOption
                                        values={
                                            notificationCode != undefined
                                                ? notificationCode.Data.map((e) => {
                                                      return {
                                                          value: e['NotificationCodeID'],
                                                          label: e['NotificationCodeDesc'],
                                                      };
                                                  })
                                                : []
                                        }
                                        disabled={false}
                                        onChange={onChangeEvent}
                                        onBlur={onBlur}
                                        value={value === undefined ? '' : value}
                                        name={name || ''}
                                        error={errors.Event}
                                    />
                                );
                            }}
                        />
                    </FormControl>
                    <FormControl size="small">
                        <Controller
                            control={control}
                            name="Media"
                            rules={{
                                required: true,
                            }}
                            render={({ field: { onChange, onBlur, value, name } }) => {
                                return (
                                    <SelectOption
                                        values={
                                            notificationMedia != undefined
                                                ? notificationMedia.Data.map((e) => {
                                                      return {
                                                          value: e['NotificationMediaID'],
                                                          label: e['NotificationMediaDisplayName'],
                                                      };
                                                  })
                                                : []
                                        }
                                        disabled={false}
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        value={value === undefined ? '' : value}
                                        name={name || ''}
                                        error={errors.Media}
                                    />
                                );
                            }}
                        />
                    </FormControl>
                    {watchAllFields?.Event && (
                        <FormControl>
                            <Controller
                                control={control}
                                name="Carrier"
                                rules={{
                                    required: true,
                                }}
                                render={({ field: { onChange, onBlur, value, name } }) => {
                                    const isMultiSelect =
                                        watchAllFields?.Event !== 2 && watchAllFields?.Event !== 3;
                                    const onChangeCarrier = (event) => {
                                        if (!isMultiSelect) {
                                            loadSLAList(`${AppUrls.GetAllSLAs}?carrierId=${event}`);
                                        }
                                        onChange(isMultiSelect ? event : [event]);
                                    };
                                    return (
                                        <SelectMulti
                                            values={
                                                carrierList !== undefined
                                                    ? carrierList?.Data?.map((e) => {
                                                          return {
                                                              value: e['CarrierId'],
                                                              label: e['CarrierName'],
                                                          };
                                                      })
                                                    : []
                                            }
                                            disabled={false}
                                            onChange={onChangeCarrier}
                                            onBlur={onBlur}
                                            value={
                                                isMultiSelect
                                                    ? value === undefined
                                                        ? ''
                                                        : value
                                                    : Array.isArray(value) && value?.length
                                                    ? value[0]
                                                    : ''
                                            }
                                            // name={name || ''}
                                            nameForLablel="Carrier *"
                                            name={'Carrier *' || ''}
                                            error={errors.CarrierId}
                                            multi={isMultiSelect}
                                        />
                                    );
                                }}
                            />
                        </FormControl>
                    )}

                    {watchAllFields.Event !== undefined &&
                    watchAllFields.Event !== 2 &&
                    watchAllFields.Event !== 3 ? (
                        <FormControl size="small">
                            <Controller
                                control={control}
                                name="TaskStatus"
                                render={({ field: { onChange, onBlur, value, name } }) => (
                                    <SelectOption
                                        fullWidth
                                        values={
                                            taskStatusList?.Data
                                                ? taskStatusList.Data.map((e) => {
                                                      return {
                                                          value: e['ClaimTaskStatusCode'],
                                                          label: e['ClaimTaskStatusName'],
                                                      };
                                                  })
                                                : []
                                        }
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        value={value === 0 ? '' : value}
                                        name={'Task Status' || ''}
                                        error={errors.TaskStatus}
                                    />
                                )}
                            />
                        </FormControl>
                    ) : null}
                    {watchAllFields.Event !== undefined && watchAllFields.Event > 1 ? (
                        <FormControl size="small">
                            <Controller
                                control={control}
                                name="SLA"
                                rules={{
                                    required: false,
                                }}
                                render={({ field: { onChange, onBlur, value, name } }) => (
                                    <SelectOption
                                        fullWidth
                                        values={
                                            sLAsList?.Data
                                                ? sLAsList.Data.map((e) => {
                                                      return {
                                                          value: e['SLAId'],
                                                          label: e['SLADefinition'],
                                                      };
                                                  })
                                                : []
                                        }
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        value={value === 0 ? '' : value}
                                        name={'SLA' || ''}
                                        error={errors.SLA}
                                    />
                                )}
                            />
                        </FormControl>
                    ) : null}
                </Box>
                <Box className="gridMob">
                    <Box>
                        <Box
                            style={{
                                backgroundColor: 'rgb(247 247 247)',
                                padding: 15,
                                borderRadius: 6,
                            }}
                        >
                            <Typography variant="h3" fontWeight={700} marginBottom={1}>
                                Recipients
                            </Typography>
                            <Box>
                                <Box>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={
                                                    assignedResourceState
                                                    // (watchAllFields.assignedResource !== undefined &&
                                                    //     watchAllFields.Media !== 4 &&
                                                    //     watchAllFields.Media !== 5) ||
                                                    // watchAllFields.Event !== 1
                                                    //     ? watchAllFields.assignedResource
                                                    //     : false
                                                    // watchAllFields.assignedResource !== undefined &&
                                                    // watchAllFields.assignedResource
                                                }
                                                {...register('assignedResource', {
                                                    required: false,
                                                    disabled:
                                                        (watchAllFields.Media !== undefined &&
                                                            watchAllFields.Media !== 4 &&
                                                            watchAllFields.Media !== 5) ||
                                                        watchAllFields.Event !== 1
                                                            ? false
                                                            : true,
                                                })}
                                            />
                                        }
                                        label="Assigned Resource"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={
                                                    resourseMangerState
                                                    // (watchAllFields.resourceManager !== undefined &&
                                                    //     watchAllFields.Media !== 1 &&
                                                    //     watchAllFields.Media !== 5) ||
                                                    // watchAllFields.Event !== 1
                                                    //     ? watchAllFields.resourceManager
                                                    //     : false
                                                }
                                                {...register('resourceManager', {
                                                    required: false,
                                                    disabled:
                                                        (watchAllFields.Media !== undefined &&
                                                            watchAllFields.Media !== 1 &&
                                                            watchAllFields.Media !== 5) ||
                                                        watchAllFields.Event !== 1
                                                            ? false
                                                            : true,
                                                })}
                                            />
                                        }
                                        label="Resource Manager"
                                    />

                                    {/* <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={
                                            watchAllFields.homeOwner !== undefined &&
                                            watchAllFields.Media !== 1 &&
                                            watchAllFields.Media !== 4 &&
                                            watchAllFields.Media !== 5
                                                ? watchAllFields.homeOwner
                                                : false
                                        }
                                        {...register('homeOwner', {
                                            required: false,
                                            disabled:
                                                watchAllFields.Media !== undefined &&
                                                watchAllFields.Media !== 1 &&
                                                watchAllFields.Media !== 4 &&
                                                watchAllFields.Media !== 5
                                                    ? false
                                                    : true,
                                        })}
                                    />
                                }
                                label="Home Owner"
                            /> */}
                                </Box>
                                <Box>
                                    {/* <Box style={{ display: 'grid' }}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={
                                                (watchAllFields.recipientUserRoles !== undefined &&
                                                    watchAllFields.Media !== 1 &&
                                                    watchAllFields.Media !== 5) ||
                                                watchAllFields.Event !== 1
                                                    ? watchAllFields.recipientUserRoles
                                                    : false
                                            }
                                            {...register('recipientUserRoles', {
                                                required: false,
                                                disabled:
                                                    (watchAllFields.Media !== undefined &&
                                                        watchAllFields.Media !== 1 &&
                                                        watchAllFields.Media !== 5) ||
                                                    watchAllFields.Event !== 1
                                                        ? false
                                                        : true,
                                            })}
                                        />
                                    }
                                    label="Recipient User Roles"
                                />
                                {watchAllFields.recipientUserRoles !== undefined &&
                                watchAllFields.recipientUserRoles === true ? (
                                    <FormControl>
                                        <Controller
                                            control={control}
                                            name="Role"
                                            rules={{
                                                required: false,
                                            }}
                                            render={({
                                                field: { onChange, onBlur, value, name },
                                            }) => (
                                                <SelectMulti
                                                    values={
                                                        userRoleList !== undefined
                                                            ? userRoleList?.Data?.map((e) => {
                                                                  return {
                                                                      value: e['RoleId'],
                                                                      label: e['RoleName'],
                                                                  };
                                                              })
                                                            : []
                                                    }
                                                    disabled={false}
                                                    onChange={onChange}
                                                    onBlur={onBlur}
                                                    value={value === undefined ? '' : value}
                                                    // name={name || ''}
                                                    multi={true}
                                                    nameForLablel="Role *"
                                                    name={'Role *' || ''}
                                                    error={errors.Role}
                                                />
                                            )}
                                        />
                                    </FormControl>
                                ) : null}
                            </Box> */}

                                    <Box style={{ display: 'grid' }}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={
                                                        watchAllFields.others !== undefined &&
                                                        watchAllFields.Media !== 1 &&
                                                        watchAllFields.Media !== 2 &&
                                                        watchAllFields.Media !== 4 &&
                                                        watchAllFields.Media !== 5
                                                            ? watchAllFields.others
                                                            : false
                                                    }
                                                    {...register('others', {
                                                        required: false,
                                                        disabled:
                                                            watchAllFields.Media !== undefined &&
                                                            watchAllFields.Media !== 1 &&
                                                            watchAllFields.Media !== 2 &&
                                                            watchAllFields.Media !== 4 &&
                                                            watchAllFields.Media !== 5
                                                                ? false
                                                                : true,
                                                    })}
                                                />
                                            }
                                            label="Others (Add Email addresses)"
                                        />
                                        {watchAllFields.others !== undefined &&
                                        watchAllFields.others === true ? (
                                            <FormControl size="small" style={{ marginBottom: 15 }}>
                                                <TextField
                                                    size="small"
                                                    error={errors.role}
                                                    id="outlined-required"
                                                    label="Email"
                                                    defaultValue=""
                                                    InputLabelProps={{
                                                        shrink:
                                                            watchAllFields.email === ''
                                                                ? false
                                                                : true,
                                                    }}
                                                    {...register('email', { required: true })}
                                                />
                                            </FormControl>
                                        ) : null}
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                        <FormControlLabel
                            style={{ marginLeft: 'auto' }}
                            control={
                                <Checkbox
                                    checked={
                                        watchAllFields.active !== undefined
                                            ? watchAllFields.active
                                            : false
                                    }
                                    {...register('active', { required: false })}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                            }
                            label="Active"
                        />
                        <Box>
                            <FormControl size="small" style={{ marginBottom: 15, width: '100%' }}>
                                <TextField
                                    fullWidth
                                    size="small"
                                    style={{ width: '100%' }}
                                    error={errors.role}
                                    id="outlined-required"
                                    label="Title"
                                    defaultValue=""
                                    InputLabelProps={{
                                        shrink: watchAllFields.title === '' ? false : true,
                                    }}
                                    {...register('title', { required: true })}
                                />
                            </FormControl>
                        </Box>
                        {/* </div> */}
                    </Box>
                    {/* <FormControl sx={{ m: 1, width: "143ch" }}> */}
                    <Editor
                        style={{ marginBottom: 15 }}
                        tools={[
                            [Bold, Italic, Underline, Strikethrough],
                            [Subscript, Superscript],
                            [AlignLeft, AlignCenter, AlignRight, AlignJustify],
                            [Indent, Outdent],
                            [OrderedList, UnorderedList],
                            FontSize,
                            FontName,
                            FormatBlock,
                            [Undo, Redo],
                            [Link, Unlink, InsertImage, ViewHtml],
                            [InsertTable],
                            [AddRowBefore, AddRowAfter, AddColumnBefore, AddColumnAfter],
                            [DeleteRow, DeleteColumn, DeleteTable],
                            [MergeCells, SplitCell],
                        ]}
                        contentStyle={{
                            height: '47vh',
                        }}
                        // defaultContent={content}
                        ref={editor}

                        // value={editorValue}
                    />
                </Box>
                {/* </FormControl> */}
                <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Button
                        variant="outlined"
                        type="button"
                        onClick={() => {
                            setIsOpen(false);
                            setNotificationValues({
                                templateId: 0,
                                carrierId: 0,
                            });
                            setSaveResponse({});
                            setValue('title', '');
                            setValue('Media', '');
                            if (editor.current) {
                                const view = editor.current.view;
                                EditorUtils.setHtml(view, '');
                            }

                            setValue('Event', '');
                        }}
                    >
                        Cancel
                    </Button>
                    <Button disabled={!isSubmitButtonEnabled()} variant="contained" type="submit">
                        {notificationSaveRes.isSubmitting ? (
                            <CircularProgress color="inherit" />
                        ) : (
                            'Save'
                        )}
                    </Button>
                </Box>
            </Stack>
            {savedResponse.IsSuccess != undefined ? (
                <Alert
                    // style={{ marginTop: 15 }}
                    severity={savedResponse.IsSuccess ? 'success' : 'error'}
                >
                    {savedResponse.Message}
                </Alert>
            ) : null}
        </>
    );
};

export default ManageNotification;
ManageNotification.propTypes = {
    notificationValues: PropTypes.arrayOf(
        PropTypes.shape({
            templateId: PropTypes.number.isRequired,
            carreierId: PropTypes.number.isRequired,
        }),
    ),
    setIsOpen: PropTypes.any,
    setIsLoadList: PropTypes.any,
    setNotificationValues: PropTypes.any,
};
