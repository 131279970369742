import { useEffect } from 'react';
import { isNil, map, reduce, uniq } from 'lodash';
// import { LoginPage } from '../features/login/login.page';
import { useStorage } from '../hooks/use-storage';
import { useAppContext } from '../hooks/use-app-context.hook';
import { useLoadData } from '../hooks/use-load-data.hook';
import { LoadingIndicator } from '../components/loading-indicator.component';
import { AppUrls } from './route.url';
import { useCurrentUser } from '../hooks/user-current-user.hook';
import { LoginPage } from '../features/login/login.onelogin';
// import { LoginPage } from '../features/login/login.page';
//  import { LoginPage } from '../features/login/login.page copy';

export const AuthorizeComponent = ({ children, isPublic = false }) => {
    const { dispatch } = useAppContext();
    const [authToken, setToken] = useStorage('AUTH_STATE', null);
    const [isLogOutState, setisLogOutState] = useStorage('LogOut_STATE', null);

    const { isAuthenticated } = useCurrentUser();
    // const isLoading = false;
    // const userData = [];
    // const isError = false;

    const {
        isLoading,
        result: userData,
        isError,
    } = useLoadData(AppUrls.UserDetails, isNil(authToken));

    useEffect(() => {
        if (userData !== undefined) {
            initUserContext();
        }
    }, [userData]);

    useEffect(() => {
        if (isError) {
            setToken(null);
        }
    }, [isError]);

    const initUserContext = async () => {
        const currentPermissions = reduce();
        // userData.Data.UserMenuPermissions,
        // (prevValue, currentValue) => {
        //   return [
        //     ...prevValue,
        //     ...map(currentValue.permissions, (p) => p.permissionName),
        //   ];
        // },
        // []
        dispatch({
            // ...user,
            ...userData.Data,
            // currentPermissions: uniq(currentPermissions),
            isAuthenticated: true,
        });
    };

    if (!isPublic && isNil(authToken)) {
        return <LoginPage />;
    }

    return (
        <>
            <LoadingIndicator isLoading={isLoading && !isPublic} />
            {isError && (
                <div>Error loading user. Please refresh the page or contact your administrator</div>
            )}
            {((isAuthenticated && !isLoading) || isPublic) && children}
        </>
    );
};
