import PropTypes from 'prop-types';
import { Box, Stack, Typography, IconButton, Button } from '@mui/material/';
import { useNavigate } from 'react-router-dom';
import { decryptWithAES, encryptWithAES } from '../../utils/global-functions';
import moment from 'moment/moment';
import Moment from 'moment';
export const MapInfoClaimComponent = ({ claim }) => {
    const navigate = useNavigate();

    const navigateToClaimDetailPage = () => {
        if (claim.ClaimGuID) {
            let param = {
                state: {
                    page: 'claim',
                    guId: claim.ClaimGuID,
                },
            };
            let encryptedParam = encryptWithAES(JSON.stringify(param));
            window.open(`/${encryptedParam}`, '_blank');
        }
    };

    if (!claim) {
        return <Typography variant="h6">No Info</Typography>;
    }

    return (
        <Box padding={1}>
            <Box className={'clmPopGrid-3 autogrid'}>
                {claim.ClaimNumber && (
                    <Stack>
                        <Typography variant="h6">{claim.ClaimNumber}</Typography>
                        <Typography variant="caption">Claim Number</Typography>
                    </Stack>
                )}
                {claim.InsuredName && (
                    <Stack>
                        <Typography whiteSpace="nowrap" variant="h6">
                            {claim.InsuredName}
                        </Typography>
                        <Typography variant="caption">Insured Name</Typography>
                    </Stack>
                )}{' '}
                {claim.AssignedToName && (
                    <Stack>
                        <Typography whiteSpace="nowrap" variant="h6">
                            {claim.AssignedToName}
                        </Typography>
                        <Typography variant="caption">Assigned To</Typography>
                    </Stack>
                )}
                <>
                    <Stack style={{ display: 'flex', alignItems: 'flex-start' }}>
                        <Button
                            style={{ marginLeft: 'auto', boxShadow: 'none' }}
                            variant="contained"
                            size="small"
                            onClick={navigateToClaimDetailPage}
                        >
                            More
                        </Button>
                    </Stack>
                </>
            </Box>
            <Stack
                style={{
                    fontSize: '20px',
                    marginBottom: 10,
                    borderTop: '1px solid #eee',
                    paddingTop: 10,
                }}
            >
                {claim.PropertyAddress && (
                    <Typography variant="h6">{claim.PropertyAddress}</Typography>
                )}
                {claim.InsuredOfficePhone && (
                    <Typography variant="h6">{claim.InsuredOfficePhone}</Typography>
                )}

                {claim.InsuredEmail && <Typography variant="h6">{claim.InsuredEmail}</Typography>}
                <Typography style={{ fontSize: '14px' }} variant="inherit">
                    Address
                </Typography>
            </Stack>
            <Box className="clmPopGrid-all">
                {claim.CarrierName && (
                    <Stack>
                        <Typography variant="h4">{claim.CarrierName}</Typography>
                        <Typography variant="caption">Carrier</Typography>
                    </Stack>
                )}
                {claim.TypeOfLoss && (
                    <Stack>
                        <Typography variant="h4">{claim.TypeOfLoss}</Typography>
                        <Typography variant="caption">Type of Loss</Typography>
                    </Stack>
                )}
                {claim.ClaimStatusName && (
                    <Stack>
                        <Typography variant="h4">{claim.ClaimStatusName}</Typography>
                        <Typography variant="caption">Status</Typography>
                    </Stack>
                )}
                {claim.DateOfLoss && (
                    <Stack>
                        <Typography variant="h4">
                            {moment(claim.DateOfLoss).format('MM-DD-YYYY')}
                        </Typography>
                        <Typography variant="caption">Date Of Loss</Typography>
                    </Stack>
                )}

                <Stack style={{ paddingTop: claim.HasReservation === true ? 16 : 0 }}>
                    <Typography variant="h4">
                        {claim.HasReservation === true
                            ? Moment(claim.ReservationStart).format('MM-DD-YYYY hh:mm:ss A') +
                              '  to  ' +
                              Moment(claim.ReservationEnd).format('hh:mm:ss A')
                            : 'N/A'}
                    </Typography>
                    <Typography variant="caption">Reservation Time</Typography>
                </Stack>
            </Box>
            <Box className="clmPopGrid-all inheritflex">
                {claim.InspectionType && (
                    <Stack>
                        <Typography variant="h4">{claim.InspectionType}</Typography>
                        <Typography variant="caption">Inspection Type</Typography>
                    </Stack>
                )}
                {claim.PerilName && (
                    <Stack>
                        <Typography variant="h4">{claim.PerilName}</Typography>
                        <Typography variant="caption">Peril</Typography>
                    </Stack>
                )}
            </Box>
        </Box>
    );
};

MapInfoClaimComponent.propTypes = {
    claim: PropTypes.any.isRequired,
};
