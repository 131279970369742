import React from 'react';
import PropTypes from 'prop-types';
import { usePostData2 } from '../../hooks/use-post-data-2.hook';
import { AppUrls } from '../../core/route.url';
import { LinearProgress } from '@mui/material';
import { useState } from 'react';
import moment from 'moment';
import UserSlaTrackList from './userSlaTrackList';
import { usePostData } from '../../hooks/use-post-data.hook';
import Moment from 'moment';

const SlaList = (props) => {
    const { userId } = props || {};
    const [isLoadList, setIsLoadList] = React.useState(false);
    const { submitData, response } = usePostData(AppUrls.GetSLAsByUser);

    React.useEffect(() => {
        if (userId !== null && userId !== undefined) setIsLoadList(true);
    }, [userId]);

    React.useEffect(() => {
        if (isLoadList && userId !== undefined) {
            setIsLoadList(false);
            submitData({
                AssigneeId: userId,
            });
        }
    }, [isLoadList]);

    const [claimFilters, setClaimFilters] = useState({
        Carrier: '',
        ClaimStatus: '',
        EventCode: '',
        LossType: '',
        City: '',
        ZipCode: '',
        DateReceived: '',
    });

    const [slaList, setSlaList] = useState([]);
    React.useEffect(() => {
        if (response.result != undefined) {
            if (response.result.IsSuccess) {
                // to format the date
                response.result.Data.forEach((o) => {
                    o.BreachedTime =
                        o.BreachedTime === null
                            ? ''
                            : Moment(o.BreachedTime).format('MM-DD-YYYY hh:mm A');
                    o.SLAEndTime =
                        o.SLAEndTime === null
                            ? ''
                            : Moment(o.SLAEndTime).format('MM-DD-YYYY hh:mm A');
                });
                // console.log('response.result.Data', response.result.Data);
                setSlaList(response.result.Data);
            }
        }
    }, [response]);

    return (
        <div>
            {response.result != undefined ? (
                <UserSlaTrackList
                    filterList={slaList}
                    OnHandleChange={() => {}}
                    setClaimFilters={setClaimFilters}
                    forUpdate={0}
                    isLoadingData={response.isLoading}
                />
            ) : (
                <LinearProgress></LinearProgress>
            )}
        </div>
    );
};

SlaList.propTypes = {
    userId: PropTypes.string,
};

export default SlaList;
