import React from 'react';
import { Alert, Box, Modal } from '@mui/material';
import { ClaimMap } from './claim-profile-mapview';
import PropTypes from 'prop-types';
import Moment from 'moment';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100vh',
    bgcolor: 'background.paper',
    boxShadow: 3,
    p: 3,
    height: '70vh',
};
const ClaimListMapView = ({ mapViewState }) => {
    return (
        <div>
            <Box sx={style}>
                <Box className="infomapview">
                    <Box>
                        <h3 style={{ marginBottom: 2 }}>{mapViewState.claimNumber}</h3>
                        <small>Claim #</small>
                    </Box>
                    <Box>
                        <h5>{mapViewState.insuredName}</h5>
                        <small>Insured Name</small>
                    </Box>
                    <Box>
                        <Box className="address">
                            <h5>{mapViewState.insuredAddress}</h5>
                            <small>Address</small>
                        </Box>
                    </Box>
                    <Box>
                        <Box>
                            <h5>
                                {mapViewState.HasReservation === true
                                    ? Moment(mapViewState.ReservationStart).format(
                                          'MM-DD-YYYY hh:mm:ss A',
                                      ) +
                                      '  to  ' +
                                      Moment(mapViewState.ReservationEnd).format('hh:mm:ss A')
                                    : 'N/A'}
                            </h5>
                            <small>Reservation Time</small>
                        </Box>
                    </Box>
                </Box>
                <ClaimMap
                    style={{ height: '100%' }}
                    position={{
                        lat: parseFloat(mapViewState.lat),
                        lng: parseFloat(mapViewState.long),
                    }}
                    index={1}
                />

                <div>{/* <FormControl sx={{ m: 1, width: '53ch' }}> */}</div>
            </Box>
        </div>
    );
};

export default ClaimListMapView;
ClaimListMapView.propTypes = {
    mapViewState: PropTypes.shape({
        isShowMapView: PropTypes.bool.isRequired,
        lat: PropTypes.string.isRequired,
        long: PropTypes.string.isRequired,
        claimNumber: PropTypes.string.isRequired,
        insuredName: PropTypes.string.isRequired,
        InsuredAddress: PropTypes.string.isRequired,
        HasReservation: PropTypes.bool.isRequired,
        ReservationStart: PropTypes.string.isRequired,
        ReservationEnd: PropTypes.string.isRequired,
    }),
};
