import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Inspector_calendar from './inspector-calendar';
import { useLoadData } from '../../hooks/use-load-data.hook';
import { Tabs, Tab, Typography, Box } from '@mui/material/';
import { MapComponent } from '../../components/map/map.component';
import { MarkerType } from '../../utils/marker-util';
import { ConstructionOutlined } from '@mui/icons-material';
import { AppUrls } from '../../core/route.url';
import { useLoadData2 } from '../../hooks/use-load-data-2.hook';

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;
    return (
        <Box
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography component={'span'}>{children}</Typography>
                </Box>
            )}
        </Box>
    );
};

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

const Inspector_map_tab = ({ InspectorMapDetails, filterList }) => {
    const { isLoading, result, isError } = useLoadData(
        'MapView/GetAssignedClaims?resourceId=' + InspectorMapDetails.UserId,
        !InspectorMapDetails || !InspectorMapDetails.UserId,
    );

    const [claimData, setClaimData] = useState();

    useEffect(() => {
        if (result && result.Data) {
            const claims = result.Data.map((claim) => {
                return createClaimMapData(claim, false, InspectorMapDetails);
            });

            setClaimData(claims);
        }
    }, [result]);

    const createClaimMapData = (claim, isSelected, assingtoResource) => {
        return {
            id: claim.ClaimNumber,
            position: { lat: parseFloat(claim.LAT), lng: parseFloat(claim.LONG) },
            markerType: claim.AssignedToId
                ? MarkerType.CLAIM_ASSIGNED
                : MarkerType.CLAIM_UNASSIGNED,
            isSelected: isSelected,
            color: getClaimColor(claim, isSelected, assingtoResource),
            dataType: 'Claim',
            data: { ...claim },
        };
    };

    const currentResource = {
        id: InspectorMapDetails.UserId,
        position: {
            lat: parseFloat(InspectorMapDetails.LAT || 0),
            lng: parseFloat(InspectorMapDetails.LONG || 0),
        },
        markerType: MarkerType.RESOURCE,
        isSelected: true,
        color: {
            fillColor: '#037bfc',
            strokeColor: 'Black',
        },
        radius: InspectorMapDetails.WorkRadius * 1609.34,
        dataType: 'Resource',
        data: {
            ...InspectorMapDetails,
            ContactAddress:
                InspectorMapDetails.ContactAddress +
                InspectorMapDetails.City +
                ', ' +
                InspectorMapDetails.StateName +
                ', ' +
                InspectorMapDetails.Zip,
            AssignedClaims: InspectorMapDetails.AssignedClaimCount,
        },
    };

    const getClaimColor = ({ DateOfLoss }, isSelected, assingtoResource) => {
        const color = {
            fillColor: '#037bfc',
        };

        const diffDays = getDateDiffInDays(new Date(DateOfLoss), new Date());

        if (diffDays > 30) {
            color.strokeColor = 'Red';
        } else if (diffDays > 15) {
            color.strokeColor = 'Orange';
        } else {
            color.strokeColor = 'Black';
        }
        return color;
    };

    const getDateDiffInDays = (date1, date2) => {
        const diffTime = Math.abs(new Date(date1) - new Date(date2));
        return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    };

    const { result: InspectorSchedule, reload } = useLoadData2();

    const [calendarFilterList, setCalendarFilterList] = useState([]);

    useEffect(() => {
        if (InspectorMapDetails.UserId !== undefined) {
            reload(AppUrls.GetInspectorSchedule + '?inspectorId=' + InspectorMapDetails.UserId, '');
        }
    }, [InspectorMapDetails.UserId]);

    useEffect(() => {
        if (InspectorSchedule) {
            setCalendarFilterList(InspectorSchedule.Data);
        }
    }, [InspectorSchedule]);

    useEffect(() => {
        if (InspectorSchedule && InspectorSchedule.Data) {
            InspectorSchedule.Data.forEach((p) => {
                p.ScheduleStartTime = new Date(p.ScheduleStartTime);
                p.ScheduleEndTime = new Date(p.ScheduleEndTime);
            });
            setCalendarFilterList(InspectorSchedule.Data);
        }
    }, [InspectorSchedule]);

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    // const getMapComponent = () => {
    //     if (isLoading) {
    //         return <>Loading Data</>;
    //     }
    //     if (isError) {
    //         return <div>Error loading data</div>;
    //     }
    //     if (claimData) {
    //         <MapComponent
    //             mapData={[...claimData, currentResource]}
    //             mapContainerStyle={{
    //                 width: '100%',
    //                 height: '50vh',
    //             }}
    //             showClusters={false}
    //         />;
    //     }
    // };

    return (
        <>
            <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                        <Tab label="Map view" {...a11yProps(0)} />
                        <Tab label="Calendar" {...a11yProps(1)} />
                        {/* <Tab label="Licenses" {...a11yProps(2)} /> */}
                    </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                    {isLoading && <>Loading Data</>}
                    {isError && <div>Error loading data</div>}
                    {claimData && (
                        <MapComponent
                            mapData={[...claimData, currentResource]}
                            mapContainerStyle={{
                                width: '100%',
                                height: '50vh',
                            }}
                            showClusters={false}
                        />
                    )}
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <Inspector_calendar inspectorDetails={calendarFilterList} />
                </TabPanel>
                <TabPanel value={value} index={2}>
                    {/* <Claim_profile_task_tab
            cliamProfileDetails={props.cliamProfileDetails}
          /> */}
                </TabPanel>
            </Box>
        </>
    );
};

export default Inspector_map_tab;
