import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { Colors } from '../../utils/colors';
import ExpansionMenu from '../ExpansionMenu';
import { AppInputTypes } from '../../utils/constants';
import TillNowCheckBox from './TillNowCheckBox';
import CustomTextField from '../CustomTextField';
import moment from 'moment';

const ExperienceQuestions = (props) => {
    const {
        adjusterExperienceQuestionDetail,
        onClickExperience,
        onClickTillNow,
        onChangeExperienceQuestionField,
    } = props;
    return (
        <Grid item md={12} rowSpacing={1} mt={4}>
            <Grid mb={2} component={'h3'} color={Colors.DODGER_BLUE}>
                Adjuster Experience
            </Grid>
            <Grid md={12} item>
                {adjusterExperienceQuestionDetail.map((experience, experienceIndex) => (
                    <ExpansionMenu
                        key={experienceIndex}
                        label={experience?.Experience}
                        isVisible={experience?.visible}
                        onClick={() => onClickExperience(experience?.ExperienceID)}
                        type={'checkbox-list'}
                    >
                        <Grid md={12} rowSpacing={2} columnSpacing={2} p={2} container item>
                            {experience?.Questions.map((question, questionIndex) => {
                                const {
                                    fieldName,
                                    fieldType,
                                    options,
                                    values: selectedValues,
                                    fieldError,
                                    isTillNow = false,
                                } = question || {};
                                let questionOptions = options?.split(',');
                                const values = selectedValues ?? [];
                                return (
                                    <Grid key={questionIndex} xs={12} item md={6}>
                                        <Grid container item component={'h5'} mb={1}>
                                            <Grid component={'h4'} item md={6}>
                                                {fieldName}
                                            </Grid>
                                            <Grid item md={6}>
                                                {fieldType === AppInputTypes.FromToDatePicker ? (
                                                    <TillNowCheckBox
                                                        onClickCheckBox={() =>
                                                            onClickTillNow({
                                                                experienceId:
                                                                    experience?.ExperienceID,
                                                                questionIndex: questionIndex,
                                                            })
                                                        }
                                                        tillNow={isTillNow}
                                                    />
                                                ) : null}
                                            </Grid>
                                        </Grid>
                                        <CustomTextField
                                            showTextFieldPropError={true}
                                            inputType={fieldType}
                                            fullWidth
                                            size="small"
                                            required
                                            error={!!fieldError}
                                            variant="outlined"
                                            value={values}
                                            label={''}
                                            InputLabelProps={{
                                                shrink: false,
                                            }}
                                            onChange={(e) =>
                                                onChangeExperienceQuestionField({
                                                    value: e?.target?.value,
                                                    experienceId: experience?.ExperienceID,
                                                    questionIndex: questionIndex,
                                                })
                                            }
                                            SelectProps={{
                                                displayEmpty: true,
                                                value: values[0] ?? '',
                                                onChange: (event) => {
                                                    event.preventDefault();
                                                    onChangeExperienceQuestionField({
                                                        value: event?.target?.value,
                                                        experienceId: experience?.ExperienceID,
                                                        questionIndex: questionIndex,
                                                    });
                                                },
                                            }}
                                            options={questionOptions}
                                            multiSelectProps={{
                                                options:
                                                    questionOptions?.map((item) => {
                                                        return {
                                                            label: item,
                                                            value: item,
                                                        };
                                                    }) || [],

                                                onChange: (list) => {
                                                    let valuesToAdd = list?.map(
                                                        (item) => item?.value,
                                                    );
                                                    onChangeExperienceQuestionField({
                                                        value: valuesToAdd,
                                                        experienceId: experience?.ExperienceID,
                                                        questionIndex: questionIndex,
                                                    });
                                                },
                                                value: values?.map((item) => {
                                                    return {
                                                        label: item,
                                                        value: item,
                                                    };
                                                }),
                                            }}
                                            fromToDatePickerProps={{
                                                fromDate: {
                                                    value: moment(values[0]),
                                                    onChange: (date) =>
                                                        onChangeExperienceQuestionField({
                                                            value: date,
                                                            experienceId: experience?.ExperienceID,
                                                            questionIndex: questionIndex,
                                                            dateType: 'from',
                                                        }),
                                                },
                                                toDate: {
                                                    value: moment(values[1]),
                                                    onChange: (date) =>
                                                        onChangeExperienceQuestionField({
                                                            value: date,
                                                            experienceId: experience?.ExperienceID,
                                                            questionIndex: questionIndex,
                                                            dateType: 'to',
                                                        }),
                                                    disabled: isTillNow,
                                                },
                                            }}
                                            fileUploadProps={{
                                                value: values[0],
                                                onChange: (event) => {
                                                    event.preventDefault();
                                                    onChangeExperienceQuestionField({
                                                        value: event?.target?.value,
                                                        experienceId: experience?.ExperienceID,
                                                        questionIndex: questionIndex,
                                                    });
                                                },
                                                onClearInput: () => {
                                                    onChangeExperienceQuestionField({
                                                        value: '',
                                                        experienceId: experience?.ExperienceID,
                                                        questionIndex: questionIndex,
                                                    });
                                                },
                                            }}
                                        />

                                        {/* <Grid
                                                className="Mui-error css-k4qjio-MuiFormHelperText-root"
                                                item
                                                component={'h5'}
                                                fontWeight="normal"
                                                mt={1}
                                            >
                                                {fieldError}
                                            </Grid> */}
                                    </Grid>
                                );
                            })}
                        </Grid>
                    </ExpansionMenu>
                ))}
            </Grid>
        </Grid>
    );
};

ExperienceQuestions.propTypes = {
    adjusterExperienceQuestionDetail: PropTypes.array,
    onClickExperience: PropTypes.func,
    onClickTillNow: PropTypes.func,
    onChangeExperienceQuestionField: PropTypes.func,
};

export default ExperienceQuestions;
