import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import PropTypes from 'prop-types';
import { Divider } from '@mui/material';

const DeleteModal = ({
    isDeleteOpen,
    setIsDeleteOpen,
    propertyValues,
    setPropertyValues,
    handleDelete,
}) => {
    const handleClose = () => {
        setIsDeleteOpen(false);
    };

    const handleConForm = () => {
        handleDelete(propertyValues.idValue);
        setPropertyValues({ idValue: 0, name: '' });
        setIsDeleteOpen(false);
    };

    return (
        <div>
            <Dialog
                open={isDeleteOpen}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                {/* <DialogTitle id="alert-dialog-title">
          {"Use Google's location service?"}
        </DialogTitle> */}
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Do you want to delete?
                    </DialogContentText>
                </DialogContent>
                <Divider />
                <DialogActions>
                    <Button onClick={handleClose}>No</Button>
                    <Button onClick={handleConForm} autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default DeleteModal;

DeleteModal.propTypes = {
    isDeleteOpen: PropTypes.bool,
    setIsDeleteOpen: PropTypes.func,
    propertyValues: PropTypes.any,
    setPropertyValues: PropTypes.func,
    handleDelete: PropTypes.func,
};
