import React from 'react';
import { usePostData } from '../../hooks/use-post-data.hook';
import { AppUrls } from '../../core/route.url';
import UserList from './user-list';

const User = () => {
    const { submitData, response } = usePostData(AppUrls.UserList, 'POST');

    const [filterList, setFilterList] = React.useState([]);
    const [filterListForRoleColumn, setFilterListForRoleColumn] = React.useState([]);
    const [isLoadList, setIsLoadList] = React.useState(true);
    const [initialResult, setInitialResult] = React.useState([]);

    React.useEffect(() => {
        if (isLoadList) {
            setIsLoadList(false);
            submitData({
                PageNo: 1,
                PageSize: 500,
                SortDir: 'Ascending',
                SortExp: '',
                StartDate: null,
                EndDate: null,
                UserName: '',
                FirstName: '',
                LastName: '',
                EmailAddress: '',
                IsActive: true,
                RoleCode: '',
            });
        }
    }, [isLoadList, response.result]);

    React.useEffect(() => {
        if (response.result !== undefined ? (response.result.Data !== null ? 1 : 0) : 0) {
            response.result.Data.forEach((o) => {
                o.IsActive = o.IsActive === true ? 'Active' : 'Inactive';
            });
            setFilterList(response.result.Data);
            setInitialResult(response.result.Data);
            setFilterListForRoleColumn(response.result.Data);
        } else {
            setFilterList([]);
            setInitialResult([]);
        }
    }, [response]);

    // React.useEffect(() => {
    //     if (response.result !== undefined ? (response.result.Data !== null ? 1 : 0) : 0) {
    //         if (filterList.length > 0) {
    //             filterListForRoleColumn.forEach((o) => {
    //                 o.RoleName = o.RoleName === '' ? 'No Roles' : o.RoleName;
    //             });
    //             setFilterListForRoleColumn(filterListForRoleColumn);
    //         } else {
    //             setFilterList([]);
    //             setInitialResult([]);
    //         }
    //     }
    // }, [filterList]);

    const OnHandleChange = (searchterm) => {
        setFilterList(
            initialResult.filter((data) => {
                return (
                    (data.UserName &&
                        data.UserName.toLowerCase().includes(searchterm.toLocaleLowerCase())) ||
                    (data.UserFullName &&
                        data.UserFullName.toLowerCase().includes(searchterm.toLocaleLowerCase())) ||
                    (data.RoleName &&
                        data.RoleName.toLowerCase().includes(searchterm.toLocaleLowerCase())) ||
                    (data.EmailAddress &&
                        data.EmailAddress.toLowerCase().includes(searchterm.toLocaleLowerCase())) ||
                    (data.Phone &&
                        data.Phone.toLowerCase().includes(searchterm.toLocaleLowerCase())) ||
                    (data.ContactAddress &&
                        data.ContactAddress.toLowerCase().includes(
                            searchterm.toLocaleLowerCase(),
                        )) ||
                    (data.City &&
                        data.City.toLowerCase().includes(searchterm.toLocaleLowerCase())) ||
                    (data.StateName &&
                        data.StateName.toLowerCase().includes(searchterm.toLocaleLowerCase())) ||
                    (data.Zip && data.Zip.toLowerCase().includes(searchterm.toLocaleLowerCase())) ||
                    (data.ManagerName &&
                        data.ManagerName.toLowerCase().includes(searchterm.toLocaleLowerCase()))
                );
            }),
        );
    };

    return (
        <div className="main-content">
            {filterList.length > 0 ? (
                <>
                    <UserList
                        OnHandleChange={OnHandleChange}
                        filterList={filterList}
                        setIsLoadList={setIsLoadList}
                        filterListForRoleColumn={filterListForRoleColumn}
                    />
                </>
            ) : (
                <>
                    <UserList
                        OnHandleChange={OnHandleChange}
                        filterList={[]}
                        setIsLoadList={setIsLoadList}
                        filterListForRoleColumn={[]}
                    />
                </>
            )}
        </div>
    );
};

export default User;
User.propTypes = {};
