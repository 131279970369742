import * as React from 'react';
import { process } from '@progress/kendo-data-query';
import {
    Grid,
    GridColumn as Column,
    GridToolbar,
    getSelectedState,
} from '@progress/kendo-react-grid';
import { columnForUserSLATrack } from '../../components/kendoGrid/columns';
import { ColumnMenu } from '../../components/kendoGrid/customColumnMenu';
import { Link, useNavigate } from 'react-router-dom';
import { TextField, Box, LinearProgress } from '@mui/material';
import editIcon from '../../images/pencil.svg';
import { Tooltip } from '@progress/kendo-react-tooltip';
import deleteIcon from '../../images/trash.svg';
import { getter } from '@progress/kendo-react-common';
import { encryptWithAES } from '../../utils/global-functions';
import { useState } from 'react';

const REPLACE_REGEX = /\s/g;
const DEFAULT_PAGE = 100;
const DATA_ITEM_KEY = 'SLATrackingID';
const idGetter = getter(DATA_ITEM_KEY);

const UserSlaTrackList = ({ OnHandleChange, filterList, onHandleDeleteClick }) => {
    const history = useNavigate();
    const createDataState = (dataState) => {
        return {
            result: process(filterList.slice(0), dataState),
            dataState: dataState,
        };
    };

    const [stateColumns, setStateColumns] = React.useState(columnForUserSLATrack);

    const [initialState, setInitialState] = React.useState(
        createDataState({
            take: DEFAULT_PAGE,
            skip: 0,
        }),
    );
    const [result, setResult] = React.useState(initialState.result);

    React.useEffect(() => {
        setInitialState(
            createDataState({
                take: DEFAULT_PAGE,
                skip: 0,
            }),
        );
    }, [filterList]);

    React.useEffect(() => {
        setResult(initialState.result);
    }, [initialState]);

    const [dataState, setDataState] = React.useState(initialState.dataState);

    const dataStateChange = (event) => {
        let updatedState = createDataState(event.dataState);
        setResult(updatedState.result);
        setDataState(updatedState.dataState);
    };

    const [selectedState, setSelectedState] = React.useState({});

    const onSelectionChange = React.useCallback(
        (event) => {
            const newSelectedState = getSelectedState({
                event,
                selectedState: selectedState,
                dataItemKey: DATA_ITEM_KEY,
            });

            setSelectedState(newSelectedState);
        },
        [selectedState],
    );

    const onHeaderSelectionChange = React.useCallback((event) => {
        const checkboxElement = event.syntheticEvent.target;
        const checked = checkboxElement.checked;
        const newSelectedState = {};
        event.dataItems.forEach((item) => {
            newSelectedState[idGetter(item)] = checked;
        });
        setSelectedState(newSelectedState);
    }, []);

    const onColumnsSubmit = (columnsState) => {
        setStateColumns(columnsState);
    };

    const handleDeleteClick = (claimTaskId) => {
        onHandleDeleteClick(claimTaskId);
    };

    const editCell = (props) => {
        if (props.rowType === 'groupHeader') {
            return null;
        }
        return (
            <>
                <td className="k-command-cell hover">
                    <button className="icon-btn grid-icon">
                        <img src={editIcon} title="Edit" />
                    </button>

                    <button className="icon-btn grid-icon">
                        <img src={deleteIcon} title="Delete" />
                    </button>
                </td>
            </>
        );
    };

    const navigateCell = (props) => {
        if (props.rowType === 'groupHeader') {
            return null;
        }
        return (
            <>
                <td className="k-command-cell hover">
                    {' '}
                    <a
                        onClick={() => {
                            let param = {
                                state: {
                                    page: 'task',
                                    taskGuId: props.dataItem.ClaimTaskGuID,
                                    taskCode: props.dataItem.ClaimTaskTypeId,
                                    guId: props.dataItem.ClaimGuID,
                                },
                            };
                            let encryptedParam = encryptWithAES(JSON.stringify(param));
                            history(`/${encryptedParam}`, {
                                state: {
                                    page: 'task',
                                    taskGuId: props.dataItem.ClaimTaskGuID,
                                    taskCode: props.dataItem.ClaimTaskTypeId,
                                },
                            });
                        }}
                    >
                        {props.dataItem.ClaimTaskNumber}
                    </a>
                </td>
            </>
        );
    };

    const carrierCell = (props) => {
        if (props.rowType === 'groupHeader') {
            return null;
        }
        return (
            <>
                <td className="k-command-cell hover">
                    {' '}
                    <a
                        onClick={() => {
                            history(`/carriers/view`, {
                                state: {
                                    carrierId: `${props.dataItem.CarrierUniqueId}`,
                                },
                            });
                        }}
                    >
                        {props.dataItem.CarrierName}
                    </a>
                </td>
            </>
        );
    };

    const claimCell = (props) => {
        if (props.rowType === 'groupHeader') {
            return null;
        }
        return (
            <>
                <td className="k-command-cell hover">
                    {' '}
                    <a
                        onClick={() => {
                            let param = {
                                state: {
                                    page: 'claim',
                                    guId: props.dataItem.ClaimGuID,
                                },
                            };
                            let encryptedParam = encryptWithAES(JSON.stringify(param));
                            history(`/${encryptedParam}`);
                        }}
                    >
                        {props.dataItem.ClaimNumber}
                    </a>
                </td>
            </>
        );
    };

    const handleChangeValue = (e) => {
        OnHandleChange(e.target.value);
    };

    const elapsedPerCell = (props) => {
        if (props.rowType === 'groupHeader') {
            return null;
        }

        return (
            <>
                <td className="k-command-cell hover">
                    {' '}
                    {props.dataItem.ElapsedPercentage}
                    {!props.dataItem.HasBreached &&
                        !props.dataItem.HasRisk &&
                        !props.dataItem.IsStopped && (
                            <LinearProgressWithLabel
                                value={props.dataItem.ElapsedPercentage}
                                color="success"
                            />
                        )}
                    {props.dataItem.HasBreached &&
                        !props.dataItem.HasRisk &&
                        !props.dataItem.IsStopped && (
                            <LinearProgressWithLabel
                                value={props.dataItem.ElapsedPercentage}
                                color="error"
                            />
                        )}
                    {!props.dataItem.HasBreached &&
                        props.dataItem.HasRisk &&
                        !props.dataItem.IsStopped && (
                            <LinearProgressWithLabel
                                value={props.dataItem.ElapsedPercentage}
                                color="warning"
                            />
                        )}
                </td>
            </>
        );
    };
    function LinearProgressWithLabel(props) {
        return (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ width: '100%', mr: 1 }}>
                    <LinearProgress variant="determinate" {...props} />
                </Box>
            </Box>
        );
    }
    const [searchValue, setSearchValue] = useState('');

    const hasSearchKeyExist = (slaItem) => {
        return (
            slaItem.ClaimNumber?.toLowerCase()
                .replace(REPLACE_REGEX, '')
                .includes(searchValue?.toLocaleLowerCase().replace(REPLACE_REGEX, '')) ||
            slaItem.SLAName?.toLowerCase()
                .replace(REPLACE_REGEX, '')
                .includes(searchValue?.toLocaleLowerCase().replace(REPLACE_REGEX, '')) ||
            slaItem.ElapsedTime?.toString()
                ?.toLowerCase()
                .replace(REPLACE_REGEX, '')
                .includes(searchValue?.toLocaleLowerCase().replace(REPLACE_REGEX, '')) ||
            slaItem.PausedTime?.toString()
                ?.toLowerCase()
                .replace(REPLACE_REGEX, '')
                .includes(searchValue?.toLocaleLowerCase().replace(REPLACE_REGEX, '')) ||
            slaItem.BreachedTime?.toString()
                ?.toLowerCase()
                .replace(REPLACE_REGEX, '')
                .includes(searchValue?.toLocaleLowerCase().replace(REPLACE_REGEX, '')) ||
            slaItem.ElapsedPercentage?.toString()
                ?.toLowerCase()
                .replace(REPLACE_REGEX, '')
                .includes(searchValue?.toLocaleLowerCase().replace(REPLACE_REGEX, ''))
        );
    };

    const filterSLAList = (list = []) => {
        return list?.filter((slaItem) => hasSearchKeyExist(slaItem));
    };

    const filterGroup = (array, fn) => {
        return array.reduce((r, o) => {
            var children = filterGroup(o.items || [], fn);
            if (fn(o) || children.length)
                r.push(Object.assign({}, o, children.length && { children }));
            return r;
        }, []);
    };

    const getFilteredList = (data = { data: [], total: 0 }) => {
        let filteredData =
            data?.data?.length && data?.data[0]?.hasOwnProperty('SLATrackingID')
                ? filterSLAList(data?.data)
                : filterGroup(data?.data ?? [], (slaItem) => hasSearchKeyExist(slaItem));
        return {
            data: filteredData,
            total: data?.total,
        };
    };

    return (
        <>
            <div>
                <Grid
                    data={
                        result !== undefined && result.data !== undefined
                            ? getFilteredList(result)
                            : []
                    }
                    {...dataState}
                    onDataStateChange={dataStateChange}
                    onSelectionChange={onSelectionChange}
                    onHeaderSelectionChange={onHeaderSelectionChange}
                    sortable={true}
                    selectable={{
                        enabled: true,
                        drag: false,
                        cell: false,
                        mode: 'multiple',
                    }}
                    pageable={{
                        buttonCount: 5,
                        info: true,
                        type: 'numeric',
                        pageSizes: [100, 200, 500],
                        previousNext: true,
                    }}
                    groupable={true}
                    pageSize={DEFAULT_PAGE}
                    style={{
                        maxHeight: 'calc(100vh - 182px)',
                    }}
                    reorderable={true}
                    resizable={true}
                >
                    <GridToolbar>
                        <div className="search-wrap">
                            <div style={{ paddingLeft: 3 }}>
                                <TextField
                                    required
                                    id="outlined-required"
                                    label=""
                                    defaultValue=""
                                    size="small"
                                    onChange={(e) => setSearchValue(e?.target?.value)}
                                    value={searchValue}
                                />
                            </div>
                        </div>
                    </GridToolbar>

                    {stateColumns.map(
                        (column, idx) =>
                            column.show &&
                            (column.field == 'optn' ? (
                                <Column
                                    key={idx}
                                    field={column.field}
                                    title={column.title}
                                    cell={editCell}
                                    filter={column.filter}
                                    groupable
                                    columnMenu={(props) => (
                                        <ColumnMenu
                                            {...props}
                                            columns={stateColumns}
                                            onColumnsSubmit={onColumnsSubmit}
                                        />
                                    )}
                                />
                            ) : column.field == 'ElapsedPercentage' ? (
                                <Column
                                    key={idx}
                                    field={column.field}
                                    title={column.title}
                                    cell={elapsedPerCell}
                                    filter={column.filter}
                                    groupable
                                    columnMenu={(props) => (
                                        <ColumnMenu
                                            {...props}
                                            columns={stateColumns}
                                            onColumnsSubmit={onColumnsSubmit}
                                        />
                                    )}
                                />
                            ) : (
                                <Column
                                    key={idx}
                                    field={column.field}
                                    title={
                                        <Tooltip
                                            openDelay={100}
                                            position="top"
                                            anchorElement="target"
                                        >
                                            <div
                                                style={{
                                                    textAlign: 'center',
                                                }}
                                            >
                                                <span title={column.title}>{column.title}</span>
                                            </div>
                                        </Tooltip>
                                    }
                                    groupable
                                    filter={column.filter}
                                    columnMenu={(props) => (
                                        <ColumnMenu
                                            {...props}
                                            columns={stateColumns}
                                            onColumnsSubmit={onColumnsSubmit}
                                        />
                                    )}
                                />
                            )),
                    )}
                </Grid>
            </div>
        </>
    );
};

export default UserSlaTrackList;
