import { createContext, useReducer } from 'react';


function appContextReducer(state, data) {   
            return {
                ...state,
                currentUser: data,
            };        
}

function AppContextProvider({ children }) {
    const appState = {
        currentUser: {
            isAuthenticated: false,
            fullName: '',
            userName: '',
            emailAddress: '',
            phone: '',
            userGUID: '',
            userRoles: [],
            currentPermissions: [],
        },
    };
    const [state, dispatch] = useReducer(appContextReducer, appState);
    const value = { state, dispatch };
    return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
}

const AppContext = createContext(undefined);

export { AppContext, AppContextProvider };
