import React from 'react';
import {
    Grid,
    GridColumn as Column,
    getSelectedState,
    GridToolbar,
} from '@progress/kendo-react-grid';
import { getter } from '@progress/kendo-react-common';
import { columnsForUsers } from '../../components/kendoGrid/columns';
import { process } from '@progress/kendo-data-query';
import { ColumnMenu } from '../../components/kendoGrid/customColumnMenu';
import { CheckBoxColumnMenu } from '../../components/kendoGrid/checkboxColumnMenu';
import { GridPDFExport } from '@progress/kendo-react-pdf';
import linkopen from '../../images/link-open.svg';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { Link, useNavigate } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import iconexcel from '../../images/file-excel.svg';
import iconpdf from '../../images/file-pdf-solid.svg';
import { Box, Typography, Breadcrumbs, Paper } from '@mui/material';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import useUserPermissions from '../../hooks/use-user-permissions.hook';
import moment from 'moment';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const DATA_ITEM_KEY = 'UserId';
const SELECTED_FIELD = 'selected';
const idGetter = getter(DATA_ITEM_KEY);

const InspectorList = ({ OnHandleChange, filterList }) => {
    let initialState = {};
    const [display, setDisplay] = React.useState(false);
    const history = useNavigate();
    const { handleSetPrintPermissions } = useUserPermissions();
    const createDataState = (dataState) => {
        return {
            result: process(filterList.slice(0), dataState),
            dataState: dataState,
        };
    };

    const [stateColumns, setStateColumns] = React.useState(
        // columnsForUsers.filter((data) => {
        //     return data.field !== 'optn';
        // }),
        columnsForUsers,
    );
    const [idValue, setIdValue] = React.useState(0);
    const [idValues, setIdValues] = React.useState([]);
    const [groupOfGrid, setGroupOfGrid] = React.useState([]);
    const onColumnsSubmit = (columnsState) => {
        setStateColumns(columnsState);
    };

    initialState = createDataState({
        take: 100,
        skip: 0,
    });

    const [result, setResult] = React.useState(initialState.result);

    React.useEffect(() => {
        initialState = createDataState({
            take: 100,
            skip: 0,
            group: groupOfGrid,
        });
        setResult(initialState.result);
    }, [filterList]);

    const dataStateChange = (event) => {
        let updatedState = createDataState(event.dataState);
        setGroupOfGrid(event.dataState.group);
        setResult(updatedState.result);
        setDataState(updatedState.dataState);
    };
    const expandChange = (event) => {
        const isExpanded =
            event.dataItem.expanded === undefined
                ? event.dataItem.aggregates
                : event.dataItem.expanded;
        event.dataItem.expanded = !isExpanded;
        setResult({ ...result });
    };

    const [dataState, setDataState] = React.useState(initialState.dataState);

    const editCellForIsActive = (props) => {
        if (props.rowType === 'groupHeader') {
            return null;
        }
        return (
            <>
                <td className="k-command-cell">
                    {props.dataItem.IsActive ? 'Active' : 'Inactive'}
                </td>
            </>
        );
    };

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 480,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 3,
    };

    const navigateCell = (props) => {
        if (props.rowType === 'groupHeader') {
            return null;
        }
        return (
            <>
                <td className="k-command-cell">
                    {' '}
                    <Link to={`${props.dataItem.UserUniqueId}`}>{props.dataItem.UserName}</Link>
                </td>
            </>
        );
    };

    const navigateCount = (props) => {
        if (props.rowType === 'groupHeader') {
            return null;
        }
        return (
            <>
                <td className="k-command-cell">
                    {' '}
                    <Link to={`${props.dataItem.UserUniqueId}`}>
                        {props.dataItem.AssignedClaimCount}
                    </Link>
                </td>
            </>
        );
    };

    const customColumnMenu = (props) => <CheckBoxColumnMenu {...props} data={filterList} />;

    const handleChangeValue = (e) => {
        OnHandleChange(e.target.value);
    };
    const _export = React.useRef(null);

    const excelExport = () => {
        if (_export.current !== null) {
            _export.current.save();
        }
    };

    let _pdfExport;

    const exportPDF = () => {
        _pdfExport.save(
            process(filterList, {
                take: filterList.length + 1,
                skip: 0,
                group: dataState.group,
                filter: dataState.filter,
            }).data,
        );
    };

    const editCell = (props) => {
        if (props.rowType === 'groupHeader') {
            return null;
        }
        return (
            <>
                <td className="k-command-cell">
                    <button
                        onClick={() => {
                            history(
                                window.open(`/inspector/${props.dataItem.UserUniqueId}`, '_blank'),
                            );
                        }}
                        style={{ marginLeft: 10 }}
                        className="icon-btn"
                    >
                        <img style={{ width: 19 }} src={linkopen} title=" " />
                    </button>
                </td>
            </>
        );
    };

    const exportGrid = (value) => {
        return (
            <Grid
                data={
                    result !== undefined && result.data !== undefined
                        ? result.data.map((item) => ({
                              ...item,
                          }))
                        : []
                }
                {...dataState}
                style={{
                    maxHeight: 'calc(100vh - 147px)',
                    display: value ? 'none' : '',
                }}
                // dataItemKey={DATA_ITEM_KEY}
                // selectedField={SELECTED_FIELD}
                selectable={{
                    enabled: true,
                    drag: false,
                    cell: false,
                    mode: 'multiple',
                }}
                // onHeaderSelectionChange={onHeaderSelectionChange}
                onDataStateChange={dataStateChange}
                sortable={true}
                pageable={{
                    buttonCount: 5,
                    info: true,
                    type: 'numeric',
                    pageSizes: [100, 200, 500, 1000],
                    previousNext: true,
                }}
                groupable={true}
                total={result.total}
                pageSize={8}
                resizable={true}
                onRowDoubleClick={() => {}}
            >
                {stateColumns
                    .filter((data, i) => {
                        return data.show === true && data.field !== 'optn';
                    })
                    .map((d, index) => {
                        return <Column key={index} field={d.field} title={d.title} />;
                    })}
                {/* <Column key={1} field="ClaimNumber" title="Claim Number" />
                <Column key={2} field="InsuredName" title="Insured Name" />
                <Column key={3} field="CarrierName" title="Carrier" />
                <Column key={4} field="StateCode" title="State Code" />
                <Column key={5} field="ClaimStatusName" title="Status" />
                <Column key={6} field="ScheduledOn" title="Scheduled" />
                <Column key={7} field="InspectedOn" title="Inspected" />
                <Column key={8} field="AssignedToName" title="Assigned" />
                <Column key={9} field="ScheduledOn" title="Scheduled" />
                <Column key={9} field="InspectedOn" title="Inspected" /> */}
            </Grid>
        );
    };

    return (
        <>
            <div>
                <section className="userlist-header">
                    <Box className="resGrid">
                        <Box>
                            <Typography className="title m-0">Resources</Typography>
                            <Breadcrumbs aria-label="breadcrumb" style={{ marginBottom: 10 }}>
                                <Link
                                    to={'/'}
                                    underline="hover"
                                    color="inherit"
                                    style={{ textDecoration: 'none' }}
                                >
                                    Home
                                </Link>
                                <Link
                                    to={'/inspector'}
                                    underline="hover"
                                    color="text.primary"
                                    aria-current="page"
                                    style={{ textDecoration: 'none' }}
                                >
                                    Resources
                                </Link>
                            </Breadcrumbs>
                        </Box>
                        <Box className="statRes">
                            <Box>
                                <Typography variant="span">Total Inspectors</Typography>
                                <Typography variant="h1">{filterList.length}</Typography>
                            </Box>
                            <Box>
                                <Typography variant="span">Assigned Claims</Typography>
                                <Typography variant="h1">
                                    {filterList[0] && filterList[0].TotalAssignedClaimCount}
                                </Typography>
                            </Box>
                            <Box>
                                <Typography variant="span">Unassigned Claims</Typography>
                                <Typography variant="h1">
                                    {filterList[0] && filterList[0].TotalUnAssignedClaimCount}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </section>
            </div>
            <div className="claims-list-grid">
                {/* <ExcelExport
                    data={result !== undefined && result.data !== undefined ? result.data : []}
                    ref={_export}
                > */}
                <Grid
                    data={result !== undefined && result.data !== undefined ? result : []}
                    {...dataState}
                    style={{
                        maxHeight: 'calc(100vh - 147px)',
                    }}
                    // dataItemKey={DATA_ITEM_KEY}
                    // selectedField={SELECTED_FIELD}
                    selectable={{
                        enabled: true,
                        drag: false,
                        cell: false,
                        mode: 'multiple',
                    }}
                    // onHeaderSelectionChange={onHeaderSelectionChange}
                    onDataStateChange={dataStateChange}
                    sortable={true}
                    pageable={{
                        buttonCount: 5,
                        info: true,
                        type: 'numeric',
                        pageSizes: [100, 200, 500, 1000],
                        previousNext: true,
                    }}
                    groupable={true}
                    total={result.total}
                    pageSize={8}
                    resizable={true}
                    onRowDoubleClick={() => {}}
                    expandField="expanded"
                    onExpandChange={expandChange}
                >
                    <GridToolbar>
                        <div className="search-wrap">
                            <div>
                                <TextField
                                    required
                                    id="outlined-required"
                                    label=""
                                    defaultValue=""
                                    size="small"
                                    onChange={(e) => handleChangeValue(e)}
                                />
                            </div>
                        </div>
                    </GridToolbar>
                    {handleSetPrintPermissions('Resources') ? (
                        <GridToolbar>
                            <div>
                                <button
                                    title="Export to Excel"
                                    className="icon-btn"
                                    onClick={excelExport}
                                >
                                    <img src={iconexcel} title="Export to Excel" />
                                </button>

                                <button className="icon-btn" onClick={exportPDF}>
                                    <img src={iconpdf} title="Export to PDF" />
                                </button>
                            </div>
                        </GridToolbar>
                    ) : null}
                    {/* <Column
                            field={SELECTED_FIELD}
                            width="40px"
                            headerSelectionValue={
                                result !== undefined && result.data !== undefined
                                    ? result.data.findIndex(
                                          (item) => !selectedState[idGetter(item)],
                                      ) === -1
                                    : 0
                            }
                        /> */}

                    {stateColumns.map(
                        (column, idx) =>
                            column.show &&
                            (column.field === 'UserName' ? (
                                <Column
                                    key={idx}
                                    field={column.field}
                                    title={column.title}
                                    cell={navigateCell}
                                    filter={column.filter}
                                    columnMenu={(props) => (
                                        <ColumnMenu
                                            {...props}
                                            columns={stateColumns}
                                            onColumnsSubmit={onColumnsSubmit}
                                        />
                                    )}
                                />
                            ) : column.field === 'AssignedClaimCount' ? (
                                <Column
                                    key={idx}
                                    field={column.field}
                                    title={column.title}
                                    cell={navigateCount}
                                    filter={column.filter}
                                    columnMenu={(props) => (
                                        <ColumnMenu
                                            {...props}
                                            columns={stateColumns}
                                            onColumnsSubmit={onColumnsSubmit}
                                        />
                                    )}
                                />
                            ) : column.field == 'IsActive' ? (
                                <Column
                                    width={100}
                                    key={idx}
                                    field={column.field}
                                    title={column.title}
                                    // cell={editCellForIsActive}
                                    filter={column.filter}
                                    columnMenu={(props) => (
                                        <ColumnMenu
                                            {...props}
                                            columns={stateColumns}
                                            onColumnsSubmit={onColumnsSubmit}
                                        />
                                    )}
                                />
                            ) : column.field === 'optn' ? (
                                <Column
                                    width={100}
                                    key={idx}
                                    field={column.field}
                                    title={column.title}
                                    cell={editCell}
                                    filter={column.filter}
                                    columnMenu={(props) => (
                                        <ColumnMenu
                                            {...props}
                                            columns={stateColumns}
                                            onColumnsSubmit={onColumnsSubmit}
                                        />
                                    )}
                                />
                            ) : column.field == 'StateCode' ? (
                                <Column
                                    key={idx}
                                    field={column.field}
                                    title={column.title}
                                    filter={column.filter}
                                    columnMenu={customColumnMenu}
                                />
                            ) : (
                                <Column
                                    key={idx}
                                    field={column.field}
                                    title={column.title}
                                    // cell={editCell}
                                    filter={column.filter}
                                    columnMenu={(props) => (
                                        <ColumnMenu
                                            {...props}
                                            columns={stateColumns}
                                            onColumnsSubmit={onColumnsSubmit}
                                        />
                                    )}
                                />
                            )),
                    )}
                </Grid>
                {/* </ExcelExport> */}
                <ExcelExport
                    fileName={`ResourcesExport_${moment(new Date()).format('MM-DD-YYYY hh mm a')}`}
                    style={{ display: 'none' }}
                    // data={result !== undefined && result.data !== undefined ? result.data : []}
                    // ref={_export}
                    data={
                        filterList
                            ? process(filterList, {
                                  take: filterList.length + 1,
                                  skip: 0,
                                  group: dataState.group,
                                  filter: dataState.filter,
                              }).data
                            : []
                    }
                    ref={_export}
                    groupable={true}
                    group={dataState.group}
                >
                    {exportGrid(true)}
                </ExcelExport>
                <GridPDFExport
                    fileName={`ResourcesExport_${moment(new Date()).format('MM-DD-YYYY hh mm a')}`}
                    ref={(element) => {
                        _pdfExport = element;
                    }}
                    margin="1cm"
                >
                    {exportGrid(false)}
                </GridPDFExport>
                {/* <GridPDFExport
                    ref={(element) => {
                        _pdfExport = element;
                    }}
                    margin="1cm"
                >
                    {
                        <Grid
                            data={
                                result !== undefined && result.data !== undefined
                                    ? result.data.map((item) => ({
                                          ...item,
                                          //   [SELECTED_FIELD]: selectedState[idGetter(item)],
                                      }))
                                    : []
                            }
                            {...dataState}
                            style={{
                                maxHeight: 'calc(100vh - 234px)',
                            }}
                            // dataItemKey={DATA_ITEM_KEY}
                            // selectedField={SELECTED_FIELD}
                            selectable={{
                                enabled: true,
                                drag: false,
                                cell: false,
                                mode: 'multiple',
                            }}
                            // onSelectionChange={onSelectionChange}
                            // onHeaderSelectionChange={onHeaderSelectionChange}
                            onDataStateChange={dataStateChange}
                            sortable={true}
                            pageable={{
                                buttonCount: 5,
                                info: true,
                                type: 'numeric',
                                pageSizes: [25, 50, 100, 200],
                                previousNext: true,
                            }}
                            resizable={true}
                            groupable={true}
                            total={result.total}
                            pageSize={8}
                            onRowDoubleClick={() => {}}
                        >
                            <Column field="UserName" title="UserName" />
                            <Column field="UserFullName" title="Name" />
                            <Column field="RoleName" title="Role" />
                            <Column field="AssignedClaimCount" title="Claims" />
                            <Column field="EmailAddress" title="Email" />
                            <Column field="Phone" title="Phone" />
                            <Column field="ContactAddress" title="Address" />
                            <Column field="City" title="City" />
                            <Column field="StateCode" title="State" />
                            <Column field="Zip" title="Zip" />
                            <Column field="ManagerName" title="Manager" />
                            <Column field="IsActive" title="Status" />
                        </Grid>
                    }
                </GridPDFExport> */}
            </div>
        </>
    );
};

export default InspectorList;

InspectorList.propTypes = {
    filterList: PropTypes.array.isRequired,
    setIsLoadList: PropTypes.any.isRequired,
};
