import PropTypes from 'prop-types';
import { ResourceCardComponent } from './resource-card.component';
import { SelectedClaimsComponent } from './selected-claims.component';
import { Alert, Box } from '@mui/material';
import { usePostData2 } from '../../hooks/use-post-data-2.hook';
import { useEffect } from 'react';
import { ConfirmationButton } from '../../components/confirmation-button.dialog';
import { AlertDialog } from '../../components/alert.dialog';
import { useState } from 'react';

export const AssignComponent = ({
    resource,
    claims,
    toggleClaimSelection,
    toggleResourceSelection,
    locateMapData,
    refreshPage,
}) => {
    const [isAlertOpen, setAlertOpen] = useState(false);

    const {
        submitData,
        response: { isLoading, result, isError },
    } = usePostData2('Claim/AssignClaim');

    useEffect(() => {
        if (result) {
            // setAlertOpen(true);
            refreshPage();
        }
    }, [result]);

    const handleAssignClaim = async () => {
        const claimsToAssign = claims
            ?.filter((c) => {
                return c.isSelected;
            })
            .map(({ data: claim }) => ({
                assignedToId: resource.data.ResourceId,
                AssignedToName: resource.data.ResourceName,
                claimGuID: claim.ClaimGuID,
            }));
        if (claimsToAssign.length > 0) {
            submitData(claimsToAssign);
        }
    };

    const handleSuccess = () => {
        setAlertOpen(false);
        // refreshPage();
    };

    return (
        <div className="resourcesTab">
            <h4>CLAIM ASSIGNMENT</h4>
            <hr />
            {isLoading && <Alert severity="info">Please wait...</Alert>}
            {isError && <Alert severity="error">Failed to submit assignment</Alert>}
            <div>
                <h3 style={{ marginTop: 15 }}>Resource:</h3>
                {resource ? (
                    <ResourceCardComponent
                        resourceData={resource}
                        toggleResourceSelection={toggleResourceSelection}
                        locateResource={locateMapData}
                    />
                ) : (
                    <Alert severity="warning">Please choose a resource.</Alert>
                )}
            </div>
            <div>
                <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <h3 style={{ marginTop: 15 }}>Claims:</h3>
                    {resource && claims && claims.length > 0 && (
                        <ConfirmationButton
                            title="Assign"
                            confirmaionMessageTitle="Claim Assignment"
                            confirmaionMessage={
                                'Are you sure you want to assign ' +
                                (claims.length > 1 ? 'these claims to ' : 'this claim to ') +
                                resource.data.ResourceName +
                                '?'
                            }
                            onOK={handleAssignClaim}
                        />
                    )}
                </Box>

                {claims && claims.length > 0 ? (
                    <SelectedClaimsComponent
                        claims={claims}
                        toggleClaimSelection={toggleClaimSelection}
                        locateClaim={locateMapData}
                    />
                ) : (
                    <Alert severity="warning">Please select the claims you wish to assign.</Alert>
                )}

                <AlertDialog
                    isOpen={isAlertOpen}
                    title="Success"
                    message={claims.length > 1 ? 'Claims' : 'Claim' + ' assigned successfully...'}
                    onClose={handleSuccess}
                />
            </div>
        </div>
    );
};

AssignComponent.propTypes = {
    resourceData: PropTypes.shape({
        id: PropTypes.number.isRequired,
        position: PropTypes.shape({
            lat: PropTypes.number.isRequired,
            lng: PropTypes.number.isRequired,
        }),
        isSelected: PropTypes.bool,
        showInfo: PropTypes.bool,
        color: PropTypes.any,
        data: PropTypes.shape({
            ResourceName: PropTypes.string.isRequired,
            EmailAddress: PropTypes.string.isRequired,
        }).isRequired,
    }).isRequired,
    claims: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            position: PropTypes.shape({
                lat: PropTypes.number.isRequired,
                lng: PropTypes.number.isRequired,
            }),
            isSelected: PropTypes.bool.isRequired,
            showInfo: PropTypes.bool,
            color: PropTypes.any,
            data: PropTypes.shape({
                ClaimNumber: PropTypes.string.isRequired,
                InsuredName: PropTypes.string.isRequired,
                InsuredAddress: PropTypes.string.isRequired,
            }).isRequired,
        }).isRequired,
    ).isRequired,
    toggleClaimSelection: PropTypes.func,
    toggleResourceSelection: PropTypes.func,
    setLocateMarkerInfo: PropTypes.func,
    refreshPage: PropTypes.func,
};
