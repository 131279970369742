import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    Alert,
    Box,
    Button,
    CircularProgress,
    FormControl,
    TextField,
    Modal,
    Divider,
    FormControlLabel,
    Checkbox,
    FormGroup,
    Grid,
    Typography,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import SelectOption from '../../components/select-option';
import SelectMulti from '../../components/select-multi';
import { AppUrls } from '../../core/route.url';
import { usePostData } from '../../hooks/use-post-data.hook';
import { usePostData2 } from '../../hooks/use-post-data-2.hook';
import { forEach } from 'lodash';
import { useLoadData2 } from '../../hooks/use-load-data-2.hook';

const SlaForm = ({
    setIsOpen,
    carrierId,
    setSLAIsLoad,
    setIdValue,
    idValue,
    isOpen,
    statusResponse,
}) => {
    const {
        register,
        handleSubmit,
        control,
        setValue,
        watch,

        formState: { errors },
    } = useForm();

    const [endStatus, setEndStatus] = React.useState([]);

    const { submitData, response } = usePostData(AppUrls.SaveSLA, 'POST');
    const [savedResponse, setSaveResponse] = useState({});
    // const { response: statusResponse } = usePostData2(AppUrls.ClaimProfileStatusBar, {
    //     pageName: 'Task',
    //     ClaimTaskStatusCode: 'CLAIM_TASK',
    // });
    const {
        isLoading: loading,
        result: resultData,
        isError: Error,
        reload,
        paramValue,
    } = useLoadData2();

    useEffect(() => {
        if (idValue !== 0) {
            reload(AppUrls.GetSLA + `?sLAId=${idValue}`, '');
        }
    }, [idValue]);

    const watchAllFields = watch();

    // React.useEffect(() => {
    //     if (statusResponse !== undefined) {
    //         if (statusResponse.Data !== undefined && watchAllFields.startingStatus !== undefined) {
    //             if (
    //                 watchAllFields.startingStatus > watchAllFields.endingStatus ||
    //                 watchAllFields.startingStatus === watchAllFields.endingStatus
    //             ) {
    //                 watch('endingStatus', undefined);
    //             }
    //             setEndStatus(
    //                 statusResponse.Data.filter((data, index) => {
    //                     return data.SeqNo > watchAllFields.startingStatus;
    //                 }),
    //             );
    //         }
    //     }
    // }, [watchAllFields.startingStatus]);

    useEffect(() => {
        if (response.result != undefined) {
            setSaveResponse(response.result);

            const timer = setTimeout(() => {
                setSaveResponse({});
                setSLAIsLoad(true);
                setIsOpen(false);
                setValue('slaDefinition', '');
                setValue('preferedDurationDays', '');
                setValue('preferedDurationHours', '');
                setValue('PreferedDurationMinutes', '');
                setValue('cutOffDurationDays', '');
                setValue('CutOffDurationHours', '');
                setValue('CutOffDurationMinutes', '');

                setValue('startingStatus', '');
                setValue('endingStatus', '');

                setValue('Taskstatus', '');
                setValue('active', false);
                setValue('appliesToReservationClaims', false);
            }, 1000);
            return () => clearTimeout(timer);
        }
    }, [response]);

    React.useEffect(() => {
        if (resultData && resultData ? true : false) {
            setValue('slaDefinition', resultData.Data.SLADefinition);
            setValue(
                'preferedDurationDays',
                resultData.Data.PreferedDurationDays === 0
                    ? undefined
                    : resultData.Data.PreferedDurationDays.toString(),
            );
            setValue(
                'preferedDurationHours',
                resultData.Data.PreferedDurationHours === 0
                    ? undefined
                    : resultData.Data.PreferedDurationHours.toString(),
            );
            setValue(
                'PreferedDurationMinutes',
                resultData.Data.PreferedDurationMinutes === 0
                    ? undefined
                    : resultData.Data.PreferedDurationMinutes.toString(),
            );
            setValue(
                'cutOffDurationDays',
                resultData.Data.CutOffDurationDays === 0
                    ? undefined
                    : resultData.Data.CutOffDurationDays.toString(),
            );
            setValue(
                'CutOffDurationHours',
                resultData.Data.CutOffDurationHours === 0
                    ? undefined
                    : resultData.Data.CutOffDurationHours.toString(),
            );
            setValue(
                'CutOffDurationMinutes',
                resultData.Data.CutOffDurationMinutes === 0
                    ? undefined
                    : resultData.Data.CutOffDurationMinutes.toString(),
            );

            setValue(
                'startingStatus',
                resultData.Data.SLAStartStatusCode,
                // statusResponse && statusResponse
                //     ? statusResponse.Data.filter((d, index) => {
                //           return d.ClaimTaskStatusCode === resultData.Data.SLAStartStatusCode;
                //       })[0]?.SeqNo
                //     : '',
            );
            setValue(
                'endingStatus',
                resultData.Data.SLAEndStatusCode,
                // statusResponse && statusResponse
                //     ? statusResponse.Data.filter((d, index) => {
                //           return d.ClaimTaskStatusCode === resultData.Data.SLAEndStatusCode;
                //       })[0]?.SeqNo
                //     : '',
            );

            setValue(
                'Taskstatus',
                resultData.Data.ClaimTaskStatusId === 0
                    ? undefined
                    : resultData.Data.ClaimTaskStatusId?.toString(),
            );
            setValue(
                'changeTheStatusClaim',
                resultData.Data.ClaimTaskStatusId !== 0 ? true : false,
            );

            setValue('active', resultData.Data.IsActive);
            setValue('appliesToReservationClaims', resultData.Data.ReserveOnly);
        }
    }, [resultData]);

    React.useEffect(() => {
        if (!isOpen) {
            setValue('slaDefinition', '');
            setValue('preferedDurationDays', '');
            setValue('preferedDurationHours', '');
            setValue('PreferedDurationMinutes', '');
            setValue('cutOffDurationDays', '');
            setValue('CutOffDurationHours', '');
            setValue('CutOffDurationMinutes', '');

            setValue('startingStatus', '');
            setValue('endingStatus', '');

            setValue('Taskstatus', '');
            setValue('active', false);
            setValue('appliesToReservationClaims', false);
        }
    }, [isOpen]);

    const daysValue = Array.from({ length: 31 }, (v, k) => k + 1).map((e, i) => {
        return {
            value: `${e}`,
            label: `${e}`,
        };
    });

    const hoursValue = Array.from({ length: 24 }, (v, k) => k + 1).map((e, i) => {
        return {
            value: `${e}`,
            label: `${e}`,
        };
    });
    const minuteValue = Array.from({ length: 60 }, (v, k) => k + 1).map((e, i) => {
        return {
            value: `${e}`,
            label: `${e}`,
        };
    });

    const onSubmit = async (data) => {
        submitData({
            SLAId: idValue,
            SLATypeId: carrierId === 0 ? 1 : 2,
            TenantId: 'string',
            CarrierId: carrierId,
            SLADefinition: data.slaDefinition,
            PreferedDurationDays:
                data.preferedDurationDays && data.preferedDurationDays !== ''
                    ? parseInt(data.preferedDurationDays)
                    : 0,
            PreferedDurationHours:
                data.preferedDurationHours && data.preferedDurationHours !== ''
                    ? parseInt(data.preferedDurationHours)
                    : 0,
            PreferedDurationMinutes:
                data.PreferedDurationMinutes && data.PreferedDurationMinutes !== ''
                    ? parseInt(data.PreferedDurationMinutes)
                    : 0,
            CutOffDurationDays:
                data.cutOffDurationDays && data.cutOffDurationDays !== ''
                    ? parseInt(data.cutOffDurationDays)
                    : 0,
            CutOffDurationHours:
                data.CutOffDurationHours && data.CutOffDurationHours !== ''
                    ? parseInt(data.CutOffDurationHours)
                    : 0,
            CutOffDurationMinutes:
                data.CutOffDurationMinutes && data.CutOffDurationMinutes !== ''
                    ? parseInt(data.CutOffDurationMinutes)
                    : 0,

            SLAEndStatusTemp: '',

            CreatedBy: 0,
            UpdatedBy: 0,
            CreatedOn: '2022-08-19T10:34:42.896Z',
            IsActive: data.active,
            ClaimTaskStatusId: data.changeTheStatusClaim ? data.Taskstatus : 0,
            SLAStartStatusCode: data.startingStatus,
            // statusResponse !== undefined && data.startingStatus !== undefined
            //     ? statusResponse.Data.filter((d, index) => {
            //           return d.SeqNo === data.startingStatus;
            //       })[0] !== undefined
            //         ? statusResponse.Data.filter((d, index) => {
            //               return d.SeqNo === data.startingStatus;
            //           })[0].ClaimTaskStatusCode
            //         : ''
            //     : '',
            SLAEndStatusCode: data.endingStatus,
            // statusResponse !== undefined && data.endingStatus !== undefined
            //     ? statusResponse.Data.filter((d, index) => {
            //           return d.SeqNo === data.endingStatus;
            //       })[0] !== undefined
            //         ? statusResponse.Data.filter((d, index) => {
            //               return d.SeqNo === data.endingStatus;
            //           })[0].ClaimTaskStatusCode
            //         : ''
            //     : '',

            ReserveOnly: data.appliesToReservationClaims,
        });
    };

    return (
        <>
            <h2 style={{ marginBottom: 20 }}>SLA Information</h2>
            <Box
                component="form"
                noValidate
                autoComplete="off"
                onSubmit={handleSubmit(onSubmit)}
                // style={{ zIndex: 10, position: 'absolute' }}
            >
                <div>
                    <TextField
                        fullWidth
                        size="small"
                        required
                        error={errors.slaDefinition}
                        id="outlined-required"
                        label="SLA Definition"
                        defaultValue=""
                        InputLabelProps={{
                            shrink: watchAllFields.slaDefinition === '' ? false : true,
                        }}
                        {...register('slaDefinition', { required: true })}
                    />
                    <Box style={{ display: 'flex', columnGap: 15, marginTop: 15 }}>
                        <FormControl fullWidth size="small">
                            <Controller
                                fullWidth
                                size="small"
                                control={control}
                                name="startingStatus"
                                rules={{
                                    required: true,
                                }}
                                render={({ field: { onChange, onBlur, value, name } }) => (
                                    <SelectOption
                                        fullWidth
                                        size="small"
                                        values={
                                            statusResponse !== undefined
                                                ? statusResponse?.Data?.map((e) => {
                                                      return {
                                                          value: e['ClaimTaskStatusCode'],
                                                          label: e['ClaimTaskStatusName'],
                                                      };
                                                  })
                                                : []
                                        }
                                        disabled={false}
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        value={value === undefined ? '' : value}
                                        // name={name || ''}
                                        name={'Starting Status' || ''}
                                        error={errors.startingStatus}
                                    />
                                )}
                            />
                        </FormControl>
                        <FormControl fullWidth size="small">
                            <Controller
                                control={control}
                                name="endingStatus"
                                rules={{
                                    required: true,
                                }}
                                render={({ field: { onChange, onBlur, value, name } }) => (
                                    <SelectOption
                                        fullWidth
                                        size="small"
                                        values={
                                            statusResponse !== undefined
                                                ? statusResponse?.Data?.map((e) => {
                                                      return {
                                                          value: e['ClaimTaskStatusCode'],
                                                          label: e['ClaimTaskStatusName'],
                                                      };
                                                  })
                                                : []
                                            // endStatus !== undefined
                                            //     ? endStatus?.map((e) => {
                                            //           return {
                                            //               value: e['ClaimTaskStatusCode'],
                                            //               label: e['ClaimTaskStatusName'],
                                            //           };
                                            //       })
                                            //     : []
                                        }
                                        disabled={
                                            watchAllFields.startingStatus !== undefined
                                                ? false
                                                : true
                                        }
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        value={value === undefined ? '' : value}
                                        // name={name || ''}
                                        name={'Ending Status' || ''}
                                        error={errors.endingStatus}
                                    />
                                )}
                            />
                        </FormControl>
                    </Box>
                    <Box style={{ flex: 1 }}>
                        <FormControlLabel
                            fullWidth
                            control={
                                <Checkbox
                                    checked={
                                        watchAllFields.appliesToReservationClaims !== undefined
                                            ? watchAllFields.appliesToReservationClaims
                                            : false
                                    }
                                    {...register('appliesToReservationClaims', {
                                        required: false,
                                    })}
                                />
                            }
                            label="Applies to Reservation claims"
                        />
                    </Box>
                    <Typography variant="h3" style={{ marginTop: 15, marginBottom: 15 }}>
                        Prefered Duration
                    </Typography>
                    <Box style={{ display: 'flex', columnGap: 15, marginTop: 15 }}>
                        <FormControl
                            fullWidth
                            size="small"
                            className={
                                (watchAllFields.preferedDurationDays &&
                                    watchAllFields.preferedDurationDays !== '') ||
                                (watchAllFields.preferedDurationHours &&
                                    watchAllFields.preferedDurationHours !== '') ||
                                (watchAllFields.PreferedDurationMinutes &&
                                    watchAllFields.PreferedDurationMinutes !== '')
                                    ? 'nonValidateClass'
                                    : 'validateClass'
                            }
                        >
                            <Controller
                                fullWidth
                                size="small"
                                control={control}
                                name="preferedDurationDays"
                                rules={{
                                    required:
                                        (watchAllFields.preferedDurationDays &&
                                            watchAllFields.preferedDurationDays !== '') ||
                                        (watchAllFields.preferedDurationHours &&
                                            watchAllFields.preferedDurationHours !== '') ||
                                        (watchAllFields.PreferedDurationMinutes &&
                                            watchAllFields.PreferedDurationMinutes !== '')
                                            ? false
                                            : true,
                                }}
                                render={({ field: { onChange, onBlur, value, name } }) => (
                                    <SelectMulti
                                        values={daysValue}
                                        disabled={false}
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        value={value === undefined ? '' : value}
                                        // name={name || ''}
                                        nameForLablel="Days "
                                        name={'Day' || ''}
                                        error={
                                            (watchAllFields.preferedDurationDays &&
                                                watchAllFields.preferedDurationDays !== '') ||
                                            (watchAllFields.preferedDurationHours &&
                                                watchAllFields.preferedDurationHours !== '') ||
                                            (watchAllFields.PreferedDurationMinutes &&
                                                watchAllFields.PreferedDurationMinutes !== '')
                                                ? false
                                                : true
                                        }
                                        multi={false}
                                    />
                                )}
                            />
                        </FormControl>
                        <FormControl
                            fullWidth
                            size="small"
                            className={
                                (watchAllFields.preferedDurationDays &&
                                    watchAllFields.preferedDurationDays !== '') ||
                                (watchAllFields.preferedDurationHours &&
                                    watchAllFields.preferedDurationHours !== '') ||
                                (watchAllFields.PreferedDurationMinutes &&
                                    watchAllFields.PreferedDurationMinutes !== '')
                                    ? 'nonValidateClass'
                                    : 'validateClass'
                            }
                        >
                            <Controller
                                fullWidth
                                size="small"
                                control={control}
                                name="preferedDurationHours"
                                rules={{
                                    required:
                                        (watchAllFields.preferedDurationDays &&
                                            watchAllFields.preferedDurationDays !== '') ||
                                        (watchAllFields.preferedDurationHours &&
                                            watchAllFields.preferedDurationHours !== '') ||
                                        (watchAllFields.PreferedDurationMinutes &&
                                            watchAllFields.PreferedDurationMinutes !== '')
                                            ? false
                                            : true,
                                }}
                                render={({ field: { onChange, onBlur, value, name } }) => (
                                    <SelectMulti
                                        values={hoursValue}
                                        disabled={false}
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        value={value === undefined ? '' : value}
                                        // name={name || ''}
                                        nameForLablel="Hours "
                                        name={'Hours' || ''}
                                        error={
                                            (watchAllFields.preferedDurationDays &&
                                                watchAllFields.preferedDurationDays !== '') ||
                                            (watchAllFields.preferedDurationHours &&
                                                watchAllFields.preferedDurationHours !== '') ||
                                            (watchAllFields.PreferedDurationMinutes &&
                                                watchAllFields.PreferedDurationMinutes !== '')
                                                ? false
                                                : true
                                        }
                                        multi={false}
                                    />
                                )}
                            />
                        </FormControl>
                        <FormControl
                            fullWidth
                            size="small"
                            style={{ backgroundColor: '' }}
                            className={
                                (watchAllFields.preferedDurationDays &&
                                    watchAllFields.preferedDurationDays !== '') ||
                                (watchAllFields.preferedDurationHours &&
                                    watchAllFields.preferedDurationHours !== '') ||
                                (watchAllFields.PreferedDurationMinutes &&
                                    watchAllFields.PreferedDurationMinutes !== '')
                                    ? 'nonValidateClass'
                                    : 'validateClass'
                            }
                        >
                            <Controller
                                fullWidth
                                size="small"
                                control={control}
                                name="PreferedDurationMinutes"
                                rules={{
                                    required:
                                        (watchAllFields.preferedDurationDays &&
                                            watchAllFields.preferedDurationDays !== '') ||
                                        (watchAllFields.preferedDurationHours &&
                                            watchAllFields.preferedDurationHours !== '') ||
                                        (watchAllFields.PreferedDurationMinutes &&
                                            watchAllFields.PreferedDurationMinutes !== '')
                                            ? false
                                            : true,
                                }}
                                render={({ field: { onChange, onBlur, value, name } }) => (
                                    <SelectMulti
                                        values={minuteValue}
                                        disabled={false}
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        value={value === undefined ? '' : value}
                                        // name={name || ''}
                                        nameForLablel="Minute "
                                        name={'Minute' || ''}
                                        error={
                                            (watchAllFields.preferedDurationDays &&
                                                watchAllFields.preferedDurationDays !== '') ||
                                            (watchAllFields.preferedDurationHours &&
                                                watchAllFields.preferedDurationHours !== '') ||
                                            (watchAllFields.PreferedDurationMinutes &&
                                                watchAllFields.PreferedDurationMinutes !== '')
                                                ? false
                                                : true
                                        }
                                        multi={false}
                                    />
                                )}
                            />
                        </FormControl>
                    </Box>
                    <Typography variant="h3" style={{ marginTop: 15, marginBottom: 15 }}>
                        CutOff Duration
                    </Typography>
                    <Box style={{ display: 'flex', columnGap: 15, marginTop: 15 }}>
                        <FormControl
                            fullWidth
                            size="small"
                            className={
                                (watchAllFields.cutOffDurationDays &&
                                    watchAllFields.cutOffDurationDays !== '') ||
                                (watchAllFields.CutOffDurationHours &&
                                    watchAllFields.CutOffDurationHours !== '') ||
                                (watchAllFields.CutOffDurationMinutes &&
                                    watchAllFields.CutOffDurationMinutes !== '')
                                    ? 'nonValidateClass'
                                    : 'validateClass'
                            }
                        >
                            <Controller
                                fullWidth
                                size="small"
                                control={control}
                                name="cutOffDurationDays"
                                rules={{
                                    required:
                                        (watchAllFields.cutOffDurationDays &&
                                            watchAllFields.cutOffDurationDays !== '') ||
                                        (watchAllFields.CutOffDurationHours &&
                                            watchAllFields.CutOffDurationHours !== '') ||
                                        (watchAllFields.CutOffDurationMinutes &&
                                            watchAllFields.CutOffDurationMinutes !== '')
                                            ? false
                                            : true,
                                }}
                                render={({ field: { onChange, onBlur, value, name } }) => (
                                    <SelectMulti
                                        fullWidth
                                        size="small"
                                        values={daysValue}
                                        disabled={false}
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        value={value === undefined ? '' : value}
                                        // name={name || ''}
                                        nameForLablel="Days "
                                        name={'Day' || ''}
                                        error={
                                            (watchAllFields.cutOffDurationDays &&
                                                watchAllFields.cutOffDurationDays !== '') ||
                                            (watchAllFields.CutOffDurationHours &&
                                                watchAllFields.CutOffDurationHours !== '') ||
                                            (watchAllFields.CutOffDurationMinutes &&
                                                watchAllFields.CutOffDurationMinutes !== '')
                                                ? false
                                                : true
                                        }
                                        multi={false}
                                    />
                                )}
                            />
                        </FormControl>
                        <FormControl
                            fullWidth
                            size="small"
                            className={
                                (watchAllFields.cutOffDurationDays &&
                                    watchAllFields.cutOffDurationDays !== '') ||
                                (watchAllFields.CutOffDurationHours &&
                                    watchAllFields.CutOffDurationHours !== '') ||
                                (watchAllFields.CutOffDurationMinutes &&
                                    watchAllFields.CutOffDurationMinutes !== '')
                                    ? 'nonValidateClass'
                                    : 'validateClass'
                            }
                        >
                            <Controller
                                fullWidth
                                size="small"
                                control={control}
                                name="CutOffDurationHours"
                                rules={{
                                    required:
                                        (watchAllFields.cutOffDurationDays &&
                                            watchAllFields.cutOffDurationDays !== '') ||
                                        (watchAllFields.CutOffDurationHours &&
                                            watchAllFields.CutOffDurationHours !== '') ||
                                        (watchAllFields.CutOffDurationMinutes &&
                                            watchAllFields.CutOffDurationMinutes !== '')
                                            ? false
                                            : true,
                                }}
                                render={({ field: { onChange, onBlur, value, name } }) => (
                                    <SelectMulti
                                        fullWidth
                                        size="small"
                                        values={hoursValue}
                                        disabled={false}
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        value={value === undefined ? '' : value}
                                        // name={name || ''}
                                        nameForLablel="Hours "
                                        name={'Hours' || ''}
                                        error={
                                            (watchAllFields.cutOffDurationDays &&
                                                watchAllFields.cutOffDurationDays !== '') ||
                                            (watchAllFields.CutOffDurationHours &&
                                                watchAllFields.CutOffDurationHours !== '') ||
                                            (watchAllFields.CutOffDurationMinutes &&
                                                watchAllFields.CutOffDurationMinutes !== '')
                                                ? false
                                                : true
                                        }
                                        multi={false}
                                    />
                                )}
                            />
                        </FormControl>
                        <FormControl
                            fullWidth
                            size="small"
                            className={
                                (watchAllFields.cutOffDurationDays &&
                                    watchAllFields.cutOffDurationDays !== '') ||
                                (watchAllFields.CutOffDurationHours &&
                                    watchAllFields.CutOffDurationHours !== '') ||
                                (watchAllFields.CutOffDurationMinutes &&
                                    watchAllFields.CutOffDurationMinutes !== '')
                                    ? 'nonValidateClass'
                                    : 'validateClass'
                            }
                        >
                            <Controller
                                fullWidth
                                size="small"
                                control={control}
                                name="CutOffDurationMinutes"
                                rules={{
                                    required:
                                        (watchAllFields.cutOffDurationDays &&
                                            watchAllFields.cutOffDurationDays !== '') ||
                                        (watchAllFields.CutOffDurationHours &&
                                            watchAllFields.CutOffDurationHours !== '') ||
                                        (watchAllFields.CutOffDurationMinutes &&
                                            watchAllFields.CutOffDurationMinutes !== '')
                                            ? false
                                            : true,
                                }}
                                render={({ field: { onChange, onBlur, value, name } }) => (
                                    <SelectMulti
                                        fullWidth
                                        size="small"
                                        values={minuteValue}
                                        disabled={false}
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        value={value === undefined ? '' : value}
                                        // name={name || ''}
                                        nameForLablel="Minute "
                                        name={'Minute' || ''}
                                        error={
                                            (watchAllFields.cutOffDurationDays &&
                                                watchAllFields.cutOffDurationDays !== '') ||
                                            (watchAllFields.CutOffDurationHours &&
                                                watchAllFields.CutOffDurationHours !== '') ||
                                            (watchAllFields.CutOffDurationMinutes &&
                                                watchAllFields.CutOffDurationMinutes !== '')
                                                ? false
                                                : true
                                        }
                                        multi={false}
                                    />
                                )}
                            />
                        </FormControl>
                    </Box>

                    <Typography variant="h3" style={{ marginTop: 15, marginBottom: 10 }}>
                        SLA Action
                    </Typography>

                    <Box style={{ display: 'flex', columnGap: 15, marginTop: 15 }}>
                        <Box style={{ flex: 1 }}>
                            <FormControlLabel
                                fullWidth
                                control={
                                    <Checkbox
                                        checked={
                                            watchAllFields.changeTheStatusClaim !== undefined
                                                ? watchAllFields.changeTheStatusClaim
                                                : false
                                        }
                                        {...register('changeTheStatusClaim', {
                                            required: false,
                                        })}
                                    />
                                }
                                label="Change claim task status to"
                            />
                        </Box>
                        <Box style={{ flex: 2 }}>
                            {watchAllFields.changeTheStatusClaim ? (
                                <FormControl fullWidth size="small">
                                    <Controller
                                        fullWidth
                                        size="small"
                                        control={control}
                                        name="Taskstatus"
                                        rules={{
                                            required: watchAllFields.changeTheStatusClaim
                                                ? true
                                                : false,
                                        }}
                                        render={({ field: { onChange, onBlur, value, name } }) => (
                                            <SelectOption
                                                values={
                                                    statusResponse !== undefined
                                                        ? statusResponse?.Data?.filter((data)=>{return(data.ClaimTaskStatusCode===
                                                            'PEND_DISPATCH')}).map((e) => {
                                                              return {
                                                                  value: e['ClaimTaskStatusId'],
                                                                  label: e['ClaimTaskStatusName'],
                                                              };
                                                          })
                                                        : []
                                                }
                                                onChange={onChange}
                                                onBlur={onBlur}
                                                disabled={false}
                                                value={value === undefined ? '' : value}
                                                // name={name || ''}
                                                name={'Select status' || ''}
                                                error={errors.Taskstatus}
                                            />
                                        )}
                                    />
                                </FormControl>
                            ) : null}
                        </Box>
                    </Box>
                </div>
                <div className="d-flex" style={{ marginTop: 20, alignItems: 'flex-end' }}>
                    <Button
                        onClick={() => {
                            setIsOpen(false);
                            setValue('slaDefinition', '');
                            setValue('preferedDurationDays', '');
                            setValue('preferedDurationHours', '');
                            setValue('PreferedDurationMinutes', '');
                            setValue('cutOffDurationDays', '');
                            setValue('CutOffDurationHours', '');
                            setValue('CutOffDurationMinutes', '');

                            setValue('startingStatus', '');
                            setValue('endingStatus', '');

                            setValue('Taskstatus', '');
                            setValue('active', false);
                        }}
                        variant="outlined"
                        type="button"
                    >
                        Cancel
                    </Button>
                    <FormControlLabel
                        style={{ marginLeft: 'auto' }}
                        control={
                            <Checkbox
                                checked={
                                    watchAllFields.active !== undefined
                                        ? watchAllFields.active
                                        : false
                                }
                                {...register('active', { required: false })}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        }
                        label="Active"
                    />

                    <Button variant="contained" type="submit">
                        {response.isSubmitting ? <CircularProgress color="inherit" /> : 'Save'}
                    </Button>
                </div>
            </Box>
            {savedResponse.IsSuccess != undefined ? (
                <Alert
                    style={{ marginTop: 15 }}
                    severity={savedResponse.IsSuccess ? 'success' : 'error'}
                >
                    {savedResponse.Message}
                </Alert>
            ) : null}
        </>
    );
};

export default SlaForm;

SlaForm.propTypes = {
    setIsOpen: PropTypes.func,
    CarrierUniqueID: PropTypes.any.isRequired,
    carrierId: PropTypes.any.isRequired,
    setSLAIsLoad: PropTypes.func,
    idValue: PropTypes.func,
    setIdValue: PropTypes.func,
    statusResponse: PropTypes.any,
};
