export const GOOGLE_MAPS_API_KEY = 'AIzaSyBKDeDCyCAHKPru29FgkbODlVNwQGEzDPg';

export class AppInputTypes {
    static TextField = 'TEXT_FIELD';
    static TextArea = 'TEXT_AREA';
    static DropDown = 'DROP_DOWN';
    static MultiSelectDropDown = 'MULTI_SELECT_DROP_DOWN';
    static FromToDatePicker = 'FROM_TO_DATE_PICKER';
    static FileUpload = 'FILE_UPLOAD';
}

export class AppRegex {
    static Email =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
}

export class LovCodes {
    static PFDN = 'PFDN';
    static PFSK = 'PFSK';
    static PATW = 'PATW';
}

export class ExperienceCodes {
    static CMDA = 'CMDA';
    static CMDFA = 'CMDFA';
    static CMDFR = 'CMDFR';
    static INSDA = 'INSDA';
    static PTYFA = 'PTYFA';
    static NFIFA = 'NFIFA';
    static AUTOA = 'AUTOA';
}

export class DateFormats {
    static MM_DD_YY_WITH_SLASH = 'MM/DD/YY';
}
export const DEFAULT_MAP_POINT_RADIUS = 200;
export const MAP_MARKER_SELECT_COLOR = 'Blue';
