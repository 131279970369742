import React from 'react';
import PropTypes from 'prop-types';
import { Box, Divider, Grid, Tooltip, Typography } from '@mui/material';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import VisibilityIcon from '@mui/icons-material/Visibility';

const ProfileCard = (props) => {
    const {
        profileInfo,
        experiences: experienceList,
        onClickViewDetail,
        id,
        expanded,
        onDownloadPdf,
    } = props;
    const { firstName, lastName, pilotNumber, email, address, entryId } = profileInfo || {};
    const experiences = experienceList
        .filter(
            (exp, expIndex) =>
                (expanded ? true : expIndex < 4) &&
                exp?.questions?.some((x) => x.selectedOptions?.length > 0),
        )
        .map((exp, expIndex) => {
            return {
                ...exp,
                questions: expanded
                    ? exp?.questions
                    : exp?.questions?.filter((quest, questIndex) => questIndex < 4),
            };
        });

    return (
        <Box mb={1} mt={1} sx={styles.container}>
            <Grid item container md={11.6} p={1}>
                <Grid item md={2.3} p={1}>
                    <Grid md={12} item>
                        <Typography color={'text.primary'} variant="h3" fontWeight={600}>
                            {`${firstName} ${lastName}`}
                        </Typography>
                    </Grid>
                    <Grid item md={12}>
                        <Typography component={'p'}>{pilotNumber}</Typography>
                        <Typography component={'p'}>{email}</Typography>
                    </Grid>
                    <Grid md={12} item mt={1}>
                        <Typography component={'p'}>{address}</Typography>
                    </Grid>
                </Grid>
                <Grid item container md={9.7} p={1} spacing={1} columnSpacing={4}>
                    {experiences?.length &&
                        experiences.map((experience, experienceIndex) => {
                            return (
                                <Grid key={experienceIndex} item md={6}>
                                    <Typography
                                        color={'primary.main'}
                                        fontWeight={600}
                                        component={'h3'}
                                    >
                                        {experience?.label}
                                    </Typography>
                                    <Grid item container spacing={1} pt={1} columnSpacing={4}>
                                        {experience?.questions.map((question, questionIndex) => {
                                            return (
                                                <Grid key={questionIndex} md={6} item>
                                                    <Grid item>
                                                        <Tooltip
                                                            arrow
                                                            title={question?.selectedOptions?.toString()}
                                                            placement="top"
                                                        >
                                                            <Typography
                                                                color={'text.primary'}
                                                                component={'h4'}
                                                                fontWeight={600}
                                                                sx={{
                                                                    overflowWrap: 'break-word',
                                                                    clear: 'both',
                                                                    overflow: 'hidden',
                                                                    whiteSpace: 'nowrap',
                                                                    textOverflow: 'ellipsis',
                                                                }}
                                                            >
                                                                {question?.selectedOptions?.toString()}
                                                            </Typography>
                                                        </Tooltip>
                                                    </Grid>
                                                    <Grid item>
                                                        <Typography
                                                            color={'text.secondary'}
                                                            component={'p'}
                                                        >
                                                            {question?.label}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            );
                                        })}
                                    </Grid>
                                </Grid>
                            );
                        })}
                </Grid>
            </Grid>
            <Divider orientation="vertical" flexItem />
            <Grid
                item
                style={{ width: '50px' }}
                p={1}
                container
                direction={'column'}
                display="flex"
                alignItems="center"
            >
                <Grid sx={{ cursor: 'pointer' }} onClick={() => onDownloadPdf(entryId, email)} item>
                    <i className="iconPdf" style={{ marginTop: 10 }}></i>
                </Grid>
                <Grid onClick={() => onClickViewDetail(id)} sx={{ cursor: 'pointer' }} item>
                    <VisibilityIcon color={'disabled'} style={{ fontSize: 24, marginTop: 10 }} />
                </Grid>
            </Grid>
        </Box>
    );
};

ProfileCard.propTypes = {
    profileInfo: PropTypes.any,
    experiences: PropTypes.array,
    onClickViewDetail: PropTypes.func,
    expanded: PropTypes.bool,
    onDownloadPdf: PropTypes.func,
};

const styles = {
    container: {
        display: 'flex',
        alignItems: 'flex-start',
        width: '100%',
        border: (theme) => `1px solid ${theme.palette.divider}`,
        borderRadius: 1,
        bgcolor: 'background.paper',
        color: 'text.secondary',
    },
};

export default ProfileCard;
