import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { process } from '@progress/kendo-data-query';
import { Grid, GridColumn as Column, GridToolbar } from '@progress/kendo-react-grid';
import { columnsAuditTrail } from '../../components/kendoGrid/columns';
import { ColumnMenu } from '../../components/kendoGrid/customColumnMenu';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Box, Breadcrumbs, Typography, TextField, IconButton } from '@mui/material';
import { GridPDFExport } from '@progress/kendo-react-pdf';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import moment from 'moment';
import iconexcel from '../../images/file-excel.svg';
import iconpdf from '../../images/file-pdf-solid.svg';
import { useState } from 'react';
import { Cached } from '@mui/icons-material';

const REPLACE_REGEX = /\s/g;

const AuditTrailList = (props) => {
    const history = useNavigate();
    const createDataState = (dataState) => {
        return {
            result: process(props.filterList, dataState),
            dataState: dataState,
        };
    };

    const [AuditColumns, setAuditColumns] = React.useState(columnsAuditTrail);

    const [initialState, setInitialState] = React.useState(
        createDataState({
            take: 100,
            skip: 0,
        }),
    );
    const [result, setResult] = React.useState(initialState.result);

    const [searchValue, setSearchValue] = useState('');

    const _export = React.useRef(null);

    const excelExport = () => {
        if (_export.current !== null) {
            _export.current.save();
        }
    };

    let _pdfExport;

    const exportPDF = () => {
        _pdfExport.save(
            process(props.filterList, {
                take: props.filterList.length + 1,
                skip: 0,
                group: dataState.group,
                filter: dataState.filter,
            }).data,
        );
    };

    React.useEffect(() => {
        setInitialState(
            createDataState({
                take: 100,
                skip: 0,
            }),
        );
    }, [props.filterList]);

    React.useEffect(() => {
        setResult(initialState.result);
    }, [initialState]);

    const [dataState, setDataState] = React.useState(initialState.dataState);

    const dataStateChange = (event) => {
        let updatedState = createDataState(event.dataState);
        setResult(updatedState.result);
        setDataState(updatedState.dataState);
    };

    const onColumnsSubmit = (columnsState) => {
        setAuditColumns(columnsState);
    };

    // const getExcelFilterList = (filterList) => {
    //     return filterList.map((p) => {
    //         if (p.DateReceived)
    //             p.DateReceived = moment(p.DateReceived).format('MM-DD-YYYY hh:mm a');
    //         if (p.ScheduledOn) p.ScheduledOn = moment(p.ScheduledOn).format('MM-DD-YYYY hh:mm a');
    //         if (p.InspectedOn) p.InspectedOn = moment(p.InspectedOn).format('MM-DD-YYYY hh:mm a');
    //         if (p.ReservationStart)
    //             p.ReservationStart = moment(p.ReservationStart).format('MM-DD-YYYY hh:mm a');

    //         return p;
    //     });
    // };

    const exportGrid = (value) => {
        return (
            <Grid
                data={result}
                {...dataState}
                onDataStateChange={dataStateChange}
                sortable={true}
                pageable={{
                    buttonCount: 5,
                    info: true,
                    type: 'numeric',
                    pageSizes: [100, 200, 500, 1000],
                    previousNext: true,
                }}
                groupable={true}
                pageSize={100}
                style={{
                    // maxHeight: 'calc(100vh - 234px)',
                    maxHeight: 'calc(100vh - 193px)',
                    display: value ? 'none' : '',
                }}
            >
                {AuditColumns.map(
                    (column, idx) =>
                        column.show && (
                            <Column
                                key={idx}
                                field={column.field}
                                title={column.title}
                                filter={column.filter}
                                format={column.format}
                                columnMenu={(props) => (
                                    <ColumnMenu {...props} columns={AuditColumns} />
                                )}
                            />
                        ),
                )}
            </Grid>
        );
    };

    const hasSearchKeyExist = (messageItem) => {
        return (
            messageItem?.DateOn?.toLowerCase()
                .replace(REPLACE_REGEX, '')
                .includes(searchValue?.toLocaleLowerCase().replace(REPLACE_REGEX, '')) ||
            messageItem?.UserFullName?.toLowerCase()
                .replace(REPLACE_REGEX, '')
                .includes(searchValue?.toLocaleLowerCase().replace(REPLACE_REGEX, '')) ||
            messageItem?.APIMessage?.toLowerCase()
                .replace(REPLACE_REGEX, '')
                .includes(searchValue?.toLocaleLowerCase().replace(REPLACE_REGEX, '')) ||
            messageItem?.LogSource?.toLowerCase()
                .replace(REPLACE_REGEX, '')
                .includes(searchValue?.toLocaleLowerCase().replace(REPLACE_REGEX, ''))
        );
    };

    const filterListBySearch = (list = []) => {
        return list?.filter((messageItem) => hasSearchKeyExist(messageItem));
    };

    const filterGroup = (array, fn) => {
        return array.reduce((r, o) => {
            var children = filterGroup(o.items || [], fn);
            if (fn(o) || children.length)
                r.push(Object.assign({}, o, children.length && { children }));
            return r;
        }, []);
    };

    const getFilteredList = (data = { data: [], total: 0 }) => {
        let filteredData =
            data?.data?.length && data?.data[0]?.hasOwnProperty('LogID')
                ? filterListBySearch(data?.data)
                : filterGroup(data?.data ?? [], (messageItem) => hasSearchKeyExist(messageItem));
        return {
            data: filteredData,
            total: data?.total,
        };
    };

    return (
        <>
            <Box marginBottom={1}>
                <Typography className="title" variant="h1">
                    Audit Logs
                </Typography>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link
                        to={'/'}
                        underline="hover"
                        color="inherit"
                        style={{ textDecoration: 'none' }}
                    >
                        Home
                    </Link>
                    <Link
                        to={'/audittrail'}
                        underline="hover"
                        color="text.primary"
                        aria-current="page"
                        style={{ textDecoration: 'none' }}
                    >
                        Audit Logs
                    </Link>
                </Breadcrumbs>
            </Box>
            <div className="audit-trail-list-grid">
                <Grid
                    data={getFilteredList(result)}
                    {...dataState}
                    onDataStateChange={dataStateChange}
                    sortable={true}
                    pageable={{
                        buttonCount: 5,
                        info: true,
                        type: 'numeric',
                        pageSizes: [100, 200, 500, 1000],
                        previousNext: true,
                    }}
                    groupable={true}
                    pageSize={100}
                    style={{
                        maxHeight: 'calc(100vh - 193px)',
                    }}
                    total={result.total}
                >
                    <GridToolbar>
                        <div className="search-wrap">
                            <div style={{ paddingLeft: 3 }}>
                                <TextField
                                    required
                                    id="outlined-required"
                                    label=""
                                    defaultValue=""
                                    size="small"
                                    value={searchValue}
                                    onChange={(e) => setSearchValue(e.target.value)}
                                />
                            </div>
                            <IconButton
                                onClick={() => {
                                    props?.reload();
                                }}
                                className="clearIcon"
                                title="Clear All filter"
                            >
                                <Cached />
                            </IconButton>
                        </div>
                    </GridToolbar>
                    <GridToolbar>
                        {/* {handleSetPrintPermissions('Claims') ? ( */}
                        <div>
                            <button
                                title="Export to Excel"
                                className="icon-btn"
                                onClick={excelExport}
                            >
                                <img src={iconexcel} title="Export to Excel" />
                            </button>

                            <button className="icon-btn" onClick={exportPDF}>
                                <img src={iconpdf} title="Export to PDF" />
                            </button>
                        </div>
                        {/* ) : null} */}
                    </GridToolbar>
                    {AuditColumns.map(
                        (column, idx) =>
                            column.show && (
                                <Column
                                    key={idx}
                                    field={column.field}
                                    title={column.title}
                                    filter={column.filter}
                                    format={column.format}
                                    columnMenu={(props) => (
                                        <ColumnMenu {...props} columns={AuditColumns} />
                                    )}
                                />
                            ),
                    )}
                </Grid>
                <ExcelExport
                    fileName={`AuditLogsExport_${moment(new Date()).format('MM-DD-YYYY hh mm a')}`}
                    style={{ display: 'none' }}
                    data={
                        props.filterList
                            ? process(props.filterList, {
                                  take: props.filterList.length + 1,
                                  skip: 0,
                                  group: dataState.group,
                                  filter: dataState.filter,
                              }).data
                            : []
                    }
                    ref={_export}
                    groupable={true}
                    group={dataState.group}
                >
                    {exportGrid(true)}
                </ExcelExport>
                <GridPDFExport
                    fileName={`AuditLogsExport_${moment(new Date()).format('MM-DD-YYYY hh mm a')}`}
                    ref={(element) => {
                        _pdfExport = element;
                    }}
                    margin="1cm"
                >
                    {exportGrid(false)}
                </GridPDFExport>
            </div>
        </>
    );
};

export default AuditTrailList;
