import React from 'react';
import PropTypes from 'prop-types';
import { AppUrls } from '../../core/route.url';
import { Box, LinearProgress, Grid, Typography, Paper, Stack } from '@mui/material';
import { usePostData2 } from '../../hooks/use-post-data-2.hook';
import { Delete } from '@mui/icons-material/';
import Moment from 'moment';
const UserClaimHistoryActivityList = ({ filterList }) => {
    const dat = new Date();

    return (
        <>
            {filterList.map((history, index) => {
                return (
                    <Stack className="treelist" key={index}>
                        <box>
                            <Typography variant="h4">
                                {Moment(history.ActivityDate).format('MM-DD-YYYY')}
                                {Moment(history.ActivityDate).format('MM-DD-YYYY') ===
                                Moment(dat).format('MM-DD-YYYY')
                                    ? ` -Today`
                                    : null}
                            </Typography>

                            {history.ClaimActivitiesList.length > 0
                                ? history.ClaimActivitiesList.map((historyData, i) => {
                                      return (
                                          <Paper
                                              className="historyTab"
                                              key={i}
                                              style={{ boxShadow: 'none' }}
                                          >
                                              <Typography variant="caption">
                                                  {Moment(historyData.ActivityOn).format('hh:mm A')}
                                              </Typography>
                                              {/* <Delete /> */}
                                              <Grid container spacing={2} m={0}>
                                                  <Grid xs={2}>
                                                      <Typography
                                                          variant="h3"
                                                          color={'black'}
                                                          style={{
                                                              paddingTop: 0,
                                                              fontWeight: 600,
                                                              fontSize: '12px',
                                                          }}
                                                      >
                                                          {historyData.ClaimNumber}
                                                      </Typography>
                                                      {/* <Typography fontSize={12} color={'#9E9E9E'}>
                                                          {historyData.UserEmail}
                                                      </Typography> */}
                                                  </Grid>
                                                  <Grid xs={10}>
                                                      {/* <Typography style={{ paddingRight: 120 }}>
                                                          {historyData.ClaimNumber}
                                                      </Typography> */}
                                                      <Typography style={{ paddingRight: 120 }}>
                                                          {historyData.ActivityDetail}
                                                      </Typography>
                                                      <Typography className="update-status">
                                                          {historyData.ClaimActivityTypeName}
                                                      </Typography>
                                                  </Grid>
                                              </Grid>
                                          </Paper>
                                      );
                                  })
                                : null}
                        </box>

                        {/* <Paper className="historyTab">
                            <Typography variant="caption">1:36 PM</Typography>
                            <Delete />
                            <Grid container spacing={2} m={0}>
                                <Grid xs={4}>
                                    <Typography>Lottie H. Caffrey</Typography>
                                    <Typography>JoanneMHelms@pilotcat.com</Typography>
                                </Grid>
                                <Grid xs={6}>
                                    <Typography>
                                        Lorem Ipsum is simply dummy text of the printing and
                                        typesetting industry. Lorem Ipsum has been the industry's
                                        standard dummy text ever since the 1500s,{' '}
                                    </Typography>
                                </Grid>
                                <Grid xs={2}>
                                    <Typography>Image Uploaded</Typography>
                                </Grid>
                            </Grid>
                        </Paper> */}
                    </Stack>
                );
            })}
        </>
    );
};

export default UserClaimHistoryActivityList;

UserClaimHistoryActivityList.propTypes = {
    cliamProfileDetails: PropTypes.any.isRequired,
};
