import { usePostData2 } from '../../hooks/use-post-data-2.hook';
import { MapComponent } from '../../components/map/map.component';
import {
    Grid,
    Switch,
    FormControlLabel,
    Breadcrumbs,
    Typography,
    LinearProgress,
    TextField,
    Autocomplete,
} from '@mui/material/';
import { MapViewFilter } from './mapview-filter.component';
import Box from '@mui/material/Box';
import { useState, useEffect } from 'react';
import { MarkerType } from '../../utils/marker-util';
import { Link } from 'react-router-dom';
import { AssignComponent } from './assign.component';
export const MapViewPage = () => {
    const {
        submitData: reloadClaims,
        response: { isLoading, result, isError },
    } = usePostData2('MapView/GetMapViewClaims');

    const {
        submitData: reloadResource,
        response: {
            isLoading: isResourceLoading,
            result: resourceResult,
            isError: loadingResourceError,
        },
    } = usePostData2('MapView/GetResources');

    const passedFilters = JSON.parse(sessionStorage.getItem('claim_filter_values'));
    const [showResources, setShowResources] = useState(true);
    const [showClaims, setShowClaims] = useState(true);
    const [claimData, setClaimData] = useState();
    const [locateMapClaimData, setLocateMapClaimData] = useState();
    const [locateMapResourceData, setLocateMapResourceData] = useState();
    const [resourceData, setResourceData] = useState();
    const [mapCenter, setMapCenter] = useState();
    const [claimFilters, setClaimFilters] = useState(
        passedFilters
            ? {
                  ClaimNumber: '',
                  CarrierId: passedFilters.CarrierId,
                  ClaimStatusId: passedFilters.ClaimStatusId,
                  EventId: passedFilters.EventId,
                  LossType: passedFilters.LossType,
                  DateReceived: passedFilters.DateReceived,
              }
            : {
                  ClaimNumber: '',
                  CarrierId: '',
                  ClaimStatusId: '',
                  EventId: '',
                  LossType: '',
                  DateReceived: '',
              },
    );

    const [resourceFilters, setResourceFilters] = useState(
        passedFilters
            ? {
                  ResourceId: 0,
                  MinAssigned: 0,
                  MaxAssigned: 0,
                  Rating: '',
              }
            : {
                  ResourceId: 0,
                  MinAssigned: 0,
                  MaxAssigned: 0,
                  Rating: '',
              },
    );

    useEffect(() => {
        if (resourceFilters) {
            const copyFilters = { ...resourceFilters };
            Object.keys(copyFilters).forEach((key) => {
                if (copyFilters[key] === null || copyFilters[key] === '') {
                    delete copyFilters[key];
                }
            });
            reloadResource(copyFilters);
        }
    }, [resourceFilters]);

    useEffect(() => {
        onApplyFilters();
    }, [claimFilters]);

    useEffect(() => {
        if (result && result?.Data && resourceResult && resourceResult?.Data) {
            const resources = resourceResult.Data.map((resource) => {
                return createReaourceMapData(resource);
            });
            setResourceData(resources);

            const claims = result.Data.map((claim) => {
                return createClaimMapData(claim, false, false, resources);
            });

            setClaimData(claims);
            setLocateMapClaimData(null);
            setLocateMapResourceData(null);
            setMapCenter({ markerData: [...claims, ...resources] });
            console.log(67, result);
        }
    }, [result, resourceResult]);

    const selectedResource = resourceData?.find((element) => element.isSelected);
    const selectedClaims = claimData?.filter((element) => element.isSelected);

    const onApplyFilters = () => {
        refreshPage();
    };

    const refreshPage = () => {
        if (claimFilters) {
            const copyFilters = { ...claimFilters };
            Object.keys(copyFilters).forEach((key) => {
                if (copyFilters[key] === null || copyFilters[key] === '') {
                    delete copyFilters[key];
                }
            });
            reloadClaims(copyFilters);
        } else {
            reloadClaims({});
        }
    };

    const createClaimMapData = (claim, isSelected, showInfo = false, resources) => {
        return {
            id: claim.ClaimGuID,
            position: { lat: parseFloat(claim.LAT), lng: parseFloat(claim.LONG) },
            markerType: claim.AssignedToId
                ? MarkerType.CLAIM_ASSIGNED
                : MarkerType.CLAIM_UNASSIGNED,
            isSelected: isSelected,
            showInfo: showInfo,
            color: getClaimColor(claim, resources),
            dataType: 'Claim',
            data: { ...claim },
        };
    };

    const createReaourceMapData = (resource, isSelected = false, showInfo = false) => {
        return {
            id: resource.ResourceId,
            position: { lat: parseFloat(resource.LAT), lng: parseFloat(resource.LONG) },
            markerType: MarkerType.RESOURCE,
            isSelected: isSelected,
            showInfo: showInfo,
            color: {
                fillColor: generateRandomColor(),
                strokeColor: 'Black',
            },
            radius: resource.WorkRadius * 1609.34,
            dataType: 'Resource',
            data: { ...resource },
        };
    };

    const handleMarkerClick = (markerId, dataType) => {
        if (dataType === 'Claim') {
            toggleClaimSelection(markerId);
        }
        if (dataType === 'Resource') {
            toggleResourceSelection(markerId);
        }
    };

    const toggleResourceSelection = (id) => {
        claimData?.forEach((element) => {
            element.showInfo = false;
        });
        resourceData?.forEach((element) => {
            element.showInfo = false;
            element.isSelected = element.id === id && !element.isSelected;
        });
        setResourceData([...resourceData]);
    };

    const toggleClaimSelection = (id) => {
        resourceData?.forEach((element) => {
            element.showInfo = false;
        });
        claimData?.forEach((element) => {
            element.showInfo = false;
            if (element.id === id) element.isSelected = !element.isSelected;
        });
        setClaimData([...claimData]);
    };

    const handleLocateMapData = (data) => {
        if (data && data.dataType && data.id) {
            setMapCenter({
                markerData: { ...data },
            });

            setLocateMapResourceData(data.dataType === 'Resource' ? data : null);
            setLocateMapClaimData(data.dataType === 'Claim' ? data : null);
        } else {
            setLocateMapResourceData(null);
            setLocateMapClaimData(null);
        }
    };

    const handleToggleResourceClick = () => {
        setShowResources(!showResources);
    };

    const handleToggleClaimClick = () => {
        setShowClaims(!showClaims);
    };

    const getClaimColor = ({ AssignedToId, DateOfLoss }, resources) => {
        const assingtoResource = resources?.find((r) => r.data?.ResourceId === AssignedToId);
        const color = {
            fillColor: assingtoResource ? assingtoResource.color.fillColor : 'White',
        };

        const diffDays = getDateDiffInDays(new Date(DateOfLoss), new Date());

        if (diffDays > 30) {
            color.strokeColor = 'Red';
        } else if (diffDays > 15) {
            color.strokeColor = 'Orange';
        } else {
            color.strokeColor = 'Black';
        }
        return color;
    };

    const getDateDiffInDays = (date1, date2) => {
        const diffTime = Math.abs(new Date(date1) - new Date(date2));
        return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    };

    const generateRandomColor = () => {
        const maxVal = 0xffffff; // 16777215
        let randomNumber = Math.random() * maxVal;
        randomNumber = Math.floor(randomNumber);
        randomNumber = randomNumber.toString(16);
        const randColor = randomNumber.padStart(6, 0);
        return `#${randColor.toUpperCase()}`;
    };

    const getMapDataBasedOnVisibility = () => {
        if (showClaims && showResources) {
            return [...claimData, ...resourceData];
        } else if (showClaims) {
            return [...claimData];
        } else if (showResources) {
            return [...resourceData];
        } else {
            return [];
        }
    };

    return (
        <div className="main-content">
            <Grid container marginBottom={2}>
                <Grid item xs={6}>
                    <Typography variant="h1" className="title m-0">
                        Map View
                    </Typography>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link
                            to={'/'}
                            underline="hover"
                            color="inherit"
                            style={{ textDecoration: 'none' }}
                        >
                            Home
                        </Link>

                        <Link
                            to={'/mapview'}
                            underline="hover"
                            color="text.primary"
                            aria-current="page"
                            style={{ textDecoration: 'none' }}
                        >
                            Map view
                        </Link>
                    </Breadcrumbs>
                </Grid>
                <Grid item xs={6} style={{ textAlign: 'right' }}>
                    <FormControlLabel
                        value="Resources"
                        control={
                            <Switch
                                color="primary"
                                checked={showResources}
                                onChange={handleToggleResourceClick}
                            />
                        }
                        label="Resources"
                        labelPlacement="start"
                    />
                    <FormControlLabel
                        value="Claims"
                        control={
                            <Switch
                                color="primary"
                                checked={showClaims}
                                onChange={handleToggleClaimClick}
                            />
                        }
                        label="Claims"
                        labelPlacement="start"
                    />
                </Grid>
            </Grid>
            <Grid className="mapviewFilter">
                <MapViewFilter
                    currentFilters={claimFilters}
                    setClaimFilters={setClaimFilters}
                    onApplyFilters={onApplyFilters}
                />
            </Grid>

            {(isError || loadingResourceError) && (
                <Box sx={{ width: '100%' }}>Error Loading Data</Box>
            )}
            {(isLoading || isResourceLoading) && (
                <Box sx={{ width: '100%' }}>
                    <LinearProgress />
                </Box>
            )}
            {claimData && resourceData && (
                <Grid container spacing={2} className="mapviewContainer">
                    <Grid item xl={9} md={8}>
                        <Box className="mapTopfilter">
                            <Autocomplete
                                size="small"
                                options={resourceData}
                                value={locateMapResourceData}
                                getOptionLabel={(option) =>
                                    option.dataType === 'Resource' && option.data.ResourceName
                                }
                                renderInput={(params) => (
                                    <TextField
                                        size="small"
                                        {...params}
                                        label="Locate Resource"
                                        variant="outlined"
                                        fullWidth
                                    />
                                )}
                                onChange={(event, newValue) => handleLocateMapData(newValue)}
                            />
                            <Autocomplete
                                size="small"
                                value={locateMapClaimData}
                                options={claimData}
                                getOptionLabel={(option) =>
                                    option.dataType === 'Claim' && option.data.ClaimNumber
                                }
                                renderInput={(params) => (
                                    <TextField
                                        size="small"
                                        {...params}
                                        label="Locate Claim"
                                        variant="outlined"
                                        fullWidth
                                    />
                                )}
                                onChange={(event, newValue) => handleLocateMapData(newValue)}
                            />
                        </Box>
                        <MapComponent
                            mapData={getMapDataBasedOnVisibility()}
                            mapContainerStyle={{
                                width: '100%',
                                height: 'calc(100% - 70px)',
                            }}
                            centerPosition={mapCenter}
                            showClusters={true}
                            onMarkerClick={handleMarkerClick}
                        />
                    </Grid>
                    <Grid item xl={3} md={4}>
                        <AssignComponent
                            resource={selectedResource}
                            claims={selectedClaims}
                            toggleClaimSelection={toggleClaimSelection}
                            toggleResourceSelection={toggleResourceSelection}
                            locateMapData={handleLocateMapData}
                            refreshPage={refreshPage}
                        />
                    </Grid>
                </Grid>
            )}
        </div>
    );
};
