import PropTypes from 'prop-types';
import { Polyline } from '@react-google-maps/api';
import { getCirclePath } from '../../utils/map.util';

export const MapRadiusComponent = ({ center, radius, color }) => {
    const lineSymbol = {
        path: 'M 0,-1 0,1',
        strokeOpacity: 1,
        scale: 2,
    };

    if (!center || !radius || !color) {
        return null;
    }

    return (
        <Polyline
            options={{
                path: getCirclePath(center, radius),
                fillColor: 'white',
                strokeColor: color.fillColor,
                strokeOpacity: 0,
                fillOpacity: 1,
                icons: [
                    {
                        icon: lineSymbol,
                        offset: '0',
                        repeat: '10px',
                    },
                ],
            }}
        />
    );
};

MapRadiusComponent.propTypes = {
    center: PropTypes.shape({
        lat: PropTypes.number.isRequired,
        lng: PropTypes.number.isRequired,
    }).isRequired,
    radius: PropTypes.number.isRequired,
    color: PropTypes.shape({
        fillColor: PropTypes.string.isRequired,
    }),
};
