import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Alert, Box, Button, IconButton, FormControl, Typography } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import SelectOption from '../select-option';
import { AppUrls } from '../../core/route.url';
import { useLoadData } from '../../hooks/use-load-data.hook';
import { usePostData } from '../../hooks/use-post-data.hook';
import { useCurrentUser } from '../../hooks/user-current-user.hook';
import CircularProgress from '@mui/material/CircularProgress';
import { Save } from '@mui/icons-material/';

const TimeZoneSelect = ({ popOpen, popen }) => {
    const {
        register,
        handleSubmit,
        control,
        setValue,
        watch,
        formState: { errors },
    } = useForm();
    //
    const {
        isLoading: timeZoneLoading,
        result: timeZoneResult,
        isError: timeZoneError,
    } = useLoadData(AppUrls.GetTimeZone);

    const { submitData, response } = usePostData(AppUrls.SaveTimeZone, 'POST');

    const [savedResponse, setSaveResponse] = useState({});
    const [isLoad, setIsLoad] = useState(true);
    const currentUser = useCurrentUser();

    const watchFields = watch();

    useEffect(() => {
        if (watchFields.timeZones !== undefined) {
            popOpen(true);
        }
    }, [watchFields.timeZones]);

    useEffect(() => {
        if (
            timeZoneResult !== undefined &&
            currentUser.UserTimeZone !== null &&
            currentUser !== undefined &&
            popen &&
            isLoad &&
            currentUser.UserTimeZone !== ''
        ) {
            setIsLoad(false);
            setValue(
                'timeZones',
                timeZoneResult.Data?.filter((data, index) => {
                    return data.TimeZoneName === currentUser.UserTimeZone;
                })[0]?.TimeZoneId,
            );
        }
        // if (!popen) {
        //     setValue('timeZones', '');
        // }
    }, [timeZoneResult, popen]);

    useEffect(() => {
        if (response.result != undefined) {
            setSaveResponse(response.result);

            const timer = setTimeout(() => {
                setSaveResponse({});
            }, 3000);
            return () => clearTimeout(timer);
        }
    }, [response]);

    const onSubmit = async (data) => {
        submitData({
            UserId: currentUser.UserId,
            TimeZoneId: data.timeZones,
        });
    };

    return (
        <>
            <Box component="form" noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                {' '}
                <Typography variant="caption">Select time zone</Typography>
                <div className="gridoth-login">
                    <FormControl fullWidth variant="standard">
                        <Controller
                            control={control}
                            name="timeZones"
                            rules={{
                                required: false,
                            }}
                            render={({ field: { onChange, onBlur, value, name } }) => (
                                <SelectOption
                                    values={
                                        timeZoneResult !== undefined
                                            ? timeZoneResult.Data?.map((e) => {
                                                  return {
                                                      value: e['TimeZoneId'],
                                                      label: e['TimeZoneName'],
                                                  };
                                              })
                                            : []
                                    }
                                    disabled={false}
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    value={value === undefined ? '' : value}
                                    error={errors.CarrierId}
                                />
                            )}
                        />
                    </FormControl>
                    <Box>
                        <IconButton size="medium">
                            {response.isSubmitting ? (
                                <CircularProgress />
                            ) : (
                                <Save onClick={handleSubmit(onSubmit)} />
                            )}
                        </IconButton>
                    </Box>
                </div>
            </Box>
            {savedResponse.IsSuccess != undefined ? (
                <Alert
                    // style={{ marginTop: 15 }}
                    severity={savedResponse.IsSuccess ? 'success' : 'error'}
                >
                    {savedResponse.Message}
                </Alert>
            ) : null}
        </>
    );
};
export default TimeZoneSelect;

TimeZoneSelect.propTypes = {
    popOpen: PropTypes.func.isRequired,
    popen: PropTypes.bool,
};
