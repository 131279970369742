import React from 'react';
import { AppUrls } from '../../core/route.url';
import { usePostData } from '../../hooks/use-post-data.hook';
import { usePostData2 } from '../../hooks/use-post-data-2.hook';
import { useLocation } from 'react-router-dom';
import refreshIcon from '../../images/refresh.svg';
import carrierIcon from '../../images/carrier-icon.svg';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import {
    Stack,
    LinearProgress,
    Typography,
    Breadcrumbs,
    Grid,
    Box,
    Container,
    List,
    ListItem,
} from '@mui/material';
import moment from 'moment/moment';
import CarrierProfilePicView from './carrier-form-profile-picture';
import CarrierProfileTab from './carrier-profile-tab';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
const CarrierView = (props) => {
    const location = useLocation();

    const { submitData: submitDataForGet, response: getResponse } = usePostData(
        AppUrls.CarrierForGet,
        'POST',
    );
    const { submitData: submitDataForGetStatusCount, response: getResponseStatusCount } =
        usePostData(AppUrls.ClaimStatusWiseCountGet, 'POST');

    const { submitData: submitDataForGetClaimsCount, response: getResponseClaimsCount } =
        usePostData(AppUrls.ClaimscountForCarrier, 'POST');

    const [carrierdetails, setcarrierdetails] = React.useState([]);
    const [claimscountdetails, setclaimsCountdetails] = React.useState([]);
    const [ClaimStatusCountdetails, setClaimStatusCountdetails] = React.useState([]);
    const [isLoaddt, setIsLoaddt] = React.useState(false);
    const [isLoadcount, setIsLoadcount] = React.useState(false);
    const [isLoadClaimcount, setIsLoadClaimcount] = React.useState(false);
    const [fromDatevalue, setFromDateValue] = React.useState(moment(new Date()).subtract(7, 'd'));
    const [toDatevalue, setToDateValue] = React.useState(new Date());
    const [refreshedDate, setRefreshedDate] = React.useState(new Date());

    const [statusValue, setStatusValue] = React.useState(0);

    React.useEffect(() => {
        if (location.state.carrierId !== '') {
            setIsLoaddt(true);
            setIsLoadcount(true);
            setIsLoadClaimcount(true);
        }
    }, []);

    React.useEffect(() => {
        if (isLoaddt == true) {
            setIsLoaddt(false);
            submitDataForGet({
                CarrierName: '',
                CarrierUniqueId: location.state.carrierId,
            });
        }
    }, [isLoaddt]);

    React.useEffect(() => {
        if (getResponse.result != undefined) {
            setcarrierdetails(getResponse.result.Data);
        }
    }, [getResponse]);

    React.useEffect(() => {
        if (isLoadcount == true) {
            setIsLoadcount(false);
            submitDataForGetStatusCount({
                CarrierUniqueID: location.state.carrierId,
                DateFrom: fromDatevalue,
                DateTo: toDatevalue,
            });
        }
    }, [isLoadcount]);

    React.useEffect(() => {
        if (isLoadClaimcount == true) {
            setIsLoadClaimcount(false);
            submitDataForGetClaimsCount({
                CarrierUniqueID: location.state.carrierId,
                DateFrom: fromDatevalue,
                DateTo: toDatevalue,
            });
        }
    }, [isLoadClaimcount]);

    React.useEffect(() => {
        if (getResponseStatusCount.result != undefined) {
            setClaimStatusCountdetails(getResponseStatusCount.result.Data);
        }
    }, [getResponseStatusCount]);

    React.useEffect(() => {
        if (getResponseClaimsCount.result != undefined) {
            setclaimsCountdetails(getResponseClaimsCount.result.Data);
        }
    }, [getResponseClaimsCount]);

    React.useEffect(
        () => {
            setRefreshedDate(new Date());
        },
        [getResponseClaimsCount],
        [getResponseStatusCount],
    );

    const refreshClick = (statusId) => {
        setIsLoadcount(true);
        setIsLoadClaimcount(true);
    };

    const handleDateFromChange = (newValue) => {
        if (
            moment(moment(newValue).format('YYYY-MM-DD')).isSame(
                moment(toDatevalue).format('YYYY-MM-DD'),
            )
                ? false
                : !moment(toDatevalue).isAfter(newValue)
        ) {
            setFromDateValue(newValue);
            //setToDateValue(newValue);
        } else {
            setFromDateValue(newValue);
            setStatusValue(0);
            setIsLoadcount(true);
            setIsLoadClaimcount(true);
        }
    };
    const handleDateToChange = (newValue) => {
        if (
            moment(moment(fromDatevalue).format('YYYY-MM-DD')).isSame(
                moment(newValue).format('YYYY-MM-DD'),
            )
                ? false
                : !moment(fromDatevalue).isBefore(newValue)
        ) {
            setToDateValue(newValue);
        } else {
            setToDateValue(newValue);
            setStatusValue(0);
            setIsLoadcount(true);
            setIsLoadClaimcount(true);
        }
    };

    const reloadClaimClick = (statusId) => {
        setStatusValue(statusId);
    };

    return (
        <div className="main-content">
            {carrierdetails != undefined ? (
                <>
                    {/* carrier head start */}

                    <Grid container marginBottom={2}>
                        <Grid item xs={6}>
                            <Typography variant="h1" className="title m-0">
                                {carrierdetails.CarrierName}
                            </Typography>
                            <Breadcrumbs aria-label="breadcrumb">
                                <Link
                                    to={'/'}
                                    underline="hover"
                                    color="inherit"
                                    style={{ textDecoration: 'none' }}
                                >
                                    Home
                                </Link>
                                <Link
                                    to={'/carriers'}
                                    underline="hover"
                                    color="text.primary"
                                    aria-current="page"
                                    style={{ textDecoration: 'none' }}
                                >
                                    Carriers
                                </Link>
                            </Breadcrumbs>
                        </Grid>
                        <Grid item xs={6} sx={{ display: 'flex' }}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <Stack direction="row" sx={{ marginLeft: 'auto' }} spacing={2}>
                                    <DesktopDatePicker
                                        label="From"
                                        inputFormat="dd/MM/yyyy"
                                        value={fromDatevalue}
                                        onChange={handleDateFromChange}
                                        renderInput={(params) => (
                                            <TextField
                                                size="small"
                                                {...params}
                                                error={
                                                    moment(
                                                        moment(fromDatevalue).format('YYYY-MM-DD'),
                                                    ).isSame(
                                                        moment(toDatevalue).format('YYYY-MM-DD'),
                                                    )
                                                        ? false
                                                        : !moment(toDatevalue).isAfter(
                                                              fromDatevalue,
                                                          )
                                                }
                                            />
                                        )}
                                        maxDate={new Date()}
                                    />
                                    <DesktopDatePicker
                                        label="To"
                                        inputFormat="dd/MM/yyyy"
                                        value={toDatevalue}
                                        onChange={handleDateToChange}
                                        renderInput={(params) => (
                                            <TextField
                                                size="small"
                                                {...params}
                                                error={
                                                    moment(
                                                        moment(fromDatevalue).format('YYYY-MM-DD'),
                                                    ).isSame(
                                                        moment(toDatevalue).format('YYYY-MM-DD'),
                                                    )
                                                        ? false
                                                        : !moment(fromDatevalue).isBefore(
                                                              toDatevalue,
                                                          )
                                                }
                                            />
                                        )}
                                        // minDate={fromDatevalue}
                                    />
                                </Stack>
                            </LocalizationProvider>
                        </Grid>
                    </Grid>

                    {/* carrier head end */}

                    {/* carrier Profile start */}
                    <Container className="card carrier-profile" maxWidth="false">
                        <Box class="d-flex lastupdated">
                            <Typography component="div" class="ml-auto">
                                Last Refreshed On: {refreshedDate.toLocaleDateString()}{' '}
                                {refreshedDate.toLocaleTimeString()}
                                <a
                                    component="button"
                                    className="refresh-icon hover"
                                    onClick={() => refreshClick(0)}
                                >
                                    <img src={refreshIcon} alt="" />
                                </a>
                            </Typography>
                        </Box>

                        <Box class="grid-carrier-profile-stack1">
                            {/* Carrier Details Start  */}
                            <Box className="carrier-list-stack">
                                <Stack>
                                    {/*go here image */}
                                    <CarrierProfilePicView
                                        carrierdetails={carrierdetails}
                                    ></CarrierProfilePicView>
                                </Stack>
                                <Stack>
                                    <Typography variant="h3">
                                        {carrierdetails.CarrierName}
                                    </Typography>
                                    <Box className="light-green mb-1">
                                        <img src={carrierIcon} width="17" alt="" />
                                        <Typography variant="string"> Carrier</Typography>
                                    </Box>
                                    <List>
                                        <ListItem>
                                            XA ID: &nbsp;
                                            <Typography variant="string" className="text-bold">
                                                {carrierdetails.XACarrierId}
                                            </Typography>
                                        </ListItem>
                                        <ListItem className="phone">
                                            {carrierdetails.ContactPhone}
                                        </ListItem>
                                        <ListItem className="email">
                                            {carrierdetails.ContactEmail}
                                        </ListItem>
                                        <ListItem>
                                            Contact: &nbsp;
                                            <Typography variant="string" className="text-bold">
                                                {carrierdetails.ContactName}
                                            </Typography>
                                        </ListItem>
                                    </List>
                                </Stack>
                            </Box>
                            {/* Carrier Details End  */}

                            {/* Claims Count Start  */}
                            {claimscountdetails != undefined ? (
                                <>
                                    <Box className="border-blue">
                                        <a
                                            component="button"
                                            className="refresh-icon"
                                            onClick={() => refreshClick(0)}
                                        >
                                            <img src={refreshIcon} alt="" />
                                        </a>
                                        <Box
                                            onClick={() => reloadClaimClick(0)}
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'flex-end',
                                                width: '100%',
                                            }}
                                        >
                                            <Typography variant="h6" className="label-class">
                                                Total Received
                                            </Typography>
                                            <Typography variant="h1" sx={{ marginLeft: 'auto' }}>
                                                {claimscountdetails.TotalReceived}
                                            </Typography>
                                        </Box>
                                    </Box>

                                    <Box className="border-green">
                                        <a
                                            component="button"
                                            className="refresh-icon"
                                            onClick={() => refreshClick(0)}
                                        >
                                            <img src={refreshIcon} alt="" />
                                        </a>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'flex-end',
                                                width: '100%',
                                            }}
                                            onClick={() => reloadClaimClick(7)}
                                        >
                                            <Typography variant="h6" className="label-class">
                                                Total completed
                                            </Typography>
                                            <Typography sx={{ marginLeft: 'auto' }} variant="h1">
                                                {claimscountdetails.TotalCompleted}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </>
                            ) : (
                                <>
                                    <Box className="border-blue">
                                        <a
                                            component="button"
                                            className="refresh-icon"
                                            onClick={() => refreshClick(0)}
                                        >
                                            <img src={refreshIcon} alt="" />
                                        </a>
                                        <Box
                                            onClick={() => reloadClaimClick(0)}
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'flex-end',
                                                width: '100%',
                                            }}
                                        >
                                            <Typography variant="h6" className="label-class">
                                                Total Received
                                            </Typography>
                                            <Typography variant="h1" sx={{ marginLeft: 'auto' }}>
                                                0
                                            </Typography>
                                        </Box>
                                    </Box>

                                    <Box className="border-green">
                                        <a
                                            component="button"
                                            className="refresh-icon"
                                            onClick={() => refreshClick(0)}
                                        >
                                            <img src={refreshIcon} alt="" />
                                        </a>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'flex-end',
                                                width: '100%',
                                            }}
                                            onClick={() => reloadClaimClick(0)}
                                        >
                                            <Typography variant="h6" className="label-class">
                                                Total completed
                                            </Typography>
                                            <Typography sx={{ marginLeft: 'auto' }} variant="h1">
                                                0
                                            </Typography>
                                        </Box>
                                    </Box>
                                </>
                            )}
                            {/* Claims Count End */}
                        </Box>

                        {/* Claims Status count list start */}
                        <Grid container spacing={2}>
                            {ClaimStatusCountdetails != undefined ? (
                                ClaimStatusCountdetails.map((objcount) => (
                                    <>
                                        <Grid item xs={2} className="blocks">
                                            <Box className="grid-carrier">
                                                {/*  <Link
                                                    component="button"
                                                    onClick={() =>
                                                        refreshClick(objcount.ClaimStatusId)
                                                    }
                                                >
                                                    <img src={refreshIcon} alt="" />
                                                </Link>*/}

                                                <Typography
                                                    className="refresh-icon hover"
                                                    component="div"
                                                ></Typography>
                                                <Box
                                                    onClick={() =>
                                                        reloadClaimClick(objcount.ClaimStatusId)
                                                    }
                                                >
                                                    <Typography variant="h4">
                                                        {objcount.StatusCount}
                                                    </Typography>
                                                    <Typography variant="h6">
                                                        {objcount.ClaimStatusName}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Grid>
                                    </>
                                ))
                            ) : (
                                <>
                                    <LinearProgress />
                                </>
                            )}
                        </Grid>
                        {/* Claims Status Count list end  */}
                    </Container>

                    {/* carrier profile end */}

                    {/* Tabbed block */}
                    <Container className="card" maxWidth="false">
                        {/* Tabbed Claims/SLA container start */}
                        <CarrierProfileTab
                            CarrierUniqueID={carrierdetails.CarrierUniqueId}
                            fromDatevalue={fromDatevalue}
                            toDatevalue={toDatevalue}
                            statusValue={statusValue}
                            carrierId={carrierdetails.CarrierId}
                            setStatusValue={setStatusValue}
                        />
                        {/* Tabbed  Claims/SLA container end */}
                    </Container>
                </>
            ) : (
                <>
                    <LinearProgress />
                </>
            )}
        </div>
    );
};
export default CarrierView;

CarrierView.propTypes = {};
