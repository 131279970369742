import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { process } from '@progress/kendo-data-query';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { columnsNotification } from '../../components/kendoGrid/columns';
import { ColumnMenu } from '../../components/kendoGrid/customColumnMenu';
import { useNavigate } from 'react-router-dom';
import { Button, Modal, Box, Stack, Breadcrumbs, Typography } from '@mui/material';
import ManageNotification from './manage-notification';
import editIcon from '../../images/pencil.svg';
import deleteIcon from '../../images/trash.svg';
import { AppUrls } from '../../core/route.url';
import { usePostData } from '../../hooks/use-post-data.hook';
import PropTypes from 'prop-types';
import { CircularProgress, Backdrop } from '@mui/material';
import DeleteModal from '../../components/delete-modal';
import { Link } from 'react-router-dom';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    bgcolor: 'background.paper',
    boxShadow: 3,
    p: 3,
};

const NotificationList = ({ filterList, setIsLoadList }) => {
    const [isOpen, setIsOpen] = React.useState(false);
    const [notificationValues, setNotificationValues] = React.useState({
        TemplateGroupId: 0,
        carreierId: 0,
    });

    const [templateIdForDelete, setTemplateIdForDelete] = React.useState(0);
    const [propertyValues, setPropertyValues] = React.useState({ idValue: 0, name: '' });
    const [isDeleteOpen, setIsDeleteOpen] = React.useState(false);

    const { submitData: submitDataForDelete, response: responseForDelete } = usePostData(
        AppUrls.NotificationDelete + `?TemplateGroupId=${templateIdForDelete}`,
        'Delete',
    );

    const createDataState = (dataState) => {
        return {
            result: process(filterList, dataState),
            dataState: dataState,
        };
    };

    const [NotificationColumns, setAuditColumns] = React.useState(columnsNotification);

    const [initialState, setInitialState] = React.useState(
        createDataState({
            take: 100,
            skip: 0,
        }),
    );

    const [result, setResult] = React.useState(initialState.result);

    React.useEffect(() => {
        setInitialState(
            createDataState({
                take: 100,
                skip: 0,
            }),
        );
    }, [filterList]);

    React.useEffect(() => {
        setResult(initialState.result);
    }, [initialState]);

    React.useEffect(() => {
        if (responseForDelete.result !== undefined) {
            setIsLoadList(true);
        }
    }, [responseForDelete]);

    const [dataState, setDataState] = React.useState(initialState.dataState);

    const dataStateChange = (event) => {
        let updatedState = createDataState(event.dataState);
        setResult(updatedState.result);
        setDataState(updatedState.dataState);
    };

    const onColumnsSubmit = (columnsState) => {
        setAuditColumns(columnsState);
    };

    const handleClose = () => {
        setIsOpen(false);
        setNotificationValues({
            TemplateGroupId: 0,
            carrierId: 0,
        });
    };

    const onHandleDeleteClick = (id) => {
        submitDataForDelete();
        setTemplateIdForDelete(0);
    };

    const handleDeleteClick = (inspectionId, name) => {
        setPropertyValues({ idValue: inspectionId, name: name });

        setIsDeleteOpen(true);
    };

    const editCellForIsActive = (props) => {
        if (props.rowType === 'groupHeader') {
            return null;
        }
        return (
            <>
                <td className="k-command-cell">
                    {props.dataItem.IsActive ? 'Active' : 'Inactive'}
                </td>
            </>
        );
    };

    const editCell = (props) => {
        if (props.rowType === 'groupHeader') {
            return null;
        }
        return (
            <>
                <td className="k-command-cell">
                    <button
                        className="icon-btn grid-icon"
                        onClick={() => {
                            setNotificationValues({
                                TemplateGroupId: props.dataItem.TemplateGroupId,
                                carrierId: props.dataItem.CarrierId,
                            });
                            setIsOpen(true);
                        }}
                    >
                        <img src={editIcon} title="Edit" />
                    </button>

                    <button
                        className="icon-btn grid-icon"
                        onClick={() => {
                            setTemplateIdForDelete(props.dataItem.TemplateGroupId);
                            handleDeleteClick(
                                props.dataItem.TemplateGroupId,
                                props.dataItem.TemplateName,
                            );
                        }}
                    >
                        <img src={deleteIcon} title="Delete" />
                    </button>
                </td>
            </>
        );
    };

    return (
        <>
            <DeleteModal
                isDeleteOpen={isDeleteOpen}
                setIsDeleteOpen={setIsDeleteOpen}
                propertyValues={propertyValues}
                setPropertyValues={setPropertyValues}
                handleDelete={onHandleDeleteClick}
            />
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                // onClick={handleClose}
                open={responseForDelete.isSubmitting}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Box style={{ display: 'flex', alignItems: 'flex-end', marginBottom: 15 }}>
            <Box>
                    <Typography className="title" variant="h1">
                    Notification
                    </Typography>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link to={'/'}
                            underline="hover"
                            color="inherit"
                            style={{ textDecoration: 'none' }}>
                            Home
                        </Link>
                        <Link   to={'/notification'}
                            underline="hover"
                            color="text.primary"
                            aria-current="page"
                            style={{ textDecoration: 'none' }}>
                        Notification
                        </Link>
                    </Breadcrumbs>
                </Box>
                <Button
                        onClick={() => {
                            setIsOpen(true);
                            setNotificationValues({
                                TemplateGroupId: 0,
                                carrierId: 0,
                            });
                        }}
                        variant="contained"
                        className="ml-auto"
                        type="button"
                    >
                        Add New
                    </Button>
                </Box>


            <div>
                <Modal
                    open={isOpen}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Stack sx={style}>
                        <ManageNotification
                            notificationValues={notificationValues}
                            setIsOpen={setIsOpen}
                            setIsLoadList={setIsLoadList}
                            setNotificationValues={setNotificationValues}
                        />
                    </Stack>
                </Modal>
                <Grid
                    style={{
                        maxHeight: 'calc(100vh - 135px)',
                        minHeight: 'calc(100vh - 135px)',
                    }}
                    data={result}
                    {...dataState}
                    onDataStateChange={dataStateChange}
                    sortable={true}
                    pageable={{
                        buttonCount: 5,
                        info: true,
                        type: 'numeric',
                        pageSizes: [25, 50, 100, 200],
                        previousNext: true,
                    }}
                    groupable={true}
                    pageSize={10}
                >
                    {NotificationColumns.map(
                        (column, idx) =>
                            column.show &&
                            (column.field == 'optn' ? (
                                <Column
                                    width={100}
                                    key={idx}
                                    field={column.field}
                                    title={column.title}
                                    cell={editCell}
                                    filter={column.filter}
                                    columnMenu={(props) => (
                                        <ColumnMenu
                                            {...props}
                                            columns={NotificationColumns}
                                            onColumnsSubmit={onColumnsSubmit}
                                        />
                                    )}
                                />
                            ) : column.field == 'IsActive' ? (
                                <Column
                                    width={100}
                                    key={idx}
                                    field={column.field}
                                    title={column.title}
                                    cell={editCellForIsActive}
                                    filter={column.filter}
                                    columnMenu={(props) => (
                                        <ColumnMenu
                                            {...props}
                                            columns={NotificationColumns}
                                            onColumnsSubmit={onColumnsSubmit}
                                        />
                                    )}
                                />
                            ) : (
                                <Column
                                    key={idx}
                                    field={column.field}
                                    title={column.title}
                                    filter={column.filter}
                                    columnMenu={(props) => (
                                        <ColumnMenu
                                            {...props}
                                            columns={NotificationColumns}
                                            onColumnsSubmit={onColumnsSubmit}
                                        />
                                    )}
                                />
                            )),
                    )}
                </Grid>
            </div>
        </>
    );
};

export default NotificationList;
NotificationList.propTypes = {
    filterList: PropTypes.array.isRequired,
    setIsLoadList: PropTypes.any,
};
