import React from 'react';
import { usePostData } from '../../hooks/use-post-data.hook';
import { AppUrls } from '../../core/route.url';
import AssignmentListing from './assignmentTypeListing';
import { CircularProgress, Backdrop } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';

const AssignmentType = () => {
    const { submitData, response } = usePostData(AppUrls.AssignmentTypeForList, 'POST');
    const { submitData: submitDataForDelete, response: responseForDelete } = usePostData(
        AppUrls.AssignmentTypeForDelete,
        'Delete',
    );
    const [filterList, setFilterList] = React.useState([]);
    const [isLoadList, setIsLoadList] = React.useState(false);
    const [initialResult, setInitialResult] = React.useState([]);

    React.useEffect(() => {
        setIsLoadList(true);
    }, []);
    React.useEffect(() => {
        if (isLoadList) {
            setIsLoadList(false);
            submitData({
                PageNo: 1,
                PageSize: 10,
                SortDir: '',
                SortExp: '',
                StartDate: '2022-05-01T04:54:29.010Z',
                EndDate: '2022-06-08T04:54:29.010Z',
                AssignmentType: '',
                AssignmentDescription: '',
            });
        }
    }, [isLoadList, response.result, responseForDelete.result]);

    // React.useEffect(() => {
    //     if (response.result != undefined) {
    //         setFilterList(response.result.Data);
    //         setInitialResult(response.result.Data);
    //     }
    // }, [response]);

    React.useEffect(() => {
        if (response.result !== undefined ? (response.result.Data !== null ? 1 : 0) : 0) {
            if (response.result.Data.length > 0) {
                setFilterList(response.result.Data);
                setInitialResult(response.result.Data);
            } else {
                setFilterList([]);
                setInitialResult([]);
            }
        }
    }, [response]);

    React.useEffect(() => {
        if (responseForDelete.result != undefined) {
            if (responseForDelete.result.IsSuccess) {
                setIsLoadList(true);
            }
        }
    }, [responseForDelete]);

    const onHandleDeleteClick = (assignmentTypeId) => {
        submitDataForDelete({
            AssignmentTypeId: assignmentTypeId,
        });
    };

    const OnHandleChange = (searchterm) => {
        setFilterList(
            initialResult.filter((data) => {
                return (
                    (data.AssignmentType &&
                        data.AssignmentType.toLowerCase().includes(
                            searchterm.toLocaleLowerCase(),
                        )) ||
                    (data.AssignmentDescription &&
                        data.AssignmentDescription.toLowerCase().includes(
                            searchterm.toLocaleLowerCase(),
                        ))
                );
            }),
        );
    };

    return (
        <div className="main-content">
           
                <>
                    {filterList.length > 0 ? (
                        <>
                            <AssignmentListing
                                filterList={filterList}
                                setIsLoadList={setIsLoadList}
                                onHandleDeleteClick={onHandleDeleteClick}
                                OnHandleChange={OnHandleChange}
                            />
                            <Backdrop
                                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                                open={responseForDelete.isSubmitting}
                            >
                                <CircularProgress color="inherit" />
                            </Backdrop>
                        </>
                    ) : (
                        <>
                            <AssignmentListing
                                filterList={[]}
                                setIsLoadList={setIsLoadList}
                                onHandleDeleteClick={onHandleDeleteClick}
                                OnHandleChange={OnHandleChange}
                            />
                            <Backdrop
                                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                                open={responseForDelete.isSubmitting}
                            >
                                <CircularProgress color="inherit" />
                            </Backdrop>
                        </>
                    )}
                </>
            
        </div>
    );
};

export default AssignmentType;
