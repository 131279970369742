import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthorizeComponent } from '../../core/authorize.component';
import { Logout } from '../../hooks/use-storage';
import { useCurrentUser } from '../../hooks/user-current-user.hook';

export const UnauthorizedPage = () => {
    const user = useCurrentUser();
    const history = useNavigate();
    useEffect(() => {
        Logout('AUTH_STATE');
        Logout('LogOut_STATE');
        Logout('queary_data');
        Logout('report_name');

        window.location.href = '/';
    }, []);
    return (
        // <AuthorizeComponent>
        <div>You are unauthorized to view this page</div>
        // </AuthorizeComponent>
    );
};
