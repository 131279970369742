import React from 'react';
import PropTypes from 'prop-types';
import { InputAdornment, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';

const SearchInput = (props) => {
    return (
        <TextField
            id="input-with-icon-textfield"
            size="small"
            style={{ marginBottom: 10 }}
            fullWidth
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        {props?.value ? (
                            <ClearIcon onClick={props?.onClear} sx={{ cursor: 'pointer' }} />
                        ) : (
                            <SearchIcon style={{ fontSize: 18 }} />
                        )}
                    </InputAdornment>
                ),
            }}
            variant="outlined"
            onChange={props?.onChange}
            value={props?.value}
        />
    );
};

SearchInput.propTypes = {
    onChange: PropTypes.func,
    onClear: PropTypes.func,
    value: PropTypes.string,
};

export default SearchInput;
