import React from 'react';
import PropTypes from 'prop-types';
import { Button, Grid, MenuItem, Stack, TextField } from '@mui/material';
import { AppInputTypes } from '../utils/constants';
import Select, { components } from 'react-select';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';

const CustomTextField = (screensProps) => {
    const {
        inputType = AppInputTypes.TextField,
        options = [],
        multiSelectProps,
        fromToDatePickerProps,
        fullWidth,
        size,
        required,
        error,
        helperText,
        variant,
        value,
        label,
        InputLabelProps,
        InputProps,
        onChange,
        showTextFieldPropError = true,
        SelectProps,
    } = screensProps;

    let props = {
        fullWidth,
        size,
        required,
        error,
        helperText,
        variant,
        value,
        label,
        InputLabelProps,
        InputProps,
        onChange,
        SelectProps,
    };

    const MultiSelectOption = (selectOptionProps) => {
        return (
            <div>
                <components.Option {...selectOptionProps}>
                    <input
                        type="checkbox"
                        checked={selectOptionProps.isSelected}
                        onChange={() => null}
                    />{' '}
                    <label>{selectOptionProps?.label}</label>
                </components.Option>
            </div>
        );
    };

    return inputType === AppInputTypes.TextArea || inputType === AppInputTypes.TextField ? (
        <TextField
            {...props}
            fullWidth
            size="small"
            id="outlined-required"
            InputProps={{ ...{ style: { fontSize: 12 } }, ...props?.InputProps }}
            InputLabelProps={{ style: { fontSize: 12 }, ...props?.InputLabelProps }}
            multiline={inputType === AppInputTypes.TextArea}
            error={showTextFieldPropError ? !!error : null}
            helperText={showTextFieldPropError ? helperText : null}
            {...(inputType === AppInputTypes.TextArea && {
                minRows: 3,
                maxRows: 3,
            })}
        />
    ) : inputType === AppInputTypes.DropDown ? (
        <TextField
            {...props}
            select
            fullWidth
            size="small"
            id="outlined-required"
            InputProps={{ ...{ style: { fontSize: 12 } }, ...props?.InputProps }}
            InputLabelProps={{ ...{ ...{ style: { fontSize: 12 } } }, ...props?.InputLabelProps }}
            error={showTextFieldPropError ? !!error : null}
            helperText={showTextFieldPropError ? helperText : null}
        >
            <MenuItem value="">
                <em>None</em>
            </MenuItem>

            {options?.length
                ? options?.map((option, optionIndex) => {
                      return (
                          <MenuItem key={optionIndex} value={option}>
                              {option}
                          </MenuItem>
                      );
                  })
                : []}
        </TextField>
    ) : inputType === AppInputTypes.MultiSelectDropDown ? (
        <Select
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            allowSelectAll={true}
            className="multi-select"
            menuPortalTarget={document.body}
            styles={{
                control: (base) => ({
                    ...base,
                    borderColor: error ? '#d32f2f' : base?.borderColor,
                }),
                menuPortal: (base) => ({
                    ...base,
                    zIndex: 9999,
                }),
                multiValue: (provided) => {
                    return {
                        ...provided,
                        backgroundColor: '#fff',
                        borderRadius: 15,
                        border: 'solid',
                        borderWidth: 1,
                        borderColor: 'hsl(0, 0%, 80%)',
                    };
                },
                multiValueRemove: (provided) => {
                    return {
                        ...provided,
                        borderRadius: 15,
                    };
                },
            }}
            options={multiSelectProps?.options}
            onChange={multiSelectProps?.onChange}
            value={multiSelectProps?.value}
            components={{
                Option: MultiSelectOption,
            }}
            isMulti={true}
        />
    ) : inputType === AppInputTypes.FromToDatePicker ? (
        <>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Stack
                    direction="row"
                    sx={{
                        marginLeft: 'auto',
                    }}
                    spacing={1}
                >
                    <DesktopDatePicker
                        label="From"
                        onError={(error) => {
                            console.log('From date error', error);
                        }}
                        inputFormat="dd/MM/yyyy"
                        value={fromToDatePickerProps?.fromDate?.value}
                        onChange={fromToDatePickerProps?.fromDate?.onChange}
                        renderInput={(params) => (
                            <TextField fullWidth size="small" {...params} disabled />
                        )}
                    />
                    <DesktopDatePicker
                        label="To"
                        onError={(error) => {
                            console.log('To date error', error);
                        }}
                        inputFormat="dd/MM/yyyy"
                        value={fromToDatePickerProps?.toDate?.value}
                        onChange={fromToDatePickerProps?.toDate?.onChange}
                        renderInput={(params) => (
                            <TextField fullWidth size="small" {...params} disabled />
                        )}
                        disabled={fromToDatePickerProps?.toDate?.disabled}
                    />
                </Stack>
            </LocalizationProvider>
        </>
    ) : inputType === AppInputTypes.FileUpload ? (
        <TextField
            {...props}
            fullWidth
            size="small"
            disabled
            InputProps={{
                style: {
                    paddingRight: 0,
                    fontSize: 12,
                },
                endAdornment: (
                    <>
                        {screensProps?.fileUploadProps?.value ? (
                            <i
                                style={{
                                    cursor: 'pointer',
                                    marginTop: '13px',
                                }}
                                className="close-icon"
                                onClick={props?.fileUploadProps?.onClearInput}
                            />
                        ) : null}
                        <Button
                            size="small"
                            variant="contained"
                            component="label"
                            fullWidth
                            style={{
                                flex: 1,
                                alignItems: 'center',
                                height: '100%',
                            }}
                        >
                            <i className="attach-icon" />
                            <input
                                onChange={screensProps?.fileUploadProps?.onChange}
                                type="file"
                                hidden
                            />
                        </Button>
                    </>
                ),
            }}
            error={false}
            helperText={null}
        />
    ) : null;
};

CustomTextField.propTypes = {
    inputType: PropTypes.string,
    options: PropTypes.array,
    multiSelectProps: PropTypes.object,
    fromToDatePickerProps: PropTypes.object,
    fullWidth: PropTypes.bool,
    size: PropTypes.string,
    required: PropTypes.bool,
    error: PropTypes.any,
    helperText: PropTypes.string,
    variant: PropTypes.string,
    value: PropTypes.any,
    label: PropTypes.string,
    InputLabelProps: PropTypes.object,
    InputProps: PropTypes.object,
    onChange: PropTypes.func,
    showTextFieldPropError: PropTypes.bool,
    SelectProps: PropTypes.object,
};

export default CustomTextField;
